import * as React from "react";
import MapMYOBCustomerToNeto from "./MapMYOBCustomerToNeto";
import { CustomerMappingsMyobToNetoModel } from "../../../../shared/models/mappings/UserMappingModels";

export interface IMapCustomerProps {
    myobCustomerToNeto: CustomerMappingsMyobToNetoModel;
    setMyobCustomerToNeto: (value: CustomerMappingsMyobToNetoModel) => void;
    onInputBlurred: (obj: any) => void;
    isMappingsEmpty: boolean,
    errors: any;
    touched: any;
}

const CustomerMapping: React.FC<IMapCustomerProps> = (props) => {
    return (
        <div className="container-fluid">
            <MapMYOBCustomerToNeto
                myobCustomerToNeto={props.myobCustomerToNeto}
                setMyobCustomerToNeto={props.setMyobCustomerToNeto}
                isMappingsEmpty={props.isMappingsEmpty}
                errors={props.errors}
                touched={props.touched}
                onInputBlurred={props.onInputBlurred}
            />
        </div>
    );
};

export default CustomerMapping
