import * as React from 'react';
import { useEffect, useState, useContext } from 'react';

import firebase from '../../shared/config/firebaseConfig';
import { AppContext } from '../../store/AppContext';
import { createBrowserHistory } from "history";
import _ from 'lodash';
import { RMAListModel, SaleOrderViewModel } from './models/SaleOrderViewModels';
import { Button } from "primereact/button";
import Header from './details/Header';
import LineItem from './details/LineItem';
import { Convert,getRmaList } from './services/OrderService';
import { DATABASE_COLLECTIONS } from '../../shared/config/constants';
import './OrderDetailsContainer.css';
const { USER_DETAILS, CONNECTORS, ORDERS } = DATABASE_COLLECTIONS;

export interface ISaleOrderDetailProps {
    docId: string,
    selectedConnector: string,
    netoRMAId: string
}

const RMADetailsContainer: React.FC<ISaleOrderDetailProps> = (props) => {
    let { State } = useContext(AppContext);
    const history = createBrowserHistory();
    const [toggleViewModel, setToggleViewModel] = useState<number>(1);
    const [viewModel, setViewModel] = useState<SaleOrderViewModel>({} as SaleOrderViewModel);
    const [rmaViewModel, setRmaViewModel] = useState<any>({} as any);
    const [incomingOrderProps, setIncomingOrderProps] = useState({ OrderId: "", Status: "" });
    const [rmaList, setRmaList] = useState<Array<RMAListModel>>([]);

    useEffect(() => {
        const db = firebase.firestore();
        db.collection(`${USER_DETAILS}`).doc(State.currentUser.currentUserId)
            .collection(`${CONNECTORS}`).doc(props?.selectedConnector)
            .collection(`${ORDERS}`).doc(props?.docId).onSnapshot(snapshot => {
                const data:any = snapshot?.data();
                
                const rmaData = data?.RMA?.find((x: any) => {
                    if (x?.RmaIncoming?.RmaID === props?.netoRMAId) {
                        return x
                    }
                })
                // let netoOrder = Convert('neto', data?.Status,
                //     data?.Message, data?.WarningMessage, data?.Date,
                //     data) ?? {} as SaleOrderViewModel;

                // let myobOrder = Convert('myob', data?.Status,
                //     data?.Message, data?.WarningMessage, data?.Date,
                //     data) ?? {} as SaleOrderViewModel;

                let netoRMAData = Convert('netoRMA', data?.Status,
                    data?.Message, data?.WarningMessage, data?.Date,
                    rmaData,data?.IncomingOrder?.OrderID) ?? {} as any;

                let myobRMAData = Convert('myobRMA', data?.Status,
                    data?.Message, data?.WarningMessage, data?.Date,
                    rmaData,data?.OutgoingOrder?.Number) ?? {} as any;

                const rmaList = getRmaList(data?.RMA);
                // setIncomingOrderProps({ OrderId: netoOrder?.OrderNumber, Status: netoOrder?.OrderInternalStatus });
                setRmaViewModel(toggleViewModel === 2 ? myobRMAData : netoRMAData);
                setRmaList(rmaList);
            });
    }, [toggleViewModel]);

    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between mt-1">
                <div className="topnav mt-2">
                    <Button className={`${toggleViewModel && toggleViewModel === 1 ? "bg-dark text-white p-button-secondary p-button-sm " : "p-button-secondary p-button-sm  p-button-text p-button-plain"}`} onClick={() => setToggleViewModel(1)}>Maropost RMA</Button>
                    <Button className={`${toggleViewModel && toggleViewModel === 2 ? "bg-dark text-white p-button-secondary p-button-sm " : "p-button-secondary p-button-sm  p-button-text p-button-plain"}`} onClick={() => setToggleViewModel(2)}>Myob RMA</Button>
                </div>
                <Button icon="pi pi-angle-left" label="Go Back" className="p-button-text p-button-plain" onClick={() => history.goBack()} />
            </div>
            <br />
            <Header
                OrderID={rmaViewModel.RMANumber}
                NetoAndMyobIds={rmaViewModel?.NetoAndMyobOrderId}
                Username={rmaViewModel?.Customer?.UserName}
                OrderStatus={rmaViewModel.RMAStatus}
                OrderDate={rmaViewModel.RMADate}
                OrderInternalStatus={rmaViewModel?.OrderInternalStatus}
                ConnectorId={props?.selectedConnector}
                RMA={true}
                props={props}
            />
            <LineItem
                lineItems={rmaViewModel?.LineItems}
                totalLineItemAmount={viewModel?.TotalLineItemAmount}
                rmaLineItem={toggleViewModel}
            />
        </div>
    )
}

export default RMADetailsContainer
