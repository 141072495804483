import { IAppState } from "../../store/IAppState";
import { API_SERVER_URL, NETO_DOMAINS } from "../../shared/config/constants";
import _ from "lodash";
import moment from "moment";
import { writeInfo } from "../../shared/services/LoggingService";
import {
  deleteConnectorDataService,
  getConnectors,
  addUserConnector,
  getUserConnectors,
  syncMyobConnectivity,
  disconnectConnector,
  saveNetoConnectionConfig,
} from "../../dataServices/ConnectorDataService";
export const deleteConnectorService = async (docId: string, State: any) => {
  await deleteConnectorDataService(docId, State);
};

export const populateGridService = (userConnectors: any) => {
  let listOfGridModel: Array<any> = [];
  userConnectors &&
    userConnectors?.map((doc: any, index: number) => {
      let gridModel = {
        numberOfRows: index + 1,
        connector: {
          id: doc.id,
          name: doc.data()?.type ?? "",
          label: doc.data()?.label ?? "",
        },
        company: {
          targetCompany: doc.data()?.target ?? "",
          targetCompanyName: doc.data().myob?.company?.name ?? "",
          sourceCompany: doc.data()?.source ?? "",
          sourceCompanyName:
            /*State?.currentUser?.isAddonUser ?*/ doc.data().neto
              ?.oAuthCredentials?.store_domain ?? "",
          sourceCompanyNameTwo: doc.data().neto?.credentials?.storeDomain ?? "",
          sourceCompanyIsConnected: doc.data()?.neto?.isConnected,
          targetCompanyIsConnected: doc.data()?.myob?.isConnected,
          info: {
            WebhookApiKey: doc.data()?.neto?.credentials?.webhookApiKey ?? "",
            ApiKey: doc.data()?.neto?.credentials?.storeApiKey ?? "",
            StoreDomain: doc.data()?.neto?.credentials?.storeDomain ?? "",
            UserName: doc.data()?.neto?.credentials?.userName ?? "",
            WebhookUri: doc.data()?.neto?.credentials?.webhookUri ?? "",
          },
        },
        instruction: {
          enabled: doc.data()?.source === "Neto" ? false : true,
          url: doc.data()?.neto?.credentials?.webhookUri ?? "",
        },
      };
      listOfGridModel.push(gridModel);
    });

  return listOfGridModel;
};

export const disconnectConnectorService = async (
  currentUserId: string,
  // State: IAppState,
  source: string,
  docId: string,
  deleteMappings: boolean,
  sourceCompanyName: string,
  deAuthNeto: any,
  setDeAuthNeto: any,
  Dispatch: any
) => {
  //Dispatch({ Type: "ENABLE_APP_LOADING", Payload: true });
  let result: any = {};
  switch (source) {
    case "Maropost":
      // if (State.currentUser?.isAddonUser) {
      //   // setDeAuthNeto({...setDeAuthNeto, isLoading: true, isOpen: true });
      //   // let res = await Operation.deAuthNeto({ userId: State.currentUser.currentUserId, connectorId: docId, storeDomain: sourceCompanyName ?? '' })
      //   // if(res.isSuccess){
      //   //   setDeAuthNeto({...deAuthNeto, isLoading: false, url: res?.response?.result, isOpen: true });
      //   // }
      //   // else{
      //   //   setDeAuthNeto({...deAuthNeto, isLoading: false, url: '', error: res?.response?.result, isOpen: true });
      //   // }
      // } else {
      result = await disconnectConnector(
        //Operation.disconnectConnector(
        "neto",
        { credentials: {}, isConnected: false },
          /*State.currentUser.*/currentUserId,
        docId,
        deleteMappings
      );
      if (result?.isSucceed) {
        if (deleteMappings) {
          // Dispatch({ Type: "SET_USER_MAPPINGS", Payload: {} });
        }
        Dispatch({
          Type: "SET_REQUEST_STATUS",
          Payload: { isSucceed: true, message: result?.message },
        });
        await writeInfo(`Message: Neto Disconnected,  UserId: ${/*State.currentUser.*/currentUserId}, ConnectorId: ${docId}, Action-DateTime:${moment().format("DD MMM yyyy hh:mm A")}`);
      }
      else {
        Dispatch({
          Type: "SET_REQUEST_STATUS",
          Payload: { isSucceed: false, message: result?.message },
        });
      }
      // }
      //Dispatch({ Type: "DISABLE_APP_LOADING", Payload: false });
      break;
    case "Myob":
      result = await disconnectConnector(
        //Operation.disconnectConnector(
        "myob",
        {
          // company: { companyId: "", name: "" },
          // credentials: {},
          isConnected: false,
        },
        /*State.currentUser.*/currentUserId,
        docId,
        deleteMappings
      );
      if (result?.isSucceed) {
        if (deleteMappings) {
          // Dispatch({ Type: "SET_USER_MAPPINGS", Payload: {} });
        }
        Dispatch({
          Type: "SET_REQUEST_STATUS",
          Payload: { isSucceed: true, message: result?.message },
        });
        await writeInfo(`Message: Myob Disconnected , UserId: ${/*State.currentUser.*/currentUserId}, ConnectorId: ${docId}, Action-DateTime:${moment().format("DD MMM yyyy hh:mm A")}`);
      }
      else
        Dispatch({
          Type: "SET_REQUEST_STATUS",
          Payload: { isSucceed: false, message: result?.message },
        });

      //Dispatch({ Type: "DISABLE_APP_LOADING", Payload: false });
      break;
    default:
      console.log();
  }
};

export const savenetoConfigurationService = async (
  setIsNetoConfigSaving: React.Dispatch<React.SetStateAction<boolean>>,
  setDisableLoading: React.Dispatch<React.SetStateAction<boolean>>,
  netoConnection: {
    WebhookApiKey: string;
    ApiKey: string;
    StoreDomain: string;
    UserName: string;
    WebhookUri: string;
  },
  userConnectors: any,
  setNetoConfigError: React.Dispatch<React.SetStateAction<string | null>>,
  Dispatch: any,
  State: IAppState,
  currentDocId: string,
  setNetoModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  netoModalOpen: boolean
) => {
  setIsNetoConfigSaving(true);
  setDisableLoading(true);

  if (
    netoConnection &&
    netoConnection.ApiKey &&
    netoConnection.StoreDomain &&
    netoConnection.UserName
  ) {
    if (
      userConnectors?.some(
        (u: any) =>
          u.data()?.neto?.credentials?.storeDomain ===
          netoConnection.StoreDomain
      )
    ) {
      setIsNetoConfigSaving(false);
      setDisableLoading(false);
      setNetoConfigError(
        "Connector with the Same Store/Company & Source already added!"
      );
      Dispatch({
        Type: "SET_REQUEST_STATUS",
        Payload: {
          isSucceed: false,
          message:
            "Connector with the Same Store/Company & Source already added!",
        },
      });
    } else {
      const isCorrect = NETO_DOMAINS?.some(domain => netoConnection?.StoreDomain?.endsWith(domain))
      if (!isCorrect) {
        setNetoConfigError(
          "Store domain is incorrect or invalid format!, Please used correct domain"
        );
        setIsNetoConfigSaving(false);
        setDisableLoading(false);
        return;
      }

      if (netoConnection?.StoreDomain?.startsWith('https://')) {
        setNetoConfigError(
          "Store domain is incorrect or invalid format!, Please remove 'https://' from store domain"
        );
        setIsNetoConfigSaving(false);
        setDisableLoading(false);
        return;
      }

      const obj = {
        storeDomain: netoConnection.StoreDomain,
        storeApiKey: netoConnection.ApiKey,
        userName: netoConnection.UserName,
        webhookApiKey: netoConnection.WebhookApiKey,
        userId: State.currentUser.currentUserId,
        connectorId: currentDocId,
        webhookUri: API_SERVER_URL,
      };
      let webhookResponse = await saveNetoConnectionConfig(obj);

      if (webhookResponse && webhookResponse.isSuccess === false) {
        setIsNetoConfigSaving(false);
        setDisableLoading(false);
        setNetoConfigError(webhookResponse.response);
        Dispatch({
          Type: "SET_REQUEST_STATUS",
          Payload: { isSucceed: false, message: webhookResponse.response },
        });
      } else {
        setIsNetoConfigSaving(false);
        setDisableLoading(false);
        setNetoConfigError(null);
        Dispatch({
          Type: "SET_REQUEST_STATUS",
          Payload: {
            isSucceed: true,
            message: "Maropost Configuration saved Successfully",
          },
        });
        setNetoModalOpen(!netoModalOpen);
        await writeInfo(`Message: Neto Connected, UserId: ${State.currentUser.currentUserId}, ConnectorId: ${currentDocId}, Action-DateTime:${moment().format("DD MMM yyyy hh:mm A")}`);
      }
    }
  }
  else {
    setNetoConfigError("Validation Error: All values are required");
    Dispatch({
      Type: "SET_REQUEST_STATUS",
      Payload: {
        isSucceed: false,
        message: "Some error occurred while saving Maropost Configuration",
      },
    });
    setIsNetoConfigSaving(false);
    setDisableLoading(false);
  }
};

export const getAllConnectors = async () => {
  return await getConnectors();
};

export const createUserConnector = async (
  userId: string,
  connectorId: string
) => {
  return await addUserConnector(userId, connectorId);
};

export const getUserAllConnectors = async (userId: string) => {
  return await getUserConnectors(userId);
};

export const connectorsSnapshot = (userId: string) => {
  return syncMyobConnectivity(userId);
};
