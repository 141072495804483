import * as Yup from "yup";

const SalesOrderMappingSchema = Yup.object().shape({
    sendOrdersFromMYOBToNeto: Yup.boolean().optional(),
    sendPaymentsFromMYOBToNeto: Yup.boolean().optional(),
    orderCanceled: Yup.boolean().optional(),
    orderNumberPrefix: Yup.string().trim().optional(),
    roundingItem: Yup.string().trim().required('Rounding Item is required'),
    sendRma: Yup.boolean().optional(),
    isRmaSync: Yup.boolean().optional(),
    rmaSync: Yup.string().when('isRmaSync', {
        is: (val: boolean) => val === true,
        then: Yup.string().required("Required Payment Frequency"),
        otherwise: Yup.string().optional(),
    }),
    retreiveOrderWithStatuses: Yup.array().of(Yup.string()).when('sendOrdersFromMYOBToNeto', {
        is: (val: boolean) => val === true,
        then: Yup.array().of(Yup.string().required()).min(1).required("Required Retreive Order Status"),
        otherwise: Yup.array().of(Yup.string()).optional(),
    }),
    orderStatus: Yup.array().when('sendOrdersFromMYOBToNeto', {
        is: (val: boolean) => val === true,
        then: Yup.array().of(
            Yup.object().shape({
                myobSaleType: Yup.string().required(),
                netoStatus: Yup.string().required()
            }),
        ).min(1).required("Required Order Statuses"),
        otherwise: Yup.array().of(Yup.object()).optional(),
    }),
    splitkitComponentValue: Yup.boolean().optional(),
    isDiscountItemUsed: Yup.boolean().optional(),
    surchargeItem: Yup.string().required("Required Surcharge Items"),
    assignJobCode: Yup.string().optional(),
    defaultOnlineCustomerValue: Yup.string().optional(),
    stockWriteOffAccount: Yup.string().when('isRmaSync', {
        is: (val: boolean) => val === true,
        then: Yup.string().required("Required"),
        otherwise: Yup.string().optional(),
    }),
    invoiceDeliveryStatus: Yup.string().required("Required Invoice Delivery Status"),
    shippingItem: Yup.string().required("Required Shipping Item"),
    discountItem: Yup.string().when('isDiscountItemUsed', {
        is: (val: boolean) => val === true,
        then: Yup.string().required("Required itemLevelDiscount Item"),
        otherwise: Yup.string().optional(),
    }),
    autoInvoiceNumber: Yup.boolean().optional(),
    assignCustomer: Yup.string().required("Required Assign Customer"),
    defaultLineItemAccount: Yup.string().required("Required Default LineItem Account"),
    dispatchedOrderDate: Yup.string().required("Required Dispatched Order Date"),
    selectedPaymentMethodMYOBToNeto: Yup.string().when('sendPaymentsFromMYOBToNeto', {
        is: (val: boolean) => val === true,
        then: Yup.string().required("Selected Payment Method MYOB To Neto is Required"),
        otherwise: Yup.string().optional(),
    }),
    myobToNetoPaymentFrequency: Yup.string().when('sendPaymentsFromMYOBToNeto', {
        is: (val: boolean) => val === true,
        then: Yup.string().required("Required Myob To Neto Payment Frequency"),
        otherwise: Yup.string().optional(),
    })
});

const ProductMappingSchema = Yup.object().shape({
    isChecked: Yup.boolean().optional(),
    useIncomeAccount: Yup.string().when('isChecked', {
        is: (val: boolean) => val === true,
        then: Yup.string().required("Income Account is required"),
        otherwise: Yup.string().optional()
    }),
    useExpenseAccount: Yup.string().when('isChecked', {
        is: (val: boolean) => val === true,
        then: Yup.string().required("Expense Account is required"),
        otherwise: Yup.string().optional()
    }),
    useSellingTax: Yup.string().when('isChecked', {
        is: (val: boolean) => val === true,
        then: Yup.string().required("Selling Tax is required"),
        otherwise: Yup.string().optional()
    }),
    useBuyingTax: Yup.string().when('isChecked', {
        is: (val: boolean) => val === true,
        then: Yup.string().required("Buying Tax is required"),
        otherwise: Yup.string().optional()
    }),
    useInventorizedItems: Yup.string().when('isChecked', {
        is: (val: boolean) => val === true,
        then: Yup.string().required("Inventorized Items is required"),
        otherwise: Yup.string().optional()
    }),
    netoToMyobProduct: Yup.string().when('isChecked', {
        is: (val: boolean) => val === true,
        then: Yup.string().required("Neto To Myob Product is required"),
        otherwise: Yup.string().optional()
    }),

    useIncomeAccountForInventorizedItems: Yup.string().when('useInventorizedItems', {
        is: (val: string) => val === "InventorizedItems",
        then: Yup.string().when("isChecked", {
            is: (val: boolean) => val === true,
            then: Yup.string().required('Required'),
        }),
        otherwise: Yup.string().optional(),
    }),
    useCOGSAccount: Yup.string().when('useInventorizedItems', {
        is: (val: string) => val === "InventorizedItems",
        then: Yup.string().when("isChecked", {
            is: (val: boolean) => val === true,
            then: Yup.string().required('Required'),
        }),
        otherwise: Yup.string().optional(),
    }),
    useInventoryAssestAccount: Yup.string().when('useInventorizedItems', {
        is: (val: string) => val === "InventorizedItems",
        then: Yup.string().when("isChecked", {
            is: (val: boolean) => val === true,
            then: Yup.string().required('Required'),
        }),
        otherwise: Yup.string().optional(),
    }),
});

const ProductStockMappingSchema = Yup.object().shape({
    isChecked: Yup.boolean().optional(),
    isCheckedBox: Yup.boolean().optional(),

    stockQuantity: Yup.string().when('isChecked', {
        is: (val: boolean) => val === true,
        then: Yup.string().when("isCheckedBox", {
            is: (val: boolean) => val === true,
            then: Yup.string().optional(),
            otherwise: Yup.string().required()
        }),
        otherwise: Yup.string().optional()
    }),
    excludedFields: Yup.array().of(Yup.string()).optional(),
    defaultWarehouse: Yup.string().when('isChecked', {
        is: (val: boolean) => val === true,
        then: Yup.string().when("isCheckedBox", {
            is: (val: boolean) => val === true,
            then: Yup.string().optional(),
            otherwise: Yup.string().required()
        }),
        otherwise: Yup.string().optional()
    }),
    syncAs: Yup.string().when('isChecked', {
        is: (val: boolean) => val === true,
        then: Yup.string().required("Sync As is required"),
        otherwise: Yup.string().optional()
    }),
    isProductsPriceSync: Yup.boolean().optional(),
    myobToNetoProductFrequency: Yup.string().when('isChecked', {
        is: (val: boolean) => val === true,
        then: Yup.string().required("Product frequency is required"),
        otherwise: Yup.string().optional()
    })
});

const SynchronizationFeatureMappingSchema = Yup.object().shape({
    isChecked: Yup.boolean().optional(),
    accountTimezone: Yup.string().when("isChecked", {
        is: (val: boolean) => val === true,
        then: Yup.string().required("Required"),
        otherwise: Yup.string().optional()
    }),
    sendSyncReport: Yup.string().when("isChecked", {
        is: (val: boolean) => val === true,
        then: Yup.string().required("Required"),
        otherwise: Yup.string().optional()
    }),
    integrationsStartingDate: Yup.string().when("isChecked", {
        is: (val: boolean) => val === true,
        then: Yup.string().required("Required"),
        otherwise: Yup.string().optional()
    }),
});

const PaymentMappingSchema = Yup.object().shape({
    isChecked: Yup.boolean().optional(),
    depositPaymentInAccount: Yup.string().when("isChecked", {
        is: (val: boolean) => val === true,
        then: Yup.string().required("Required"),
        otherwise: Yup.string().optional()
    }),
    otherAccounts: Yup.array().optional(),
    // accCredit: Yup.string().optional(),
    netoToMyobPaymentSync: Yup.string().when("isChecked", {
        is: (val: boolean) => val === true,
        then: Yup.string().required("Required"),
        otherwise: Yup.string().optional()
    }),
});

const TaxCodeMappingSchema = Yup.object().shape({
    sellingTaxForCustomers: Yup.string().required("Required Selling Tax For New Customers"),
    noTaxExpense: Yup.string().required("Required No Tax Exp"),
    goodAndServiceTaxPercent: Yup.string().required("Required Good And Service Tax"),
});

const WarehouseMappingSchema = Yup.array().of(
    Yup.object().shape({
        warehouse: Yup.object().shape({
            label: Yup.string().required("Required Warehouse"),
            value: Yup.string().required("Required Warehouse")
        }),
        identifier: Yup.string().required("Required Warehouse"),
        selectedValue: Yup.string().required("Required Warehouse"),
        selectedName: Yup.string().required("Required Warehouse")
    }).required()
);

const CustomerMappingNetoToMyobSchema = Yup.object().shape({
    isChecked: Yup.boolean().optional()
});

const CustomerMappingMyobToNetoSchema = Yup.object().shape({
    isChecked: Yup.boolean().optional(),
    syncAs: Yup.string().when("isChecked", {
        is: (val: boolean) => val === true,
        then: Yup.string().required("Required"),
        otherwise: Yup.string().optional()
    }),
    myobToNetoCustomerFrequency: Yup.string().when("isChecked", {
        is: (val: boolean) => val === true,
        then: Yup.string().required("Required"),
        otherwise: Yup.string().optional()
    })
});

const ExistingDataMappingSchema = Yup.object().shape({
    orderType: Yup.string().when("isCheckedOrders", {
        is: (val: boolean) => val === true,
        then: Yup.string().required("Required"),
        otherwise: Yup.string().optional()
    }),
    orderStartingDate: Yup.string().when("isCheckedOrders", {
        is: (val: boolean) => val === true,
        then: Yup.string().required("Required"),
        otherwise: Yup.string().optional()
    }),
    orderIdentityPrefix: Yup.string().when("orderIdentity", {
        is: (val: string) => val === "OrderNumberWithPrefix",
        then: Yup.string().when("isCheckedOrders", {
            is: (val: boolean) => val === true,
            then: Yup.string().required('required')
        }),
        otherwise: Yup.string().optional()
    }),
    isCheckedCustomer: Yup.boolean().optional(),
    syncCustomersAs: Yup.string().when("isCheckedCustomer", {
        is: (val: boolean) => val === true,
        then: Yup.string().required("Required"),
        otherwise: Yup.string().optional()
    }),
    isCheckedOrders: Yup.boolean().optional(),
    orderIdentity: Yup.string().when("isCheckedOrders", {
        is: (val: boolean) => val === true,
        then: Yup.string().required("Order Identity is required"),
        otherwise: Yup.string().optional()
    }),
    isCheckedProducts: Yup.boolean().optional(),
    syncProductsAs: Yup.string().when("isCheckedProducts", {
        is: (val: boolean) => val === true,
        then: Yup.string().required("Required"),
        otherwise: Yup.string().optional()
    })
});

const MappingsNetoMyobSchema = Yup.object().shape({
    salesOrderMapping: SalesOrderMappingSchema.required(),
    productMapping: ProductMappingSchema.required(),
    productStockMapping: ProductStockMappingSchema.required(),
    synchronizationFeatures: SynchronizationFeatureMappingSchema.required(),
    paymentMapping: PaymentMappingSchema.required(),
    taxCodeMappings: TaxCodeMappingSchema.required(),
    // warehouseMappings: WarehouseMappingSchema.min(1).required(),
    netoCustomerToMyob: CustomerMappingNetoToMyobSchema.required(),
    myobCustomerToNeto: CustomerMappingMyobToNetoSchema.required(),
    mapExistingData: ExistingDataMappingSchema.required(),
});

export default MappingsNetoMyobSchema;