import * as React from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from "primereact/progressspinner";
import { Message } from "primereact/message";

export interface INetoConfigurationComponentProps {
    isOpen: boolean,
    onChange: (value: boolean) => void,
    netoConnection: any,
    setNetoConnection: (value: any) => void,
    saveNetoConfiguration: () => void,
    isLoading: boolean,
    alertMsg: string | null,
    disabledLoading: boolean
}

const NetoConfigurationComponent: React.FC<INetoConfigurationComponentProps> = (props) => {
    const renderFooter = () => {
        return (
            <div className="d-flex justify-content-between">
                <div>
                    {props.alertMsg && <Message className="text-wrap" severity="error" text={props.alertMsg} />}
                </div>
                <div>
                    {props.isLoading && <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="4" animationDuration=".5s" />}
                    <Button label="Save" className="p-button-raised p-button-primary" disabled={props.disabledLoading} onClick={() => props.saveNetoConfiguration()} />
                    <Button label="Cancel" className="p-button-raised p-button-danger" disabled={props.disabledLoading} onClick={() => props.onChange(!props.isOpen)} />
                </div>
            </div>
        );
    }

    return (
        <Dialog header="Maropost Configurations" visible={props.isOpen} onHide={() => { }} closable={false} draggable={false} breakpoints={{ '960px': '75vw' }} style={{ width: '40vw' }} footer={renderFooter()}>
            <div>
                <div className='form-group row mt-3'>
                    <label className='col-md-4 col-form-label'>User API Key</label>
                    <div className='col-md-8'>
                        <input
                            placeholder="Enter User API key"
                            type="text"
                            value={props.netoConnection.ApiKey}
                            onChange={(e) => props.setNetoConnection({ ...props.netoConnection, ApiKey: e.target.value.replace(/\s/g, '') })}
                            className="form-control"
                        />
                    </div>
                </div>
                <div className='form-group row'>
                    <label className='col-md-4 col-form-label'>Store Domain</label>
                    <div className='col-md-8'>
                        <input
                            placeholder="Example store.mymaropost.com.au or store.com.au"
                            type="text"
                            value={props.netoConnection.StoreDomain}
                            onChange={(e) => props.setNetoConnection({ ...props.netoConnection, StoreDomain: e.target.value.replace(/\s/g, '') })}
                            className="form-control"
                        />
                    </div>
                </div>
                <div className='form-group row'>
                    <label className='col-md-4 col-form-label'>User Name</label>
                    <div className='col-md-8'>
                        <input
                            placeholder="Enter User Name"
                            type="text"
                            value={props.netoConnection.UserName}
                            onChange={(e) => props.setNetoConnection({ ...props.netoConnection, UserName: e.target.value.replace(/\s/g, '') })}
                            className="form-control"
                        />
                    </div>
                </div>
                <div className='form-group row'>
                    <label className='col-md-4 col-form-label'>Webhook API Key</label>
                    <div className='col-md-8'>
                        <input
                            placeholder="Enter Webhook API key"
                            type="text"
                            value={props.netoConnection.WebhookApiKey}
                            onChange={(e) => props.setNetoConnection({ ...props.netoConnection, WebhookApiKey: e.target.value.replace(/\s/g, '') })}
                            className="form-control"
                        />
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default NetoConfigurationComponent
