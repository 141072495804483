import _ from 'lodash';
import { ValueLabel } from '../../../shared/models';
import { getAllUserConnectors } from '../../../dataServices';
import { QueryDocumentSnapshot, DocumentData } from "@firebase/firestore-types";
import { ProductEntityModel, ProductGridModel } from '../models';
import * as util from '../../../shared/Utilities';
import NetoProductModel from '../models/NetoProductModel';
import MyobProductModel from '../models/MyobProductModel';
import { getImportedProducts, getImportFailedProducts } from '../../../dataServices/ProductDataService';

export const getAllConnectorLabels = (
    currentUserId: string
  ): Promise<Array<ValueLabel>> => {
    return new Promise(async (resolve, reject) => {
      
        const model = await getAllUserConnectors(currentUserId);
        if (model.isSucceed && !_.isEmpty(model.result)) {
          const list = filterConnectorLabels(model.result);
          resolve(list);
        } else {
          resolve([]);
        }    
    });
};

export const getFailedImportProductsDataSnapshot = (userId: string, connector: string, limit: number, sortField: string, sortOrder: number, firstVisible?: any, lastVisible?: any, mode?: string, query?: any) => {
    return getImportFailedProducts(userId, connector, limit, sortField, sortOrder, firstVisible, lastVisible, mode, query);
}

export const getImportedProductsDataSnapshot = (userId: string, connector: string, limit: number, sortField: string, sortOrder: number, firstVisible?: any, lastVisible?: any, mode?: string, query?: any) => {
    return getImportedProducts(userId, connector, limit, sortField, sortOrder, firstVisible, lastVisible, mode, query);
}

export const convertProductEntityToGridModel = (userData: ProductEntityModel, index: number) => {
    let formattedDate = util.userTimezone(userData?.lastSyncAt);
    return {
        Number: (index + 1).toString(),
        LastSyncAt: formattedDate.toString(),
        lastSyncAtForFilter:  new Date(formattedDate),
        NetoProduct: transformNetoProductModel(userData?.neto),
        MyobProduct: transformMyobProductModel(userData?.myob),
    } as ProductGridModel
}

const transformNetoProductModel = (netoProduct: NetoProductModel) => {
    if(netoProduct){
        return {...netoProduct, IsInventoriedNeto: netoProduct?.Virtual === "True" ? "Non-Inventoried" : "Inventoried"};
    }

    return {} as NetoProductModel;
}

const transformMyobProductModel = (myobProduct: MyobProductModel) => {
    if(myobProduct){
        return {...myobProduct, IsInventoriedMyob: myobProduct?.IsInventoried === true ? "Inventoried" : "Non-Inventoried"};
    }

    return {} as MyobProductModel;
}

const filterConnectorLabels = (connectors?: Array<QueryDocumentSnapshot<DocumentData>>) => {
    const labels: Array<ValueLabel> = [];
    connectors?.map((doc: QueryDocumentSnapshot<DocumentData>) => {
        labels.push({ label: doc.data()?.label, value: doc.id });
    });
    return labels;
}