import React, { useEffect, useState } from "react";
import { ValueLabel } from '../shared/models';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';

export interface ISelectInputProps {
  options: Array<ValueLabel>,
  selectedOptionValue: string,
  onChange: (value: string) => void,
  onBlur?: (value: string) => void,
  name?: string,
  hasError?: boolean,
}

const SelectInput: React.FC<any> = (props) => {
  const [selectedOption, setSelectedOption] = useState<string>();
  const [options, setOptions] = useState<Array<ValueLabel>>(props.options ?? []);

  useEffect(() => {
    setOptions(props.options)
  }, [props.options]);

  useEffect(() => {
    setSelectedOption(props.selectedOptionValue ?? '');
  }, [props.selectedOptionValue, props.options]);

  const onChangeOption = (e: DropdownChangeParams) => {
    props.onChange(e.value);
  }

  return (
    <Dropdown
      {...props}
      value={selectedOption}
      onChange={onChangeOption}
      onBlur={(e: React.FocusEvent) => props.onBlur && props.onBlur(e.target.nodeValue ?? "")}
      options={props.options}
      filter
      className="p-dropdown p-dropdown-trigger"
      style={{ border: `${props?.hasError ? '1px solid red' : '1px solid hsl(0, 0%, 80%)'}`, backgroundColor: 'white', display: 'flex', textAlign: 'start' }}
    />
  )
}

export default SelectInput