import { updateSystemUsers, getAllSystemUsers } from "../../../dataServices/SystemUsersDataService";
import { UsersGridModel } from "../models/UsersGridModel";
import { UsersQueryRequestResponse } from "../models/UsersQueryRequestResponse";
import { UserUpdateRequestResponse } from "../models/UserUpdateRequestResponse";

export const updateUsers = async (data: UsersGridModel) => {
    const response = await updateSystemUsers(data);
    return response as UserUpdateRequestResponse;
}

export const queryAllSystemUsers = async (size?: number) => {
    const response = await getAllSystemUsers(size);
    return response as UsersQueryRequestResponse;
}