import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import Label from '../../../../components/Label';
import Input from '../../../../components/Input';
import SelectInput from '../../../../components/SelectInput';
import Boolean from '../../../../components/Boolean';
import Datepicker from '../../../../components/Datepicker';
import { AppContext } from '../../../../store/AppContext';
import { synchronizeExistingSystemCustomers, synchronizeExistingSystemOrders, synchronizeExistingSystemProducts } from '../services/MappingService';
import * as util from '../../../../shared/Utilities';
import { ExistingDataMappingsModel } from '../../../../shared/models/mappings/UserMappingModels';
import ExistingDataCustomersSynchronize from './ExistingData/ExistingDataCustomersSynchronize';
import ExistingDataOrdersSynchronize from './ExistingData/ExistingDataOrdersSynchronize';
import ExistingDataProductsSynchronize from './ExistingData/ExistingDataProductsSynchronize';

export interface IExistingDataProps {
    mapExistingData: ExistingDataMappingsModel;
    setExistingData: (value: ExistingDataMappingsModel) => void;
    onInputBlurred: (obj: any) => void;
    isMappingsEmpty: boolean;
    submitCount: any;
    touched: any;
    errors: any;
}

const ExistingData: React.FC<IExistingDataProps> = (props) => {
    let { State, Dispatch } = useContext(AppContext);
    const { id } = useParams<any>();
    const [existingDataMapping, setExistingDataMapping] = useState<ExistingDataMappingsModel>({} as ExistingDataMappingsModel);
    const [CustomersToggle, setCustomersToggle] = useState<boolean>(false);
    const [OrdersToggle, setOrdersToggle] = useState<boolean>(false);
    const [ProductsToggle, setProductsToggle] = useState<boolean>(false);
    const [syncExistingCustomersLoading, setSyncExistingCustomersLoading] = useState<boolean>(false);
    const [syncExistingOrdersLoading, setSyncExistingOrdersLoading] = useState<boolean>(false);
    const [syncExistingProductsLoading, setSyncExistingProductsLoading] = useState<boolean>(false);
    const [isCustomerTabActive, setIsCustomerTabActive] = useState<number | undefined>(undefined);
    const [isOrderTabActive, setIsOrderTabActive] = useState<number | undefined>(undefined);
    const [isProductTabActive, setIsProductTabActive] = useState<number | undefined>(undefined);


    const headerTemplate = (headingText: string, hasError: boolean, smallText?: string) => (<React.Fragment><div className="d-flex"><h6>{headingText} {smallText && <small>({smallText})</small>}</h6>
        {hasError && <small className="ml-3 mt-1 text-danger">(There are some validation errors)</small>}</div></React.Fragment>);

    const header = {
        sourceHeader: 'Maropost',
        targetHeader: 'MYOB AccountRight',
        header1: headerTemplate("Customers Synchronize", CustomersToggle),
        header2: headerTemplate("Orders Synchronize", OrdersToggle),
        header3: headerTemplate("Products Synchronize", ProductsToggle),
    };

    let [touchedFields, setTouchedFields] = useState(props.touched);

    useEffect(() => {
        setTouchedFields(props.touched)
    }, [props.touched])

    const onInputBlur = (touched: any) => {
        props.onInputBlurred(touched);
    }

    useEffect(() => {
        setExistingDataMapping(props?.mapExistingData ?? {} as ExistingDataMappingsModel);
    }, [props?.mapExistingData])

    useEffect(() => {
        //#region CustomersToggle
        if (props.submitCount > 0 && (props.touched?.mapExistingData?.syncCustomersAs && props.errors?.mapExistingData?.syncCustomersAs)
        ) {
            setCustomersToggle(true);
            setIsCustomerTabActive(0);
        }
        else{
            setCustomersToggle(false);
        }
        //#endregion
        //#region Oders Toggle
        if (props.submitCount > 0 && ((props.touched?.mapExistingData?.orderType && props.errors?.mapExistingData?.orderType)
            || (props.touched?.mapExistingData?.orderStartingDate && props.errors?.mapExistingData?.orderStartingDate))
        ) {
            setOrdersToggle(true);
            setIsOrderTabActive(0);
        }
        else{
            setOrdersToggle(false);
        }
        //#endregion
        //#region Products Toggle
        if (props.submitCount > 0 && (props.touched?.mapExistingData?.syncProductsAs && props.errors?.mapExistingData?.syncProductsAs)
        ) {
            setProductsToggle(true);
            setIsProductTabActive(0);
        }
        else{
            setProductsToggle(false);
        }
        //#endregion
    }, [props.touched?.mapExistingData, props.errors?.mapExistingData])

    const setCustomerMapping = () => {
        props.setExistingData(existingDataMapping);
    }

    const onSubComponentsFocusChanged = (value: ExistingDataMappingsModel) => {
        props.setExistingData(value);
    }

    const synExistingCustomers = async () => {
        setSyncExistingCustomersLoading(true);
        let result = await synchronizeExistingSystemCustomers(State?.currentUser?.currentUserId, id);
        if (result?.isSucceed) {
            Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: true, message: result?.message } });
            setSyncExistingCustomersLoading(false);
        }
        else {
            Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: false, message: result?.message } });
            setSyncExistingCustomersLoading(false);
        }
    }

    const synExistingOrders = async () => {
        setSyncExistingOrdersLoading(true);
        let result = await synchronizeExistingSystemOrders(State?.currentUser?.currentUserId, id);
        if (result?.isSucceed) {
            Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: true, message: result?.message } });
            setSyncExistingOrdersLoading(false);
        }
        else {
            Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: false, message: result?.message } });
            setSyncExistingOrdersLoading(false);
        }
    }

    const synExistingProducts = async () => {
        setSyncExistingProductsLoading(true);
        let result = await synchronizeExistingSystemProducts(State?.currentUser?.currentUserId, id);
        if (result?.isSucceed) {
            Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: true, message: result?.message } });
            setSyncExistingProductsLoading(false);
        }
        else {
            Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: false, message: result?.message } });
            setSyncExistingProductsLoading(false);
        }
    }

    return (
        <div className="container-fluid">
            <Accordion activeIndex={isCustomerTabActive}>
                <AccordionTab header={header.header1}>
                    <ExistingDataCustomersSynchronize 
                        touched={props.touched}
                        errors={props.errors}
                        existingDataMappings={existingDataMapping}
                        onInputBlur={onInputBlur}
                        onFocusChanged={onSubComponentsFocusChanged}
                        isMappingsEmpty={props.isMappingsEmpty}
                        synExistingCustomers={synExistingCustomers}
                        syncExistingCustomersLoading={syncExistingCustomersLoading}
                    />
                </AccordionTab>
            </Accordion>
            
            <Accordion activeIndex={isOrderTabActive}>
                <AccordionTab header={header.header2}>
                    <ExistingDataOrdersSynchronize 
                        touched={props.touched}
                        errors={props.errors}
                        existingDataMappings={existingDataMapping}
                        isMappingsEmpty={props.isMappingsEmpty}
                        onFocusChanged={onSubComponentsFocusChanged}
                        onInputBlur={onInputBlur}
                        synExistingOrders={synExistingOrders}
                        syncExistingOrdersLoading={syncExistingOrdersLoading}
                    />
                </AccordionTab>
            </Accordion>
            
            <Accordion activeIndex={isProductTabActive}>
                <AccordionTab header={header.header3}>
                    <ExistingDataProductsSynchronize 
                        touched={props.touched}
                        errors={props.errors}
                        existingDataMappings={existingDataMapping}
                        isMappingsEmpty={props.isMappingsEmpty}
                        onFocusChanged={onSubComponentsFocusChanged}
                        onInputBlur={onInputBlur}
                        synExistingProducts={synExistingProducts}
                        syncExistingProductsLoading={syncExistingProductsLoading}
                    />
                </AccordionTab>
            </Accordion>
        </div>
    )
}

export default ExistingData
