import * as React from 'react';
import { useState, useEffect } from 'react';
import _ from 'lodash';
import Label from '../../../../../components/Label';
import Boolean from '../../../../../components/Boolean';
import SelectInput from '../../../../../components/SelectInput';
import { SalesOrderMappingsModel } from "../../../../../shared/models/mappings/UserMappingModels";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as util from '../../../../../shared/Utilities';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { AppContext } from '../../../../../store/AppContext';

export interface INetoSalesOrderToMYOBInvoiceBasicPaymentOptionsProps {
    touched: any,
    errors: any,
    isMappingsEmpty: boolean,
    salesOrderBasicMappingsPaymentOptions: SalesOrderMappingsModel,
    syncMyobStockToNetoLoading: boolean,
    onInputBlur: (val: any) => void,
    syncMyobToNetoPayments: () => void,
    setSalesOrderBasicMappingPaymentOptions: (val: SalesOrderMappingsModel) => void,
    onFocusChanged: (value: SalesOrderMappingsModel) => void,
}

const NetoSalesOrderToMYOBInvoiceBasicPaymentOptions: React.FC<INetoSalesOrderToMYOBInvoiceBasicPaymentOptionsProps> = (props) => {
    let { State, Dispatch } = React.useContext(AppContext);
    const [paymentOptionsMapping, setPaymentOptionsMapping] = useState<SalesOrderMappingsModel>(props.salesOrderBasicMappingsPaymentOptions ?? {} as SalesOrderMappingsModel);

    useEffect(() => {
        setPaymentOptionsMapping(props.salesOrderBasicMappingsPaymentOptions ?? {} as SalesOrderMappingsModel);
    }, [props.salesOrderBasicMappingsPaymentOptions]);

    const onFocusChanged = () => {
        props.onFocusChanged(paymentOptionsMapping);
    }

    return (
        <React.Fragment>
            <div className="d-flex justify-content-between">
                <Boolean
                    hasError={
                        props.touched?.salesOrderMapping?.sendPaymentsFromMYOBToNeto &&
                            props.errors?.salesOrderMapping?.sendPaymentsFromMYOBToNeto ? true : false}
                    value={paymentOptionsMapping.sendPaymentsFromMYOBToNeto}
                    onChange={(val: boolean) => setPaymentOptionsMapping({ ...paymentOptionsMapping, sendPaymentsFromMYOBToNeto: val })}
                    text={"Send Payments from MYOB AccountRight to Maropost"}
                    onBlur={(e: any) => {
                        props.onInputBlur({
                            ...props.touched, salesOrderMapping: {
                                ...props.touched.salesOrderMapping, sendPaymentsFromMYOBToNeto: true
                            }
                        });
                        if (props.salesOrderBasicMappingsPaymentOptions.sendPaymentsFromMYOBToNeto !== paymentOptionsMapping.sendPaymentsFromMYOBToNeto)
                            onFocusChanged();
                    }}
                />
                <button disabled={props.isMappingsEmpty ? true : (!paymentOptionsMapping.sendPaymentsFromMYOBToNeto || props.syncMyobStockToNetoLoading)}
                    onClick={() => props.syncMyobToNetoPayments()} type="button" className="btn btn-dark btn-sm">Sync
                    <FontAwesomeIcon spin={props.syncMyobStockToNetoLoading} className="ml-1" icon={faSync} />
                </button>
            </div>
            <div className="row">
                <div className="col-6"></div>
                <div className="col-6 text-right">
                    {!_.isEmpty(props.salesOrderBasicMappingsPaymentOptions.lastRunAt) ?
                        <small className="text-muted"> <i className="pi pi-check-circle" style={{ 'fontSize': '1em' }}></i> {paymentOptionsMapping.message},{paymentOptionsMapping.lastRunAt && util.userTimezone(paymentOptionsMapping.lastRunAt)} </small> :
                        <small className="text-muted"> <i className="pi pi-clock" style={{ 'fontSize': '1em' }}></i> No Synchronized Data</small>
                    }
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-6 d-flex">
                    <Label text={"Myob To Maropost Payment"} /> <p className="text-danger">*</p>
                </div>
                <div className="col-6">
                    <SelectInput
                        hasError={
                            props.touched?.salesOrderMapping?.myobToNetoPaymentFrequency &&
                                props.errors?.salesOrderMapping?.myobToNetoPaymentFrequency ? true : false
                        }
                        options={State.formsLookup?.genericSchedulerFrequency?.values ?? []}
                        selectedOptionValue={paymentOptionsMapping.myobToNetoPaymentFrequency}
                        onChange={(val: string) => setPaymentOptionsMapping({ ...paymentOptionsMapping, myobToNetoPaymentFrequency: val })}
                        onBlur={(e: any) => {
                            props.onInputBlur({
                                ...props.touched, salesOrderMapping: {
                                    ...props.touched.salesOrderMapping, myobToNetoPaymentFrequency: true
                                }
                            });
                            if (props.salesOrderBasicMappingsPaymentOptions.myobToNetoPaymentFrequency !== paymentOptionsMapping.myobToNetoPaymentFrequency)
                                onFocusChanged();
                        }}
                    />
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-md-6 d-flex">
                    <Label text={'Default Payment Method'} /> <p className="text-danger">*</p>
                </div>
                <div className="col-md-6">
                    <SelectInput
                        hasError={
                            props.touched?.salesOrderMapping?.selectedPaymentMethodMYOBToNeto &&
                                props.errors?.salesOrderMapping?.selectedPaymentMethodMYOBToNeto ? true : false}
                        options={State.formsLookup?.retriveOrdersFromNeto?.paymentMapping?.paymentMethod?.values ?? []}
                        selectedOptionValue={paymentOptionsMapping.selectedPaymentMethodMYOBToNeto}
                        onChange={(val: string) => setPaymentOptionsMapping({ ...paymentOptionsMapping, selectedPaymentMethodMYOBToNeto: val })}
                        onBlur={() => {
                            props.onInputBlur({
                                ...props.touched, salesOrderMapping: {
                                    ...props.touched.salesOrderMapping, selectedPaymentMethodMYOBToNeto: true
                                }
                            });
                            if (props.salesOrderBasicMappingsPaymentOptions.selectedPaymentMethodMYOBToNeto !== paymentOptionsMapping.selectedPaymentMethodMYOBToNeto)
                                onFocusChanged();
                        }}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default NetoSalesOrderToMYOBInvoiceBasicPaymentOptions
