import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import Label from '../../../../../components/Label';
import Input from '../../../../../components/Input';
import SelectInput from '../../../../../components/SelectInput';
import Boolean from '../../../../../components/Boolean';
import Datepicker from '../../../../../components/Datepicker';
import { AppContext } from '../../../../../store/AppContext';
import { synchronizeExistingSystemCustomers, synchronizeExistingSystemOrders, synchronizeExistingSystemProducts } from '../../services/MappingService';
import * as util from '../../../../../shared/Utilities';
import { ExistingDataMappingsModel } from '../../../../../shared/models/mappings/UserMappingModels';

export interface IExistingDataOrdersSynchronizeProps {
    touched: any,
    errors: any,
    isMappingsEmpty: boolean,
    syncExistingOrdersLoading: boolean,
    synExistingOrders: () => void,
    existingDataMappings: ExistingDataMappingsModel,
    onInputBlur: (val: any) => void,
    onFocusChanged: (value: ExistingDataMappingsModel) => void,
}

const ExistingDataOrdersSynchronize: React.FC<IExistingDataOrdersSynchronizeProps> = (props) => {
    let { State, Dispatch } = useContext(AppContext);
    const [existingData, setExistingData] = useState<ExistingDataMappingsModel>(props.existingDataMappings ?? {} as ExistingDataMappingsModel);

    useEffect(() => {
        setExistingData(props.existingDataMappings);
    }, [props.existingDataMappings])

    const onFocusChanged = () => {
        props.onFocusChanged(existingData);
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col d-flex justify-content-between">
                    <Boolean
                        hasError={
                            props?.touched?.mapExistingData?.isCheckedOrders &&
                                props?.errors?.mapExistingData?.isCheckedOrders ? true : false
                        }
                        text={'Sync Orders in system'}
                        value={existingData.isCheckedOrders}
                        onChange={(val: boolean) => setExistingData({ ...existingData, isCheckedOrders: val })}
                        onBlur={(e: any) => {
                            props.onInputBlur({
                                ...props.touched, mapExistingData: {
                                    ...props.touched.mapExistingData, isCheckedOrders: true
                                }
                            });
                            if (props.existingDataMappings?.isCheckedOrders != existingData.isCheckedOrders)
                                onFocusChanged();
                        }}
                    />
                    <button disabled={props.isMappingsEmpty && props.isMappingsEmpty === true ? true : (!existingData.isCheckedOrders || props.syncExistingOrdersLoading)}
                        onClick={props.synExistingOrders}
                        type="button" className="btn btn-dark btn-sm">Sync
                        <FontAwesomeIcon spin={props.syncExistingOrdersLoading} className="ml-1" icon={faSync} />
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                </div>
                <div className="col-6 text-right">
                    {!_.isEmpty(existingData.orderLastRunAt) ?
                        <small className="text-muted"> <i className="pi pi-check-circle" style={{ 'fontSize': '1em' }}></i> {existingData.orderSyncMessage},{existingData.orderLastRunAt && util.userTimezone(existingData.orderLastRunAt)} </small> :
                        <small className="text-muted"> <i className="pi pi-clock" style={{ 'fontSize': '1em' }}></i> No Synchronized Data</small>
                    }
                </div>
            </div>
            <br />
            <div className="row">
                <div className='col-6 d-flex'>
                    <Label text={'Order Identity'} /> <p className="text-danger">*</p>
                </div>
                <div className="col-6">
                    <SelectInput
                        hasError={
                            props.touched?.mapExistingData?.orderIdentity &&
                                props.errors?.mapExistingData?.orderIdentity ? true : false}
                        options={State.formsLookup?.existingData?.orderIdentity?.values!}
                        selectedOptionValue={existingData.orderIdentity}
                        onChange={(val: string) => setExistingData({ ...existingData, orderIdentity: val })}
                        onBlur={(e: any) => {
                            props.onInputBlur({
                                ...props.touched, mapExistingData: {
                                    ...props.touched.mapExistingData, orderIdentity: true
                                }
                            });
                            if (props.existingDataMappings?.orderIdentity !== existingData.orderIdentity)
                                onFocusChanged();
                        }}
                    />

                </div>
            </div>
            {existingData.orderIdentity === "OrderNumberWithPrefix" &&
                <div className='row'>
                    <div className="col-6 mt-4">
                    </div>
                    <div className="col-6 mt-4">
                        <Input
                            hasError={
                                existingData.isCheckedOrders && props.touched?.mapExistingData?.orderIdentityPrefix &&
                                    props.errors?.mapExistingData?.orderIdentityPrefix ? true : false}
                            placeholder="Enter Order Number Prefix"
                            value={existingData.orderIdentityPrefix}
                            onChange={(val: string) => setExistingData({ ...existingData, orderIdentityPrefix: val })}
                            onBlur={(e: any) => {
                                props.onInputBlur({
                                    ...props.touched, mapExistingData: {
                                        ...props.touched.mapExistingData, orderIdentityPrefix: true
                                    }
                                });
                                if (props.existingDataMappings?.orderIdentityPrefix !== existingData.orderIdentityPrefix)
                                    onFocusChanged();
                            }}
                        />
                    </div>
                </div>
            }
            <br />
            <div className="row">
                <div className='col-6 d-flex'>
                    <Label text={'Order Type'} /> <p className="text-danger">*</p>
                </div>
                <div className="col-6">
                    <SelectInput
                        hasError={
                            props.touched?.mapExistingData?.orderType &&
                                props.errors?.mapExistingData?.orderType ? true : false}
                        options={State.formsLookup?.existingData?.orderType?.values!}
                        selectedOptionValue={existingData.orderType}
                        onChange={(val: string) => setExistingData({ ...existingData, orderType: val })}
                        onBlur={(e: any) => {
                            props.onInputBlur({
                                ...props.touched, mapExistingData: {
                                    ...props.touched.mapExistingData, orderType: true
                                }
                            });
                            if (props.existingDataMappings?.orderType !== existingData.orderType)
                                onFocusChanged();
                        }}
                    />

                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-6 d-flex">
                    <Label text={'Order Import Date'} /> <p className="text-danger">*</p>
                </div>
                <div className="col-6">
                    <Datepicker
                        hasError={
                            props.touched?.mapExistingData?.orderStartingDate &&
                                props.errors?.mapExistingData?.orderStartingDate ? true : false}
                        selectedDate={existingData.orderStartingDate}
                        onChange={(val: string) => setExistingData({ ...existingData, orderStartingDate: val })}
                        max={true}
                        onBlur={(e: any) => {
                            props.onInputBlur({
                                ...props.touched, mapExistingData: {
                                    ...props.touched.mapExistingData, orderStartingDate: true
                                }
                            });
                            if (props.existingDataMappings?.orderStartingDate !== existingData.orderStartingDate)
                                onFocusChanged();
                        }}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default ExistingDataOrdersSynchronize
