import * as React from "react";
import RMAGridContainer from "../features/Order/RMAGridContainer";
import "./SaleOrderGridPage.css"

const RMAGridPage: React.FC = () => {
  return (
    <RMAGridContainer />
  );
};

export default RMAGridPage;
