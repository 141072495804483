import * as React from 'react';
import { useState, useEffect } from 'react';
import _ from 'lodash';
import Label from '../../../../../components/Label';
import Boolean from '../../../../../components/Boolean';
import Input from '../../../../../components/Input';
import SelectInput from '../../../../../components/SelectInput';
import { SalesOrderMappingsModel } from "../../../../../shared/models/mappings/UserMappingModels";
import { AppContext } from '../../../../../store/AppContext';
import AsyncSelectInput from '../../../../../components/AsyncSelectInput';

export interface INetoSalesOrderToMYOBInvoiceBasicAdvanceOptionsProps {
    touched: any,
    errors: any,
    isMappingsEmpty: boolean,
    salesOrderBasicMappings: SalesOrderMappingsModel,
    storeName: string,
    onInputBlur: (val: any) => void,
    getUiMappingItemsFromServer: (val: string) => void,
    syncMyobToNetoPayments: () => void,
    setSalesOrderBasicMapping: (val: SalesOrderMappingsModel) => void,
    onFocusChanged: (value: SalesOrderMappingsModel) => void,
}

const NetoSalesOrderToMYOBInvoiceBasicAdvanceOptions: React.FC<INetoSalesOrderToMYOBInvoiceBasicAdvanceOptionsProps> = (props) => {
    let { State, Dispatch } = React.useContext(AppContext);
    const [advanceOptions, setAdvanceOptions] = useState<SalesOrderMappingsModel>(props.salesOrderBasicMappings ?? {} as SalesOrderMappingsModel);
    const [allOptionsForItemsUI, setAllOptionsForItemsUI] = useState<any>([]);

    useEffect(() => {
        setAdvanceOptions(props.salesOrderBasicMappings);
    }, [props.salesOrderBasicMappings]);

    const onFocusChanged = () => {
        props.onFocusChanged(advanceOptions);
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className='col-6 d-flex'>
                    <Label text={'Date for Dispatched Orders'} /> <p className="text-danger">*</p>
                </div>
                <div className="col-6 text-left">
                    <SelectInput
                        hasError={
                            props.touched?.salesOrderMapping?.dispatchedOrderDate &&
                                props.errors?.salesOrderMapping?.dispatchedOrderDate ? true : false}
                        options={State.formsLookup?.retriveOrdersFromNeto?.advanceOptions.dateForDispatchedOrders.values || []}
                        selectedOptionValue={advanceOptions.dispatchedOrderDate}
                        onChange={(value: string) => setAdvanceOptions({ ...advanceOptions, dispatchedOrderDate: value })}
                        onBlur={() => {
                            props.onInputBlur({
                                ...props.touched, salesOrderMapping: {
                                    ...props.touched.salesOrderMapping, dispatchedOrderDate: true
                                }
                            });
                            if (props.salesOrderBasicMappings?.dispatchedOrderDate !== advanceOptions.dispatchedOrderDate) {
                                onFocusChanged();
                            }
                        }}
                    />
                    <small className="text-muted">
                        Select which date to be applied to invoice when Maropost Order is Dispatched
                    </small>
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-6 d-flex">
                    <Label text={'Split kit component'} />
                </div>
                <div className="col-6 text-left">
                    <Boolean
                        text={''}
                        value={advanceOptions.splitkitComponentValue}
                        onChange={(value: boolean) => setAdvanceOptions({ ...advanceOptions, splitkitComponentValue: value })}
                        onBlur={(e: any) => {
                            props.onInputBlur({
                                ...props.touched, salesOrderMapping: {
                                    ...props.touched.salesOrderMapping, splitkitComponentValue: true
                                }
                            });
                            if (props.salesOrderBasicMappings?.splitkitComponentValue !== advanceOptions.splitkitComponentValue)
                                onFocusChanged();
                        }}
                    />
                    <small className="text-muted">
                        Bundles and kits will be split to their component products
                    </small>
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-6 d-flex">
                    <Label text={'Use Surcharge Item'} /> <p className="text-danger">*</p>
                </div>
                <div className="col-6 text-left">
                    <AsyncSelectInput
                        isAsync={true}
                        setAllOptionsForItemsUI={setAllOptionsForItemsUI}
                        allOptionsForItemsUI={allOptionsForItemsUI}
                        hasError={
                            props.touched?.salesOrderMapping?.surchargeItem &&
                                props.errors?.salesOrderMapping?.surchargeItem ? true : false}
                        options={State.formsLookup?.retriveOrdersFromNeto?.advanceOptions.surChargeItem.values ?? []}
                        selectedOptionValue={advanceOptions.surchargeItem}
                        onChange={(value: string) => setAdvanceOptions({ ...advanceOptions, surchargeItem: value })}
                        itemsFromServer={props.getUiMappingItemsFromServer}
                        onBlur={(e: any) => {
                            props.onInputBlur({
                                ...props.touched, salesOrderMapping: {
                                    ...props.touched.salesOrderMapping, surchargeItem: true
                                }
                            });
                            if (props.salesOrderBasicMappings?.surchargeItem !== advanceOptions.surchargeItem)
                                onFocusChanged();
                        }}
                    />
                    <small className="text-muted">
                        Select the product that will be used to represent surcharges
                    </small>
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-6 d-flex">
                    <Label text={'Use Shipping Item'} /> <p className="text-danger">*</p>
                </div>
                <div className="col-6 text-left">
                    <AsyncSelectInput
                        isAsync={true}
                        setAllOptionsForItemsUI={setAllOptionsForItemsUI}
                        allOptionsForItemsUI={allOptionsForItemsUI}
                        hasError={
                            props.touched?.salesOrderMapping?.shippingItem &&
                                props.errors?.salesOrderMapping?.shippingItem ? true : false}
                        options={State.formsLookup?.retriveOrdersFromNeto?.advanceOptions.shippingItem.values || []}
                        selectedOptionValue={advanceOptions.shippingItem}
                        itemsFromServer={props.getUiMappingItemsFromServer}
                        onChange={(value: string) => setAdvanceOptions({ ...advanceOptions, shippingItem: value })}
                        onBlur={(e: any) => {
                            props.onInputBlur({
                                ...props.touched, salesOrderMapping: {
                                    ...props.touched.salesOrderMapping, shippingItem: true
                                }
                            });
                            if (props.salesOrderBasicMappings?.shippingItem !== advanceOptions.shippingItem)
                                onFocusChanged();
                        }}
                    />
                    <small className="text-muted">
                        Select the product that will be used to represent shipping costs.
                    </small>
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-6 d-flex">
                    <Label text={State.formsLookup?.retriveOrdersFromNeto?.advanceOptions.roundingItem?.label ?? ""} /> <p className="text-danger">*</p>
                </div>
                <div className="col-6 text-left">
                    <AsyncSelectInput
                        isAsync={true}
                        setAllOptionsForItemsUI={setAllOptionsForItemsUI}
                        allOptionsForItemsUI={allOptionsForItemsUI}
                        hasError={
                            props.touched?.salesOrderMapping?.roundingItem &&
                                props.errors?.salesOrderMapping?.roundingItem ? true : false}
                        options={State.formsLookup?.retriveOrdersFromNeto?.advanceOptions?.roundingItem?.values ?? []}
                        selectedOptionValue={advanceOptions.roundingItem}
                        itemsFromServer={props.getUiMappingItemsFromServer}
                        onChange={(value: string) => setAdvanceOptions({ ...advanceOptions, roundingItem: value })}
                        onBlur={(e: any) => {
                            props.onInputBlur({
                                ...props.touched, salesOrderMapping: {
                                    ...props.touched.salesOrderMapping, roundingItem: true
                                }
                            });
                            if (props.salesOrderBasicMappings?.roundingItem !== advanceOptions.roundingItem)
                                onFocusChanged();
                        }}
                    />
                    <small className="text-muted">
                        This product will be used for adjustment of invoice/order total amount.
                    </small>
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-6 d-flex">
                    <Label text={'Export discounts as separate line item'} />
                </div>
                <div className="col-6 text-left">
                    <Boolean
                        text={''}
                        value={advanceOptions.isDiscountItemUsed}
                        onChange={(value: boolean) => setAdvanceOptions({ ...advanceOptions, isDiscountItemUsed: value })}
                        onBlur={(e: any) => {
                            props.onInputBlur({
                                ...props.touched, salesOrderMapping: {
                                    ...props.touched.salesOrderMapping, isDiscountItemUsed: true
                                }
                            });
                            if (props.salesOrderBasicMappings?.isDiscountItemUsed !== advanceOptions.isDiscountItemUsed)
                                onFocusChanged();
                        }}
                    />
                    {/* <small className="text-muted">
                        Discount will item used
                    </small> */}
                </div>
            </div>
            <br />
            {advanceOptions.isDiscountItemUsed &&
                <div className="row">
                    <div className="col-6 d-flex">
                        <Label text={State.formsLookup?.retriveOrdersFromNeto?.advanceOptions.discountItem?.label ?? ""} /> <p className="text-danger">*</p>
                    </div>
                    <div className="col-6 text-left">
                        <AsyncSelectInput
                            isAsync={true}
                            setAllOptionsForItemsUI={setAllOptionsForItemsUI}
                            allOptionsForItemsUI={allOptionsForItemsUI}
                            hasError={
                                props.touched?.salesOrderMapping?.discountItem &&
                                    props.errors?.salesOrderMapping?.discountItem ? true : false}
                            options={State.formsLookup?.retriveOrdersFromNeto?.advanceOptions?.discountItem?.values ?? []}
                            selectedOptionValue={advanceOptions.discountItem}
                            itemsFromServer={props.getUiMappingItemsFromServer}
                            onChange={(value: string) => setAdvanceOptions({ ...advanceOptions, discountItem: value })}
                            onBlur={(e: any) => {
                                props.onInputBlur({
                                    ...props.touched, salesOrderMapping: {
                                        ...props.touched.salesOrderMapping, discountItem: true
                                    }
                                });
                                if (props.salesOrderBasicMappings?.discountItem !== advanceOptions.discountItem)
                                    onFocusChanged();
                            }}
                        />
                        <small className="text-muted">
                            This product will be used as a discount of invoice/order in total amount.
                        </small>
                    </div>
                </div>
            }
            <br />

            <div className="row">
                <div className="col-6 d-flex">
                    <Label text={'Default Line Item Account'} /> <p className="text-danger">*</p>
                </div>
                <div className="col-6 text-left">
                    <SelectInput
                        hasError={
                            props.touched?.salesOrderMapping?.defaultLineItemAccount &&
                                props.errors?.salesOrderMapping?.defaultLineItemAccount ? true : false}
                        options={State.formsLookup?.retriveOrdersFromNeto?.advanceOptions.defaultLineItemAccount.values || []}
                        selectedOptionValue={advanceOptions.defaultLineItemAccount}
                        onChange={(value: string) => setAdvanceOptions({ ...advanceOptions, defaultLineItemAccount: value })}
                        onBlur={(e: any) => {
                            props.onInputBlur({
                                ...props.touched, salesOrderMapping: {
                                    ...props.touched.salesOrderMapping, defaultLineItemAccount: true
                                }
                            });
                            if (props.salesOrderBasicMappings?.defaultLineItemAccount !== advanceOptions.defaultLineItemAccount)
                                onFocusChanged();
                        }}
                    />
                    <small className="text-muted">
                        Account to be associated with line item if product does not have
                        an account
                    </small>
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-6 d-flex">
                    <Label text={'Assign them to the'} /> <p className="text-danger">*</p>
                </div>
                <div className="col-6 text-left">
                    <SelectInput
                        hasError={
                            props.touched?.salesOrderMapping?.assignCustomer &&
                                props.errors?.salesOrderMapping?.assignCustomer ? true : false
                        }
                        options={State.formsLookup?.retriveOrdersFromNeto?.advanceOptions.customers.values || []}
                        selectedOptionValue={advanceOptions.assignCustomer}
                        onChange={(value: string) => setAdvanceOptions({ ...advanceOptions, assignCustomer: value })}
                        onBlur={(e: any) => {
                            props.onInputBlur({
                                ...props.touched, salesOrderMapping: {
                                    ...props.touched.salesOrderMapping, assignCustomer: true
                                }
                            });
                            if (props.salesOrderBasicMappings?.assignCustomer !== advanceOptions.assignCustomer)
                                onFocusChanged();
                        }}
                    />
                    <small className="text-muted">
                        {advanceOptions.assignCustomer === "OriginalCustomer" ? "For Customer with no company name , are treated as individual user based on first/last in MYOB AccountRight." : ""}<br />
                    </small>
                </div>
            </div>
            <br />
            {advanceOptions.assignCustomer == "GenericOnlineSaleCustomer" && <div className="row">
                <div className="col-6 d-flex">
                    <Label text={'Default Online Customer'} /> {
                        advanceOptions.assignCustomer === "GenericOnlineSaleCustomer" ? <p className="text-danger">*</p> : ''
                    }
                </div>
                <div className="col-6 text-left">
                    <Input
                        hasError={
                            props.touched?.salesOrderMapping?.defaultOnlineCustomerValue &&
                                props.errors?.salesOrderMapping?.defaultOnlineCustomerValue ? true : false
                        }
                        maxLength={15}
                        onChange={(value: any) => setAdvanceOptions({ ...advanceOptions, defaultOnlineCustomerValue: value.replace(/[\"\'~`!@#$%^&()_={}[\]:;*,.<>+\/?-]+|\d+D|^\s+$/g, '') })}
                        value={advanceOptions.defaultOnlineCustomerValue}
                        readOnly={advanceOptions.assignCustomer !== "GenericOnlineSaleCustomer" ? true : false}
                        onBlur={(e: any) => {
                            props.onInputBlur({
                                ...props.touched, salesOrderMapping: {
                                    ...props.touched.salesOrderMapping, defaultOnlineCustomerValue: true
                                }
                            });
                            if (props.salesOrderBasicMappings?.defaultOnlineCustomerValue !== advanceOptions.defaultOnlineCustomerValue)
                                onFocusChanged();
                        }}
                    />
                    <small className="text-muted">
                        {advanceOptions.assignCustomer === "GenericOnlineSaleCustomer" ? "Max 15 chars, without spacing." : ""}<br />
                        This name is used to assign all sales to one Default online
                        customer. If left blank, all sales that will be synced will be
                        assigned to a customer named - {props?.storeName}
                    </small>
                </div>
            </div>}
            <br />
            <div className="row">
                <div className="col-6 d-flex">
                    <Label text={'Invoice Delivery Status'} /> <p className="text-danger">*</p>
                </div>
                <div className="col-6">
                    <SelectInput
                        hasError={
                            props.touched?.salesOrderMapping?.invoiceDeliveryStatus &&
                                props.errors?.salesOrderMapping?.invoiceDeliveryStatus ? true : false}
                        options={State.formsLookup?.retriveOrdersFromNeto?.advanceOptions.deliveryStatus.values ?? []}
                        selectedOptionValue={advanceOptions.invoiceDeliveryStatus}
                        onChange={(value: string) => setAdvanceOptions({ ...advanceOptions, invoiceDeliveryStatus: value })}
                        onBlur={(e: any) => {
                            props.onInputBlur({
                                ...props.touched, salesOrderMapping: {
                                    ...props.touched.salesOrderMapping, invoiceDeliveryStatus: true
                                }
                            });
                            if (props.salesOrderBasicMappings?.invoiceDeliveryStatus !== advanceOptions.invoiceDeliveryStatus)
                                onFocusChanged();
                        }}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default NetoSalesOrderToMYOBInvoiceBasicAdvanceOptions
