import { Card } from "primereact/card";
import React, { useContext } from "react";
import { AppContext } from "../store/AppContext";
import ChangePasswordComponent from "../features/membership/components/ChangePassword";
import GoogleIcon from "../assets/GoogleIcon.png"

const UserProfile: React.FC = () => {
  let { State } = useContext(AppContext);
  let { isSignInWithGoogle, currentUserEmail } = State.currentUser;

  const header = (
    <div className="p-3" style={{ backgroundColor: "#d3d3d3b8" }}>
      <h4>User Profile</h4>
    </div>
  );

  return (
    <div className="container my-5">
      <Card header={header} className="bg-light shadow">
        {!isSignInWithGoogle ? (
          <ChangePasswordComponent />
        ) : (
          <>
            <img
              className="img-fluid my-5"
              width="70px"
              src={GoogleIcon}
              alt="googleIcon"
            />
            <h5 className="text-capitalize">
              You are sign in with google account
            </h5>
            <p className="text-muted">{currentUserEmail}</p>
          </>
        )}
      </Card>
    </div>
  );
};

export default UserProfile;
