import { useReducer, useState } from "react";
import { FormsLookup } from '../shared/models/mappings/FormLookups';
import { UserMappingModel } from '../shared/models/mappings/UserMappingModels';
import { IAppState } from "./IAppState";

interface IAction{
    Type: string;
    Payload: any
}

const reducer = (state: IAppState, action: IAction): IAppState => {
  switch (action.Type) {
    case "SET_REQUEST_STATUS":
      let status = { ...action.Payload };
      return { ...state, requestStatus: status };

    case "SET_FORMSLOOKUP":
      let formsLookup: FormsLookup = { ...action.Payload };
      return { ...state, formsLookup: formsLookup };

    case "SET_USER_MAPPINGS":
      let mappings: UserMappingModel = { ...action.Payload };
      return { ...state, userMappings: mappings };

    case "ENABLE_APP_LOADING":
      return { ...state, isLoading: true };

    case "DISABLE_APP_LOADING":
      return { ...state, isLoading: false };

    case "SET_USER_MYOB_TO_NETO_MAPPINGS":
      let userMappings: any = { ...action.Payload };
      return { ...state, userMyobToNetoMappings: userMappings };

    case "IS_USER_LOGGED_IN":
      let loggedInUser: any = { ...action.Payload };
      return { ...state, currentUser: loggedInUser };

    case "IS_USER_LOGGED_OFF":
      let loggedOffUser: any = { ...action.Payload };
      return { ...state, currentUser: loggedOffUser };

    case "SET_SALES_ORDER":
      let salesOrders: any = { ...action.Payload };
      return { ...state, salesOrder: salesOrders };

    case "SET_SELECTED_CONNECTOR":
      let selectedConnector: any = { ...action.Payload };
      return { ...state, selectedConnectorOnGrid: selectedConnector.id };

    default:
      return state;
  }
};

export const useAppState=(initState:IAppState):[IAppState,(action:IAction)=>void]=>{    
    const[state, dispatch] = useReducer(reducer,initState);

    return [state, dispatch];    
}
 