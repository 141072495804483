import { writeLog } from '../../dataServices/DataService';
const LEVELS = {
  INFO: 'info',
  ERROR: 'error' 
}

export const writeInfo = async (message:string) => {
  return writeLog(LEVELS.INFO, message);
}

export const writeException = async (message:string) => {
  await writeLog(LEVELS.ERROR, message);
}