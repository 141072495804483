import * as React from "react";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import _ from "lodash";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from "primereact/button";
import { InputSwitch, InputSwitchChangeParams } from 'primereact/inputswitch';
import { UsersGridModel } from "./models/UsersGridModel";
import { updateUsers } from "./services/UserService";
import "./UsersGridContainer.css";
import { AppContext } from "../../store/AppContext";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import History from "../../shared/components/History";
import UserSchedulerStatue2 from "../../pages/userSchedulerStatue2";
import { Dialog } from "primereact/dialog";
// import { createBrowserHistory } from "history";

export interface IUsersGridContainerProps {
  users: Array<UsersGridModel>,
  loading: boolean,
  setLoading: (value: boolean) => void,
  reprocess: (value: boolean) => void
}

const UsersGridContainer: React.FC<IUsersGridContainerProps> = ({ users, loading, setLoading, reprocess }) => {
  let { State, Dispatch } = useContext(AppContext);
  const [allUsers, setAllUsers] = useState<Array<UsersGridModel>>(users);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [userSchedulerId, setUserSchedulerId] = useState();
  // const browserHistory = createBrowserHistory();
  const history = useHistory();
  const dt = useRef<DataTable>(null);
  const toast = useRef<Toast>(null);

  useEffect(() => {
    users && users.map((u: UsersGridModel) => {
      u.CreatedDate = moment(u.CreatedDate).format("DD MMM yyyy hh:mm A");
    });
    setAllUsers(users);
  }, [users]);

  const updateChange = async (userEmail: string, { disabled, isAddonUser, isActive }: any) => {
    let allUsersCopy = [...allUsers];
    if (userEmail) {
      const index = allUsersCopy.findIndex((x) => x.Email === userEmail);

      if (index < 0) {
        return;
      }

      if (!_.isNull(disabled)) {
        allUsersCopy[index].Disabled = disabled;
      }
      if (!_.isNull(isActive)) {
        allUsersCopy[index].IsApproved = isActive;
      }

      setLoading(true);
      const user = allUsersCopy[index];
      let res = await updateUsers(user);

      if (res?.isSucceed) {
        setLoading(false);
        setAllUsers(allUsersCopy);
        toast?.current?.show({ severity: 'success', summary: 'Message', detail: res?.message, contentClassName: '' });
      }
      else {
        setLoading(false);
        toast?.current?.show({ severity: 'error', summary: 'Message', detail: res?.message, contentClassName: '' });
      }
    }
  }

  const itemTemplate = (data: any) => {
    return (
      <div className="row align-baseline">
        <h6 className="ml-2"> {data.Name} </h6>
      </div>
    );
  };

  const loginAsOnClick = (rowData: any) => {
    Dispatch({
      Type: "IS_USER_LOGGED_IN",
      Payload: { ...State.currentUser, currentUserId: rowData?.id, isAddonUser: rowData?.IsAddonUser },
    });
    history.push("/connectors");
  }

  const headerTemplate = (
    <div className="d-flex justify-content-between">
      <h5 className="text-left">Users</h5>
      <div className="col-6 text-right">
        <Button label="Refresh" className={"p-button-raised p-button-secondary"} onClick={() => reprocess(true)} icon="pi pi-refresh" style={{ marginRight: '5px' }} />
        <Button label="Use Own Connectors" className={"p-button-raised p-button-secondary"} onClick={() => {
          localStorage.removeItem("SelectedIdByAdmin")
          History.push("/connectors")
        }} icon="pi pi-power-off" />
      </div>
    </div>
  );

  const verifiedEmailTemplate = (data: any) => (
    <div >
      {data.EmailVerified === true ? <i className="pi pi-check" style={{ 'fontSize': '1.5em', color: 'green' }}></i>
        : <i className="pi pi-ban" style={{ 'fontSize': '1.5em', color: 'red' }}></i>
      }
    </div>
  );

  const disabledTemplate = (data: any, index: any) => (
    <div >
      <React.Fragment>
        <InputSwitch checked={data?.Disabled} onChange={(e: InputSwitchChangeParams) => updateChange(data?.Email, { disabled: e.value, isAddonUser: null, isActive: null })} /*!data?.Disabled*/ />
      </React.Fragment>
    </div>
  );

  const addonTemplate = (data: any, index: any) => (
    <div >
      <React.Fragment>
        <InputSwitch checked={data?.IsAddonUser} disabled />
        {/* onChange={(e: InputSwitchChangeParams) => updateChange(data?.Email, { disabled: null, isAddonUser: e.value, isActive: null })} !data?.Disabled /> */}
      </React.Fragment>
    </div>
  );

  const activeTemplate = (data: any, index: any) => (
    <React.Fragment>
      <InputSwitch checked={data.IsApproved} onChange={(e: InputSwitchChangeParams) => updateChange(data?.Email, { disabled: null, isAddonUser: null, isActive: e.value })} />
    </React.Fragment>
  );

  const loginAsTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <Button
          label="Login As"
          onClick={() => loginAsOnClick(rowData)}
        />
      </React.Fragment>
    );
  };

  // const viewSchedulerTemplate = (rowData: any) => {
  //   return (
  //     <React.Fragment>
  //       <Button 
  //         label="Scheduler Status"
  //         className={"p-button-raised p-button-secondary"}
  //         onClick={() => {
  //           setUserSchedulerId(rowData)
  //           setIsOpen(true)
  //         }}
  //       />
  //     </React.Fragment>
  //   );
  // };



  return (
    <div className="container-fluid datascroller-demo">
      <div className="card mt-5">
        <DataTable
          header={headerTemplate}
          value={allUsers}
          lazy={loading}
          rowsPerPageOptions={[5, 10, 15, 20, 50]}
          ref={dt}
          loading={loading}
          rows={10}
          autoLayout
          paginator
          sortField="CreatedDate" sortOrder={-1}
          emptyMessage="No Records!"
          selectionMode="multiple"
          dataKey="id"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
          <Column style={{ width: '15rem' }} field="Name" header="Name" body={itemTemplate} sortable></Column>
          <Column style={{ width: '15rem' }} field="Email" header="Email" sortable filterMatchMode='contains' filter filterPlaceholder="Search By Email"></Column>
          <Column style={{ width: '10rem' }} field="EmailVerfied" header="Email Verfied" body={verifiedEmailTemplate}></Column>
          <Column style={{ width: '10rem' }} field="Disabled" header="Disabled" body={disabledTemplate}></Column>
          <Column style={{ width: '10rem' }} field="IsApproved" header="Approved" body={activeTemplate}></Column>
          <Column style={{ width: '10rem' }} field="IsAddonUser" header="Addon User" body={addonTemplate}></Column>
          <Column style={{ width: '10rem' }} field="CreatedDate" header="Created Date" filterMatchMode='contains' filter filterPlaceholder="Search By Date"></Column>
          {/* <Column style={{ width: '10rem' }} field="Scheduler Status" header="Scheduler Status" body={viewSchedulerTemplate}></Column> */}
          <Column style={{ width: '10rem' }} field="loginAs" header="Login As" sortable body={loginAsTemplate}></Column>
        </DataTable>
        <Toast ref={toast} />
        <Dialog header="Sync Processing" visible={isOpen} maximizable onHide={() => { setIsOpen(false) }} draggable={false} breakpoints={{ '960px': '75vw' }} style={{ width: '55vw' }}>
          <>
            <UserSchedulerStatue2 user={userSchedulerId} />
            <div className="d-flex justify-content-end mt-3">

              <Button onClick={() => setIsOpen(false)} className=" p-button p-component p-button-raised p-button-danger "> Close</Button>
            </div>
          </>
        </Dialog>
      </div>
    </div>
  );
};

export default UsersGridContainer
