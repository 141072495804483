import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import Label from "../../../../components/Label";
import SelectInput from "../../../../components/SelectInput";
import { AppContext } from "../../../../store/AppContext";
import { TaxCodeMappingsModel } from "../../../../shared/models/mappings/UserMappingModels";

export interface INetoToMYOBTaxCodeMappingProps {
    taxCodeMapping: TaxCodeMappingsModel,
    setTaxCodeMapings: (value: TaxCodeMappingsModel) => void,
    errors?: any,
    touched?: any,
    submitCount: any,
    onInputBlurred: (obj: any) => void,
}

const NetoToMYOBTaxCodeMapping: React.FC<INetoToMYOBTaxCodeMappingProps> = (props) => {
    let { State } = useContext(AppContext);

    const [taxCodeMapping, setTaxCodeMapping] = useState<TaxCodeMappingsModel>({} as TaxCodeMappingsModel);
    const [MapSaleTaxCodesToggle, setMapSaleTaxCodesToggle] = useState<boolean>(false);
    const [isTabActive, setIsTabActive] = useState<number | undefined>(undefined);

    const headerTemplate = (headingText: string, hasError: boolean, smallText?: string) => (<React.Fragment><div className="d-flex"><h6>{headingText} {smallText && <small>({smallText})</small>}</h6>
        {hasError && <small className="ml-3 mt-1 text-danger">(There are some validation errors)</small>}</div></React.Fragment>);

    const header = {
        sourceHeader: 'Maropost',
        targetHeader: 'MYOB AccountRight',
        header1: headerTemplate("Sale Tax Codes", MapSaleTaxCodesToggle),
    };

    let [touchedFields, setTouchedFields] = useState(props.touched);

    const onInputBlur = (touched: any) => {
        props.onInputBlurred(touched);
    }

    useEffect(() => {
        setTouchedFields(props.touched);
    }, [props.touched])

    useEffect(() => {
        setTaxCodeMapping(props.taxCodeMapping ?? {} as TaxCodeMappingsModel);
    }, [props.taxCodeMapping]);


    useEffect(() => {
        //#region Sales Tax Code
        const taxCodeKeys = props.errors?.taxCodeMappings ? Object.keys(props.errors?.taxCodeMappings) : [];
        if (props.submitCount > 0 && ((props.touched?.taxCodeMappings?.noTaxExpense && props.errors?.taxCodeMappings?.noTaxExpense)
            ||
            (props.touched?.taxCodeMappings?.goodAndServiceTaxPercent && props.errors?.taxCodeMappings?.goodAndServiceTaxPercent))
        ) {
            setMapSaleTaxCodesToggle(true);
            setIsTabActive(0);
        }
        else if(!taxCodeKeys.includes('noTaxExpense') || !taxCodeKeys.includes('goodAndServiceTaxPercent')){
            setMapSaleTaxCodesToggle(false);
        }
        //#endregion
    }, [props.touched?.taxCodeMappings, props.errors?.taxCodeMappings])


    const setTaxCodeMappings = () => {
        props.setTaxCodeMapings(taxCodeMapping);
    }

    return (
        <div className="container-fluid">
            <div className="row text-left ml-2">
                <div className="col-6">
                    <h6>{header.sourceHeader}</h6>
                </div>
                <div className="col-6">
                    <h6>{header.targetHeader}</h6>
                </div>
            </div>
            <hr />

            <div className="row">
                <div className="col-6 d-flex">
                    <Label text={"Default Selling Tax Code for New Customers"} /> <p className="text-danger">*</p>
                </div>
                <div className="col-6">
                    <SelectInput
                        hasError={
                            props.touched?.taxCodeMappings?.sellingTaxForCustomers &&
                                props.errors?.taxCodeMappings?.sellingTaxForCustomers ? true : false
                        }
                        options={State.formsLookup?.taxCodeMapping?.defaultSellingTaxCode?.values!}
                        selectedOptionValue={taxCodeMapping.sellingTaxForCustomers} //useSellingTaxForNewCustomers}
                        onChange={(val: string) => setTaxCodeMapping({ ...taxCodeMapping, sellingTaxForCustomers: val })} //setSellingTaxForNewCustomers}
                        onBlur={(e: any) => {
                            onInputBlur({
                                ...touchedFields, taxCodeMappings: {
                                    ...touchedFields.taxCodeMappings, sellingTaxForCustomers: true
                                }
                            });
                            if (props.taxCodeMapping?.sellingTaxForCustomers !== taxCodeMapping.sellingTaxForCustomers)
                                setTaxCodeMappings();
                        }}
                    />
                </div>
            </div>
            <br />
            <Accordion activeIndex={isTabActive}>
                <AccordionTab header={header.header1}>
                    <React.Fragment>
                        <div className="row">
                            <div className="col-6 d-flex">
                                <Label text={'No Tax [0.00%]'} /> <p className="text-danger">*</p>
                            </div>
                            <div className="col-6">
                                <SelectInput
                                    hasError={
                                        props.touched?.taxCodeMappings?.noTaxExpense &&
                                            props.errors?.taxCodeMappings?.noTaxExpense ? true : false
                                    }
                                    options={State.formsLookup?.taxCodeMapping?.noTaxEXP?.values!}
                                    selectedOptionValue={taxCodeMapping.noTaxExpense} //useNoTaxExp}
                                    onChange={(val: string) => setTaxCodeMapping({ ...taxCodeMapping, noTaxExpense: val })}
                                    onBlur={(e: any) => {
                                        onInputBlur({
                                            ...touchedFields, taxCodeMappings: {
                                                ...touchedFields.taxCodeMappings, noTaxExpense: true
                                            }
                                        });
                                        if (props.taxCodeMapping?.noTaxExpense !== taxCodeMapping.noTaxExpense)
                                            setTaxCodeMappings();
                                    }}
                                />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-6 d-flex">
                                <Label text={'Tax [10.00 %]'} /> <p className="text-danger">*</p>
                            </div>
                            <div className="col-6">
                                <SelectInput
                                    hasError={
                                        props.touched?.taxCodeMappings?.goodAndServiceTaxPercent &&
                                            props.errors?.taxCodeMappings?.goodAndServiceTaxPercent ? true : false
                                    }
                                    options={State.formsLookup?.taxCodeMapping?.taxGST?.values!}
                                    selectedOptionValue={taxCodeMapping.goodAndServiceTaxPercent} //useGoodAndServiceTax}
                                    onChange={(val: string) => setTaxCodeMapping({ ...taxCodeMapping, goodAndServiceTaxPercent: val })}//setGoodAndServiceTax}
                                    onBlur={(e: any) => {
                                        onInputBlur({
                                            ...touchedFields, taxCodeMappings: {
                                                ...touchedFields.taxCodeMappings, goodAndServiceTaxPercent: true
                                            }
                                        });
                                        if (props.taxCodeMapping?.goodAndServiceTaxPercent !== taxCodeMapping.goodAndServiceTaxPercent)
                                            setTaxCodeMappings();
                                    }}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                </AccordionTab>
            </Accordion>
        </div>
    )
}

export default NetoToMYOBTaxCodeMapping




