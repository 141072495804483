import * as React from 'react';
import { useState, useEffect } from 'react';
import _ from 'lodash';
import Label from '../../../../../components/Label';
import Boolean from '../../../../../components/Boolean';
import Input from '../../../../../components/Input';
import { SalesOrderMappingsModel } from "../../../../../shared/models/mappings/UserMappingModels";
import { AppContext } from '../../../../../store/AppContext';

export interface INetoSalesOrderToMYOBInvoiceBasicInvoiceNumberProps {
    touched: any,
    errors: any,
    isMappingsEmpty: boolean,
    salesOrderBasicMappings: SalesOrderMappingsModel,
    syncMyobStockToNetoLoading: boolean,
    onInputBlur: (val: any) => void,
    syncMyobToNetoPayments: () => void,
    setSalesOrderBasicMapping: (val: SalesOrderMappingsModel) => void,
    onFocusChanged: (value: SalesOrderMappingsModel) => void,
}

const NetoSalesOrderToMYOBInvoiceBasicInvoiceNumber: React.FC<INetoSalesOrderToMYOBInvoiceBasicInvoiceNumberProps> = (props) => {
    let { State, Dispatch } = React.useContext(AppContext);
    const [invoiceNumberMappings, setInvoiceNumberMappings] = useState<SalesOrderMappingsModel>(props.salesOrderBasicMappings ?? {} as SalesOrderMappingsModel);

    useEffect(() => {
        setInvoiceNumberMappings(props.salesOrderBasicMappings);
    }, [props.salesOrderBasicMappings]);

    const onFocusChanged = () => {
        props.onFocusChanged(invoiceNumberMappings);
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-6 d-flex">
                    <Label text={'Order Number Prefix'} />
                </div>
                <div className="col-6">
                    <Input
                        maxLength={8}
                        match={"/^\S*$/"}
                        hasError={
                            props.touched?.salesOrderMapping?.orderNumberPrefix &&
                                props.errors?.salesOrderMapping?.orderNumberPrefix ? true : false}
                        onChange={(val: string) => setInvoiceNumberMappings({ ...invoiceNumberMappings, orderNumberPrefix: val })}
                        value={invoiceNumberMappings.orderNumberPrefix}
                        onBlur={(e: any) => {
                            props.onInputBlur({
                                ...props.touched, salesOrderMapping: {
                                    ...props.touched.salesOrderMapping, orderNumberPrefix: true
                                }
                            });
                            if (props.salesOrderBasicMappings.orderNumberPrefix !== invoiceNumberMappings.orderNumberPrefix)
                                onFocusChanged();
                        }}
                    />
                    <div className="text-muted text-left">
                        <small>Add a short prefix e.g, "NT", to ensure orders from your store have unique numbers that also identity their source system.</small>
                    </div>
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-6 d-flex">
                    <Label text={'Automatic Invoice Numbers'} />
                </div>
                <div className="col-6 text-left">
                    <div className="">
                        <Boolean
                            text={''}
                            value={invoiceNumberMappings.autoInvoiceNumber}
                            onChange={(value: any) => setInvoiceNumberMappings({ ...invoiceNumberMappings, autoInvoiceNumber: value })}
                            name="autoInvoiceNumber"
                            onBlur={(e: any) => {
                                props.onInputBlur({
                                    ...props.touched, salesOrderMapping: {
                                        ...props.touched.salesOrderMapping, autoInvoiceNumber: true
                                    }
                                });
                                if (props.salesOrderBasicMappings?.autoInvoiceNumber !== invoiceNumberMappings.autoInvoiceNumber)
                                    onFocusChanged();
                            }}
                        />
                    </div>
                    <small className="text-muted">
                        Let the accounting generate Invoice Number
                    </small>
                </div>
            </div>
        </React.Fragment>
    )
}

export default NetoSalesOrderToMYOBInvoiceBasicInvoiceNumber
