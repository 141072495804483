import * as React from 'react';
import { useEffect, useState, useContext } from "react";
import Label from "../../../../../components/Label";
import SelectInput from "../../../../../components/SelectInput";
import { AppContext } from "../../../../../store/AppContext";
import { PaymentMappingsModel } from "../../../../../shared/models/mappings/UserMappingModels";
import Boolean from '../../../../../components/Boolean'
import Alert from '../../../../../components/Alert';
export interface INetoToMYOBPaymentMappingPaymentMethodsProps {
  touched: any,
  errors: any,
  paymentMappings: PaymentMappingsModel,
  onInputBlur: (val: any) => void,
  onFocusChanged: (value: PaymentMappingsModel) => void,
}

const NetoToMYOBPaymentMappingPaymentMethods: React.FC<INetoToMYOBPaymentMappingPaymentMethodsProps> = (props) => {
  let { State, Dispatch } = useContext(AppContext);
  const [paymentMapping, setPaymentMapping] = useState<PaymentMappingsModel>(props.paymentMappings ?? {} as PaymentMappingsModel);

  useEffect(() => {
    setPaymentMapping(props.paymentMappings);
    // setPaymentMapping({ ...props?.paymentMappings, accCredit: State.formsLookup?.paymentMapping?.accountCredit?.values ? State.formsLookup?.paymentMapping?.accountCredit?.values[0]?.value : "" })
  }, [props.paymentMappings]);

  const onFocusChanged = () => {
    props.onFocusChanged(paymentMapping);
  }

  const setOtherAccounts = (key: string, value: string) => {
    const otherPayments = paymentMapping?.otherAccounts ? [...paymentMapping?.otherAccounts] : [];

    if (otherPayments && otherPayments.length > 0) {
      let methodIndex = otherPayments.findIndex((x) => x.netoMethod === key);
      if (methodIndex > -1) {
        otherPayments[methodIndex] = { myobAccount: value, netoMethod: key }
      }
      else {
        otherPayments.push({ myobAccount: value, netoMethod: key });
      }
    }
    else {
      otherPayments.push({ myobAccount: value, netoMethod: key });
    }

    setPaymentMapping({ ...paymentMapping, otherAccounts: otherPayments });
  }

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-6 text-left">
          <Label text="Do not use default payment account for un mapped payment methods" />
        </div>
        <div className=" col-6">
          <Boolean
            hasError={
              props?.touched?.paymentMapping?.isPaymentProcessWithMethods &&
                props?.errors?.paymentMapping?.isPaymentProcessWithMethods ? true : false
            }
            text={''}
            value={paymentMapping.isPaymentProcessWithMethods}
            onChange={(val: boolean) => setPaymentMapping({ ...paymentMapping, isPaymentProcessWithMethods: val })}
            onBlur={(e: any) => {
              props.onInputBlur({
                ...props?.touched, paymentMapping: {
                  ...props?.touched.paymentMapping, isPaymentProcessWithMethods: true
                }
              });
              if (props?.paymentMappings?.isPaymentProcessWithMethods != paymentMapping.isPaymentProcessWithMethods)
                onFocusChanged();
            }}
          />
        </div>
      </div>
      <br />
      {/* <Alert heading={"Note"} text={"* If checked, then payments will not be sent from Maropost to MYOB AccountRight for un mapped payment methods. * If not checked, then the default payment account will be used for un mapped payment methods"} /> */}
      <div className="alert alert-primary text-left" role="alert" style={{lineHeight: '1', maxWidth:"900px"}}>
        <p>NOTE:</p>
        <p>1- If checked, then payments will not be sent from Maropost to MYOB AccountRight for un mapped payment methods.</p>
        <p>2- If not checked, then the default payment account will be used for un mapped payment methods.</p>
    </div>
      {/* <div className="row">
              <div className="col-6 d-flex">
                <Label text={'RMA Account'} />
              </div>
              <div className="col-6">
                <SelectInput
                  hasError={
                    props?.touched?.paymentMapping?.accCredit &&
                      props?.errors?.paymentMapping?.accCredit ? true : false
                  }
                  options={State.formsLookup?.paymentMapping?.accountCredit?.values!}
                  selectedOptionValue={paymentMapping.accCredit}
                  onChange={(val: string) => setPaymentMapping({...paymentMapping, accCredit: val})}
                  onBlur={() => {
                    props.onInputBlur({
                      ...props.touched, paymentMapping: {
                        ...props.touched.paymentMapping, accCredit: true
                      }
                    });
                    if (props.paymentMappings?.accCredit !== paymentMapping.accCredit)
                      onFocusChanged();
                  }}
                />
              </div>
            </div> */}
      {State.formsLookup?.paymentMapping && State.formsLookup?.paymentMapping?.otherAccounts && State.formsLookup?.paymentMapping?.otherAccounts?.length > 0 ?
        State.formsLookup?.paymentMapping?.otherAccounts?.map((pay, index) => (
          <div key={index}>
            <div className="row">
              <div className="col-6 d-flex">
                <Label text={pay.label} />
              </div>
              <div className="col-6">
                <SelectInput
                  hasError={
                    props?.touched?.paymentMapping?.accCredit &&
                      props?.errors?.paymentMapping?.accCredit ? true : false
                  }
                  options={pay?.values}
                  selectedOptionValue={
                    paymentMapping.otherAccounts && paymentMapping.otherAccounts.length > 0 ?
                      (paymentMapping.otherAccounts?.find((x: any) => x.netoMethod === pay?.key))?.myobAccount ?? "" : ""}
                  onChange={(value: string) => setOtherAccounts((pay?.key ?? ''), value)}
                  onBlur={() => {
                    props.onInputBlur({
                      ...props.touched, paymentMapping: {
                        ...props.touched.paymentMapping, accCredit: true
                      }
                    });
                    onFocusChanged();
                  }}
                />
              </div>
            </div>
            <br />
          </div>
        ))
        : 'No Accounts!'
      }
    </React.Fragment>
  )
}

export default NetoToMYOBPaymentMappingPaymentMethods
