import * as React from 'react';
import { DataTable } from 'primereact/datatable';
import { useContext, useEffect, useRef, useState } from 'react';
import { ValueLabel } from '../../shared/models';
import { AppContext } from '../../store/AppContext';
import Label from '../../components/Label';
import SelectInput from '../../components/SelectInput';
import _ from 'lodash';
import * as util from '../../shared/Utilities';
import { ProductEntityModel, ProductGridModel } from './models';
import { convertProductEntityToGridModel, getAllConnectorLabels, getImportedProductsDataSnapshot } from './services/ProductService';
import { Subscription } from 'rxjs';
import { getImportFailedProductsApproach1 } from '../../dataServices/ProductDataService';
import moment from 'moment';
import { TabPanel, TabView } from 'primereact/tabview';
import ProductGridErrorComponent from './components/ProductGridErrorComponent';
import ProductGridComponent from './components/ProductGridComponent';
import { getUserConnectorConfiguration } from '../Mappings/NetoToMyob/services/MappingService';
import myobToNeto from "../../assets/myobToNeto.png"
import netoToMyob from "../../assets/netoToMyob.png"
import netoToMyob2Way from "../../assets/netoToMyob2Way.png"

export const useDebouncedEffect = (effect: any, deps: any, delay: number) => {
  useEffect(() => {
      const handler = setTimeout(() => effect(), delay);

      return () => clearTimeout(handler);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps || [], delay]);
}

const ProductGridContainer: React.FC = () => {
  let { State, Dispatch } = useContext(AppContext);
  const dt = useRef<DataTable>(null);
  const [connectors, setConnectors] = useState<Array<ValueLabel>>([{ label: "", value: "" }]);
  const [selectedConnector, setSelectedConnector] = useState<string>(State.selectedConnectorOnGrid ?? "");
  const [ProductData, setProductData] = useState<Array<ProductGridModel>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [fLoading, setFLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [failedProductsData, setFailedProductsData] = useState<Array<ProductGridModel>>([]);
  const [startAfterRecord, setStartAfterRecord] = useState<any>();
  const [startBeforeRecord, setStartBeforeRecord] = useState<any>();
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);
  const [isPrevDisabled, setIsPrevDisabled] = useState<boolean>(false);
  const [netoToMyobSync, setNetoToMyobSync] = useState(false);
  const [myobToNetoSync, setMyobToNetoSync] = useState(false);
  const filterFields = {
    SKU: 'neto.SKU',
    Number: 'myob.Number',
    NetoName: 'neto.Name',
    MyobName: 'myob.Name',
    IsInventoriedNeto: 'neto.Virtual', //'neto.IsInventoried',
    IsInventoriedMyob: 'myob.IsInventoried',
    syncErrorMessageNeto: 'neto.syncErrorMessage',
    syncErrorMessageMyob: 'myob.syncErrorMessage',
    lastSyncAt: 'lastSyncAt',
  }
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 5,
    page: 1,
    sortField: "lastSyncAt",
    sortOrder: -1,
    filters: undefined,
    pageMode: '',
    pageSize: 50,
  });

  useEffect(() => {
    (async () => {
      if (selectedConnector == "") return
      let userMappings = await getUserConnectorConfiguration(State.currentUser.currentUserId, selectedConnector);
      setNetoToMyobSync(userMappings?.result?.mappings?.itemMapping?.isChecked)
       setMyobToNetoSync(userMappings?.result?.mappings?.productStockMapping?.isChecked)
    })();
   
  }, [selectedConnector]);

  

  useEffect(() => {
    (async () => {
      setLoading(true);
      const connectorsList = await getAllConnectorLabels(State?.currentUser?.isAdminUser && localStorage.SelectedIdByAdmin ? localStorage.SelectedIdByAdmin : State.currentUser.currentUserId);
      setConnectors(connectorsList);
      setLoading(false);
      if (State.currentUser.currentUserId) {
        setSelectedConnector(connectorsList.length && connectorsList[0].value ? connectorsList[0].value : "");
      }
    })();

    return () => {
      Dispatch({
        Type: "SET_SALES_ORDER",
        Payload: { connector: "", data: [] },
      });
    };
  }, []);

  useEffect(() => {
    triggerPagination();
  }, [activeTab])

  useEffect(() => { 
    setLoading(true);
    setLazyParams({
      first: 0,
      rows: 5,
      page: 1,
      sortField: "lastSyncAt",
      sortOrder: -1,
      filters: undefined,
      pageMode: '',
      pageSize: 50,
    });
    setProductData([]);
    setFailedProductsData([]);
  }, [selectedConnector]);

  useDebouncedEffect(() => { setLoading(true); processPagination(); }, [lazyParams], 1000);

  useDebouncedEffect(() => { 
    triggerPagination(); 
  }, [selectedConnector], 1000);

  const processPagination = () => {
    if (State?.currentUser?.currentUserId && selectedConnector) {
      let filtersArray : any = [];
      if (lazyParams?.filters) {
        const filters: any = lazyParams.filters;
        if (filters['NetoProduct.SKU']) {
          if(!_.isEmpty(filters['NetoProduct.SKU']?.value))
          filtersArray.push({ key: filterFields.SKU, value: filters['NetoProduct.SKU']?.value })
        }

        if (filters['MyobProduct.Number']) {
          if(!_.isEmpty(filters['MyobProduct.Number']?.value))
          filtersArray.push({ key: filterFields.Number, value: filters['MyobProduct.Number']?.value })
        }

        if (filters['NetoProduct.Name']) {
          if(!_.isEmpty(filters['NetoProduct.Name']?.value))
          filtersArray.push({ key: filterFields.NetoName, value: filters['NetoProduct.Name']?.value })
        }

        if (filters['MyobProduct.Name']) {
          if(!_.isEmpty(filters['MyobProduct.Name']?.value))
          filtersArray.push({ key: filterFields.MyobName, value: filters['MyobProduct.Name']?.value })
        }

        if (filters['NetoProduct.IsInventoriedNeto']?.value) {
          if(!_.isEmpty(filters['NetoProduct.IsInventoriedNeto']?.value))
          filtersArray.push({ key: filterFields.IsInventoriedNeto, value: filters['NetoProduct.IsInventoriedNeto']?.value
          === 'Inventoried' ? 'False' : 'True'}) //['y', 'True'] : ['n', 'False']})
        }

        if (filters['MyobProduct.IsInventoriedMyob']?.value) {
          if(!_.isEmpty(filters['MyobProduct.IsInventoriedMyob']?.value))
          filtersArray.push({ key: filterFields.IsInventoriedMyob, value: filters['MyobProduct.IsInventoriedMyob']?.value === 'Inventoried' ? true : false })
        }

        if (filters['NetoProduct.syncErrorMessage']?.value) {
          if(!_.isEmpty(filters['NetoProduct.syncErrorMessage']?.value))
          filtersArray.push({ key: filterFields.syncErrorMessageNeto, value: filters['NetoProduct.syncErrorMessage']?.value })
        }

        if (filters['MyobProduct.syncErrorMessage']?.value) {
          if(!_.isEmpty(filters['MyobProduct.syncErrorMessage']?.value))
          filtersArray.push({ key: filterFields.syncErrorMessageMyob, value: filters['MyobProduct.syncErrorMessage']?.value })
        }

        if (filters['lastSyncAt']) {
          if((filters?.lastSyncAt?.value))
          // filtersArray.push({ key: filterFields.lastSyncAt, value: filters['lastSyncAt']?.value })
          filtersArray.push({ key: filterFields.lastSyncAt, value:  util.getUserTimezoneStartAndEndOfADay(moment(filters?.lastSyncAt?.value)?.format('YYYY-MM-DD')) });
        }
      }
      
      let ProductSubscription: Subscription;
        ProductSubscription = getImportedProductsDataSnapshot(State.currentUser.currentUserId, selectedConnector,
          lazyParams.pageSize, lazyParams?.sortField, lazyParams?.sortOrder,
          startBeforeRecord, startAfterRecord, lazyParams.pageMode, filtersArray).subscribe((e: any) => {
            
            let products: any = [];
            e?.products && _.size(e?.products) > 0 && e?.products.map((doc: any, index: number) => {
              let userData: ProductEntityModel = doc?.data();
              products.push(convertProductEntityToGridModel(userData, index));
            });

            if (e.products && e.products.length > 0) {
              setProductData(products);
              
              if (e?.lastVisible) {
                setStartAfterRecord(e?.lastVisible);
              }
              if (e?.firstVisible) {
                setStartBeforeRecord(e?.firstVisible);
              }

              if(lazyParams.pageMode === 'ALL'){
                setIsNextDisabled(true);
                setIsPrevDisabled(true);
              } else {
                setIsNextDisabled(false);
                setIsPrevDisabled(false);
              }

              setLoading(false);
            }
            else {
              if(lazyParams.pageMode === 'ALL'){
                setProductData([]);
                setIsNextDisabled(true);
                setIsPrevDisabled(true);
              } else if(lazyParams.pageMode === 'PREV'){
                setIsPrevDisabled(true);
              } else if(lazyParams.pageMode === 'NEXT'){
                setIsNextDisabled(true);
              } else {
                setProductData([]);
                setIsNextDisabled(true);
                setIsPrevDisabled(true);
              }

              setLoading(false);
            }
          });
      util.getTimezoneFromFirebase(State?.currentUser?.isAdminUser && localStorage.SelectedIdByAdmin ? localStorage.SelectedIdByAdmin : State.currentUser.currentUserId, selectedConnector);
    }
  }

  const getAllFailedProductsSanpshot = () => {
    setFLoading(true);
    if(!_.isEmpty(selectedConnector)){
      let failedProductSubscription: Subscription = getImportFailedProductsApproach1(State.currentUser.currentUserId, selectedConnector).subscribe((e: any) => { 
        let products : any = [];
        e?.map((doc: any, index: number) => {
          const userData : ProductEntityModel = doc?.data();
          products.push(convertProductEntityToGridModel(userData, index))
        });
      
        setFailedProductsData(products);
        setFLoading(false);
      });
    } else {
      setFLoading(false);
    }
  }

  const triggerPagination = () => {
    if(activeTab === 1){
        if(_.isEmpty(ProductData)){
          setLoading(true); 
          processPagination();
        } else {
          const productDataCopy = [...ProductData];
          setProductData(productDataCopy);
        }
    } else {
        // if(_.isEmpty(failedSalesOrderData)){ 
      getAllFailedProductsSanpshot();
        // } else {
            // setFailedSalesOrderData(failedSalesOrderData);
        // }
    }
  }

  return (
    <div className="container-fluid">
      <div className="row mt-5">
        <div className="col-3">
          <Label text={"Select Connector: "} />
        </div>
        <div className="col-9">
          <SelectInput
            options={connectors}
            selectedOptionValue={selectedConnector}
            onChange={(connector: string) => {
              Dispatch({
                Type: "SET_SELECTED_CONNECTOR",
                Payload: {
                  id: connector
                },
              });
              setSelectedConnector(connector)
            }}
          />
        </div>
      </div>

      <div className="container mt-5">
        {myobToNetoSync && !netoToMyobSync && <img src={myobToNeto} alt="myobToNeto" height="30rem" />}
        {myobToNetoSync && netoToMyobSync && <img src={netoToMyob2Way} alt="netoToMyob2Way" height="30rem" />}
        {!myobToNetoSync && netoToMyobSync && <img src={netoToMyob} alt="netoToMyob" height="30rem" />}
      </div>

      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header={<b>Failed Products</b>}>
          <ProductGridErrorComponent 
            loading={fLoading}
            productData={failedProductsData}
            // setLoading={setFLoading} 
          />
          </TabPanel>
          <TabPanel header={<b>Synced Products</b>}>
            <ProductGridComponent 
              productData={ProductData}
              isNextDisabled={isNextDisabled}
              isPrevDisabled={isPrevDisabled}
              lazyParams={lazyParams}
              loading={loading}
              setLoading={setLoading}
              selectedConnector={selectedConnector}
              setLazyParams={setLazyParams}
              setStartAfterRecord={setStartAfterRecord}
              setStartBeforeRecord={setStartBeforeRecord}
            />
          </TabPanel>
        </TabView>
    </div>
  )
}

export default ProductGridContainer