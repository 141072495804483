import { CustomerEntityModel } from '../features/Customer/models/CustomerEntityModel';
import _ from "lodash";
import firebase from "../shared/config/firebaseConfig";
import { DATABASE_COLLECTIONS, STATIC_DOCUMENTS_ID } from '../shared/config/constants';
import { Observable } from 'rxjs';
import { CustomerGridModel } from "../features/Customer/models/CustomerGridModel";
const db = firebase.firestore();
const { USER_DETAILS, CONNECTORS, FAILED_SYNC_CUSTOMERS, SUCCESS_SYNC_CUSTOMERS , RECORDCOUNTER } = DATABASE_COLLECTIONS;
const { CUSTOMER } = STATIC_DOCUMENTS_ID;
export let getFailedImportCustomersSnapshotUnsubcribe: any
export let getImportedCustomersSnapshotUnsubscribe: any
let getOrderRecordCount: any

export const getOrdersTotalCounts = (userId: string, connector: string) => {
  return new Observable(subscriber => {
    getOrderRecordCount = db.collection(`${USER_DETAILS}`).doc(userId)
      .collection(`${CONNECTORS}`).doc(connector)
      .collection(`${RECORDCOUNTER}`).doc(CUSTOMER).onSnapshot((snapshot) => {
        subscriber.next(snapshot?.data()?.count);
      });
  });
}

const makeFailedSearchClauses = (failedImportCustomersRef: firebase.firestore.Query<firebase.firestore.DocumentData> | undefined, query: any, sortField: string, sortOrder: number) => {
  let ref = failedImportCustomersRef;
  if (query && query.length > 0) {
    console.log("QUERY: ", query)
    query?.map((q: any) => {
      if(q?.key === "lastSyncAt"){
        ref = ref?.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc').where(q?.key, '>=', q?.value?.startAt).where(q?.key, '<=', q?.value?.endAt);
      }
      else if(q?.key === "myob.IsActive"){
        ref = ref?.orderBy(firebase.firestore.FieldPath.documentId()).where(q?.key, '==', q?.value);
      }
      else if(q?.key === "neto.Active"){
        ref = ref?.orderBy(firebase.firestore.FieldPath.documentId()).where(q?.key, '==', q?.value);
      }
      else {
        ref = ref?.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc').where(q?.key, '>=', q?.value).where(q?.key, '<=', q?.value  + '\uf8ff');
      }
    })
  }
  return ref;
}

const makeSuccessSearchClauses = (successImportCustomersRef: firebase.firestore.Query<firebase.firestore.DocumentData> | undefined, query: any, sortField: string, sortOrder: number) => {
  let ref = successImportCustomersRef;
  if (query && query.length > 0) {
    query?.map((q: any) => {
      if(q?.key === "lastSyncAt"){
        ref = ref?.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc').where(q?.key, '>=', q?.value?.startAt).where(q?.key, '<=', q?.value?.endAt);
      }
      else if(q?.key === "myob.IsActive"){
        ref = ref?.orderBy(firebase.firestore.FieldPath.documentId()).where(q?.key, '==', q?.value);
      } 
      else if(q?.key === "neto.Active"){
        ref = ref?.orderBy(firebase.firestore.FieldPath.documentId()).where(q?.key, '==', q?.value);
      }
      else {
        ref = ref?.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc').where(q?.key, '>=', q?.value).where(q?.key, '<=', q?.value + '\uf8ff');
      }
    })
  }
  return ref;
}

export const getFailedImportCustomers = (userId: string, connector: string, limit: number, sortField: string, sortOrder: number, firstVisible?: any, lastVisible?: any, mode?: string, query?: any) => {
  return new Observable(subscriber => {

    let failedImportCustomersRef: firebase.firestore.Query<firebase.firestore.DocumentData> | undefined = undefined;
    if (lastVisible && mode === 'NEXT') {
      failedImportCustomersRef = db.collection(`${USER_DETAILS}`).doc(userId)
        .collection(`${CONNECTORS}`).doc(connector)
        .collection(`${FAILED_SYNC_CUSTOMERS}`);

      if (!_.isEmpty(query)){
        failedImportCustomersRef = makeFailedSearchClauses(failedImportCustomersRef, query, sortField, sortOrder);
        failedImportCustomersRef = failedImportCustomersRef?.startAfter(lastVisible).limit(limit);
      } else{
        failedImportCustomersRef = failedImportCustomersRef.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc').startAfter(lastVisible).limit(limit)
      }
    }
    else if (firstVisible && mode === 'PREV') {
      failedImportCustomersRef = db.collection(`${USER_DETAILS}`).doc(userId)
        .collection(`${CONNECTORS}`).doc(connector)
        .collection(`${FAILED_SYNC_CUSTOMERS}`);

      if (!_.isEmpty(query)){
        failedImportCustomersRef = makeFailedSearchClauses(failedImportCustomersRef, query, sortField, sortOrder);
        failedImportCustomersRef = failedImportCustomersRef?.endBefore(firstVisible).limitToLast(limit);
      } else{
        failedImportCustomersRef = failedImportCustomersRef?.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc').endBefore(firstVisible).limitToLast(limit);
      }
    }
    else if (mode === 'ALL') {
      failedImportCustomersRef = db.collection(`${USER_DETAILS}`).doc(userId)
        .collection(`${CONNECTORS}`).doc(connector)
        .collection(`${FAILED_SYNC_CUSTOMERS}`);
      if (!_.isEmpty(query)){
        failedImportCustomersRef = makeFailedSearchClauses(failedImportCustomersRef, query, sortField, sortOrder);
      } 
      else{
        failedImportCustomersRef = failedImportCustomersRef.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc');
      }
    }
    else {
      failedImportCustomersRef = db.collection(`${USER_DETAILS}`).doc(userId)
        .collection(`${CONNECTORS}`).doc(connector)
        .collection(`${FAILED_SYNC_CUSTOMERS}`);

      if (!_.isEmpty(query)){
        failedImportCustomersRef = makeFailedSearchClauses(failedImportCustomersRef, query, sortField, sortOrder);
        failedImportCustomersRef = failedImportCustomersRef?.limit(limit);
      } else{
        failedImportCustomersRef = failedImportCustomersRef.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc').limit(limit);
      }
    } 

    if (failedImportCustomersRef) {
      getFailedImportCustomersSnapshotUnsubcribe = failedImportCustomersRef.onSnapshot((snapshot) => {
        let lastVal = snapshot?.docs[snapshot?.docs.length -1];
        let firstVal = snapshot?.docs[0];
        subscriber.next({ customers: snapshot?.docs, lastVisible: lastVal, firstVisible: firstVal } );
      });
    }
  })
}

export const getImportedCustomers = (userId: string, connector: string, limit: number, sortField: string, 
  sortOrder: number, firstVisible?: any, lastVisible?: any, mode?: string, query?: any) => {
  return new Observable(subscriber => {
    
    let successImportCustomersRef: firebase.firestore.Query<firebase.firestore.DocumentData> | undefined = undefined;
    if (lastVisible && mode === 'NEXT') {
      successImportCustomersRef = db.collection(`${USER_DETAILS}`).doc(userId)
        .collection(`${CONNECTORS}`).doc(connector)
        .collection(`${SUCCESS_SYNC_CUSTOMERS}`);
        if (!_.isEmpty(query)){
          successImportCustomersRef = makeSuccessSearchClauses(successImportCustomersRef, query, sortField, sortOrder);
          successImportCustomersRef = successImportCustomersRef?.startAfter(lastVisible).limit(limit)
        } else{
          successImportCustomersRef = successImportCustomersRef.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc').startAfter(lastVisible).limit(limit);
        }
    }
    else if (firstVisible && mode === 'PREV') {
      successImportCustomersRef = db.collection(`${USER_DETAILS}`).doc(userId)
        .collection(`${CONNECTORS}`).doc(connector)
        .collection(`${SUCCESS_SYNC_CUSTOMERS}`);
        if (!_.isEmpty(query)){
          successImportCustomersRef = makeSuccessSearchClauses(successImportCustomersRef, query, sortField, sortOrder);
          successImportCustomersRef = successImportCustomersRef?.endBefore(firstVisible).limitToLast(limit);
        } else{
          successImportCustomersRef = successImportCustomersRef?.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc').endBefore(firstVisible).limitToLast(limit);
        }
    }
    else if (mode === 'ALL') {
      successImportCustomersRef = db.collection(`${USER_DETAILS}`).doc(userId)
        .collection(`${CONNECTORS}`).doc(connector)
        .collection(`${SUCCESS_SYNC_CUSTOMERS}`);
        if (!_.isEmpty(query)){
          successImportCustomersRef = makeSuccessSearchClauses(successImportCustomersRef, query, sortField, sortOrder);
        } 
        else{
          successImportCustomersRef = successImportCustomersRef.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc');
        }
    }
    else {
      successImportCustomersRef = db.collection(`${USER_DETAILS}`).doc(userId)
        .collection(`${CONNECTORS}`).doc(connector)
        .collection(`${SUCCESS_SYNC_CUSTOMERS}`);
      if (!_.isEmpty(query)){
        successImportCustomersRef = makeSuccessSearchClauses(successImportCustomersRef, query, sortField, sortOrder);
        successImportCustomersRef = successImportCustomersRef?.limit(limit)
      } 
      else{
        successImportCustomersRef = successImportCustomersRef.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc').limit(limit)
      }
    }

    if (successImportCustomersRef) {
      getImportedCustomersSnapshotUnsubscribe = successImportCustomersRef.onSnapshot((snapshot) => {
        let lastVal = snapshot?.docs[snapshot?.docs.length -1];
        let firstVal = snapshot?.docs[0];
        subscriber.next({ customers: snapshot?.docs, firstVisible: firstVal, lastVisible: lastVal });
      });
    }
  })
}

export const getFailedImportCustomersApproach1 = (userId: string, connector: string) => {
    return new Observable(subscriber => {
        getFailedImportCustomersSnapshotUnsubcribe = db.collection(`${USER_DETAILS}`).doc(userId)
            .collection(`${CONNECTORS}`).doc(connector)
            .collection(`${FAILED_SYNC_CUSTOMERS}`).onSnapshot((snapshot) => {
                subscriber.next(snapshot?.docs);
            });
    })
}

export const getImportedCustomersApproach1 = (userId: string, connector: string) => {
    return new Observable(subscriber => {
        getImportedCustomersSnapshotUnsubscribe = db.collection(`${USER_DETAILS}`).doc(userId)
            .collection(`${CONNECTORS}`).doc(connector)
            .collection(`${SUCCESS_SYNC_CUSTOMERS}`).onSnapshot((snapshot) => {
                subscriber.next(snapshot?.docs);
            });
    })
};