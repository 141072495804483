import * as React from 'react';
import { DataTable } from 'primereact/datatable';
import { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../../store/AppContext';
import { Column } from 'primereact/column';
import _ from 'lodash';
import { ProductGridModel } from '../models';
import { emptyMessageTemplate, lastSyncAtBodyTemplate, rowExpansionTemplate, SourceInventoriedTemplate, statusItemTemplate, TargetInventoriedTemplate } from '../GridBodyTemplates';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { PaginatorTemplate } from 'primereact/paginator';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { InputText } from "primereact/inputtext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSlash } from '@fortawesome/free-solid-svg-icons';

interface IProductGridComponentProps {
    setLazyParams : (value: any) => void,
    lazyParams: any,
    loading: boolean,
    setLoading: (value: boolean) => void,
    setStartAfterRecord : (value: any) => void,
    setStartBeforeRecord : (value: any) => void,
    isNextDisabled: boolean,
    isPrevDisabled: boolean,
    selectedConnector: string,
    productData: Array<ProductGridModel>,
}

const ProductGridComponent : React.FC<IProductGridComponentProps> = ({ setLazyParams, lazyParams,
    loading, setLoading, setStartAfterRecord, setStartBeforeRecord, isNextDisabled,isPrevDisabled, selectedConnector, productData }) => {
  let { State, Dispatch } = useContext(AppContext);
  const dt = useRef<DataTable>(null);
  const [ProductData, setProductData] = useState<Array<ProductGridModel>>(productData ?? []);
  const [expandedRows, setExpandedRows] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = React.useState<any>();
  const [selectedStatus2, setSelectedStatus2] = React.useState<any>();
  const [dateFilter, setDateFilter] = useState<any>();
  const [filter1, setFilter1] = useState<string>('');
  const [filter2, setFilter2] = useState<string>('');
  const [filter3, setFilter3] = useState<string>('');
  const [filter4, setFilter4] = useState<string>('');
  const filterFields = {
    SKU: 'neto.SKU',
    Number: 'myob.Number',
    NetoName: 'neto.Name',
    MyobName: 'myob.Name',
    IsInventoriedNeto: 'neto.Virtual', //'neto.IsInventoried',
    IsInventoriedMyob: 'myob.IsInventoried',
    syncErrorMessageNeto: 'neto.syncErrorMessage',
    syncErrorMessageMyob: 'myob.syncErrorMessage',
    lastSyncAt: 'lastSyncAt',
  }

  useEffect(() => {
    setProductData(productData);
  }, [productData])

  useEffect(() => { 
    setFilter1('');
    setFilter2('');
    setFilter3('');
    setFilter4('');
    setSelectedStatus('');
    setSelectedStatus2('');
    setDateFilter('');
    setProductData([]);
  }, [selectedConnector]);

  const onPageRowsSizeChanged = (size: number) => {
    setStartAfterRecord({});
    setStartBeforeRecord({});
    let params = {...lazyParams, pageSize: size };
    if(size === 1) {
      params.pageMode = 'ALL';
    } else {
      params.pageMode = '';
    }

    setLazyParams(params);
  }

  const reset = (isCompleteReset?: boolean) => {
    dt.current?.reset();
    setFilter1('');
    setFilter2('');
    setFilter3('');
    setFilter4('');
    setSelectedStatus('');
    setSelectedStatus2('');
    setDateFilter('');

    if(isCompleteReset && isCompleteReset === true){
      setLazyParams({
        first: 0,
        rows: 5,
        page: 1,
        sortField: "lastSyncAt",
        sortOrder: -1,
        filters: undefined,
        pageMode: '',
        pageSize: 50,
      });
    } else {
      let params = { ...lazyParams,
        first: 0,
        rows: 5,
        page: 1,
        sortField: "lastSyncAt",
        sortOrder: -1,
        filters: undefined,
      }

      if(params.pageMode !== 'ALL'){
        params.pageMode = '';
      }

      setLazyParams(params);
    }
  }

  const onPageChange = (mode: string) => {
    switch(mode){
      case 'NEXT':
        setLazyParams({ ...lazyParams, page : lazyParams.page + 1, pageMode: 'NEXT' });
        break;
      case 'PREV':
        setLazyParams({ ...lazyParams, page : lazyParams.page - 1, pageMode: 'PREV' });
        break;
    }
  }

  const onCustomFilterChange : any = (value: string, field: string, index: number) => {
    if(index === 1){
        setFilter1(value);
        setFilter2('');
        setFilter3('');
        setFilter4('');
        // setFilter5('');
        // setFilter6('');
        setSelectedStatus('');
        setSelectedStatus2('');
        setDateFilter('');
    } else if(index === 2){
        setFilter1('');
        setFilter2(value);
        setFilter3('');
        setFilter4('');
        // setFilter5('');
        // setFilter6('');
        setSelectedStatus('');
        setSelectedStatus2('');
        setDateFilter('');
    } else if(index === 3){
        setFilter1('');
        setFilter2('');
        setFilter3(value);
        setFilter4('');
        // setFilter5('');
        // setFilter6('');
        setSelectedStatus('');
        setSelectedStatus2('');
        setDateFilter('');
    }else if(index === 4){
        setFilter1('');
        setFilter2('');
        setFilter3('');
        setFilter4(value);
        // setFilter5('');
        // setFilter6('');
        setSelectedStatus('');
        setSelectedStatus2('');
        setDateFilter('');
    }else if(index === 5){
        setFilter1('');
        setFilter2('');
        setFilter3('');
        setFilter4('');
        // setFilter5(value);
        // setFilter6('');
        setSelectedStatus('');
        setSelectedStatus2('');
        setDateFilter('');
    } else if(index === 6){
        setFilter1('');
        setFilter2('');
        setFilter3('');
        setFilter4('');
        // setFilter5('');
        // setFilter6(value);
        setSelectedStatus('');
        setSelectedStatus2('');
        setDateFilter('');
    } else if(index === 7){
        setFilter1('');
        setFilter2('');
        setFilter3('');
        setFilter4('');
        // setFilter5('');
        // setFilter6('');
        setSelectedStatus(value)
        setSelectedStatus2('')
        setDateFilter('')
    } else if(index === 8){
        setFilter1('');
        setFilter2('');
        setFilter3('');
        setFilter4('');
        // setFilter5('');
        // setFilter6('');
        setSelectedStatus('');
        setSelectedStatus2(value);
        setDateFilter('');
    }  else if(index === 9){
        setFilter1('');
        setFilter2('');
        setFilter3('');
        setFilter4('');
        // setFilter5('');
        // setFilter6('');
        setSelectedStatus('');
        setSelectedStatus2('');
        setDateFilter(value);
    }

    let queryText = value.trimStart();
    queryText = queryText.trimEnd();

    if(!_.isEmpty(queryText) && queryText.length > 1){
      let param: any = {...lazyParams, sortField: getDbFilterField(field), filters: {[field]: { value: queryText, matchMode: "contains"}} };  
      
      if(param.pageMode !== 'ALL'){
        param.pageMode = '';
      }
      
      setLazyParams(param);
    }

    if(_.isEmpty(value)){
      reset();
    }
  }

  const modelFilter1 = (field: string, index: number) => { 
    return <InputText value={filter1} style={{ width: '100%' }} className="ui-column-filter"
        onChange={(event) => onCustomFilterChange(event.target.value, field, index)} />
  }
  const modelFilter2 = (field: string, index: number) => { 
      return <InputText value={filter2} style={{ width: '100%' }} className="ui-column-filter"
          onChange={(event) => onCustomFilterChange(event.target.value, field, index)} />
  }
  const modelFilter3 = (field: string, index: number) => { 
      return <InputText value={filter3} style={{ width: '100%' }} className="ui-column-filter"
          onChange={(event) => onCustomFilterChange(event.target.value, field, index)} />
  }
  const modelFilter4 = (field: string, index: number) => { 
      return <InputText value={filter4} style={{ width: '100%' }} className="ui-column-filter"
          onChange={(event) => onCustomFilterChange(event.target.value, field, index)} />
  }
  const modelFilter7 = (field: string, index: number) => {
    return (
      <Dropdown value={selectedStatus} options={statuses}
        onChange={(event) => onCustomFilterChange(event.target.value, field, index)}
        itemTemplate={statusItemTemplate}
        placeholder="Select a Status" className="p-column-filter" />
    );
  }
  const modelFilter8 = (field: string, index: number) => {
    return (
      <Dropdown value={selectedStatus2} options={statuses}
        onChange={(event) => onCustomFilterChange(event.target.value, field, index)}
        itemTemplate={statusItemTemplate}
        placeholder="Select a Status" className="p-column-filter" />
    );
  }
  const modelFilter9 = (field: string, index: number) => {
    return (
      <Calendar readOnlyInput value={dateFilter} onChange={(event) => onCustomFilterChange(moment(event.target.value?.toString())?.format('YYYY-MM-DD'), field, index)} 
      placeholder="Search" dateFormat="dd-mm-yy" className="p-column-filter" style={{ width: '150px' }} />
    );
  }
  
  const statuses = ['Inventoried', 'Non-Inventoried'];
  const filterElementCustom1 = modelFilter1("NetoProduct.SKU", 1);
  const filterElementCustom2 = modelFilter2("MyobProduct.Number", 2);
  const filterElementCustom3 = modelFilter3("NetoProduct.Name", 3);
  const filterElementCustom4 = modelFilter4("MyobProduct.Name", 4);
  const filterElementCustom7 = modelFilter7("NetoProduct.IsInventoriedNeto", 7);
  const filterElementCustom8 = modelFilter8("MyobProduct.IsInventoriedMyob", 8);
  const filterElementCustom9 = modelFilter9("lastSyncAt", 9);
  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column />
        <Column header="SKU / Number" colSpan={2} />
        <Column header="Product Name" colSpan={2} />
        <Column header="Inventoried" colSpan={2} />
        {/* {toggleViewModel === 2 &&
          <Column header="Sync Error Message" colSpan={2} />} */}
        <Column header="Sync At" />
      </Row>
      <Row>
        <Column />
        <Column header="Maropost" filter filterMatchMode="contains" filterPlaceholder="Search" field="NetoProduct.SKU" filterElement={filterElementCustom1}/>
        <Column header="Myob" filter filterMatchMode="contains" filterPlaceholder="Search" field="MyobProduct.Number" filterElement={filterElementCustom2}/>
        <Column header="Maropost" filter filterMatchMode="contains" filterPlaceholder="Search" field="NetoProduct.Name" filterElement={filterElementCustom3}/>
        <Column header="Myob" filter filterMatchMode="contains" filterPlaceholder="Search" field="MyobProduct.Name" filterElement={filterElementCustom4}/>
        <Column header="Maropost" filter filterMatchMode="contains" filterPlaceholder="Search" filterField="NetoProduct.IsInventoriedNeto" /*sortable sortField="NetoProduct.IsInventoriedNeto"*/ field="NetoProduct.IsInventoriedNeto" filterElement={filterElementCustom7} />
        <Column header="Myob" filter filterMatchMode="contains" filterPlaceholder="Search" filterField="MyobProduct.IsInventoriedMyob" /*sortable sortField="MyobProduct.IsInventoriedMyob"*/ field="MyobProduct.IsInventoriedMyob" filterElement={filterElementCustom8} />
        {/* {toggleViewModel === 2 &&
          <Column header="Maropost" filter filterMatchMode="contains" filterPlaceholder="Search" field="NetoProduct.syncErrorMessage" filterElement={filterElementCustom5}/>}
        {toggleViewModel === 2 &&
          <Column header="Myob" filter filterMatchMode="contains" filterPlaceholder="Search" field="MyobProduct.syncErrorMessage" filterElement={filterElementCustom6}/>} */}
        <Column filter filterMatchMode="contains" filterPlaceholder="Search" sortable filterField="LastSyncAt" field="lastSyncAtForFilter" filterElement={filterElementCustom9}
        />
      </Row>
    </ColumnGroup>
  );

  const getDataTableSortField = (field: string) => {
    if(field === filterFields.lastSyncAt){
      return "lastSyncAtForFilter";
    } else if(field === filterFields.SKU){
      return "NetoProduct.SKU";
    } else if(field === filterFields.Number){
      return "MyobProduct.Number";
    } else if(field === filterFields.NetoName){
      return "NetoProduct.Name";
    } else if(field === filterFields.MyobName){
      return "MyobProduct.Name";
    } else if(field === filterFields.MyobName){
      return "MyobProduct.Name";
    }
  }

  const getDbFilterField = (field: string) => {
    if(field === 'lastSyncAt'){
      return filterFields.lastSyncAt;
    } else if(field === "NetoProduct.SKU"){
      return filterFields.SKU;
    } else if(field === "MyobProduct.Number"){
      return filterFields.Number;
    } else if(field === "NetoProduct.Name"){
      return filterFields.NetoName;
    } else if(field === "MyobProduct.Name"){
      return filterFields.MyobName;
    } else if(field === "MyobProduct.Name"){
      return filterFields.MyobName;
    } else if(field === "NetoProduct.syncErrorMessage"){
      return filterFields.syncErrorMessageNeto;
    } else if(field === "MyobProduct.syncErrorMessage"){
      return filterFields.syncErrorMessageMyob;
    }

    return '';
  }

  const header = (
    <div className="d-flex justify-content-between">
      <h5 className="text-left">Products</h5>
      <div className="col-6 text-right">
          <button
            type="button"
            className="btn btn-outline-secondary ml-2"
            onClick={() => reset(true)}>
            <div className="fa-layers ml-2">
              <FontAwesomeIcon className="mr-2" icon={faFilter} />
              <FontAwesomeIcon className="mr-2" icon={faSlash} />
            </div>
            Clear
          </button>
      </div>
    </div>
  );

  const template2: PaginatorTemplate = {
    'FirstPageLink': '',
    'LastPageLink': '',
    'NextPageLink': (options) => {
      return <Button label="Next" disabled={isNextDisabled} onClick={() => onPageChange('NEXT')} className="p-button-raised p-button-info" />
    },
    'PageLinks': '',
    'PrevPageLink': (options) => {
      return <Button label="Prev" disabled={isPrevDisabled} onClick={() => onPageChange('PREV')} className="p-button-raised p-button-info" />
    },
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options: any) => {
      const dropdownOptions = [
        { label: 50, value: 50 },
        { label: 100, value: 100 },
        { label: 200, value: 200 },
        { label: 300, value: 300 },
        { label: 400, value: 400 },
        { label: 500, value: 500 },
        { label: 1000, value: 1000 },
        { label: 'All', value: 1 }
      ];
      return (
        <>
          <span className="p-mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Page Size: </span>
          <Dropdown value={lazyParams.pageSize} options={dropdownOptions} onChange={(e) => onPageRowsSizeChanged(e.value)} appendTo={document.body} />
        </>
      );
    },
    'CurrentPageReport': (options: any) => {
      return (
        <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
          {/* {options.first} - {options.last} of {options.totalRecords} */}
        </span>
      )
    }
  };

  const onSort = (event: any) => {
    let SortOrder = -1;
    if(lazyParams?.sortOrder === -1){
      SortOrder = 1;
    }
    let _lazyParams = { ...lazyParams, sortOrder: SortOrder };

    if(event?.sortField === "lastSyncAtForFilter"){
      _lazyParams = { ..._lazyParams, sortField: filterFields.lastSyncAt };
    } 

    if(_lazyParams.filters) {
      const keys = Object.keys((_lazyParams.filters) as any) ?? [];
      if(!keys?.includes('lastSyncAt')){
          _lazyParams.filters = undefined;
          setDateFilter('');
      } 
    }

    if(_lazyParams.pageMode !== 'ALL'){
      _lazyParams.pageMode = '';
    }

    setFilter1('');
    setFilter2('');
    setFilter3('');
    setFilter4('');
    // setFilter5('');
    // setFilter6('');
    setSelectedStatus('');
    setSelectedStatus2('');

    setLazyParams(_lazyParams);
  }

  const onFilter = (event: any) => {
    let _lazyParams = { ...lazyParams, ...event };
    _lazyParams['first'] = 0;
    setLazyParams(_lazyParams);
  }

  return (
    <div className="container-fluid">
      <DataTable
        ref={dt}
        lazy
        paginator
        headerColumnGroup={headerGroup}
        value={ProductData}
        paginatorTemplate={template2}
        first={lazyParams.first}
        rows={lazyParams.pageSize === 1 ? ProductData?.length : lazyParams.pageSize}
        removableSort
        // onPage={onPage}
        onSort={onSort}
        sortField={getDataTableSortField(lazyParams.sortField)}
        onFilter={onFilter}
        filters={lazyParams.filters}
        header={header}
        autoLayout
        sortOrder={lazyParams?.sortOrder === 1 ? 1 : -1}
        emptyMessage={emptyMessageTemplate}
        loading={loading}
        selectionMode="multiple"
        paginatorClassName="p-jc-end"
        scrollable scrollHeight="490px"
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={(data: any) => rowExpansionTemplate([data])}
      >
        <Column expander style={{ width: '3em' }} />
        <Column field="NetoProduct.SKU" />
        <Column field="MyobProduct.Number" />
        <Column field="NetoProduct.Name" />
        <Column field="MyobProduct.Name" />
        <Column body={SourceInventoriedTemplate} field="NetoProduct.IsInventoriedNeto" />
        <Column body={TargetInventoriedTemplate} field="MyobProduct.IsInventoriedMyob" />
        <Column filterField="LastSyncAt" field="lastSyncAtForFilter" body={lastSyncAtBodyTemplate}/>
      </DataTable>
    </div>
  )
}

export default ProductGridComponent