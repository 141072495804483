import { SalesOrderGridModel } from '../features/Order/models/SaleOrderGridModel';
import { SaleOrderEntityModel } from '../features/Order/models/SaleOrderEntityModel';
import { convertOrderEntitytoGridModel, convertOrderEntityToRMAGridModel, convertOrderEntityToPaymentGridModel } from '../features/Order/services/OrderService';
import { QueryDocumentRecordsModel } from "./DataServiceModels";
import { API_SERVER_URL, CLOUD_FUNCTIONS } from "../shared/config/constants";
import { getIdTokenFromLocalStorage } from "./DataService";
import { OrdersReprocessRequest, OrderReprocessRequest } from "../features/Order/models";
import firebase from "../shared/config/firebaseConfig";
import { DATABASE_COLLECTIONS, STATIC_DOCUMENTS_ID } from '../shared/config/constants';
import { Observable } from 'rxjs';
import { writeException } from "../shared/services/LoggingService";
import _ from 'lodash';
// const { REPROCESS_BULK_ORDERS, ORDER_PROCESSING_NETO_TO_MYOB_TASK } = CLOUD_FUNCTIONS;
const { REPROCESS_BULK_ORDERS, ORDER_PROCESSING_NETO_TO_MYOB_TASK,MARK_AS_CLOSE_ORDERS,RE_EXPORT_NETO_RMA_TO_MYOB } = CLOUD_FUNCTIONS;
const db = firebase.firestore();
const { USER_DETAILS, CONNECTORS, ORDERS, RECORDCOUNTER } = DATABASE_COLLECTIONS;
const { ORDER } = STATIC_DOCUMENTS_ID;
export let getOrderSnapshotUnsubscribe: any
export let getOrderRecordCount: any

export const getOrdersTotalCount = (userId: string, connector: string) => {
  return new Observable(subscriber => {
    getOrderRecordCount = db.collection(`${USER_DETAILS}`).doc(userId)
      .collection(`${CONNECTORS}`).doc(connector)
      .collection(`${RECORDCOUNTER}`).doc(ORDER).onSnapshot((snapshot) => {
        subscriber.next(snapshot?.data()?.count);
      });
  });
}

const makeSearchClauses = (ordersRef: firebase.firestore.Query<firebase.firestore.DocumentData>, query: any, sortField: string, sortOrder: number, mode?: string) => {

  let ref = ordersRef;
  if (query && query.length > 0) {
    query?.map((q: any) => {
      if (q?.key === "Date") {
        if (mode && mode === 'ALL') {
          ref = ref.where(q?.key, '>=', q?.value?.startAt).where(q?.key, '<=', q?.value?.endAt);
        } else {
          ref = ref.where(q?.key, '>=', q?.value?.startAt).where(q?.key, '<=', q?.value?.endAt).orderBy(q?.key);
        }
      }
      else if (q?.key === "OutgoingOrder.Status" || q?.key === "IncomingOrder.OrderStatus" || q?.key === "Status") {
        if (mode && mode === 'ALL') {
          ref = ref.where(q?.key, '>=', q?.value).where(q?.key, '<=', q?.value + '\uf8ff').orderBy(q?.key);
        } else {
          ref = ref.where(q?.key, '>=', q?.value).where(q?.key, '<=', q?.value + '\uf8ff').orderBy(q?.key).orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc');
        }
      }
      else if (q?.key === "RmaIncoming.RmaID") {
        ref = ref.where('RMA', 'array-contains', { NetoRmaId: q?.value }).orderBy("RMA");
      }
      else {
        ref = ref.where(q?.key, '>=', q?.value).where(q?.key, '<=', q?.value + '\uf8ff').orderBy(q?.key);
      }
    })
  }
  return ref;
}

export const getOrdersFromFirebase = (userId: string, connector: string, limit: number, sortField: string, sortOrder: number,
  firstVisible?: any, lastVisible?: any, mode?: string, query?: any) => {
  return new Observable(subscriber => {
    console.log("ORDER-GRID sortField: ", sortField);
    console.log("ORDER-GRID query: ", query)

    let ordersRef: firebase.firestore.Query<firebase.firestore.DocumentData> | undefined = undefined;
    if (lastVisible && mode === 'NEXT') {
      ordersRef = db.collection(`${USER_DETAILS}`).doc(userId)
        .collection(`${CONNECTORS}`).doc(connector)
        .collection(`${ORDERS}`).where('Status', "in", ['Imported', 'ExportComplete', 'ExportStart', 'ImportComplete', 'ImportStart']); //.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc');
      if (!_.isEmpty(query)) {
        ordersRef = makeSearchClauses(ordersRef, query, sortField, sortOrder);
        ordersRef = ordersRef.startAfter(lastVisible).limit(limit);
      } else {
        ordersRef = ordersRef.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc').startAfter(lastVisible).limit(limit);
      }
    }
    else if (firstVisible && mode === 'PREV') {
      ordersRef = db.collection(`${USER_DETAILS}`).doc(userId)
        .collection(`${CONNECTORS}`).doc(connector)
        .collection(`${ORDERS}`).where('Status', "in", ['Imported', 'ExportComplete', 'ExportStart', 'ImportComplete', 'ImportStart']); //.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc');
      if (!_.isEmpty(query)) {
        ordersRef = makeSearchClauses(ordersRef, query, sortField, sortOrder);
        ordersRef = ordersRef.endBefore(firstVisible).limitToLast(limit);
      } else {
        ordersRef = ordersRef.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc').endBefore(firstVisible).limitToLast(limit);
      }
    }
    else if (mode === 'ALL') {
      ordersRef = db.collection(`${USER_DETAILS}`).doc(userId)
        .collection(`${CONNECTORS}`).doc(connector)
        .collection(`${ORDERS}`).where('Status', "in", ['Imported', 'ExportComplete', 'ExportStart', 'ImportComplete', 'ImportStart']); //.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc');
      if (!_.isEmpty(query)) {
        ordersRef = makeSearchClauses(ordersRef, query, sortField, sortOrder, 'ALL');
        ordersRef = ordersRef.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc');
      }
      else {
        ordersRef = ordersRef.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc');
      }
    }
    else {
      ordersRef = db.collection(`${USER_DETAILS}`).doc(userId)
        .collection(`${CONNECTORS}`).doc(connector)
        .collection(`${ORDERS}`).where('Status', "in", ['Imported', 'ExportComplete', 'ExportStart', 'ImportComplete', 'ImportStart']); //.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc');
      if (!_.isEmpty(query)) {
        ordersRef = makeSearchClauses(ordersRef, query, sortField, sortOrder);
        ordersRef = ordersRef.limit(limit)
      } else {
        ordersRef = ordersRef?.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc').limit(limit)
      }
    }

    if (ordersRef) {
      getOrderSnapshotUnsubscribe = ordersRef.onSnapshot((snapshot) => {
        let ordersData: Array<SalesOrderGridModel> = [];
        snapshot?.docs.map((doc: any, index: number) => {
          let entity: SaleOrderEntityModel = doc?.data();
          entity = { ...entity, id: doc?.id };
          ordersData.push(convertOrderEntitytoGridModel(entity, index));
        });
        if (ordersData.length) {
          let latestDateOrder = ordersData.map((e) => {
            return e.datePlaced;
          }).sort().reverse()[0];
          let findIndex = ordersData.findIndex((orders) =>
            orders.datePlaced === latestDateOrder
          );
          if (findIndex >= -1) {
            ordersData[findIndex].new = true;
          }
        }
        let lastRecord = snapshot?.docs[snapshot?.docs?.length - 1];
        let firstRecord = snapshot?.docs[0];
        // console.log("LAST RECORD: ", lastRecord);
        // console.log("FIRST RECORD: ", firstRecord);
        subscriber.next({ ordersData: ordersData, startBefore: firstRecord, startAfter: lastRecord });
      });
    }
  })
}

export const reprocessOrdersRequest = (
  userId: string,
  connectorId: string,
  body: OrdersReprocessRequest,
  isForced?: boolean
): Promise<QueryDocumentRecordsModel> => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log('OrderDataService reprocessOrdersRequest 131: ', connectorId);
      const idToken = await getIdTokenFromLocalStorage();
      let res = await fetch(
        `${API_SERVER_URL}/${REPROCESS_BULK_ORDERS}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${idToken}`,
          },
          body: JSON.stringify({
            connectorId,
            userId,
            orderData: body.orders,
            isForced
          }),
        }
      );
      if (res.ok) {
        resolve({ isSucceed: true });
      } else {
        resolve({ isSucceed: false });
      }
    } catch (e: any) {
      resolve({ isSucceed: false, errorMessage: e.message });
      await writeException(`ErrorMessage: ${e.message}, UserID: ${userId}, ConnectorID: ${connectorId}`);
    }
  });
};

export const markAsCloseOrdersRequestRequest = (
  userId: string,
  connectorId: string,
  body: Array<string>,
): Promise<QueryDocumentRecordsModel> => {
  return new Promise(async (resolve, reject) => {
    try {
      const idToken = await getIdTokenFromLocalStorage();
      let res = await fetch(
        `${API_SERVER_URL}/${MARK_AS_CLOSE_ORDERS}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${idToken}`,
          },
          body: JSON.stringify({
            connectorId,
            userId,
            orderIds: body
          }),
        }
      );
      if (res.ok) {
        resolve({ isSucceed: true });
      } else {
        resolve({ isSucceed: false });
      }
    } catch (e: any) {
      resolve({ isSucceed: false, errorMessage: e.message });
      await writeException(`ErrorMessage: ${e.message}, UserID: ${userId}, ConnectorID: ${connectorId}`);
    }
  });
};

export const reprocessOrderRequest = (
  body: OrderReprocessRequest
): Promise<QueryDocumentRecordsModel> => {
  return new Promise(async (resolve, reject) => {
    try {
      const idToken = await getIdTokenFromLocalStorage();
      let res = await fetch(`${API_SERVER_URL}/${ORDER_PROCESSING_NETO_TO_MYOB_TASK}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': "application/json",
            Authorization: `${idToken}`,
          },
          body: JSON.stringify(body)
        }
      );
      if (res.ok) {
        resolve({ isSucceed: true });
      } else {
        let response = await res.json();
        resolve({ isSucceed: false, errorMessage: response.message });
      }
    } catch (e: any) {
      resolve({ isSucceed: false, errorMessage: e.message });
      await writeException(`ErrorMessage: ${e.message}`);
    }
  });
};

export const reExportOrderRMARequest = (
  body: OrderReprocessRequest
): Promise<QueryDocumentRecordsModel> => {
  return new Promise(async (resolve, reject) => {
    try {
      const idToken = await getIdTokenFromLocalStorage();
      let res = await fetch(`${API_SERVER_URL}/${RE_EXPORT_NETO_RMA_TO_MYOB}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': "application/json",
            Authorization: `${idToken}`,
          },
          body: JSON.stringify(body)
        }
      );
      if (res.ok) {
        resolve({ isSucceed: true });
      } else {
        let response = await res.json();
        resolve({ isSucceed: false, errorMessage: response.message });
      }
    } catch (e: any) {
      resolve({ isSucceed: false, errorMessage: e.message });
      await writeException(`ErrorMessage: ${e.message}`);
    }
  });
};


export const getFailedOrdersFromFirebaseOld = (userId: string, connector: string) => {
  return new Observable(subscriber => {
    getOrderSnapshotUnsubscribe = db.collection(`${USER_DETAILS}`).doc(userId)
      .collection(`${CONNECTORS}`).doc(connector)
      .collection(`${ORDERS}`).where("Status", "in", ['ExportFailed', 'ImportFailed', 'NotProcessed', 'ImportStart', 'ExportStart']).onSnapshot((snapshot) => {
        let ordersData: Array<SalesOrderGridModel> = [];
        snapshot?.docs.map((doc: any, index: number) => {
          let entity: SaleOrderEntityModel = doc?.data();
          entity = { ...entity, id: doc?.id };
          ordersData.push(convertOrderEntitytoGridModel(entity, index));
        });
        if (ordersData.length) {
          let latestDateOrder = ordersData.map((e) => {
            return e.datePlaced;
          }).sort().reverse()[0];
          let findIndex = ordersData.findIndex((orders) =>
            orders.datePlaced === latestDateOrder
          );
          if (findIndex >= -1) {
            ordersData[findIndex].new = true;
          }
        }
        subscriber.next(ordersData);
      });
  })
}


export const getOrdersFromFirebaseOld = (userId: string, connector: string) => {
  return new Observable(subscriber => {
    getOrderSnapshotUnsubscribe = db.collection(`${USER_DETAILS}`).doc(userId)
      .collection(`${CONNECTORS}`).doc(connector)
      .collection(`${ORDERS}`).onSnapshot((snapshot) => {
        let ordersData: Array<SalesOrderGridModel> = [];
        snapshot?.docs.map((doc: any, index: number) => {
          let entity: SaleOrderEntityModel = doc?.data();
          entity = { ...entity, id: doc?.id };
          ordersData.push(convertOrderEntitytoGridModel(entity, index));
        });
        if (ordersData.length) {
          let latestDateOrder = ordersData.map((e) => {
            return e.datePlaced;
          }).sort().reverse()[0];
          let findIndex = ordersData.findIndex((orders) =>
            orders.datePlaced === latestDateOrder
          );
          if (findIndex >= -1) {
            ordersData[findIndex].new = true;
          }
        }
        subscriber.next(ordersData);
      });
  })
}






export const getRMAFromFirebase = (userId: string, connector: string,paymentDateFilter:string,sortOrder:number =-1) => {
  return new Observable(subscriber => {
    getOrderSnapshotUnsubscribe = db.collection(`${USER_DETAILS}`).doc(userId)
      .collection(`${CONNECTORS}`).doc(connector)
      .collection(`${ORDERS}`)
      .where('Status', "in", ['Imported', 'ExportComplete', 'ExportStart', 'ImportComplete', 'ImportStart'])
      .where('Date', '>=', paymentDateFilter).orderBy('Date', sortOrder === -1 ? 'desc' : 'asc').orderBy("RMA")
      .onSnapshot((snapshot) => {
        let rmaData: Array<any> = [];
        snapshot?.docs.map((doc: any, index: number) => {
          let entity: SaleOrderEntityModel = doc?.data();
          entity = { ...entity, id: doc?.id };
          for (const singleRMA of entity?.RMA) {
            rmaData.push(convertOrderEntityToRMAGridModel(singleRMA, entity, index));
          }
        });
        subscriber.next({ rmaData });
      });
  })
}






export const getPaymentFromFirebase = (userId: string, connector: string,paymentDateFilter:string,sortOrder:number =-1) => {
  return new Observable(subscriber => {
    getOrderSnapshotUnsubscribe = db.collection(`${USER_DETAILS}`).doc(userId)
      .collection(`${CONNECTORS}`).doc(connector)
      .collection(`${ORDERS}`)
      .where('Status', "in", ['Imported', 'ExportComplete', 'ExportStart', 'ImportComplete', 'ImportStart'])
      .where('Date', '>=', paymentDateFilter).orderBy('Date', sortOrder === -1 ? 'desc' : 'asc').orderBy("Payments")
      .onSnapshot((snapshot) => {
        let paymentData: Array<any> = [];
        snapshot?.docs.map((doc: any, index: number) => {
          let entity: any = doc?.data();
          entity = { ...entity, id: doc?.id };
          for (let singlePayment of entity?.Payments?.payments) {
            paymentData.push(convertOrderEntityToPaymentGridModel(singlePayment, entity, index));
          }
        });
        subscriber.next({ paymentData });
      });
  })
}