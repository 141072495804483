import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { useParams } from 'react-router-dom';
import { Accordion, AccordionTab } from "primereact/accordion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/free-solid-svg-icons';
import _ from "lodash";
import Label from '../../../../components/Label';
import Input from '../../../../components/Input';
import Boolean from '../../../../components/Boolean';
import SelectInput from '../../../../components/SelectInput';
import MultiSelectInput from '../../../../components/MultiSelectInput';
import { AppContext } from '../../../../store/AppContext';
import * as util from '../../../../shared/Utilities';
import AsyncSelectInput from "../../../../components/AsyncSelectInput";
import { SalesOrderMappingsModel } from "../../../../shared/models/mappings/UserMappingModels";
import { synchronizePaymentsMyobToNeto, synchronizeRMANetoToMyob, getMappingItemsBySearch } from '../services/MappingService';
import './NetoSalesOrderToMYOBInvoiceBasic.css';
import NetoSalesOrderToMYOBInvoiceBasicPaymentOptions from "./Basic/NetoSalesOrderToMYOBInvoiceBasicPaymentOptions";
import NetoSalesOrderToMYOBInvoiceBasicInvoiceNumber from "./Basic/NetoSalesOrderToMYOBInvoiceBasicInvoiceNumber";
import NetoSalesOrderToMYOBInvoiceBasicRMAProcessing from "./Basic/NetoSalesOrderToMYOBInvoiceBasicRMAProcessing";
import NetoSalesOrderToMYOBInvoiceBasicAdvanceOptions from "./Basic/NetoSalesOrderToMYOBInvoiceBasicAdvanceOptions";

export interface INetoSalesOrderToMYOBInvoiceBasicProps {
    salesOrderMappings: SalesOrderMappingsModel,
    setSalesOrderMappings: (data: SalesOrderMappingsModel) => void
    storeName: string
    isMappingsEmpty: boolean
    errors?: any,
    touched?: any,
    submitCount: number,
    onInputBlurred: (name: any) => void
}

const NetoSalesOrderToMYOBInvoiceBasic: React.FC<INetoSalesOrderToMYOBInvoiceBasicProps> = (props) => {
    let { State, Dispatch } = useContext(AppContext);
    const { id } = useParams<any>();
    const [salesOrderBasicMapping, setSalesOrderBasicMapping] = useState<SalesOrderMappingsModel>({} as SalesOrderMappingsModel);
    const [StatusesToggle, setStatusesToggle] = useState<boolean>(false);
    const [RmaToggle, setRmaToggle] = useState<boolean>(false);
    const [AdvanceOptionsToggle, setAdvanceOptionsToggle] = useState<boolean>(false);
    const [PaymentOptionsToggle, setPaymentOptionsToggle] = useState<boolean>(false);
    const [InvoiceNumberToggle, setInvoiceNumberToggle] = useState<boolean>(false);

    const headerTemplate = (headingText: string, hasError: boolean, smallText?: string) => (<React.Fragment><div className="d-flex"><h6>{headingText} {smallText && <small>({smallText})</small>}</h6>
        {hasError && <small className="ml-3 mt-1 text-danger">(There are some validation errors)</small>}</div></React.Fragment>);

    const header = {
        sourceHeader: 'Maropost',
        targetHeader: 'MYOB AccountRight',
        header1: headerTemplate("Invoice Number", false),
        header2: headerTemplate("Order Processing", StatusesToggle),
        header3: headerTemplate("RMA Processing", RmaToggle),
        header4: headerTemplate("Advance Options", AdvanceOptionsToggle),
        header5: headerTemplate("Payment Options", PaymentOptionsToggle),
    };
    const [touchedFields, setTouchedFields] = useState(props.touched);
    const [orderStatuses, setOrderStatuses] = useState<any>([]);
    const [orderStatusesLocal, setOrderStatusesLocal] = useState<any>([]);
    const [allOptionsForItemsUI, setAllOptionsForItemsUI] = useState<any>([]);
    const [orderStatusState, setOrderStatusState] = useState<string>('parent');
    const [syncMyobStockToNetoLoading, setSyncMyobStockToNetoLoading] = useState<boolean>(false);
    const [syncRmaLoading, setSyncRmaLoading] = useState<boolean>(false);

    const [header1Active, setHeader1Active] = useState<number | null>();
    const [header2Active, setHeader2Active] = useState<number | null>();
    const [header3Active, setHeader3Active] = useState<number | null>();
    const [header4Active, setHeader4Active] = useState<number | null>();
    const [header5Active, setHeader5Active] = useState<number | null>();


    useEffect(() => {
        setTouchedFields(props.touched);
    }, [props.touched]);

    useEffect(() => {
        setSalesOrderBasicMapping(props?.salesOrderMappings ?? {} as SalesOrderMappingsModel);
    }, [props.salesOrderMappings]);

    useEffect(() => {
        const salesOrderKeys = props?.errors?.salesOrderMapping ? Object.keys(props?.errors?.salesOrderMapping) : [];
        //#region Invoice Number
        if (props.submitCount > 0 && (props.touched?.salesOrderMapping?.orderNumberPrefix && props.errors?.salesOrderMapping?.orderNumberPrefix)) {
            setInvoiceNumberToggle(true);
            setHeader1Active(0);
        }
        //#endregion
        //#region RMA Sync
        if (props.submitCount > 0 && ((props.touched?.salesOrderMapping?.rmaSync && props.errors?.salesOrderMapping?.rmaSync)
            ||
            (props.touched?.salesOrderMapping?.stockWriteOffAccount && props.errors?.salesOrderMapping?.stockWriteOffAccount))) {
            setRmaToggle(true);
            setHeader3Active(0);
        }
        else if(!salesOrderKeys?.includes('rmaSync') || !salesOrderKeys?.includes('stockWriteOffAccount')){
            setRmaToggle(false);
        }
        //#endregion
        //#region Statuses
        if (props.submitCount > 0 && ((props.touched?.salesOrderMapping?.retreiveOrderWithStatuses && props.errors?.salesOrderMapping?.retreiveOrderWithStatuses)
            ||
            (props.touched?.salesOrderMapping?.orderStatus && props.errors?.salesOrderMapping?.orderStatus))
        ) {
            setStatusesToggle(true);
            setHeader2Active(0);
        }
        else if(!salesOrderKeys?.includes('retreiveOrderWithStatuses') || !salesOrderKeys?.includes('orderStatus')){
            setStatusesToggle(false);
        }
        //#endregion
        //#region Advance Options
        if (props.submitCount > 0 && ((props.touched?.salesOrderMapping?.dispatchedOrderDate && props.errors?.salesOrderMapping?.dispatchedOrderDate) ||
            (props.touched?.salesOrderMapping?.surchargeItem && props.errors?.salesOrderMapping?.surchargeItem)
            || (props.touched?.salesOrderMapping?.shippingItem && props.errors?.salesOrderMapping?.shippingItem)
            || (props.touched?.salesOrderMapping?.discountItem && props.errors?.salesOrderMapping?.discountItem)
            || (props.touched?.salesOrderMapping?.defaultLineItemAccount && props.errors?.salesOrderMapping?.defaultLineItemAccount)
            || (props.touched?.salesOrderMapping?.assignCustomer && props.errors?.salesOrderMapping?.assignCustomer)
            || (props.touched?.salesOrderMapping?.invoiceDeliveryStatus && props.errors?.salesOrderMapping?.invoiceDeliveryStatus)
            || (props.touched?.salesOrderMapping?.defaultNonInventoriedItem && props.errors?.salesOrderMapping?.defaultNonInventoriedItem)
        )) {
            setAdvanceOptionsToggle(true);
            setHeader4Active(0);
        }
        else if (
            // advanceOptions.assignCustomer === "GenericOnlineSaleCustomer" ||
            props.touched?.salesOrderMapping?.defaultOnlineCustomerValue &&
            props.errors?.salesOrderMapping?.defaultOnlineCustomerValue) {
            setAdvanceOptionsToggle(true);
            setHeader4Active(0);
        }
        else if (!salesOrderKeys?.includes('dispatchedOrderDate') || !salesOrderKeys?.includes('surchargeItem') ||
            !salesOrderKeys?.includes('shippingItem') || !salesOrderKeys?.includes('defaultLineItemAccount') ||
            !salesOrderKeys?.includes('discountItem') ||
            !salesOrderKeys?.includes('assignCustomer') || !salesOrderKeys?.includes('invoiceDeliveryStatus') ||
            !salesOrderKeys?.includes('defaultNonInventoriedItem')) {
            setAdvanceOptionsToggle(false);
        }
        //#endregion
        //#region Payment Options
        if(props.submitCount > 0 && ((props.touched?.salesOrderMapping?.selectedPaymentMethodMYOBToNeto && props.errors?.salesOrderMapping?.selectedPaymentMethodMYOBToNeto) ||
        (props.touched?.salesOrderMapping?.myobToNetoPaymentFrequency && props.errors?.salesOrderMapping?.myobToNetoPaymentFrequency))) {
            setPaymentOptionsToggle(true);
            setHeader5Active(0);
        }
        else if(!salesOrderKeys?.includes('selectedPaymentMethodMYOBToNeto') ||
        !salesOrderKeys?.includes('myobToNetoPaymentFrequency')){
            setPaymentOptionsToggle(false);
        }
        //#endregion
    }, [props.touched?.salesOrderMapping, props.errors?.salesOrderMapping]);

   
    useEffect(() => {
        let orderStatusesLocal: any = []
        if (salesOrderBasicMapping.retreiveOrderWithStatuses && salesOrderBasicMapping.retreiveOrderWithStatuses.length > 0) {
            salesOrderBasicMapping.retreiveOrderWithStatuses.map((value: string) => {
                let netoStatus = State.formsLookup?.retriveOrdersFromNeto?.retreiveOrderWithStatuses?.values?.find(f => f.value === value)
                if (orderStatusState === "local") {
                    let selectedStatus = orderStatuses?.find((f: any) => f.statuses?.value === netoStatus?.value)
                    if (selectedStatus) {
                        orderStatusesLocal.push({ statuses: netoStatus, selectedValue: selectedStatus?.selectedValue });
                    }
                    else {
                        orderStatusesLocal.push({ statuses: netoStatus, selectedValue: "" })
                    }
                }
                else {
                    let selectedStatus = props?.salesOrderMappings?.orderStatus?.find((f: any) => f.netoStatus === netoStatus?.value);
                    if (selectedStatus) {
                        orderStatusesLocal.push({ statuses: netoStatus, selectedValue: selectedStatus?.myobSaleType });
                    }
                    else {
                        orderStatusesLocal.push({ statuses: netoStatus, selectedValue: "" })
                    }
                }
            }); 
            setOrderStatuses(orderStatusesLocal);
        }
        else {
            setOrderStatuses([]);
        }
    }, [State.formsLookup?.retriveOrdersFromNeto?.retreiveOrderWithStatuses, props.salesOrderMappings?.orderStatus, salesOrderBasicMapping.retreiveOrderWithStatuses]);

    useEffect(() => {
        if (orderStatusState === 'local')
            setSalesOrderMappings();
    }, [orderStatusState]);

    const setSelectedOrderStatus = (myobValue: string, netoValue: string, index: any) => {
        let state = 'parent';
        let orderStatusesCopy: any = [...orderStatuses];
        orderStatusesCopy[index].selectedValue = myobValue;

        if (orderStatusesCopy[index].statuses.value === "New" && orderStatusesCopy[index].selectedValue === "SaleInvoice") {
            setSalesOrderBasicMapping({ ...salesOrderBasicMapping, retreiveOrderWithStatuses: ["New"] }); //setSelectedRetreiveOrderStatus(["New"]);
            state = 'local';
            setOrderStatusesLocal([{
                netoStatus: "New",
                myobSaleType: "SaleInvoice"
            }]);
        }
        setOrderStatuses(orderStatusesCopy);
        setOrderStatusState(state);
    }

    const onInputBlur = (touched: any) => {
        props.onInputBlurred(touched);
    }

    const setSalesOrderMappings = () => {
        const salesOrderMappings = {...salesOrderBasicMapping};
        salesOrderMappings.defaultOnlineCustomerValue = (salesOrderMappings.assignCustomer !== "GenericOnlineSaleCustomer" ? "" : salesOrderMappings.defaultOnlineCustomerValue ?? salesOrderMappings.defaultOnlineCustomerValue )

        if(orderStatusState === 'local'){
            salesOrderMappings.orderStatus = orderStatusesLocal;
        }
        else {
            salesOrderMappings.orderStatus = orderStatuses.map((status: any) => ({
                netoStatus: status?.statuses?.value ?? '',
                myobSaleType: status?.selectedValue ?? ''
            })) ?? [];
        }
        props.setSalesOrderMappings(salesOrderMappings);
    }

    const onSubComponentsFocusChanged = (saleOrderMapping: SalesOrderMappingsModel) => {
        props.setSalesOrderMappings(saleOrderMapping);
    }

    const syncMyobToNetoPayments = async () => {
        setSyncMyobStockToNetoLoading(true);
        let result = await synchronizePaymentsMyobToNeto(State?.currentUser?.currentUserId, id);
        if (result?.isSucceed) {
            Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: true, message: result?.message } });
            setSyncMyobStockToNetoLoading(false);
        }
        else {
            Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: false, message: result?.message } });
            setSyncMyobStockToNetoLoading(false);
        }
    }

    const syncNetoToMyobRma = async () => {
        setSyncRmaLoading(true);
        let result = await synchronizeRMANetoToMyob(State?.currentUser?.currentUserId, id);
        if (result?.isSucceed) {
            Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: true, message: result?.message } });
            setSyncRmaLoading(false);
        }
        else {
            Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: false, message: result?.message } });
            setSyncRmaLoading(false);
        }
    }

    const getUiMappingItemsFromServer = async (str: string) => {
        let res = await getMappingItemsBySearch(State?.currentUser?.currentUserId, id, str);
        if (res.isSucceed) {
            return res?.result;
        }
        else {
            return [];
        }
    }

    return (
        <div className='container-fluid'>
            <h5 className='float-left'>Configurations</h5> <br />
            <hr />
            <div className="row text-left ml-2">
                <div className="col-6">
                    <h6>{header.sourceHeader}</h6>
                </div>
                <div className="col-6">
                    <h6>{header.targetHeader}</h6>
                </div>
            </div>
            <hr />
            <Accordion activeIndex={header1Active}>
                <AccordionTab header={header.header1}>
                    <NetoSalesOrderToMYOBInvoiceBasicInvoiceNumber
                        touched={props.touched}
                        errors={props.errors}
                        salesOrderBasicMappings={salesOrderBasicMapping}
                        setSalesOrderBasicMapping={setSalesOrderBasicMapping}
                        isMappingsEmpty={props.isMappingsEmpty}
                        onInputBlur={onInputBlur}
                        syncMyobStockToNetoLoading={syncMyobStockToNetoLoading}
                        syncMyobToNetoPayments={syncMyobToNetoPayments}
                        onFocusChanged={onSubComponentsFocusChanged}
                    />
                </AccordionTab>
            </Accordion>

            <Accordion activeIndex={header2Active}>
                <AccordionTab header={header.header2}>
                    <Boolean
                        hasError={
                            props.touched?.salesOrderMapping?.sendOrdersFromMYOBToNeto &&
                                props.errors?.salesOrderMapping?.sendOrdersFromMYOBToNeto ? true : false}
                        value={salesOrderBasicMapping.sendOrdersFromMYOBToNeto}
                        onChange={(val: boolean) => setSalesOrderBasicMapping({ ...salesOrderBasicMapping, sendOrdersFromMYOBToNeto: val })}
                        text={"Send Orders from Maropost to MYOB AccountRight"}
                        onBlur={(e: any) => {
                            onInputBlur({
                                ...touchedFields, salesOrderMapping: {
                                    ...touchedFields.salesOrderMapping, sendOrdersFromMYOBToNeto: true
                                }
                            });
                            if (props.salesOrderMappings.sendOrdersFromMYOBToNeto !== salesOrderBasicMapping.sendOrdersFromMYOBToNeto)
                                setSalesOrderMappings();
                        }}
                    />
                    <br />
                    <div className='row'>
                        <div className='col-md-6 d-flex'>
                            <Label
                                text={State.formsLookup?.retriveOrdersFromNeto?.retreiveOrderWithStatuses.label ?? ''}
                            /> <p className="text-danger">*</p>
                        </div>
                        <div className='col-md-6'>
                            <MultiSelectInput
                                hasError={
                                    props.touched?.salesOrderMapping?.retreiveOrderWithStatuses &&
                                        props.errors?.salesOrderMapping?.retreiveOrderWithStatuses ? true : false}
                                options={State.formsLookup?.retriveOrdersFromNeto?.retreiveOrderWithStatuses.values!}
                                onChange={(value: Array<string>) => setSalesOrderBasicMapping({ ...salesOrderBasicMapping, retreiveOrderWithStatuses: value })} //{ setSelectedRetreiveOrderStatus(value); }}
                                selectedOptions={salesOrderBasicMapping.retreiveOrderWithStatuses}
                                onBlur={() => {
                                    onInputBlur({
                                        ...touchedFields, salesOrderMapping: {
                                            ...touchedFields.salesOrderMapping, retreiveOrderWithStatuses: true
                                        }
                                    });
                                    if (props.salesOrderMappings.retreiveOrderWithStatuses !== salesOrderBasicMapping.retreiveOrderWithStatuses)
                                        setSalesOrderMappings();
                                }}
                            />
                        </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-md-6 d-flex'>
                            <Label text={'Order Status'} /> <p className="text-danger">*</p>
                        </div>
                        <div className="col-md-6">
                            <Boolean
                                hasError={
                                    props.touched?.salesOrderMapping?.orderCanceled &&
                                        props.errors?.salesOrderMapping?.orderCanceled ? true : false}
                                value={salesOrderBasicMapping.orderCanceled}
                                onChange={(val: boolean) => setSalesOrderBasicMapping({ ...salesOrderBasicMapping, orderCanceled: val })}
                                text={"Delete Sale Order, If Order Status is Cancelled"}
                                onBlur={(e: any) => {
                                    onInputBlur({
                                        ...touchedFields, salesOrderMapping: {
                                            ...touchedFields.salesOrderMapping, orderCanceled: true
                                        }
                                    });
                                    if (props.salesOrderMappings.orderCanceled !== salesOrderBasicMapping.orderCanceled)
                                        setSalesOrderMappings();
                                }}
                            />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-6"> </div>
                        <div className='col-md-6 text-left'>
                            {orderStatuses?.map((orderStatus: any, index: any) => (
                                <div className='row border-bottom mb-2' key={index}>
                                    <div className='col-md-6'><Label text={orderStatus?.statuses?.label} /></div>
                                    <div className='col-md-6 mb-2'>
                                        <SelectInput
                                            hasError={!orderStatus?.selectedValue &&
                                                props.touched?.salesOrderMapping?.orderStatus &&
                                                props.errors?.salesOrderMapping?.orderStatus ? true : false}
                                            options={State.formsLookup?.retriveOrdersFromNeto?.orderStatuses!}
                                            selectedOptionValue={orderStatus?.selectedValue}
                                            onChange=
                                            {(value: any) => {
                                                setSelectedOrderStatus(value, orderStatus.statuses.value, index)
                                            }}
                                            onBlur={(e: any) => {
                                                onInputBlur({
                                                    ...touchedFields, salesOrderMapping: {
                                                        ...touchedFields.salesOrderMapping, orderStatus: true
                                                    }
                                                });
                                                if (props.salesOrderMappings.orderStatus !== orderStatuses) { setSalesOrderMappings(); setOrderStatusState('parent'); }
                                            }}
                                        />
                                    </div>
                                </div>
                            ))
                            }
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>

            <Accordion activeIndex={header3Active}>
                <AccordionTab header={header.header3}>
                    <NetoSalesOrderToMYOBInvoiceBasicRMAProcessing 
                        touched={props.touched}
                        errors={props.errors}
                        salesOrderBasicMappings={salesOrderBasicMapping}
                        setSalesOrderBasicMapping={setSalesOrderBasicMapping}
                        isMappingsEmpty={props.isMappingsEmpty}
                        onInputBlur={onInputBlur}
                        syncRmaLoading={syncRmaLoading}
                        syncNetoToMyobRma={syncNetoToMyobRma}
                        onFocusChanged={onSubComponentsFocusChanged}
                    />
                </AccordionTab>
            </Accordion>

            <Accordion activeIndex={header4Active}>
                <AccordionTab header={header.header4}>
                    <NetoSalesOrderToMYOBInvoiceBasicAdvanceOptions 
                        touched={props.touched}
                        errors={props.errors}
                        salesOrderBasicMappings={salesOrderBasicMapping}
                        setSalesOrderBasicMapping={setSalesOrderBasicMapping}
                        isMappingsEmpty={props.isMappingsEmpty}
                        onInputBlur={onInputBlur}
                        getUiMappingItemsFromServer={getUiMappingItemsFromServer}
                        storeName={props.storeName}
                        syncMyobToNetoPayments={syncMyobToNetoPayments}
                        onFocusChanged={onSubComponentsFocusChanged}
                    />
                </AccordionTab>
            </Accordion>

            <Accordion activeIndex={header5Active}>
                <AccordionTab header={header.header5}>
                    <NetoSalesOrderToMYOBInvoiceBasicPaymentOptions
                        touched={props.touched}
                        errors={props.errors}
                        salesOrderBasicMappingsPaymentOptions={salesOrderBasicMapping}
                        setSalesOrderBasicMappingPaymentOptions={setSalesOrderBasicMapping}
                        isMappingsEmpty={props.isMappingsEmpty}
                        onInputBlur={onInputBlur}
                        syncMyobStockToNetoLoading={syncMyobStockToNetoLoading}
                        syncMyobToNetoPayments={syncMyobToNetoPayments}
                        onFocusChanged={onSubComponentsFocusChanged}
                    />
                </AccordionTab>
            </Accordion>
        </div>
    )
}

export default NetoSalesOrderToMYOBInvoiceBasic
