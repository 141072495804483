import * as React from "react";
import { useEffect, useState, useContext } from "react";
import _ from "lodash";
import Label from '../../../../components/Label';
import SelectInput from '../../../../components/SelectInput';
import { AppContext } from '../../../../store/AppContext';
import { WarehouseCommunicationModel } from "../../../../shared/models/mappings/UserMappingModels";
import { validateWarehouseMappings } from "../services/MappingService";

export interface IWarehouseMappingProps {
    warehouseMappings: Array<WarehouseCommunicationModel>,
    // onChange: (value: any) => void,
    // errors: any,
    // touched: any,
    onInputBlurred: (obj: any) => void,
}

const WarehouseMapping: React.FC<IWarehouseMappingProps> = (props) => {
    let { State } = useContext(AppContext);

    const [WarehouseMappings, setWarehouseMappings] = useState<Array<WarehouseCommunicationModel>>(props?.warehouseMappings ?? []);
    const header = {
        sourceHeader: 'Maropost',
        targetHeader: 'MYOB AccountRight',
    };

    useEffect(() => {
        setWarehouseMappings(props?.warehouseMappings ?? [] as Array<WarehouseCommunicationModel>);
    }, [props?.warehouseMappings]);

    // useEffect(() => {
    //     setTouchedFields(props?.touched);
    // }, [props?.touched]);

    const setWarehouseMapping = (selectedValue: string, netoStore: any, index: number) => {
        let locIndex = State.formsLookup?.warehouseMapping?.myobLocations.findIndex((f: any) => f.value === selectedValue)
        let locName: any = {}
        if (locIndex >= 0) {
            locName = State.formsLookup?.warehouseMapping?.myobLocations[locIndex]
        }
        let mappingCopy = [...props.warehouseMappings];
        mappingCopy[index].selectedValue = selectedValue;
        mappingCopy[index].selectedName = locName?.label;
        mappingCopy[index].identifier = locName?.identifier;
        setWarehouseMappings(mappingCopy);
        // props.onChange(mappingCopy);
    };

    const onWarehouseBlur = () => {
        const validatedWarehouseMappings = validateWarehouseMappings(WarehouseMappings);
        setWarehouseMappings(validatedWarehouseMappings);
        props.onInputBlurred(WarehouseMappings);
    }

    // const setWarehouseMappingsToParent = () => {
    //     props.onChange(WarehouseMappings);
    // }

    return (
        <div className="container-fluid">
            {WarehouseMappings && WarehouseMappings?.length > 0 ?
                <div>
                    <div className="row text-left ml-2">
                        <div className="col-6">
                            <h6>{header.sourceHeader}</h6>
                        </div>
                        <div className="col-6 ">
                            <h6>{header.targetHeader}</h6>
                        </div>
                    </div>
                    <hr />
                    <br />
                    {WarehouseMappings?.map((obj: WarehouseCommunicationModel, index: number) => {
                        return <div key={index}>
                            <div className="row">
                                <div className="col-6 d-flex">
                                    <Label text={obj.warehouse.label} /> <p className="text-danger">*</p>
                                </div>
                                <div className="col-6">
                                    <SelectInput
                                        hasError={_.isEmpty(obj.selectedValue) &&
                                            obj.isError && obj.isTouched ? true : false
                                        }
                                        options={State.formsLookup?.warehouseMapping?.myobLocations!} 
                                        selectedOptionValue={obj.selectedValue}
                                        onChange={(value: any) => {
                                            setWarehouseMapping(value, obj.warehouse.value, index)
                                        }}
                                        onBlur={onWarehouseBlur}
                                    />
                                </div>
                            </div>
                            <br />
                        </div>
                    }
                    )}
                </div>
                :
                <div className="alert alert-info" role="alert">
                    No Warehouse Found!
                </div>
            }
        </div>
    )
}

export default WarehouseMapping
