import * as React from "react";
import { useParams } from 'react-router-dom';
import RMADetailsContainer from "../features/Order/RMADetailsContainer";

const RMADetailsPage: React.FC<any> = (props) => {
  const { orderId, connectorId,netoRMAId } = useParams<any>();

  return (
    <div>
      <RMADetailsContainer
        docId={orderId}
        selectedConnector={connectorId}
        netoRMAId={netoRMAId}
      />
    </div>
  );
};

export default RMADetailsPage;
