import * as React from "react";
import { useEffect, useState, useContext } from "react";
import _ from "lodash";
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';
import Boolean from '../../../../components/Boolean';
import Label from '../../../../components/Label';
import SelectInput from '../../../../components/SelectInput';
import { AppContext } from '../../../../store/AppContext';
import * as util from '../../../../shared/Utilities';
import { synchronizeCustomersMyobToNeto } from '../services/MappingService';
import { CustomerMappingsMyobToNetoModel } from "../../../../shared/models/mappings/UserMappingModels";

export interface IMapMYOBCustomerToNetoProps {
    myobCustomerToNeto: CustomerMappingsMyobToNetoModel,
    setMyobCustomerToNeto: (value: CustomerMappingsMyobToNetoModel) => void,
    isMappingsEmpty: boolean,
    errors: any,
    touched: any,
    onInputBlurred: (obj: any) => void
}

const MapMYOBCustomerToNeto: React.FC<IMapMYOBCustomerToNetoProps> = (props) => {
    let { State, Dispatch } = useContext(AppContext);
    const { id } = useParams<any>();

    const [customerMappingsMyobToNeto, setCustomerMappingsMyobToNeto] = useState<CustomerMappingsMyobToNetoModel>({} as CustomerMappingsMyobToNetoModel)
    const [syncMyobCustomersToNetoLoading, setSyncMyobCustomersToNetoLoading] = useState<boolean>(false);
    const [touchedFields, setTouchedFields] = useState(props.touched);

    useEffect(() => {
        setTouchedFields(props.touched);
    }, [props.touched]);

    const onInputBlur = (touched: any) => {
        props.onInputBlurred(touched);
    }

    useEffect(() => {
        setCustomerMappingsMyobToNeto(props.myobCustomerToNeto);
    }, [props.myobCustomerToNeto]);

    const setCustomerMapping = () => {
        props.setMyobCustomerToNeto(customerMappingsMyobToNeto);
    }

    const syncMyobCustomersToNeto = async () => {
        setSyncMyobCustomersToNetoLoading(true);
        let result = await synchronizeCustomersMyobToNeto(State?.currentUser?.currentUserId, id); 
        if (result?.isSucceed) {
            Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: true, message: result?.message } });
            setSyncMyobCustomersToNetoLoading(false);
        }
        else {
            Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: false, message: result?.message } });
            setSyncMyobCustomersToNetoLoading(false);
        }
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col d-flex justify-content-between">
                    <Boolean
                        hasError={
                            props?.touched?.myobCustomerToNeto?.isChecked &&
                                props?.errors.myobCustomerToNeto?.isChecked ? true : false
                        }
                        text={'When a Customer is created in MYOB AccountRight, Create new Customer in Maropost'}
                        value={customerMappingsMyobToNeto.isChecked}
                        onChange={(val: boolean) => setCustomerMappingsMyobToNeto({ ...customerMappingsMyobToNeto, isChecked: val })}
                        onBlur={(e: any) => {
                            onInputBlur({ ...touchedFields, myobCustomerToNeto: { ...touchedFields.myobCustomerToNeto, isChecked: true } })
                            if (props?.myobCustomerToNeto?.isChecked != customerMappingsMyobToNeto.isChecked)
                                setCustomerMapping();
                        }}
                    />
                    <button disabled={props.isMappingsEmpty ? true : (!customerMappingsMyobToNeto.isChecked || syncMyobCustomersToNetoLoading)} onClick={syncMyobCustomersToNeto} type="button" className="btn btn-dark btn-sm">Sync
                        <FontAwesomeIcon spin={syncMyobCustomersToNetoLoading} className="ml-1" icon={faSync} />
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                </div>
                <div className="col-6 text-right">
                    <Link to={{ pathname: "/customersGrid" }} target="_blank">
                        {!_.isEmpty(customerMappingsMyobToNeto.syncErrorMessage) ?
                            <small className="badge badge-danger" data-toggle="tooltip" data-placement="top" title={customerMappingsMyobToNeto.syncErrorMessage}> <i className="pi pi-exclamation-triangle" style={{ 'fontSize': '1em' }}></i> Sync Error </small>
                            : ''}
                        {customerMappingsMyobToNeto.syncErrorMessage ? <br /> : ''}
                    </Link>
                    {!_.isEmpty(customerMappingsMyobToNeto.lastRunAt) ?
                        <small className="text-muted"> <i className="pi pi-check-circle" style={{ 'fontSize': '1em' }}></i> {customerMappingsMyobToNeto.message},{customerMappingsMyobToNeto.lastRunAt && util.userTimezone(customerMappingsMyobToNeto.lastRunAt)} </small> :
                        <small className="text-muted"> <i className="pi pi-clock" style={{ 'fontSize': '1em' }}></i> No Synchronized Data</small>
                    }
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col-6 d-flex'>
                    <Label text={'I want my Customers to be'} /> <p className="text-danger">*</p>
                </div>
                <div className='col-6'>
                    <SelectInput
                        hasError={
                            props.touched?.myobCustomerToNeto?.syncAs &&
                                props.errors?.myobCustomerToNeto?.syncAs ? true : false}
                        options={State.formsLookup?.genericSyncOptions?.values ?? []}
                        selectedOptionValue={customerMappingsMyobToNeto.syncAs}
                        onChange={(val: string) => setCustomerMappingsMyobToNeto({ ...customerMappingsMyobToNeto, syncAs: val })}
                        onBlur={(e: any) => {
                            onInputBlur({
                                ...touchedFields, myobCustomerToNeto: {
                                    ...touchedFields.myobCustomerToNeto, syncAs: true
                                }
                            });
                            if (props.myobCustomerToNeto?.syncAs !== customerMappingsMyobToNeto.syncAs)
                                setCustomerMapping();
                        }}
                    />
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-6 d-flex">
                    <Label text={"Myob To Maropost Customer"} /> <p className="text-danger">*</p>
                </div>
                <div className="col-6">
                    <SelectInput
                        hasError={props.touched?.myobCustomerToNeto?.myobToNetoCustomerFrequency &&
                            props.errors?.myobCustomerToNeto?.myobToNetoCustomerFrequency ? true : false}
                        options={State.formsLookup?.genericSchedulerFrequency?.values!}
                        selectedOptionValue={customerMappingsMyobToNeto.myobToNetoCustomerFrequency}
                        onChange={(val: string) => setCustomerMappingsMyobToNeto({ ...customerMappingsMyobToNeto, myobToNetoCustomerFrequency: val })}
                        onBlur={(e: any) => {
                            onInputBlur({
                                ...touchedFields, myobCustomerToNeto: {
                                    ...touchedFields.myobCustomerToNeto, myobToNetoCustomerFrequency: true
                                }
                            });
                            if (props.myobCustomerToNeto?.myobToNetoCustomerFrequency !== customerMappingsMyobToNeto.myobToNetoCustomerFrequency)
                                setCustomerMapping();
                        }}
                    />
                </div>
            </div>
        </div>
    )
}


export default MapMYOBCustomerToNeto