import * as React from 'react';
import { useState, useEffect } from 'react';
import _ from 'lodash';
import Label from '../../../../../components/Label';
import Boolean from '../../../../../components/Boolean';
import SelectInput from '../../../../../components/SelectInput';
import { SalesOrderMappingsModel } from "../../../../../shared/models/mappings/UserMappingModels";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as util from '../../../../../shared/Utilities';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { AppContext } from '../../../../../store/AppContext';

export interface INetoSalesOrderToMYOBInvoiceBasicRMAProcessingProps {
    touched: any,
    errors: any,
    isMappingsEmpty: boolean,
    salesOrderBasicMappings: SalesOrderMappingsModel,
    syncRmaLoading: boolean,
    syncNetoToMyobRma: () => void,
    onInputBlur: (val: any) => void,
    setSalesOrderBasicMapping: (val: SalesOrderMappingsModel) => void,
    onFocusChanged: (value: SalesOrderMappingsModel) => void,
}

const NetoSalesOrderToMYOBInvoiceBasicRMAProcessing: React.FC<INetoSalesOrderToMYOBInvoiceBasicRMAProcessingProps> = (props) => {
    let { State, Dispatch } = React.useContext(AppContext);
    const [rmaProcessMapping, setRmaProcessMapping] = useState<SalesOrderMappingsModel>(props.salesOrderBasicMappings ?? {} as SalesOrderMappingsModel);

    useEffect(() => {
        setRmaProcessMapping(props.salesOrderBasicMappings);
    //     setRmaProcessMapping({ ...props?.salesOrderBasicMappings, stockWriteOffAccount: State.formsLookup?.retriveOrdersFromNeto?.stockWriteOffAccount?.values ? State.formsLookup?.retriveOrdersFromNeto?.stockWriteOffAccount?.values[0]?.value : "" })
    }, [props.salesOrderBasicMappings]);

    const onFocusChanged = () => {
        props.onFocusChanged(rmaProcessMapping);
    }

    return (
        <React.Fragment>
            <div className="d-flex justify-content-between">
                <Boolean
                    hasError={
                        props.touched?.salesOrderMapping?.isRmaSync &&
                            props.errors?.salesOrderMapping?.isRmaSync ? true : false}
                    value={rmaProcessMapping.isRmaSync}
                    onChange={(val: boolean) => setRmaProcessMapping({ ...rmaProcessMapping, isRmaSync: val })}
                    text={"Send RMA from Maropost to MYOB AccountRight"}
                    onBlur={(e: any) => {
                        props.onInputBlur({
                            ...props.touched, salesOrderMapping: {
                                ...props.touched.salesOrderMapping, isRmaSync: true
                            }
                        });
                        if (props.salesOrderBasicMappings.isRmaSync !== rmaProcessMapping.isRmaSync)
                            onFocusChanged();
                    }}
                />
                <button disabled={props.isMappingsEmpty ? true : (!rmaProcessMapping.isRmaSync || props.syncRmaLoading)}
                    onClick={() => props.syncNetoToMyobRma()} type="button" className="btn btn-dark btn-sm">Sync
                    <FontAwesomeIcon spin={props.syncRmaLoading} className="ml-1" icon={faSync} />
                </button>
            </div>
            <div className="row">
                <div className="col-6"></div>
                <div className="col-6 text-right">
                    {!_.isEmpty(rmaProcessMapping.rmaLastRunAt) ?
                        <small className="text-muted"> <i className="pi pi-check-circle" style={{ 'fontSize': '1em' }}></i>
                            {rmaProcessMapping.rmaSyncMessage},{rmaProcessMapping.rmaLastRunAt && util?.userTimezone(rmaProcessMapping.rmaLastRunAt)} </small> :
                        <small className="text-muted"> <i className="pi pi-clock" style={{ 'fontSize': '1em' }}></i> No Synchronized Data</small>
                    }
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-6 d-flex">
                    <Label text={"Maropost Stock Write Off Account"} /> <p className="text-danger">*</p>
                </div>
                <div className="col-6">
                    <SelectInput
                        hasError={
                            props.touched?.salesOrderMapping?.stockWriteOffAccount &&
                                props.errors?.salesOrderMapping?.stockWriteOffAccount ? true : false}
                        options={State.formsLookup?.retriveOrdersFromNeto?.stockWriteOffAccount.values!}
                        selectedOptionValue={rmaProcessMapping.stockWriteOffAccount}
                        onChange={(val: string) => setRmaProcessMapping({ ...rmaProcessMapping, stockWriteOffAccount: val })}
                        onBlur={(e: any) => {
                            props.onInputBlur({
                                ...props.touched, salesOrderMapping: {
                                    ...props.touched.salesOrderMapping, stockWriteOffAccount: true
                                }
                            });
                            if (props.salesOrderBasicMappings?.stockWriteOffAccount !== rmaProcessMapping.stockWriteOffAccount)
                                onFocusChanged();
                        }}
                    />
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-6 d-flex">
                    <Label text={"Maropost to MYOB RMA Sync"} /> <p className="text-danger">*</p>
                </div>
                <div className="col-6">
                    <SelectInput
                        hasError={
                            props.touched?.salesOrderMapping?.rmaSync &&
                                props.errors?.salesOrderMapping?.rmaSync ? true : false}
                        options={State.formsLookup?.genericSchedulerFrequency?.values ?? []}
                        selectedOptionValue={rmaProcessMapping.rmaSync}
                        onChange={(val: string) => setRmaProcessMapping({ ...rmaProcessMapping, rmaSync: val })}
                        onBlur={(e: any) => {
                            props.onInputBlur({
                                ...props.touched, salesOrderMapping: {
                                    ...props.touched.salesOrderMapping, rmaSync: true
                                }
                            });
                            if (props.salesOrderBasicMappings?.rmaSync !== rmaProcessMapping.rmaSync)
                                onFocusChanged();
                        }}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default NetoSalesOrderToMYOBInvoiceBasicRMAProcessing
