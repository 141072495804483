import * as React from "react";
import { useParams } from 'react-router-dom';
import OrderDetailsContainer from "../features/Order/OrderDetailsContainer";

const SalesOrderDataPreviewPage: React.FC<any> = (props) => {
  const { orderId, connectorId } = useParams<any>();

  return (
    <div>
      <OrderDetailsContainer
        docId={orderId}
        selectedConnector={connectorId}
      />
    </div>
  );
};

export default SalesOrderDataPreviewPage;
