import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Spinner from '../shared/components/Spinner';
import qs from 'qs';
import { isEmpty } from "lodash";
import CompanySelection from '../features/membership/CompanySelection';
import { AppContext } from '../store/AppContext';
import { setMyobCompanyInConnector, setMyobAuthorizationToken } from "../features/membership/services/membershipService";
import moment from "moment";
import { writeInfo } from "../shared/services/LoggingService";

const MYOBAuthResult: React.FC<any> = (props) => {
    let { Dispatch } = useContext(AppContext);
    let history = useHistory();
    const idToken = localStorage.idToken ?? "";
    const [closeWindowPopup, setCloseWindowPopup] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [currentUserId, setCurrentUserId] = useState<string>("");
    const [currentDocId, setCurrentDocId] = useState<string>("");
    const [companies, setCompanies] = useState<any>([]);
    const [selectedCompany, setSelectedCompany] = useState<string>('');
    const [isCompanySaving, setIsCompanySaving] = useState<boolean>(false);
    const [disableLoading, setDisableLoading] = useState(false);
    const [test, setTest] = useState<boolean>(false);

 
      

    const updateMYOBCompany = async () => {

        setIsCompanySaving(true);
        setDisableLoading(true);

        let companyIndex = companies.findIndex((i: any) => i.value === selectedCompany);
        if (companyIndex >= 0) {
            let data = {
                companyId: companies[companyIndex].value,
                name: companies[companyIndex].label
            }

            const result = await setMyobCompanyInConnector(currentUserId, currentDocId, data);
            if (result?.isSucceed) {
                try{
                    const res : any = await writeInfo(`Message : 'Myob Connected', UserId: ${currentUserId}, ConnectorId: ${currentDocId}, Action-DateTime:${moment().format("DD MMM yyyy hh:mm A")}`);  
                    if(res?.isSucceed){
                        Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: true, message: result?.message } });
                        setIsCompanySaving(false);
                        setDisableLoading(false);
                        setCloseWindowPopup(true);
                    }   
                } catch(error: any){
                    Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: false, message: error.message } });
                    setIsCompanySaving(false);
                    setDisableLoading(false);
                    setCloseWindowPopup(true);
                }
            }
            else {
                Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: false, message: result?.message } });
                setIsCompanySaving(false);
                setDisableLoading(false);
                setCloseWindowPopup(true);
            }
        }
        else {
            setIsCompanySaving(false);
            setDisableLoading(false);
        }
    }


    useEffect(() => {
        if (closeWindowPopup === true) {
            window.opener?.postMessage('true', window.origin);
            window.close();          
        }
    }, [closeWindowPopup]);

    useEffect(() => {
        let code = qs.parse(props.location.search, { ignoreQueryPrefix: true }).code
        if (isEmpty(code)) {
            history.push("/connectors");
            return;
        }

        const currentUserId = localStorage.getItem("currentUserId");
        setCurrentUserId(currentUserId ?? '');
        const currentDocId = localStorage.getItem("currentDocId");
        setCurrentDocId(currentDocId ?? '');

        (async () => {
            const response = await setMyobAuthorizationToken({
                code,
                userId: currentUserId,
                redirectUri: `${window.location.origin}/myobauthresult`,
                connectorId: currentDocId
            });
            if (response.isSucceed) {
                setIsOpen(true);
                setCompanies(response.result);
            }
            else {
                Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: false, message: response.message } });
            }
        })();
    }, [])

    return (
        <div>
            {isOpen === false ? <Spinner />
                :
                <CompanySelection
                    currentUserId={currentUserId!}
                    docId={currentDocId!}
                    companies={companies}
                    isOpen={isOpen}
                    onToggle={setIsOpen}
                    isCompanySaving={isCompanySaving}
                    selectedCompany={selectedCompany}
                    setSelectedCompany={setSelectedCompany}
                    saveCompany={updateMYOBCompany}
                    disabledLoading={disableLoading}
                />
            }
        </div>
    )
}

export default MYOBAuthResult
