import * as React from 'react';
import { Card } from 'primereact/card';
import { NotesModel } from '../models/SaleOrderViewModels';

export interface INotesDetailProps {
    Notes: NotesModel
}

const Notes : React.FC<INotesDetailProps> = ({ Notes }) => {
    const headerComponent = (
        <div className="p-3" style={{ backgroundColor: "#d3d3d3b8" }}>
            <h4>Notes</h4>
        </div>
    );

    return (
        <Card header={headerComponent} className="text-left mt-3 mb-3">
            <div className="px-3">
                <div className="row p-3">
                    <div className="col">
                        <div className="headings-size">Internal Notes:</div>
                        <p>{Notes?.OrderInternalNotes}</p>
                    </div>
                </div>
            </div>
        </Card>
    );
}

export default Notes
