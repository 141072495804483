import React from "react";
import ConnectorsContainer from "../Connector";

const ConnectorsPage: React.FC = () => {
  return (
    <>
      <ConnectorsContainer />
    </>
  );
};

export default ConnectorsPage;
