import React from "react";
import locationIcon from "../assets/location.png";
import { Card } from "primereact/card";
import './Address.css';

export interface IAddressProps {
    Title: string,
    FirstName: string,
    LastName: string,
    Address: string,
    PostCode: string,
    City: string,
    Country: string,
    State: string,
}

const Address: React.FC<IAddressProps> = ({ Title, FirstName, LastName, Address, PostCode, City, Country, State }) => {
    const headerComponent = (
        <div className="p-3" style={{ backgroundColor: "#d3d3d3b8" }}>
            <h4>
                {Title}
            </h4>
        </div>
    );

    return (
        <Card header={headerComponent} className=" text-left" style={{ width: "500rem" }}>
            <div className='row'>
                <div className="col-10">
                    <h5> {FirstName} {LastName} </h5>
                    <div className='headings-size' style={{ color: "#495057" }}> Address </div>
                    <p style={{ maxWidth: '150px' }}>{Address}</p>
                    <div>
                        <div className="d-inline-block">
                            <div className='headings-size' style={{ width: '9rem', color: "#495057" }}> City/suburb </div>
                            <p>{City}</p>
                        </div>
                        <div className="d-inline-block">
                            <div className='headings-size' style={{ color: "#495057" }}> Zip </div>
                            <p>{PostCode}</p>
                        </div>
                    </div>
                    <div>
                        <div className="d-inline-block">
                            <div className='headings-size' style={{ width: '9rem', color: "#495057" }}>State</div>
                            <p>{State}</p>
                        </div>
                        <div className="d-inline-block">
                            <div className='headings-size' style={{ color: "#495057" }}> Country </div>
                            <p>{Country}</p>
                        </div>
                    </div>
                </div>
                <div className="col-2">
                    <img src={locationIcon} style={{ opacity: 0.1 }} width="55" height="80" />
                </div>
            </div>
        </Card>

    );
};

export default Address;
