import React, { useState } from "react";
import { Button } from "primereact/button";
import { Card } from 'primereact/card';
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Message } from 'primereact/message';
import { IMembershipContainerProps } from "./models";

const MembershipContainer: React.FC<IMembershipContainerProps> = ({ onEmailChange, onPasswordChange, onSubmit, loginWithGoogle, email, password, errorMessage, isSignUp, }) => {
  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);
  const togglePasswordVisibility = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  return (
    <div className="pb-5 container">
      <Card className="border w-75 rounded p-2 mt-5 mb-5 bg-light mx-auto shadow-lg">
        <div className="mt-1 mb-5">
          <>
            {isSignUp ? (
              <h3>Create your account</h3>
            ) : (
              <h3>Login to your account</h3>
            )}
          </>
        </div>
        <div className="mb-4 mx-auto">
          <Button
            style={{
              fontWeight: 500,
              width: "-webkit-fill-available",
            }}
            className="p-button-outlined p-button-secondary justify-content-center"
            onClick={loginWithGoogle}
          >
            <img
              className="mt-1 mx-2"
              style={{ width: "1em", float: "left", height: "1em", backgroundColor: "white", }}
              src="https://ps.w.org/google-site-kit/assets/icon-256x256.png?rev=2181376"
              alt="google"
            />
            Sign {isSignUp ? "up" : "in"} with Google
          </Button>
        </div>

        <hr style={{ width: "48%", float: "left" }}></hr>
        <span style={{ color: " #ced4da" }}>or</span>
        <hr style={{ width: "48%", float: "right" }}></hr>

        <form className="my-5">
          <div>
            <InputText
              id="Email"
              className="my-2 w-100"
              type="email"
              required
              placeholder="Email"
              value={email}
              onChange={onEmailChange}
            />
          </div>
          <div className="p-grid p-fluid">
            <div className="p-col-12 p-md-12">
              <div className="p-inputgroup">

                <InputText
                  id="Password"
                  value={password}
                  type={isPasswordShown ? "text" : "password"}
                  placeholder="Password"
                  onChange={onPasswordChange}
                  required
                />
                <span className="p-inputgroup-addon">
                  <Checkbox checked={isPasswordShown} onChange={togglePasswordVisibility} />
                  <small>
                    {isPasswordShown ? "Hide" : "Show"}
                  </small>
                </span>
              </div>
            </div>
          </div>
          {errorMessage && (
            <Message className="my-2" style={{ width: "-webkit-fill-available" }} severity="error" text={errorMessage}></Message>
          )}
          <div className="text-center my-3">
            <Button
              style={{ width: "-webkit-fill-available", backgroundColor: "rgb(60 60 60)", border: "none" }}
              className="d-flex justify-content-center"
              label={isSignUp ? "CREATE ACCOUNT" : "SIGN IN"}
              onClickCapture={onSubmit}
            >
            </Button>
            <label
              style={{
                color: "#495057",
              }}
              className="mt-1"
            >
              {isSignUp
                ? "Already have An account?"
                : "Don't have an account?"}
              <Link to={isSignUp ? "/signin" : "/signup"}>
                {isSignUp ? <p>Sign In</p> : <p>Sign Up</p>}
              </Link>
            </label>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default MembershipContainer;
