import * as React from "react";
import { useEffect, useState } from "react";
import _ from "lodash";
import { ValueLabel } from '../shared/models';
import Select from 'react-select';
import { OptionTypeBase, ActionMeta } from 'react-select';

export interface ISelectInputProps {
  options: Array<ValueLabel>,
  allOptionsForItemsUI: Array<ValueLabel>,
  selectedOptionValue: string,
  onChange: (value: string) => void
  onBlur?: (value: string) => void,
  setAllOptionsForItemsUI: (value: Array<ValueLabel>) => void,
  itemsFromServer: (value: string) => void,
  name?: string,
  hasError?: boolean,
  isAsync?: boolean,
  asyncCallback?: (search: string) => any
}
//TODO:allOptionsForItemsUI from prop
const AsyncSelectInput: React.FC<ISelectInputProps> = (props) => {
  const { selectedOptionValue } = props
  const [selectedOption, setSelectedOption] = useState<OptionTypeBase>();
  const [options, setOptions] = useState<Array<ValueLabel>>(props.options || []);
  const [searchTimeout, setSearchTimeout] = useState<any>();
  const [inputValue, setInputValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (props.isAsync && props?.allOptionsForItemsUI && props?.allOptionsForItemsUI?.length) {
      let AllOptions: any = _.concat(props?.options, props?.allOptionsForItemsUI);
      let uniqOptions: any = _.uniqBy(AllOptions, 'value');
      localStorage.setItem('options', JSON.stringify(uniqOptions ?? []))
      setOptions(uniqOptions)
    } else {
      setOptions(props.options)
    }

  }, [props.options, props.allOptionsForItemsUI])


  useEffect(() => {
    if (inputValue.length > 1) {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
      setLoading(true);
      setSearchTimeout(
        setTimeout(async () => {
          const opts = await loadOptions(inputValue);
          const selectedOption = JSON.parse(localStorage.getItem('selectedOption') ?? "{}")
          const AllOptions: any = _.concat(opts, selectedOption);
          // const uniqOptions: any = _.uniqBy(AllOptions, 'value');
          props.setAllOptionsForItemsUI(AllOptions);
          setOptions(opts);
          setLoading(false)
        }, 2500)
      );
      return () => clearTimeout(searchTimeout);
    }
  }, [inputValue]);

  useEffect(() => {
    if (props.selectedOptionValue) {
      if (props.isAsync && props?.allOptionsForItemsUI && props?.allOptionsForItemsUI?.length == 0) {
        let localStorageOption = JSON.parse(localStorage.getItem('options') ?? "[]")
        let AllOptions = !_.isEmpty(localStorageOption) ? localStorageOption : props.options
        let filteredAllOption = AllOptions?.find((x: any) => x.value === props.selectedOptionValue);
        let selectedOpt = filteredAllOption && { value: filteredAllOption?.value, label: filteredAllOption?.label }
        setSelectedOption(selectedOpt)
      } else {
        let filteredOption = props.options?.find((x: any) => x.value === props.selectedOptionValue);
        let selectedOpt = filteredOption && { value: filteredOption?.value, label: filteredOption?.label }
        setSelectedOption(selectedOpt)
      }
    }
    else {
      setSelectedOption({ value: "", label: "" });
    }

  }, [props.selectedOptionValue, props.options]);


  const FormatSelectOptionsLabel = (data: any) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
    </div>
  );

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };

  const onChangeOption = (value: OptionTypeBase | null, actionType: ActionMeta<OptionTypeBase>) => {
    let localStorageOption = JSON.parse(localStorage.getItem('options') ?? "[]")
    let AllOptions = !_.isEmpty(localStorageOption) ? localStorageOption : props.options
    let filteredAllOption = AllOptions?.find((x: any) => x.value === value?.value);
    let selectedOpt = filteredAllOption && { value: filteredAllOption?.value, label: filteredAllOption?.label }
    localStorage.setItem('selectedOption', JSON.stringify(selectedOpt))
    props.onChange(value && value.value)
  }
  const colourStyles = {
    control: (styles: any) => ({ ...styles, border: `${props?.hasError ? '1px solid red' : '1px solid hsl(0, 0%, 80%)'}`, backgroundColor: 'white' }),
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
      return {
        ...styles,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      };
    }
  };

  const loadOptions = (value: string): Promise<any> => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    if (props?.isAsync && inputValue.length > 2) {
      const prmse = new Promise(resolve => {
        const res = props?.itemsFromServer(value);
        resolve(res);
      });

      return prmse;
    }
    setLoading(false)
    return new Promise((resolve, reject) => { });
  }

  return (
    <>
      {props?.isAsync === true ?
        <>
          <div className="d-flex flex-row-reverse p-1">
            {loading && <div className="spinner-border spinner-border-sm mt-1 ml-1" role="status"></div>}
            {loading && <div>Loading items... </div>}
          </div>
          <Select
            // cacheOptions
            // loadOptions={loadOptions}
            // defaultOptions={options}
            options={options}
            isMulti={false}
            styles={colourStyles}
            onChange={onChangeOption}
            onBlur={(e) => props.onBlur && props.onBlur(e.target.nodeValue || "")}
            onInputChange={(newValue: string) => setInputValue(newValue)}
            value={selectedOption}
            className="basic-select"
            classNamePrefix="select"
            formatOptionLabel={FormatSelectOptionsLabel}
          />
        </>
        :
        <Select
          {...props}
          isMulti={false}
          styles={colourStyles}
          value={selectedOption}
          onChange={onChangeOption}
          onBlur={(e) => props.onBlur && props.onBlur(e.target.nodeValue || "")}
          options={props.options}
          className="basic-select"
          classNamePrefix="select"
          formatOptionLabel={FormatSelectOptionsLabel}
        />
      }
    </>
  )
}

export default AsyncSelectInput