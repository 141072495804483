import * as React from "react";
import CustomerGridContainer from '../features/Customer'

const CustomerGridPage: React.FC = () => {
    return (
        <div>
            <CustomerGridContainer />
        </div>
    )
}

export default CustomerGridPage
