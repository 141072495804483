import React, { useState } from "react";
import MembershipContainer from "../membershipContainer";
import { ISignIn } from "../models";
import { loginWithGoogleService, SignInWithEmailAndPasswordService } from "../services/membershipService";

const SignIn: React.FC = () => {
  const [state, setState] = useState<ISignIn>({
    email: "",
    password: "",
    errorMessage: "",
  });

  const loginWithGoogle = async () => {
    try {
      await loginWithGoogleService();
    } catch (error: any) {
      setState({ ...state, errorMessage: error.message });
    }
  };

  const onSubmit = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    try {
      await SignInWithEmailAndPasswordService(state.email, state.password);
    } catch (error: any) {
      setState({ ...state, errorMessage: error.message });
    }
  };

  const onEmailChange = (event: { target: { value: string } }) => {
    setState({ ...state, email: event.target.value });
  };

  const onPasswordChange = (event: { target: { value: string } }) => {
    setState({ ...state, password: event.target.value });
  };

  return (
     <MembershipContainer
        onEmailChange={onEmailChange}
        onPasswordChange={onPasswordChange}
        email={state.email}
        password={state.password}
        onSubmit={onSubmit}
        errorMessage={state.errorMessage}
        loginWithGoogle={loginWithGoogle}
      />
  );
};

export default SignIn;

