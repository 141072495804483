import { Button } from 'primereact/button';
import * as React from 'react'
import { useEffect, useState } from 'react';
import { queryAllSystemUsers } from '../features/User/services/UserService';
import UsersGridContainer from '../features/User/UsersGridContainer';

const UsersGridPage : React.FC = () => {
    const [users, setUsers] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [reprocess, setReprocess] = useState<boolean>(false);
    useEffect(() => {
        getAllUsers();
    }, [])

    useEffect(() => {
        if(reprocess && reprocess === true){
            getAllUsers();
            setReprocess(false);
        }
    }, [reprocess])



    const getAllUsers = async () => {
        try{
            setLoading(true);
            let res = await queryAllSystemUsers();
            if(res.isSucceed)
                setUsers(res.result ?? []);
            else
                setUsers([]);

            setLoading(false);
        }
        catch(err){
            setUsers([]);
            setLoading(false);
        }
    };

    return (
        <div>
                <UsersGridContainer
                    users={users}
                    loading={loading}
                    setLoading={setLoading}
                reprocess={setReprocess} />
        </div>
    )
}

export default UsersGridPage
