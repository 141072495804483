import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const UserInActive: React.FC = () => {
  return (
    <div className="mt-5">
      <div className="alert alert-danger" role="alert">
        <h3>
          {" "}
          <FontAwesomeIcon icon={faBan} className="mr-2" />
          Unable to login, Your Account is Not Active Yet!
        </h3>
        <p>Wait for the response from the Admin</p>
      </div>
      <h5>Please Contact to Admin in any inconvenience.</h5>
    </div>
  );
};

export default UserInActive;
