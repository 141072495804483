import React, { useEffect, useState, useContext, useRef } from "react";
import moment from 'moment';
import { AppContext } from "../../store/AppContext";
import _ from "lodash";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Label from "../../components/Label";
import SelectInput from "../../components/SelectInput";
import Datepicker from '../../components/Datepicker';
import { ValueLabel } from "../../shared/models";
import { getAllConnectorLabels } from "./services/OrderService";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { actionBodyTemplateToRmaDetial, emptyMessageTemplate } from "./GridBodyTemplates";
import * as util from '../../shared/Utilities';
import { getRMAFromFirebase } from "../../dataServices";
import { Subscription } from "rxjs";

const RMAGridContainer: React.FC = () => {
    let { State, Dispatch } = useContext(AppContext);
    const [connectors, setConnectors] = useState<Array<ValueLabel>>([{ label: "", value: "" }]);
    const [selectedConnector, setSelectedConnector] = useState<string>(State.selectedConnectorOnGrid ?? "");
    const [salesRMAData, setSalesRMAData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    let [rmaDateFilter, setRmaDateFilter] = useState<any>(moment().subtract(1, 'months').format('YYYY-MM-DDTHH:mm:ss'));
    const dt = useRef<DataTable>(null);
    let getRMAFromFirebaseObserver: Subscription;

    useEffect(() => {
        (async () => {
            setLoading(true);
            const connectorsList = await getAllConnectorLabels(State.currentUser.currentUserId);
            setLoading(false);
            if (State.currentUser.currentUserId) {
                setSelectedConnector(connectorsList.length && connectorsList[0].value ? connectorsList[0].value : "");
            }
            setConnectors(connectorsList);
        })();

        return () => {
            Dispatch({
                Type: "SET_SALES_ORDER",
                Payload: { connector: "", data: [] },
            });
        };
    }, []);

    useEffect(() => {
        connectorsSnapshot(selectedConnector);
        if (State?.currentUser?.currentUserId && selectedConnector) {
            util.getTimezoneFromFirebase(State.currentUser.currentUserId, selectedConnector)
            return () => {
                getRMAFromFirebaseObserver?.unsubscribe();
            }
        }
    }, [selectedConnector]);



    const connectorsSnapshot = async (connector: string) => {
        setLoading(true);
        if (!_.isEmpty(connector)) {

            getRMAFromFirebaseObserver = getRMAFromFirebase(State.currentUser.currentUserId, selectedConnector, rmaDateFilter, -1).subscribe((e: any) => {
                setSalesRMAData(e?.rmaData);
                setLoading(false);
                Dispatch({
                    Type: "SET_SALES_ORDER",
                    Payload: { connector: connector, data: [] },
                });

            });
        }
        else {
            setLoading(false);
        }
    };

    useEffect(() => {
        connectorsSnapshot(selectedConnector)
    }, [rmaDateFilter])

    const header = (
        <div className="d-flex justify-content-center">
            <div className="col-3">
                <Label text={"Select order process date from: "} />
            </div>
            <div className="col">
                <Datepicker
                    selectedDate={rmaDateFilter}
                    onChange={(val: string) => {
                        setRmaDateFilter(val)

                    }}
                    onBlur={(e: any) => {
                        setRmaDateFilter(e.toString())

                    }}
                    paymentAndRma={true}
                />
            </div>
        </div>
    )
    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header=""></Column>
                <Column header="RMAID" colSpan={2} />
                <Column header="OrderID" colSpan={2} />
                <Column header="Customer" colSpan={2} />
                <Column header="RMA Status" colSpan={2} />
                {/* <Column header="Error Message" colSpan={2} /> */}

            </Row>
            <Row>
                <Column headerStyle={{ width: '3em' }} />
                <Column header="Maropost" filter filterMatchMode="contains" sortable filterPlaceholder="Search" field="netoRmaId" />
                <Column header="Myob" filter filterMatchMode="contains" sortable filterPlaceholder="Search" field="myobRmaId" />
                <Column header="Maropost" filter filterMatchMode="contains" sortable filterPlaceholder="Search" field="netoOrderId" />
                <Column header="Myob" filter filterMatchMode="contains" sortable filterPlaceholder="Search" field="myobOrderId" />
                <Column header="Maropost" filter filterMatchMode="contains" sortable filterPlaceholder="Search" field="netoCustomer" />
                <Column header="Myob" filter filterMatchMode="contains" sortable filterPlaceholder="Search" field="myobCustomer" />
                <Column header="Maropost" filter filterMatchMode="contains" sortable filterPlaceholder="Search" field="netoOrderStatus" />
                <Column header="Myob" filter filterMatchMode="contains" sortable filterPlaceholder="Search" field="myobOrderStatus" />
                <Column header="Sync Error Message" field="syncErrorMessage" />

            </Row>
        </ColumnGroup>
    );


    return (
        <div className="container-fluid">
            <div className="row mt-5">
                <div className="col-3">
                    <Label text={"Select Connector: "} />
                </div>
                <div className="col-9">
                    <SelectInput
                        options={connectors}
                        selectedOptionValue={selectedConnector}
                        onChange={(connector: string) => {
                            Dispatch({
                                Type: "SET_SELECTED_CONNECTOR",
                                Payload: {
                                    id: connector
                                },
                            });
                            setSelectedConnector(connector)

                        }}
                    />
                </div>
            </div>
            {/* <div className="row mt-5">
                <div className="col-3">
                    <Label text={"Select Date: "} />
                </div>
                <div className="col-3">
                    <Datepicker
                        selectedDate={rmaDateFilter}
                        onChange={(val: string) => {
                            setRmaDateFilter(val)

                        }}
                        onBlur={(e: any) => {
                            setRmaDateFilter(e.toString())

                        }}

                    />
                </div>
            </div> */}
            <br />

            <DataTable
                ref={dt}
                header={header}
                headerColumnGroup={headerGroup}
                value={salesRMAData}
                className="p-mt-6"
                dataKey="number"
                rowHover
                autoLayout
                sortField="datePlacedForFilter"
                sortOrder={-1}
                emptyMessage={emptyMessageTemplate}
                lazy={loading}
                loading={loading}
                paginator
                rows={10}
                totalRecords={salesRMAData?.length}
                rowsPerPageOptions={[10, 20, 50]}
                paginatorClassName="p-jc-end"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                rowClassName={(rowData: any) => {
                    return { 'highlight': rowData.new };
                }}>

                <Column headerStyle={{ width: '3em' }} />
                <Column field="netoRmaId" body={(data) => actionBodyTemplateToRmaDetial(selectedConnector, data)} />
                <Column field="myobRmaId" />
                <Column field="netoOrderId" />
                <Column field="myobOrderId" />
                <Column field="netoCustomer" />
                <Column field="myobCustomer" />
                <Column field="netoRmaStatus" />
                <Column field="myobRmaStatus" />
                <Column field="syncErrorMessage" className="text-danger" />
            </DataTable>

        </div>
    );
}

export default RMAGridContainer