import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import Label from '../../../../../components/Label';
import Input from '../../../../../components/Input';
import SelectInput from '../../../../../components/SelectInput';
import Boolean from '../../../../../components/Boolean';
import Datepicker from '../../../../../components/Datepicker';
import { AppContext } from '../../../../../store/AppContext';
import { synchronizeExistingSystemCustomers, synchronizeExistingSystemOrders, synchronizeExistingSystemProducts } from '../../services/MappingService';
import * as util from '../../../../../shared/Utilities';
import { ExistingDataMappingsModel } from '../../../../../shared/models/mappings/UserMappingModels';

export interface IExistingDataCustomersSynchronizeProps {
    touched: any,
    errors: any,
    isMappingsEmpty: boolean,
    existingDataMappings: ExistingDataMappingsModel,
    syncExistingCustomersLoading: boolean,
    synExistingCustomers: () => void,
    onInputBlur: (val: any) => void,
    onFocusChanged: (value: ExistingDataMappingsModel) => void,
}

const ExistingDataCustomersSynchronize: React.FC<IExistingDataCustomersSynchronizeProps> = (props) => {
    let { State, Dispatch } = useContext(AppContext);
    const [existingData, setExistingData] = useState<ExistingDataMappingsModel>(props.existingDataMappings ?? {} as ExistingDataMappingsModel);

    useEffect(() => {
        setExistingData(props.existingDataMappings);
    }, [props.existingDataMappings])

    const onFocusChanged = () => {
        props.onFocusChanged(existingData);
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className=" col d-flex justify-content-between">
                    <Boolean
                        hasError={
                            props?.touched?.mapExistingData?.isCheckedCustomer &&
                                props?.errors?.mapExistingData?.isCheckedCustomer ? true : false
                        }
                        text={'Sync Cutsomers from MYOB to Maropost'}
                        value={existingData.isCheckedCustomer}
                        onChange={(val: boolean) => setExistingData({ ...existingData, isCheckedCustomer: val })}
                        onBlur={(e: any) => {
                            props.onInputBlur({
                                ...props.touched, mapExistingData: {
                                    ...props.touched.mapExistingData, isCheckedCustomer: true
                                }
                            });
                            if (props.existingDataMappings?.isCheckedCustomer != existingData.isCheckedCustomer)
                                onFocusChanged();
                        }}
                    />
                    <button disabled={props.isMappingsEmpty && props.isMappingsEmpty === true ? true : (!existingData.isCheckedCustomer || props.syncExistingCustomersLoading)}
                        onClick={props.synExistingCustomers}
                        type="button" className="btn btn-dark btn-sm">Sync
                        <FontAwesomeIcon spin={props.syncExistingCustomersLoading} className="ml-1" icon={faSync} />
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                </div>
                <div className="col-6 text-right">
                    <Link to={{ pathname: "/customersGrid" }} target="_blank">
                        {!_.isEmpty(existingData.customerSyncErrorMessage) ?
                            <small className="badge badge-danger" data-toggle="tooltip" data-placement="top" title={existingData.customerSyncErrorMessage}> <i className="pi pi-exclamation-triangle" style={{ 'fontSize': '1em' }}></i> Sync Error</small> : ''}
                        {existingData.customerSyncErrorMessage ? <br /> : ''}
                    </Link>
                    {!_.isEmpty(existingData.customerLastRunAt) ?
                        <small className="text-muted"> <i className="pi pi-check-circle" style={{ 'fontSize': '1em' }}></i> {existingData.customerSyncMessage},{existingData.customerLastRunAt && util.userTimezone(existingData.customerLastRunAt)} </small> :
                        <small className="text-muted"> <i className="pi pi-clock" style={{ 'fontSize': '1em' }}></i> No Synchronized Data</small>
                    }
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col-6 d-flex'>
                    <Label text={State.formsLookup?.existingData?.syncCustomersAs?.label} /> <p className="text-danger">*</p>
                </div>
                <div className='col-6'>
                    <SelectInput
                        hasError={
                            props.touched?.mapExistingData?.syncCustomersAs &&
                                props.errors?.mapExistingData?.syncCustomersAs ? true : false}
                        options={State.formsLookup?.existingData?.syncCustomersAs?.values!}
                        selectedOptionValue={existingData.syncCustomersAs}
                        onChange={(val: string) => setExistingData({ ...existingData, syncCustomersAs: val })}
                        onBlur={(e: any) => {
                            props.onInputBlur({
                                ...props.touched, mapExistingData: {
                                    ...props.touched.mapExistingData, syncCustomersAs: true
                                }
                            });
                            if (props.existingDataMappings?.syncCustomersAs !== existingData.syncCustomersAs) {
                                onFocusChanged();
                            }
                        }}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default ExistingDataCustomersSynchronize
