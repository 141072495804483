import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/auth';
import { Observable } from 'rxjs';

const firebaseConfig: FirebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCK,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

interface FirebaseConfig {
  apiKey?: string,
  authDomain?: string,
  projectId?: string,
  storageBucket?: string,
  messagingSenderId?: string,
  appId?: string,
  measurementId?: string
}

const app = firebase.initializeApp(firebaseConfig);


const loggedIn$ = () => {
  return new Observable(subscriber => {
    firebase.auth().onAuthStateChanged(async (user) => {
      subscriber.next(user);
    })
  })
}

const onIdTokenChange$ = () => {
  return new Observable(subscriber => {
    firebase.auth().onIdTokenChanged(async (user) => {
      const idToken = await user?.getIdToken(true)
      if(idToken) {
        localStorage.setItem("idToken", idToken!)
      }
    })
  })
}



export { loggedIn$, onIdTokenChange$ };
export default firebase;