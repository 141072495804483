import * as React from "react";
import PaymentGridContainer from "../features/Order/PaymentGridContainer";
import "./SaleOrderGridPage.css"

const PaymentGridPage: React.FC = () => {
  return (
    <PaymentGridContainer />
  );
};

export default PaymentGridPage;
