import * as React from 'react';
import { Tooltip } from 'primereact/tooltip';
import { Badge } from "primereact/badge";
import _, { isEmpty } from 'lodash';
import { Link } from "react-router-dom";
import { SalesOrderGridModel } from './models';
import netoToMyob from "../../assets/netoToMyob.png"


export const emptyMessageTemplate = (
    <div className="text-center">
      <b>No Records Found!</b>
    </div>
);

export const datePlacedBodyTemplate = (rowData:any) => {
    return (
      <React.Fragment>
          <div>
           {!_.isEmpty(rowData?.datePlaced) ? <p>{rowData?.datePlaced}</p> : ''}
          </div>
      </React.Fragment>
    );
}

export const actionBodyTemplate = (selectedConnector: string, rowData: SalesOrderGridModel) => {
    return (
      <React.Fragment>
        <Link
          className="p-button p-button-sm p-button-raised p-button-success"
          to={`/saleOrderDataPreview/${selectedConnector}/${rowData?.documentId}`}
        >
          View Details
        </Link>
      </React.Fragment>
    );
};

export const actionBodyTemplateTwo = (selectedConnector: string, rowData: SalesOrderGridModel) => {
  return (
      <React.Fragment>
        <Link
          to={`/saleOrderDataPreview/${selectedConnector}/${rowData?.documentId}`}
        >
          {rowData?.incomingOrder?.OrderID}
        </Link>
      </React.Fragment>
    );
};

export const actionBodyTemplateToRmaDetial = (selectedConnector: string, rowData: SalesOrderGridModel) => {
  return (
      <React.Fragment>
        <Link
          to={`/RMADataPreview/${selectedConnector}/${rowData?.documentId}/${rowData?.netoRmaId}`}
        >
          {rowData?.netoRmaId}
        </Link>
      </React.Fragment>
    );
};

export const logoBody = () => {
  return (
      <React.Fragment>
       <img src={netoToMyob} alt="netoToMyob" height="25rem" />
      </React.Fragment>
    );
};

export const actionBodyTemplateToPaymentDetial = (selectedConnector: string, rowData: SalesOrderGridModel) => {
  
  return (
      <React.Fragment>
        <Link
          to={`/PaymentDataPreview/${selectedConnector}/${rowData?.documentId}/${rowData?.netoPaymentId}`}
        >
          {rowData?.netoPaymentId}
        </Link>
      </React.Fragment>
    );
};


export const statusBodyTemplate = (rowData: any, index: any) => {
    return (
      <>
        <div>
          <Tooltip
            position="top"
            target={`.order${index?.rowIndex}`}
            content={rowData?.status?.message}
          />
          <Badge
            value={rowData?.status?.orderStatus}
            severity={
              rowData?.status?.orderStatus == "ExportComplete"
                ? "success"
                : rowData?.status?.orderStatus == "NotProcessed"
                ? "secondary"
                : rowData?.status?.orderStatus == "ImportFailed"
                ? "danger" 
                : rowData?.status?.orderStatus == "ExportFailed"
                ? "danger" 
                : rowData?.status?.orderStatus == "Imported"
                ? "success"
                : "info"
            }
            className={`order${index?.rowIndex} p-mr-2 mb-1`}
          ></Badge>
          <p className={`order${index?.rowIndex} trunc my-auto`}>{rowData?.status?.message}</p>
          {/* <p style={{ color: "#a57e0a" }} className={`trunc my-auto`}>{rowData?.status?.warningMessage}</p>
          {!isEmpty(rowData?.status?.warningMessage) && <Badge value={rowData?.status?.warningMessage} severity={"warning"}></Badge>}  */}
        </div>
      </>
    );
};



export const statusItemTemplate = (option: any) => {
  return (
    <>
    <Tooltip
    position="top"
    content={`Orders with ${option} status will filter out`}
    target={`.option${option}`}
  />
    <Badge
        value={option}
        data-pr-tooltip="PrimeReact-Logo"
        data-pr-position="left" 
        className={`option${option}`}
        severity={
          option == "ExportComplete"
            ? "success"
            : option == "NotProcessed"
              ? "secondary"
              : option == "ImportFailed"
                ? "danger"
                : option == "ExportFailed"
                  ? "danger"
                  : option == "Imported"
                    ? "success"
                    : "info"
        }
      ></Badge>
      <Tooltip/>
      </>

  );
}



