import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import Label from '../../../../../components/Label';
import Input from '../../../../../components/Input';
import SelectInput from '../../../../../components/SelectInput';
import Boolean from '../../../../../components/Boolean';
import Datepicker from '../../../../../components/Datepicker';
import { AppContext } from '../../../../../store/AppContext';
import { synchronizeExistingSystemCustomers, synchronizeExistingSystemOrders, synchronizeExistingSystemProducts } from '../../services/MappingService';
import * as util from '../../../../../shared/Utilities';
import { ExistingDataMappingsModel } from '../../../../../shared/models/mappings/UserMappingModels';

export interface IExistingDataProductsSynchronizeProps {
    touched: any,
    errors: any,
    isMappingsEmpty: boolean,
    syncExistingProductsLoading: boolean,
    synExistingProducts: () => void,
    existingDataMappings: ExistingDataMappingsModel,
    onInputBlur: (val: any) => void,
    onFocusChanged: (value: ExistingDataMappingsModel) => void,
}

const ExistingDataProductsSynchronize: React.FC<IExistingDataProductsSynchronizeProps> = (props) => {
    let { State, Dispatch } = useContext(AppContext);
    const [existingData, setExistingData] = useState<ExistingDataMappingsModel>(props.existingDataMappings ?? {} as ExistingDataMappingsModel);

    useEffect(() => {
        setExistingData(props.existingDataMappings);
    }, [props.existingDataMappings])

    const onFocusChanged = () => {
        props.onFocusChanged(existingData);
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className=" col d-flex justify-content-between">
                    <Boolean
                        hasError={
                            props?.touched?.mapExistingData?.isCheckedProducts &&
                                props?.errors?.mapExistingData?.isCheckedProducts ? true : false
                        }
                        text={'Sync Products from MYOB to Maropost'}
                        value={existingData.isCheckedProducts}
                        onChange={(val: boolean) => setExistingData({ ...existingData, isCheckedProducts: val })}
                        onBlur={(e: any) => {
                            props.onInputBlur({
                                ...props.touched, mapExistingData: {
                                    ...props.touched.mapExistingData, isCheckedProducts: true
                                }
                            });
                            if (props.existingDataMappings?.isCheckedProducts != existingData.isCheckedProducts)
                                onFocusChanged();
                        }}
                    />
                    <button disabled={props.isMappingsEmpty && props.isMappingsEmpty === true ? true : (!existingData.isCheckedProducts || props.syncExistingProductsLoading)}
                        onClick={props.synExistingProducts}
                        type="button" className="btn btn-dark btn-sm">Sync
                        <FontAwesomeIcon spin={props.syncExistingProductsLoading} className="ml-1" icon={faSync} />

                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                </div>

                <div className="col-6 text-right">
                    <Link to={{ pathname: "/productsGrid" }} target="_blank">
                        {!_.isEmpty(existingData.productSyncErrorMessage) ?
                            <small className="badge badge-danger" data-toggle="tooltip" data-placement="top" title={existingData.productSyncErrorMessage}> <i className="pi pi-exclamation-triangle" style={{ 'fontSize': '1em' }}></i> Sync Error</small> : ''}
                        {existingData.productSyncErrorMessage ? <br /> : ''}
                    </Link>
                    {!_.isEmpty(existingData.productLastRunAt) ?
                        <small className="text-muted"> <i className="pi pi-check-circle" style={{ 'fontSize': '1em' }}></i> {existingData.productSyncMessage},{existingData.productLastRunAt && util.userTimezone(existingData.productLastRunAt)} </small> :
                        <small className="text-muted"> <i className="pi pi-clock" style={{ 'fontSize': '1em' }}></i> No Synchronized Data</small>
                    }
                    <br />
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col-6 d-flex'>
                    <Label text={"I want my Products to be"} /> <p className="text-danger">*</p>
                </div>
                <div className='col-6'>
                    <SelectInput
                        hasError={
                            props.touched?.mapExistingData?.syncProductsAs &&
                                props.errors?.mapExistingData?.syncProductsAs ? true : false}
                        options={State.formsLookup?.genericSyncOptions?.values!}
                        selectedOptionValue={existingData.syncProductsAs}
                        onChange={(val: string) => setExistingData({ ...existingData, syncProductsAs: val })}
                        name="syncProductsAs"
                        onBlur={(e: any) => {
                            props.onInputBlur({
                                ...props.touched, mapExistingData: {
                                    ...props.touched.mapExistingData, syncProductsAs: true
                                }
                            });
                            if (props?.existingDataMappings?.syncProductsAs !== existingData.syncProductsAs) {
                                onFocusChanged();
                            }
                        }}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default ExistingDataProductsSynchronize
