import * as React from "react";
import { Card } from "primereact/card";
import { LineItem } from '../models/SaleOrderViewModels';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import '../../../App.css';

export interface ILineItemDetailProps {
    lineItems: Array<LineItem>
    totalLineItemAmount: string,
    rmaLineItem?: any
}

const LineItems: React.FC<ILineItemDetailProps> = ({ lineItems, totalLineItemAmount, rmaLineItem = false }) => {
    const header = (
        <div className="w-100 p-3" style={{ backgroundColor: "#d3d3d3b8" }}>
            <h4> Line Items </h4>
        </div>
    );

    const itemComponent = (rowData: any) => (
        <div>
            <p>{rowData.ItemName}</p>
            <small>{rowData.ItemDescription}</small>
        </div>
    );



    return (
        <>
            {!rmaLineItem ?

                <Card header={header} className="text-left mt-3 mb-3" >
                    <DataTable value={lineItems && lineItems} scrollable scrollHeight="200px" autoLayout>
                        <Column field="Quantity" header="Qty" style={{ width: '5rem' }}></Column>
                        <Column body={itemComponent} header="Name" style={{ width: '20rem' }}></Column>
                        <Column field="SKU" header="SKU" style={{ width: '10rem' }}></Column>
                        <Column field="Tax" header="Tax Code" style={{ width: '10rem' }}></Column>
                        <Column field="Price" header="Price" style={{ width: '10rem' }}></Column>
                        <Column field="Discount" header="Discount" style={{ width: '10rem' }}></Column>
                        <Column field="Amount" header="Amount" style={{ width: '10rem' }}></Column>
                        <Column field="Location" header="Location" style={{ width: '10rem' }}></Column>
                    </DataTable>
                </Card>

                :
                rmaLineItem === 1 ?
                    <Card header={header} className="text-left mt-3 mb-3" >
                        <DataTable
                            id="nestedNetoViewTable"
                            value={lineItems && lineItems}
                            scrollable scrollHeight="200px" autoLayout>

                            <Column field="Quantity" header="Qty"></Column>
                            <Column field="ItemName" header="Product Name"></Column>
                            <Column field="SKU" header="SKU"></Column>
                            <Column field="RefundSubtotal" header="Refund Subtotal"></Column>
                            <Column field="Tax" header="Tax Code"></Column>
                            <Column field="ResolutionOutcome" header="Outcome"></Column>
                        </DataTable>
                    </Card>
                    :
                    <Card header={header} className="text-left mt-3 mb-3" >
                        <DataTable id="nestedMyobViewTable"
                           value={lineItems && lineItems}
                           >

                            <Column field="Quantity" header="Qty"></Column>
                            <Column field="ItemName" header="Product Name"></Column>
                            <Column field="SKU" header="SKU"></Column>
                            <Column field="Amount" header="Total"></Column>
                            <Column field="Tax" header="Tax Code"></Column>
                            <Column field="Account" header="Account"></Column>
                        </DataTable>
                    </Card>
            }
        </>
    )

};

{/* <DataTable id="nestedMyobViewTable"
          value={ConvertMyobLineItemToGlobalLineItemInterface(
            data.Lines
          )}>

          <Column field="Quantity" header="Qty"></Column>
          <Column field="ItemName" header="Product Name"></Column>
          <Column field="SKU" header="SKU"></Column>
          <Column field="Amount" header="Total"></Column>
          <Column field="Tax" header="Tax Code"></Column>
          <Column field="Account" header="Account"></Column>
        </DataTable> */}

export default LineItems
