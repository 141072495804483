import * as React from "react";
import { useEffect, useState, useRef } from 'react';
import { Toast } from 'primereact/toast';

export interface IToastProps {
    status: any
}

const ToastComponent: React.FC<IToastProps> = (props) => {
    const toast = useRef<Toast>(null);
    const showSuccess = (message: string) => {
        toast?.current?.show({ severity: 'success', summary: '', detail: message, life: 7000, contentClassName: '' });
    }

    const showError = (message: string) => {
        toast?.current?.show({ severity: 'error', summary: '', detail: message, life: 7000, contentClassName: '' });
    }
    useEffect(() => {
        if (props.status?.isSucceed === true) {
            showSuccess(props.status?.message);
        }
        else if(props.status?.message) {
            showError(props.status?.message);
        }
    }, [props.status]);

    return (
        <div>
            <Toast ref={toast} position="bottom-right" />
        </div>

    )
}

export default ToastComponent
