import firebase from "../shared/config/firebaseConfig";
import { API_SERVER_URL, DATABASE_COLLECTIONS, CLOUD_FUNCTIONS } from "../shared/config/constants";
import History from "../shared/components/History";
import { getIdTokenFromLocalStorage } from ".";
import { writeException, writeInfo } from "../shared/services/LoggingService";
const { USER_DETAILS, TRIGGER_MAIL, CONNECTORS } = DATABASE_COLLECTIONS;
const { MYOB_AUTHENTICATION } = CLOUD_FUNCTIONS;
const adminEmail = process.env.REACT_APP_ADMIN_EMAIL;

export const addUserToFirestore = async (user: any) => {
  const db = firebase.firestore();
  if (user) {
    await db.collection(`${USER_DETAILS}`).doc(user.uid).set({
      userId: user.uid,
      email: user.email,
      isActive: false,
      isAddonUser: false,
    });
  }
};

const addUserToFirestoreOnSignUp = async (user: any) => {
  const db = firebase.firestore();
  if (user) {
    const userDetail = {
      userId: user.uid,
      email: user.email,
      isActive: false,
      isAddonUser: false,
    };
    let userREF = db.collection(`${USER_DETAILS}`).doc(user.uid);
    await userREF.set(userDetail);
    await db.collection(`${TRIGGER_MAIL}`).add({
      to: adminEmail,
      template: {
        name: "UserAdminApproval",
        data: {
          email: userDetail.email,
        },
      },
    });
  }
};

export const loginWithGoogleAuth = async () => {
  let provider = new firebase.auth.GoogleAuthProvider();
  let res = await firebase.auth().signInWithPopup(provider);
  if (res?.additionalUserInfo?.isNewUser) {
    addUserToFirestoreOnSignUp(res.user);
  }
  History.replace("/connectors");
};

export const SignInWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  await firebase.auth().signInWithEmailAndPassword(email, password);
  History.replace("/connectors");
};

export const SignUpWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  await firebase.auth().createUserWithEmailAndPassword(email, password);
  let user = firebase.auth().currentUser;
  if (user) {
    await addUserToFirestore(user);
    History.replace("/connectors");
  }
};

export const setMYOBCompany = async (
  userId: string,
  connectorId: string,
  data: any
): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();
      await db
        .collection(`${USER_DETAILS}`)
        .doc(userId)
        .collection(`${CONNECTORS}`)
        .doc(connectorId)
        .update({
          "myob.company": data,
          "myob.isConnected": true,
        });

      resolve({ isSucceed: true, message: "Myob Connected Successfully!" });
    } catch (ex: any) {
      resolve({ isSucceed: false, message: ex.message });
      await writeException(`ErrorMessage: ${ex.message}, UserID: ${userId}, ConnectorID: ${connectorId}`);
    }
  });
};

export const setAccessTokenWithMyobAuthenticationCode = (data: any): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      const idToken = await getIdTokenFromLocalStorage();
      const res = await fetch(
        `${API_SERVER_URL}/${MYOB_AUTHENTICATION}/setAccessToken`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${idToken}`,
          },
          body: JSON.stringify(data),
        }
      )
      if(res.ok){
        const response = await res.json();
        resolve({ isSucceed: true, result: response.result });
      }
      else{
        resolve({ isSucceed: false, result: [] });
      }
    } catch (error: any) {
      resolve({ isSucceed: false, message: error.message })
      await writeException(`ErrorMessage: ${error.message}`);

    }
  });
};
