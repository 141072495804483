import React, { useState } from 'react';
import { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../store/AppContext";
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { OrderReprocessRequest } from '../models';
import * as util from '../../../shared/Utilities';
import { isEmpty } from 'lodash';
import { processOrderRequest } from '../services/OrderService';
import { Message } from 'primereact/message';
import { Link } from "react-router-dom";
import RMA from './RMA';
import { actionBodyTemplateTwo } from '../GridBodyTemplates';
import netoToMyob from "../../../assets/netoToMyob.png"

export interface IHeaderDetailProps {
  OrderID: string,
  OrderStatus: string,
  OrderMessage?: string,
  OrderWarning?: string,
  OrderDate: string,
  OrderInternalStatus: string,
  ConnectorId: string,
  incomingOrderProps?: any,
  Username: string,
  RMA?: boolean,
  NetoAndMyobIds?: string,
  props?: any
  PaymentHeader?: boolean
}

const Header: React.FC<IHeaderDetailProps> = ({ OrderID, OrderStatus, OrderMessage, OrderWarning, OrderDate, OrderInternalStatus, ConnectorId, incomingOrderProps, Username, RMA = false, NetoAndMyobIds = "", props = {}, PaymentHeader = false }) => {
  let { State, Dispatch } = useContext(AppContext);
  const [reprocessLoading, setReprocessLoading] = useState<boolean>(false);

  const reprocessing = async () => {
    setReprocessLoading(true);
    const requestModel: OrderReprocessRequest = {
      userId: /*State?.currentUser?.isAdminUser && localStorage.SelectedIdByAdmin ? localStorage.SelectedIdByAdmin : */State.currentUser.currentUserId,
      connectorId: ConnectorId,
      orderId: incomingOrderProps?.OrderId,
      orderStatus: incomingOrderProps?.Status
    };
    const orderReprocessRequestResponse = await processOrderRequest(requestModel);
    if (orderReprocessRequestResponse.isSucceed) {
      Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: true, message: "Successfully Re-processed!" } });
    }
    else {
      Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: false, message: orderReprocessRequestResponse.errorMessage } });
    }
    setReprocessLoading(false);
  }
  // onClick={() = actionBodyTemplateTwo(props?.selectedConnector,props?.docId)}
  const headerComponent = (
    <div className="px-3">
      <div className="row p-3" style={{ backgroundColor: "#d3d3d3b8" }}>
        <div className="col">
          {PaymentHeader ? (
            <Link
              className="font-weight-bold h4"
              style={{ color: "#495057" }}
              to={`/saleOrderDataPreview/${props?.selectedConnector}/${props?.docId}`}
            >
              {OrderID}
            </Link>
          ) :
            <div className="font-weight-bold h4">{OrderID}</div>
          }
        </div>
        {NetoAndMyobIds &&
          <div className="col">
            <React.Fragment>
              <Link
                className="font-weight-bold h4"
                style={{ color: "#495057" }}
                to={`/saleOrderDataPreview/${props?.selectedConnector}/${props?.docId}`}
              >
                {NetoAndMyobIds}
              </Link>
            </React.Fragment>
          </div>
        }
        <div className="col">
          <div className="mt-1">
            <span className="font-weight-bold">Date:</span>
            {!isEmpty(OrderDate) ? <p>{util.userTimezone(OrderDate)}</p> : ''}
          </div>
        </div>
        <div className="col">
          <div className="mt-1">
            <span className="font-weight-bold">Card ID/UserName:</span> {Username}
          </div>
          {/* <img src={netoToMyob} alt="netoToMyob" style={{height:"3rem" ,width:"16rem"}}  /> */}
          </div>
        <div className="col mt-1">
          <Badge value={OrderStatus} severity={
            OrderStatus == "ExportComplete"
              ? "success"
              : OrderStatus == "NotProcessed"
                ? "secondary"
                : OrderStatus == "ImportFailed"
                  ? "danger"
                  : OrderStatus == "Imported"
                    ? "success"
                    : OrderStatus == "ExportFailed"
                      ? "danger"
                      : "info"
          } className="p-mr-2"></Badge>
        </div>
        {(!RMA && !PaymentHeader) &&
          (<div className="col">
            <Button
              className="p-button-secondary p-button-sm p-button-raised bg-dark"
              disabled={reprocessLoading}
              onClick={reprocessing}
              type="button"
            >
              <FontAwesomeIcon
                spin={reprocessLoading}
                className="mr-2"
                icon={faSync}
              />
              Reprocess
            </Button>
          </div>)
        }
      </div>
    </div>
  );

  return (
    <div>
      <Card header={headerComponent}>
        {(!RMA && !PaymentHeader) &&
          (
            <>
              {OrderStatus == "ExportFailed" ? (
                <div className="d-flex">
                  {/* <div className="text-left col-6 ml-1"><span className="font-weight-bold">Error:</span> <Badge value={OrderMessage} severity={"danger"}></Badge></div> */}
                  <div className={`${isEmpty(OrderWarning) ? "justify-content-left ml-1" : "col-6 ml-1"}`}>
                    <Message severity="error" text={`Error: ${OrderMessage}`}></Message>
                  </div>
                  {!isEmpty(OrderWarning) && (
                    <div className=" col-6 ml-1">
                      <Message severity="warn" text={`Warning: ${OrderWarning}`}></Message>
                    </div>
                  )}
                </div>
              ) : (
                <div className="text-left  col-12">
                  <span className="font-weight-bold">Message:</span> {OrderMessage}
                </div>
              )}
            </>
          )
        }
      </Card>
      <br />
    </div>
  );
}

export default Header