import { Button } from "primereact/button";
import firebase from "../../../shared/config/firebaseConfig";
import { InputText } from "primereact/inputtext";
import React, { useContext, useState } from "react";
import { AppContext } from "../../../store/AppContext";
import { Message } from "primereact/message";

interface ISignUp {
  email: string;
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  errorMessage: string;
  successMessage: string;
}

const ChangePasswordComponent: React.FC = () => {
  let { State } = useContext(AppContext);
  const [changePassword, setChangePassword] = useState<ISignUp>({
    email: State.currentUser.currentUserEmail,
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    errorMessage: "",
    successMessage: "",
  });

  const reAuthenticate = () => {
    const { email, oldPassword } = changePassword;
    const user = firebase.auth().signInWithEmailAndPassword(email, oldPassword);
    return user;
  };

  const updatePassword = async (event: any) => {
    event.preventDefault();
    const { newPassword, confirmNewPassword } = changePassword;
    if (confirmNewPassword !== newPassword) {
      setChangePasswordState(
        "errorMessage",
        "new password and confirm password are not same"
      );
      return;
    }
    try {
      let isOldPasswordAuthenticated = await reAuthenticate();
      if (oldPassword === newPassword) {
        setChangePasswordState(
          "errorMessage",
          "current password and new password cannot be the same"
        );
        return;
      }
      if (isOldPasswordAuthenticated) {
        const user = firebase.auth().currentUser;
        if (user) {
          await user.updatePassword(newPassword);
          setChangePasswordState(
            "successMessage",
            "Password is successfully changed"
          );
        }
      }
    } catch (error: any) {
      setChangePasswordState(
        "errorMessage",
        error.code === "auth/wrong-password"
          ? "current password is incorrect, please try again"
          : error.message
      );
    }
  };

  const setChangePasswordState = (field: string, value: string) => {
    field !== "successMessage"
      ? setChangePassword({
        ...changePassword,
        [field]: value,
      })
      : setChangePassword({
        ...changePassword,
        errorMessage: "",
        [field]: value,
      });
  };

  let {
    email,
    errorMessage,
    successMessage,
    oldPassword,
    confirmNewPassword,
    newPassword,
  } = changePassword;
  return (
    <form onSubmit={updatePassword} className="my-5">
      <p className="text-muted mb-5">
        <strong>Email: </strong> {email}
      </p>
      <h5 className="p-text-center">Change Password</h5>
      <div className="p-formgroup-inline">
        <div className="my-3">
          <InputText
            onChange={(e: any) => {
              setChangePasswordState("oldPassword", e.target.value);
            }}
            id="oldPassword"
            type="password"
            required
            value={oldPassword}
            style={{ width: "25rem" }}
            placeholder="current password"
          />
        </div>
        <div className="my-3">
          <InputText
            onChange={(e: any) => {
              setChangePasswordState("newPassword", e.target.value);
            }}
            id="newPassword"
            type="password"
            required
            value={newPassword}
            style={{ width: "25rem" }}
            placeholder="new password"
          />
        </div>
        <div className="my-3">
          <InputText
            onChange={(e: any) => {
              setChangePasswordState("confirmNewPassword", e.target.value);
            }}
            id="confirmNewPassword"
            type="password"
            required
            value={confirmNewPassword}
            style={{ width: "25rem" }}
            placeholder="confirm new password"
          />
        </div>
        {errorMessage && (
          <div className="my-3">
            <Message severity="error" text={errorMessage} />
          </div>
        )}
        {successMessage && (
          <div className=" my-3">
            <Message severity="success" text={successMessage} />
          </div>
        )}
        <div>
          <Button
            type="submit"
            style={{ width: "25rem" }}
            label="Change Password"
            disabled={successMessage !== ""}
            className="p-button-raised bg-dark p-button-secondary"
          />
        </div>
      </div>
    </form>
  );
};

export default ChangePasswordComponent;
