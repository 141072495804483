import * as React from 'react';
import { useEffect, useState, useContext } from 'react';

import firebase from '../../shared/config/firebaseConfig';
import { AppContext } from '../../store/AppContext';
import { createBrowserHistory } from "history";
import _ from 'lodash';
import { RMAListModel, SaleOrderViewModel } from './models/SaleOrderViewModels';
import { Button } from "primereact/button";
import Header from './details/Header';
import LineItem from './details/LineItem';
import { Convert,getRmaList } from './services/OrderService';
import { DATABASE_COLLECTIONS } from '../../shared/config/constants';
import './OrderDetailsContainer.css';
import Payment from './details/Payment';
const { USER_DETAILS, CONNECTORS, ORDERS } = DATABASE_COLLECTIONS;

export interface ISalePaymentDetailProps {
    docId: string,
    selectedConnector: string,
    netoPaymentId: string
}

const PaymentDetailsContainer: React.FC<ISalePaymentDetailProps> = (props) => {
    let { State } = useContext(AppContext);
    const history = createBrowserHistory();
    const [toggleViewModel, setToggleViewModel] = useState<number>(1);
    const [paymentViewModel, setPaymentViewModel] = useState<any>({} as any);

    useEffect(() => {
        const db = firebase.firestore();
        db.collection(`${USER_DETAILS}`).doc(State.currentUser.currentUserId)
            .collection(`${CONNECTORS}`).doc(props?.selectedConnector)
            .collection(`${ORDERS}`).doc(props?.docId).onSnapshot(snapshot => {
                const data:any = snapshot?.data();
                const paymentData = data?.Payments?.payments.find((x: any) => {
                    if (x?.neto?.ID === props?.netoPaymentId) {
                        return x
                    }
                })

                let netoPaymentData = Convert('netoPayment', data?.Status,
                    data?.Message, data?.WarningMessage, data?.Date,
                    {payment:paymentData,IncomingOrder:data?.IncomingOrder}) ?? {} as any;

                let myobPaymentData = Convert('myobPayment', data?.Status,
                    data?.Message, data?.WarningMessage, data?.Date,
                    {payment:paymentData,OutgoingOrder: data?.OutgoingOrder}) ?? {} as any;
                    setPaymentViewModel(toggleViewModel === 1 ? netoPaymentData : myobPaymentData);
            });
    }, [toggleViewModel]);

    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between mt-1">
                <div className="topnav mt-2">
                    <Button className={`${toggleViewModel && toggleViewModel === 1 ? "bg-dark text-white p-button-secondary p-button-sm " : "p-button-secondary p-button-sm  p-button-text p-button-plain"}`} onClick={() => setToggleViewModel(1)}>Maropost</Button>
                    <Button className={`${toggleViewModel && toggleViewModel === 2 ? "bg-dark text-white p-button-secondary p-button-sm " : "p-button-secondary p-button-sm  p-button-text p-button-plain"}`} onClick={() => setToggleViewModel(2)}>Myob</Button>
                </div>
                <Button icon="pi pi-angle-left" label="Go Back" className="p-button-text p-button-plain" onClick={() => history.goBack()} />
            </div>
            <br />

            <Header
                OrderID={paymentViewModel.OrderNumber}
                OrderStatus={paymentViewModel.OrderStatus}
                OrderDate={paymentViewModel.OrderDate}
                Username={paymentViewModel?.Customer?.UserName}
                PaymentHeader={true}
                ConnectorId={props?.selectedConnector}
                props={props}
                OrderInternalStatus={""}
            />
            {!_.isEmpty(paymentViewModel?.PaymentDetail) && _.size(paymentViewModel?.PaymentDetail) > 0 &&
                    <Payment
                        PaymentDetails={paymentViewModel?.PaymentDetail}
                        TotalPayment={"0"}
                    />
                }
        </div>
    )
}

export default PaymentDetailsContainer
