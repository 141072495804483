import * as React from 'react';
import _ from 'lodash';
import NetoImg from "../../assets/maropost-logo.svg";
import MyobImg from "../../assets/Myob.png";
import { Column } from 'primereact/column';
import { Badge } from 'primereact/badge';
import * as util from '../../shared/Utilities';
import { Tooltip } from 'primereact/tooltip';
import { DataTable } from 'primereact/datatable';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';



export const headerTemplateNeto = (
    <img src={NetoImg} alt="Neto" style={{ width: "4rem" }} />
);

export const headerTemplateMyob = (
    <img src={MyobImg} alt="Myob" style={{ width: "4rem" }} />
);

export const lastModifiedTemplate = (rowData:any) => {
    return (
    <React.Fragment>
        <div>
          {!_.isEmpty(rowData?.MyobProduct.LastModified) ? <p>{ util.userTimezone(rowData?.MyobProduct.LastModified)}</p> : ''}
        </div>
    </React.Fragment>
    );
}

export const lastSyncAtBodyTemplate = (rowData:any) => {
    return (
      <React.Fragment>
          <div>
          {!_.isEmpty(rowData?.LastSyncAt) ? <p>{rowData?.LastSyncAt}</p> : ''}
          </div>
      </React.Fragment>
    );
}

export const netoErrorMessageTemplate = (rowData: any, index: any) => {
    return (
        <div>
            {_.isEmpty(rowData?.NetoProduct?.syncErrorMessage) ? '' :
                <>
                <div className={`text-danger products${index?.rowIndex} p-mr-2`}>
                    {rowData?.NetoProduct?.syncErrorMessage}
                </div>
                {/* <Tooltip
                    position="top"
                    target={`.product${index?.rowIndex}`}
                    content={rowData?.NetoProduct?.syncErrorMessage}
                />
                <Badge
                    value={'Error Occurred'}
                    severity={"danger"}
                    className={`product${index?.rowIndex} p-mr-2`}
                ></Badge> */}
                </>
            }
        </div>
    );
};

export const netoDescriptionLength = (rowData: any, index: any) => {
    return (
        <React.Fragment>
        {_.isEmpty(rowData?.NetoProduct?.Description) ? '' :
        <>
            <Tooltip
                position="top"
                target={`.netoDesc${index?.rowIndex}`}
                content={rowData?.NetoProduct?.Description}
            />
            <div aria-hidden="true" className={`netoDesc${index?.rowIndex}  overflow-hidden p-mr-2`} style={{ width: '80px', height: '20px', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} >
                {rowData?.NetoProduct?.Description}
            </div>
        </>
            }
        </React.Fragment>
    );
};

export const myobDescriptionLength = (rowData: any, index: any) => {
    return (
        <React.Fragment>
         {_.isEmpty(rowData?.MyobProduct?.Description) ? '' :
         <>
            <Tooltip
                position="top"
                target={`.myobDesc${index?.rowIndex}`}
                content={rowData?.MyobProduct?.Description}
            />
            <div aria-hidden="true" className={`myobDesc${index?.rowIndex} overflow-hidden  p-mr-2`} style={{ width: '80px', height: '20px', textOverflow: "ellipsis", whiteSpace: 'nowrap'}} >
                {rowData?.MyobProduct?.Description}
            </div>
        </>
        }
        </React.Fragment>
    );
};

export const netoIsBoughtBodyTemplate = (rowData: any) => {
    return (
        <div>
            {!_.isEmpty(rowData?.NetoProduct?.IsBought) ? rowData?.NetoProduct?.IsBought === "True" ?? "y" ? "Yes" : "No" : ""}
        </div>
    );
};

export const myobErrorMessageTemplate = (rowData: any, index: any) => {
    return (
        <div>
            {_.isEmpty(rowData?.MyobProduct?.syncErrorMessage) ? '' :
            <>
            <div className={`text-danger products${index?.rowIndex} p-mr-2`}>
                {rowData?.MyobProduct?.syncErrorMessage}
            </div>
                {/* <Tooltip
                    position="top"
                    target={`.product${index?.rowIndex}`}
                    content={rowData?.MyobProduct?.syncErrorMessage}
                    />
                <Badge
                    value={'Error Occurred'}
                    severity={"danger"}
                    className={`product${index?.rowIndex} p-mr-2`}
                    ></Badge> */}

            </>
            }
        </div>
    );
};

export const rowExpansionTemplate = (data: any) => {
    return (
        <div className="products-subtable" style={{ border: '5px solid #e9ecef' }}>
            <DataTable
                autoLayout
                value={data}
                id="nestedNetoViewTable">
                <Column header={headerTemplateNeto} style={{ width: '8em' }} />
                <Column header="Name" field="NetoProduct.Name" />
                <Column header="SKU" field="NetoProduct.SKU" />
                <Column header="Tax Inclusive" body={TargetTaxInclusiveTemplate} field="NetoProduct.TaxInclusive" />
                <Column header="Inventoried" body={SourceInventoriedTemplate} field="NetoProduct.Virtual" />
                <Column header="Price" field="NetoProduct.DefaultPrice" />
                <Column header="Description" field="NetoProduct.Description" body={netoDescriptionLength}/>
                <Column header="Status" body={SourceStatusTemplate} field="NetoProduct.IsActive" />
                <Column header="RRP" field="NetoProduct.RRP" />
                <Column header="eBay Description" field="NetoProduct.eBayDescription" />
                <Column header="Is Bought" field="NetoProduct.IsBought" body={netoIsBoughtBodyTemplate} />
                <Column header="UPC3" field="NetoProduct.UPC3" />
                <Column header="Shipping Height" field="NetoProduct.ShippingHeight" />
                <Column header="Gift Voucher" field="NetoProduct.IsGiftVoucher" />
                <Column header="Sub Type" field="NetoProduct.SubType" />
                <Column header="ReferenceNumber" field="NetoProduct.ReferenceNumber" />
                <Column header="Group" field="NetoProduct.Group" />
                <Column header="Pre Order Quantity" field="NetoProduct.PreOrderQuantity" />
                <Column header="Tax Free Item" field="NetoProduct.TaxFreeItem" />
                <Column header="Sell Unit Quantity" field="NetoProduct.SellUnitQuantity" />
                <Column header="Brand" field="NetoProduct.Brand" />
                <Column header="BaseUnitPerQuantity" field="NetoProduct.BaseUnitPerQuantity" />
                <Column header="Pick Zone" field="NetoProduct.PickZone" />
                <Column header="Restricted To UserGroup" field="NetoProduct.RestrictedToUserGroup" />
                <Column header="Shipping Length" field="NetoProduct.ShippingLength" />
                <Column header="Numbers Of Labels To Print" field="NetoProduct.NumbersOfLabelsToPrint" />
                <Column header="UPC1" field="NetoProduct.UPC1" />
                <Column header="Expense Account" field="NetoProduct.ExpenseAccount" />
                <Column header="Unit Of Measure" field="NetoProduct.UnitOfMeasure" />
                <Column header="Pick Priority" field="NetoProduct.PickPriority" />
                <Column header="Serial Tracking" field="NetoProduct.SerialTracking" />
                <Column header="Model" field="NetoProduct.Model" />
                <Column header="Variant Inventory IDs" field="NetoProduct.VariantInventoryIDs" />
                <Column header="Warranty" field="NetoProduct.Warranty" />
                <Column header="Is Asset" field="NetoProduct.IsAsset" />
                <Column header="Shipping Category" field="NetoProduct.ShippingCategory" />
                
            </DataTable>
            <DataTable
                autoLayout
                value={data}
                id="nestedMyobViewTable">
                <Column header={headerTemplateMyob} style={{ width: '8em' }} />
                <Column header="Name" field="MyobProduct.Name" />
                <Column header="Number" field="MyobProduct.Number" />
                <Column header="Tax Inclusive" body={TargetTaxInclusiveTemplate} field="MyobProduct.SellingDetails.IsTaxInclusive" />
                <Column header="Inventoried" body={TargetInventoriedTemplate} field="MyobProduct.IsInventoried" />
                <Column header="Price" field="MyobProduct.BaseSellingPrice"/>
                <Column header="Description" field="MyobProduct.Description" body={myobDescriptionLength}/>
                <Column header="Status" body={TargetStatusTemplate} field="MyobProduct.IsActive" />
                <Column header="Asset Account Display ID" field="MyobProduct.AssetAccount.DisplayID" />
                <Column header="Asset Account Name" field="MyobProduct.AssetAccount.Name" />
                <Column header="Tax Code" field="MyobProduct.BuyingDetails.TaxCode.Code" />
                <Column header="Cost Of Sales Account Name" field="MyobProduct.CostOfSalesAccount.Name" />
                <Column header="Income Account Name" field="MyobProduct.IncomeAccount.Name" />
                <Column header="Quantity Available" field="MyobProduct.QuantityAvailable" />
                <Column header="Is Sold" field="MyobProduct.IsSold" />
                <Column header="Expense Account Id" field="MyobProduct.ExpenseAccount.UID" />
                <Column header="Cost of Sales Account Id" field="MyobProduct.CostOfSalesAccount.UID" />
                <Column header="Income Account" field="MyobProduct.IncomeAccount.UID" />
                <Column header="Asset Account" field="MyobProduct.AssetAccount.UID" />
                <Column header="Standard Cost" field="MyobProduct.BuyingDetails.StandardCost" />
                <Column header="Standard Cost Tax Inclusive" field="MyobProduct.StandardCostTaxInclusive" />
                <Column header="Items Per Buying Unit" field="MyobProduct.BuyingDetails.ItemsPerBuyingUnit" />
                <Column header="Tax Code" field="MyobProduct.BuyingDetails.TaxCode.UID" />
                <Column header="Default Sell Location" field="MyobProduct.DefaultSellLocation.Identifier" />
                <Column header="Last Modified" field="MyobProduct.LastModified" body={lastModifiedTemplate}/>  
            </DataTable>
        </div>
    );
};



  



    export const TargetInventoriedTemplate = (rowData: any) => {
    return _.isBoolean(rowData.MyobProduct?.IsInventoried) ? rowData.MyobProduct?.IsInventoried === true ? <Badge value="Inventoried" severity="success"></Badge> : <Badge value="Non-Inventoried" severity="info" style={{whiteSpace: 'nowrap'}}></Badge>: <></>
};

export const TargetStatusTemplate = (rowData: any) => {
    return !_.isEmpty(rowData.MyobProduct?.IsActive) ? rowData.MyobProduct?.IsActive === true ? <Badge value="Active" severity="success"></Badge> : <Badge value="InActive" severity="danger"></Badge> : <></>
};

export const TargetTaxInclusiveTemplate = (rowData: any) => {
    return !_.isEmpty(rowData.MyobProduct?.SellingDetails?.IsTaxInclusive) ? rowData.MyobProduct?.SellingDetails?.IsTaxInclusive === true ? <Badge value="YES" severity="info"></Badge> : <Badge value="No" severity="info"></Badge>: <></>
};

export const SourceInventoriedTemplate = (rowData: any) => {
    return !_.isEmpty(rowData.NetoProduct?.Virtual) ? rowData.NetoProduct?.Virtual === "True" ? <Badge value="Non-Inventoried" severity="info" style={{ whiteSpace: 'nowrap' }}  ></Badge> : <Badge value="Inventoried" severity="success"></Badge> : <></>
};

export const SourceStatusTemplate = (rowData: any) => {
    return  !_.isEmpty(rowData.NetoProduct?.IsActive) ? rowData.NetoProduct?.IsActive === "True" ? <Badge value="Active" severity="success"></Badge> : <Badge value="InActive" severity="danger"></Badge>: <></>
};

export const SourceTaxInclusiveTemplate = (rowData: any) => {
    return !_.isEmpty(rowData.NetoProduct?.TaxInclusive) ? rowData.NetoProduct.TaxInclusive === "True" ? <Badge value="YES" severity="info"></Badge> : <Badge value="No" severity="info"></Badge>: <></>
};


  
export const statusItemTemplate = (option: any) => {
    return (
        <Badge
        value={option}
        severity={
            option == "Inventoried"
            ? "success"
              : option == "Non-Inventoried"
              ? "info"
              : "danger"
        }
        className={`p-mr-2 mb-1`}
      ></Badge>
      );
  }
  
export const emptyMessageTemplate = (
    <div className="text-center">
        <b>No Records Found!</b>
    </div>
);


export const headerGroup = (
    <ColumnGroup>
        <Row>
            <Column />
            <Column header="SKU / Number" colSpan={2} />
            <Column header="Product Name" colSpan={2} />
            {/* <Column header="Description" colSpan={2} /> */}
            {/* <Column header="Price" colSpan={2} /> */}
            <Column header="Inventoried" colSpan={2} />
            {/* <Column header="Status" colSpan={2} /> */}
            {/* <Column header="Tax Inclusive" colSpan={2} /> */}
            <Column header="Sync Error Message" colSpan={2} />
            <Column header="Sync At" />
        </Row>
        <Row>
            <Column />
            <Column header="Maropost" filter filterMatchMode="contains" filterPlaceholder="Search" field="NetoProduct.SKU" />
            <Column header="Myob" filter filterMatchMode="contains" filterPlaceholder="Search" field="MyobProduct.Number" />

            <Column header="Maropost" filter filterMatchMode="contains" filterPlaceholder="Search" field="NetoProduct.Name" />
            <Column header="Myob" filter filterMatchMode="contains" filterPlaceholder="Search" field="MyobProduct.Name" />


            {/* <Column header="Neto" filter filterMatchMode="contains" filterPlaceholder="Search" sortable field="NetoProduct.Description" />
            <Column header="Myob" filter filterMatchMode="contains" filterPlaceholder="Search" sortable field="MyobProduct.Description" /> */}

            {/* <Column header="Neto" filter filterMatchMode="contains" filterPlaceholder="Search" sortable field="NetoProduct.DefaultPrice" />
            <Column header="Myob" filter filterMatchMode="contains" filterPlaceholder="Search" sortable field="MyobProduct.BaseSellingPrice" /> */}

            <Column header="Maropost" filter filterMatchMode="contains" filterPlaceholder="Search" filterField="NetoProduct.IsInventoriedNeto" sortField="NetoProduct.IsInventoriedNeto" field="NetoProduct.IsInventoriedNeto" />
            <Column header="Myob" filter filterMatchMode="contains" filterPlaceholder="Search" filterField="MyobProduct.IsInventoriedMyob" sortField="MyobProduct.IsInventoriedMyob" field="MyobProduct.IsInventoriedMyob" />

            {/* <Column header="Neto" filter filterMatchMode="contains" filterPlaceholder="Search" sortable field="NetoProduct.Active" />
            <Column header="Myob" filter filterMatchMode="contains" filterPlaceholder="Search" sortable field="MyobProduct.IsActive" /> */}

            {/* <Column header="Neto" filter filterMatchMode="contains" filterPlaceholder="Search" sortable field="NetoProduct.TaxInclusive" />
            <Column header="Myob" filter filterMatchMode="contains" filterPlaceholder="Search" sortable field="MyobProduct.SellingDetails.IsTaxInclusive" /> */}

            <Column header="Maropost" filter filterMatchMode="contains" filterPlaceholder="Search" field="NetoProduct.syncErrorMessage" />
            <Column header="Myob" filter filterMatchMode="contains" filterPlaceholder="Search" field="MyobProduct.syncErrorMessage" />

            <Column filter filterMatchMode="contains" filterPlaceholder="Search" sortable filterField="LastSyncAt" field="lastSyncAtForFilter" />
        </Row>
    </ColumnGroup>
);

export const headerGroupForApproach1 = (
    <ColumnGroup>
        <Row>
            <Column />
            <Column header="SKU / Number" colSpan={2} />
            <Column header="Product Name" colSpan={2} />
            {/* <Column header="Description" colSpan={2} /> */}
            {/* <Column header="Price" colSpan={2} /> */}
            <Column header="Inventoried" colSpan={2} />
            {/* <Column header="Status" colSpan={2} /> */}
            {/* <Column header="Tax Inclusive" colSpan={2} /> */}
            <Column header="Sync Error Message" colSpan={2} />
            <Column header="Sync At" />
        </Row>
        <Row>
            <Column />
            <Column header="Maropost" filter sortable filterMatchMode="contains" filterPlaceholder="Search" field="NetoProduct.SKU" />
            <Column header="Myob" filter sortable filterMatchMode="contains" filterPlaceholder="Search" field="MyobProduct.Number" />

            <Column header="Maropost" filter sortable filterMatchMode="contains" filterPlaceholder="Search" field="NetoProduct.Name" />
            <Column header="Myob" filter sortable filterMatchMode="contains" filterPlaceholder="Search" field="MyobProduct.Name" />


            {/* <Column header="Neto" filter filterMatchMode="contains" filterPlaceholder="Search" sortable field="NetoProduct.Description" />
            <Column header="Myob" filter filterMatchMode="contains" filterPlaceholder="Search" sortable field="MyobProduct.Description" /> */}

            {/* <Column header="Neto" filter filterMatchMode="contains" filterPlaceholder="Search" sortable field="NetoProduct.DefaultPrice" />
            <Column header="Myob" filter filterMatchMode="contains" filterPlaceholder="Search" sortable field="MyobProduct.BaseSellingPrice" /> */}

            <Column header="Maropost" filter sortable filterMatchMode="contains" filterPlaceholder="Search" filterField="NetoProduct.IsInventoriedNeto" sortField="NetoProduct.IsInventoriedNeto" field="NetoProduct.IsInventoriedNeto" />
            <Column header="Myob" filter sortable filterMatchMode="contains" filterPlaceholder="Search" filterField="MyobProduct.IsInventoriedMyob" sortField="MyobProduct.IsInventoriedMyob" field="MyobProduct.IsInventoriedMyob" />

            {/* <Column header="Neto" filter filterMatchMode="contains" filterPlaceholder="Search" sortable field="NetoProduct.Active" />
            <Column header="Myob" filter filterMatchMode="contains" filterPlaceholder="Search" sortable field="MyobProduct.IsActive" /> */}

            {/* <Column header="Neto" filter filterMatchMode="contains" filterPlaceholder="Search" sortable field="NetoProduct.TaxInclusive" />
            <Column header="Myob" filter filterMatchMode="contains" filterPlaceholder="Search" sortable field="MyobProduct.SellingDetails.IsTaxInclusive" /> */}

            <Column header="Maropost" filter sortable filterMatchMode="contains" filterPlaceholder="Search" field="NetoProduct.syncErrorMessage" />
            <Column header="Myob" filter sortable filterMatchMode="contains" filterPlaceholder="Search" field="MyobProduct.syncErrorMessage" />

            <Column filter filterMatchMode="contains" filterPlaceholder="Search" sortable filterField="LastSyncAt" field="lastSyncAtForFilter" />
        </Row>
    </ColumnGroup>
);