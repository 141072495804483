import * as React from 'react';
import { useEffect, useState } from 'react';
import Input from '../../components/Input';
import SelectInput from '../../components/SelectInput';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ValueLabel } from '../../shared/models';

export interface IAddConnectorProps {
    connectors: Array<any>,
    isOpen: boolean,
    onToggle: (value: boolean) => void,
    onAdd: (value: any) => void,
    addConnectorLoading: boolean,
    setAddConnectorLoading: (value: boolean) => void,
    errorMessage?: string,
}

const AddConnector: React.FC<IAddConnectorProps> = (props) => {
    const [connectors, setConnectors] = useState<Array<ValueLabel>>([]);
    const [selectedConnector, setSelectedConnector] = useState<string>('');
    const [connectorName, setConnectorName] = useState<string>('');
    const [emptyConnectorAlert, setEmptyConnectorAlert] = useState<boolean>(false);
    const [connectorNameAlert, setConnectorNameAlert] = useState<boolean>(false);

    useEffect(() => {
        const connectors = props.connectors?.map((c) => {
            return { value: c.id, label: c.label };
        });
        setConnectors(connectors);
    }, [props.connectors]);

    useEffect(() => {
        setSelectedConnector('');
        setConnectorName('');
        setEmptyConnectorAlert(false);
        setConnectorNameAlert(false);
    }, [props.isOpen === false]);

    const addConnector = () => {
        let connectorIndex = props.connectors.findIndex(filter => filter.id === selectedConnector);
        if (!connectorName && connectorIndex <= -1) {
            setEmptyConnectorAlert(true);
            setConnectorNameAlert(true);
            return
        }

        if (connectorName) {
            if (connectorIndex > -1) {
                props.onAdd({
                    id: props.connectors[connectorIndex].id,
                    source: props.connectors[connectorIndex].source,
                    target: props.connectors[connectorIndex].target,
                    label: props.connectors[connectorIndex].label,
                    name: connectorName ?? ''

                });
                setSelectedConnector('');
                setConnectorName('');
            }
            else {
                setEmptyConnectorAlert(true);
            }
        }
        else {
            setConnectorNameAlert(true);
        }
    }

    const renderFooter = () => {
        return (
            <>
                {props.errorMessage &&
                    <small className="text-danger">{props.errorMessage}</small>
                }
                {props.addConnectorLoading ?
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div> : ''}
                <Button label="Add" className="p-button-raised p-button-primary" onClick={() => { addConnector(); }} />
                <Button label="Cancel" className="p-button-raised p-button-danger" onClick={() => { props.onToggle(!props.isOpen); props.setAddConnectorLoading(false); }} />
            </>
        );
    }

    return (
        <div>
            <Dialog header="Add Connector" visible={props.isOpen} onHide={() => { }} closable={false} draggable={false} breakpoints={{ '960px': '75vw' }} style={{ width: '35vw' }} footer={renderFooter()}>
                <>
                    <h6 className="text-muted">Select Connector</h6>
                    <SelectInput
                        hasError={emptyConnectorAlert}
                        options={connectors}
                        selectedOptionValue={selectedConnector}
                        onChange={(value: string) => { setSelectedConnector(value); setEmptyConnectorAlert(false); }}
                    />
                    <br />
                    <Input
                        placeholder="Enter Name for Connector"
                        maxLength={20}
                        hasError={connectorNameAlert}
                        onChange={(value: string) => { setConnectorName(value); setConnectorNameAlert(false); }}
                        value={connectorName}
                        disabled={props.addConnectorLoading}
                    />
                </>
            </Dialog>
        </div >
    )
}

export default AddConnector
