import React, { useEffect, useState, useContext } from "react";
import { useParams } from 'react-router-dom';
import { Accordion, AccordionTab } from "primereact/accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faSync } from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";
import Label from "../../../../components/Label";
import Boolean from "../../../../components/Boolean";
import SelectInput from "../../../../components/SelectInput";
import { AppContext } from "../../../../store/AppContext";
import { synchronizePaymentsNetoToMyob } from '../services/MappingService';
import * as util from '../../../../shared/Utilities';
import { PaymentMappingsModel } from "../../../../shared/models/mappings/UserMappingModels";
import NetoToMYOBPaymentMappingPaymentMethods from "./Payment/NetoToMYOBPaymentMappingPaymentMethods";
import Tooltips from "../../../../components/Tooltips";

export interface INetoToMYOBPaymentMappingProps {
  paymentMapping: PaymentMappingsModel,
  setPaymentMappings: (value: PaymentMappingsModel) => void,
  isMappingsEmpty: boolean,
  errors?: any,
  touched?: any,
  onInputBlurred: (name: any) => void,
}

const NetoToMYOBPaymentMapping: React.FC<INetoToMYOBPaymentMappingProps> = (props) => {
  let { State, Dispatch } = useContext(AppContext);
  const { id } = useParams<any>();
  const [paymentMapping, setPaymentMapping] = useState<PaymentMappingsModel>({} as PaymentMappingsModel);
  const [MapPaymentMethodsToggle, setMapPaymentMethodsToggle] = useState<boolean>(false);
  const [isTabActive, setIsTabActive] = useState<number | undefined>(undefined);
  
  const headerTemplate = (headingText: string, hasError: boolean, smallText?: string) => (<React.Fragment><div className="d-flex"><h6>{headingText} {smallText && <small>({smallText})</small>}</h6>
    {hasError && <small className="ml-3 mt-1 text-danger">(There are some validation errors)</small>}</div></React.Fragment>);

  const header = {
    sourceHeader: 'Maropost',
    targetHeader: 'MYOB AccountRight',
    header1: headerTemplate("Payment Methods", MapPaymentMethodsToggle),
  };

  const [syncNetoPaymentsToMyobLoading, setSyncNetoPaymentsToMyobLoading] = useState<boolean>(false);
  let [touchedFields, setTouchedFields] = useState(props.touched);

  const onInputBlur = (touched: any) => {
    props.onInputBlurred(touched);
  }

  useEffect(() => {
    setTouchedFields(props.touched);
  }, [props.touched])

  useEffect(() => {
    setPaymentMapping(props.paymentMapping ?? {} as PaymentMappingsModel);
  }, [props.paymentMapping]);

  const setPaymentMappings = () => {
    props.setPaymentMappings(paymentMapping);
  }

  const onSubComponentsFocusChanged = (value: PaymentMappingsModel) => {
    props.setPaymentMappings(value);
  }

  const syncNetoPaymentsToMyob = async () => {
    setSyncNetoPaymentsToMyobLoading(true);
    let result = await synchronizePaymentsNetoToMyob(State?.currentUser?.currentUserId, id);
    if (result?.isSucceed) {
      Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: true, message: result?.message } });
      setSyncNetoPaymentsToMyobLoading(false);
    }
    else {
      Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: false, message: result?.message } });
      setSyncNetoPaymentsToMyobLoading(false);
    }
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col d-flex justify-content-between">
          <Boolean
            hasError={
              props.touched?.paymentMapping?.isChecked &&
                props.errors?.paymentMapping?.isChecked ? true : false
            }
            text={"Send Payment from Maropost to MYOB AccountRight"}
            value={paymentMapping.isChecked}
            onChange={(val: boolean) => setPaymentMapping({ ...paymentMapping, isChecked: val })}
            name="isChecked"
            onBlur={() => {
              onInputBlur({
                ...touchedFields, paymentMapping: {
                  ...touchedFields.paymentMapping, isChecked: true
                }
              });
              if (props.paymentMapping?.isChecked != paymentMapping.isChecked)
                setPaymentMappings();
            }}
          />
          <button disabled={props.isMappingsEmpty ? true : (!paymentMapping.isChecked || syncNetoPaymentsToMyobLoading)}
            onClick={() => syncNetoPaymentsToMyob()} type="button" className="btn btn-dark btn-sm">Sync
            <FontAwesomeIcon spin={syncNetoPaymentsToMyobLoading} className="ml-1" icon={faSync} />
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-6">
        </div>
        <div className="col-6 text-right">
          {!isEmpty(paymentMapping.lastRunAt) ?
            <small className="text-muted"> <i className="pi pi-check-circle" style={{ 'fontSize': '1em' }}></i> {paymentMapping.message},{paymentMapping.lastRunAt && util.userTimezone(paymentMapping.lastRunAt)} </small> :
            <small className="text-muted"> <i className="pi pi-clock" style={{ 'fontSize': '1em' }}></i> No Synchronized Data</small>
          }
        </div>
      </div>
      <br />
      <div className="row text-left ml-2">
        <div className="col-6">
          <h6>{header.sourceHeader}</h6>
        </div>
        <div className="col-6">
          <h6>{header.targetHeader}</h6>
        </div>
      </div>
      <hr />
      <br />

      <div className="row">
        <div className="col-6 d-flex">
          <Label text={"Maropost To MYOB Payment Sync"} /> <p className="text-danger">*</p>
        </div>
        <div className="col-6">
          <SelectInput
            hasError={props.touched?.paymentMapping?.netoToMyobPaymentSync && props.errors?.paymentMapping?.netoToMyobPaymentSync ? true : false}
            options={State.formsLookup?.genericSchedulerFrequency?.values!}
            selectedOptionValue={paymentMapping.netoToMyobPaymentSync}
            onChange={(val: string) => setPaymentMapping({ ...paymentMapping, netoToMyobPaymentSync: val })}
            onBlur={(e: any) => {
              onInputBlur({
                ...touchedFields, paymentMapping: {
                  ...touchedFields.paymentMapping, netoToMyobPaymentSync: true
                }
              });
              if (props.paymentMapping?.netoToMyobPaymentSync !== paymentMapping.netoToMyobPaymentSync)
                setPaymentMappings();
            }}
          />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-6 d-flex">
          <Label text={"Deposit Payments Into Account"} /> <p className="text-danger">*</p>
          <span className="pl-2">
            {" "}
            <FontAwesomeIcon icon={faQuestionCircle} />{" "}
            {/* <Tooltips content="This is a dummy Text" /> */}
          </span>
        </div>
        <div className="col-6">
          <SelectInput
            hasError={props.touched?.paymentMapping?.depositPaymentInAccount && props.errors?.paymentMapping?.depositPaymentInAccount ? true : false}
            options={State.formsLookup?.paymentMapping?.depositPaymentIntoAccount.values!}
            selectedOptionValue={paymentMapping.depositPaymentInAccount}
            onChange={(val: string) => setPaymentMapping({ ...paymentMapping, depositPaymentInAccount: val })}
            onBlur={(e: any) => {
              onInputBlur({
                ...touchedFields, paymentMapping: {
                  ...touchedFields.paymentMapping, depositPaymentInAccount: true
                }
              });
              if (props.paymentMapping?.depositPaymentInAccount !== paymentMapping.depositPaymentInAccount)
                setPaymentMappings();
            }}
          />
        </div>
      </div>
      <br />
      <Accordion activeIndex={isTabActive}>
        <AccordionTab header={header.header1}>
          <NetoToMYOBPaymentMappingPaymentMethods 
            errors={props.errors}
            touched={props.touched}
            paymentMappings={paymentMapping}
            onFocusChanged={onSubComponentsFocusChanged}
            onInputBlur={onInputBlur}
          />
        </AccordionTab>
      </Accordion>
    </div>
  );
};

export default NetoToMYOBPaymentMapping;