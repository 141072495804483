import React, { FC, useEffect, useContext, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import UserInActive from "../membership/components/UserInActive";
import { AppContext } from "../../store/AppContext";
import SubscriptionsPage from "../../pages/SubscriptionsPage";

interface IProps {
    exact?: boolean;
    path: string;
    component: React.ComponentType<any>;
}

export const PrivateRoute: FC<IProps> = ({ component: Component, ...rest }) => {
    let { State } = useContext(AppContext);
    const [isAuth, setIsAuth] = useState(State.currentUser.loggedIn);
    const [isActive, setIsActive] = useState((State.currentUser.isActive && State.currentUser.loggedIn));
    const [hasSubscription, setIsHasSubscription] = useState((State.currentUser.loggedIn && State.currentUser.isUserHaveSubscription));

    useEffect(() => {
        setIsAuth(State.currentUser.loggedIn)
        setIsActive((State.currentUser.isActive && State.currentUser.loggedIn));
        setIsHasSubscription((State.currentUser.loggedIn && State.currentUser.isUserHaveSubscription));
    }, [State]);

    return (
        <>
            <Route
                {...rest}
                render={(props) => {
                    return isAuth ? (isAuth && isActive && hasSubscription ? (
                        <Component {...props} />) : !isActive ? (<UserInActive />) : !hasSubscription ? (<SubscriptionsPage />) : (<Redirect to={"/signin"} />
                        )
                    ) : (
                        <Redirect to={"/signin"} />
                    );
                }}
            />

        </>
    );
};
