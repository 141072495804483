import firebase from "../config/firebaseConfig";
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../store/AppContext";
import History from "./History";
import novelIntegrationsLogo from "../../assets/novelintegrations.png";
import './Navigation.css';

const Navigation: React.FC = () => {
  let { State, Dispatch } = useContext(AppContext);
  const [expandNav, setExpandNav] = useState<boolean>(false);
  const hasSubscription = State.currentUser.isUserHaveSubscription;
  const isActive = State.currentUser.isActive;
  const isAddonUser = State.currentUser.isAddonUser;

  const signOut = async () => {
    await firebase.auth().signOut();
    localStorage.clear()
    History.replace("/signin")
  };
  return (
    <div className="bg-grey d-flex sticky-top shadow">
        {/* <a className="text-white my-3 pl-3  text-uppercase text-nowrap" href="#">
          Integration Maropost To Myob
        </a> */}
      <div className="img-fluid px-5 py-2 ml-5 my-1 ">
        <Link
          to="/connectors"
          className="nav-link"
          href="/connectors"
        >
          <img src={novelIntegrationsLogo} alt="novelintegrations" height="60rem" />
        </Link>
      </div>
      <nav className=" container navbar navbar-default sticky-top navbar-dark bg-grey navbar-expand-lg">
        <button
          className="navbar-toggler"
          onClick={() => setExpandNav(!expandNav)}
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className={`${
            expandNav ? "collapse" : ""
          } navbar-collapse justify-content-end`}
          id="navbarSupportedContent"
        >
          {State.currentUser &&
            State.currentUser.loggedIn &&
            isActive === true && (
              <ul className="navbar-nav mr-autos">
                {hasSubscription && (
                  <>
                    <li className="nav-item active">
                      <Link
                        to="/connectors"
                        className="nav-link"
                        href="/connectors"
                      >
                        Connectors
                      </Link>
                    </li>
                    {/* <li className="nav-item active">
                      <Link
                        to="/salesOrderGrid"
                        className="nav-link"
                        href="/salesOrderGrid"
                        onClick={() => {
                          Dispatch({
                            Type: "SET_SELECTED_CONNECTOR",
                            Payload: {
                              id: "",
                            },
                          });
                        }}
                      >
                        Sale Orders
                      </Link>
                    </li> */}
                    <li className="nav-item active">
                      <Link
                        to="/salesOrderGridNew"
                        className="nav-link"
                        href="/salesOrderGridNew"
                        onClick={() => {
                          Dispatch({
                            Type: "SET_SELECTED_CONNECTOR",
                            Payload: {
                              id: "",
                            },
                          });
                        }}
                      >
                        Sale Orders
                        {/* (<small className='neonShadow'>new</small>) */}
                      </Link>
                    </li>

            
                    
                    {/* <li className="nav-item active">
                      <Link
                        to="/customersGrid"
                        className="nav-link"
                        href="/customersGrid"
                        onClick={() => {
                          Dispatch({
                            Type: "SET_SELECTED_CONNECTOR",
                            Payload: {
                              id: "",
                            },
                          });
                        }}
                      >
                        Customers
                      </Link>
                    </li> */}
                    <li className="nav-item active">
                      <Link
                        to="/customersGridNew"
                        className="nav-link"
                        href="/customersGridNew"
                        onClick={() => {
                          Dispatch({
                            Type: "SET_SELECTED_CONNECTOR",
                            Payload: {
                              id: "",
                            },
                          });
                        }}
                      >
                        Customers
                        {/* (<small className='neonShadow'>new</small>) */}
                      </Link>
                    </li> 
                    
                    {/* <li className="nav-item active">
                      <Link
                        to="/productsGrid"
                        className="nav-link"
                        href="/productsGrid"
                      >
                        Products
                      </Link>
                    </li> */}
                    <li className="nav-item active">
                      <Link
                        to="/productsGridNew"
                        className="nav-link"
                        href="/productsGridNew"
                      >
                        Products
                        {/* (<small className='neonShadow'>new</small>) */}
                      </Link>
                    </li>   

                    <li className="nav-item active">
                      <Link
                        to="/PaymentGrid"
                        className="nav-link"
                        href="/PaymentGrid"
                        onClick={() => {
                          Dispatch({
                            Type: "SET_SELECTED_CONNECTOR",
                            Payload: {
                              id: "",
                            },
                          });
                        }}
                      >
                        Payments
                        {/* (<small className='neonShadow'>new</small>) */}
                      </Link>
                    </li> 

                    <li className="nav-item active">
                      <Link
                        to="/RMAGrid"
                        className="nav-link"
                        href="/RMAGrid"
                        onClick={() => {
                          Dispatch({
                            Type: "SET_SELECTED_CONNECTOR",
                            Payload: {
                              id: "",
                            },
                          });
                        }}
                      >
                        RMAs
                        {/* (<small className='neonShadow'>new</small>) */}
                      </Link>
                    </li>

                               
                  </>
                )}
            {/* {!State?.currentUser?.isAddonUser && !State?.currentUser?.isAddonUser === true &&    
            <li className="nav-item active">
                  <Link
                    to="/subscriptions"
                    className="nav-link"
                    href="/subscriptions"
                  
                  >
                    Subscriptions
                  </Link>
                </li>} */}

                {State?.currentUser?.isAdminUser && <li className="nav-item active">
                  <Link
                    to="/userprofiles"
                    className="nav-link"
                    href="/userprofiles"
                  
                  >
                    Users Auth
                  </Link>
                </li>}

                
                {State?.currentUser?.isAdminUser && <li className="nav-item active">
                  <Link
                    to="/usersInfo"
                    className="nav-link"
                    href="/usersInfo"
                  
                  >
                    Users Info
                  </Link>
                </li>}
                
                

                {hasSubscription && !State?.currentUser?.isAddonUser && !State?.currentUser?.isAddonUser === true && (
                  <li className="nav-item active">
                    <Link to="/profile" className="nav-link" href="/profile">
                    <i className="pi pi-user text-white ml-4"></i> {""}
                      Profile
                    </Link>
                  </li>
                )}
              </ul>
            )}
          </div>
      </nav>
          <div className="form-inline my-2 my-lg-0 mr-5 text-nowrap">
            {State.currentUser && State.currentUser.loggedIn === true ? (
                <button
                  className="btn btn-outline-light my-2 my-sm-0"
                  type="submit"
                  onClick={()=>{
                    signOut()
                  }}
                >
                  Sign Out
                </button>
            ) : (
              <Link to="/signin">
                <button
                  className="btn btn-outline-light my-2 my-sm-0"
                  type="submit"
                >
                  Sign In
                </button>
              </Link>
            )}
        </div>

      </div>
  );
};



export default Navigation;
