import * as React from 'react';
import NetoToMyobMappingsContainer from '../features/Mappings/NetoToMyob/NetoToMyobMappingsContainer';

const NetoToMyobMappingsPage : React.FC = () => {
    return (
        <div>
            <NetoToMyobMappingsContainer />
        </div>
    )
}

export default NetoToMyobMappingsPage
