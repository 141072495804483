import * as React from 'react';
import { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import SelectInput from '../../components/SelectInput';
import { ValueLabel } from '../../shared/models';

export interface ICompanySelectionProps {
    currentUserId: string,
    docId: string,
    companies: Array<ValueLabel>,
    isOpen: boolean,
    onToggle: (value: boolean) => void,
    isCompanySaving: boolean,
    selectedCompany: string,
    setSelectedCompany: (value: string) => void,
    saveCompany: () => void,
    disabledLoading: boolean,
}

const CompanySelection: React.FC<ICompanySelectionProps> = (props) => {
    const [companies, setCompanies] = useState<Array<ValueLabel>>(props.companies ?? []);

    useEffect(() => {
        setCompanies(props.companies);
    }, [props.companies]);

    const renderFooter = () => {
        return (
            <>
                {props.isCompanySaving && <ProgressSpinner style={{width: '40px', height: '40px'}} strokeWidth="4" animationDuration=".5s"/>}
                <Button label="Save" className="p-button-raised p-button-primary" disabled={props.disabledLoading} onClick={() => props.saveCompany()} />
            </>
        );
    }

    return (
        <div>
            <Dialog header="Select MYOB Company" visible={props.isOpen} onHide={() => { }} closable={false} draggable={false} breakpoints={{ '960px': '75vw' }} style={{ width: '50vw' }} footer={renderFooter()}>
                <SelectInput
                    options={companies}
                    selectedOptionValue={props.selectedCompany}
                    onChange={props.setSelectedCompany}
                />
            </Dialog>
        </div>
    )
}

export default CompanySelection
