import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppProvider } from "./store/AppProvider";
import { errorLogging } from "./shared/errorHandler";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
const errorHandler = errorLogging();

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

window.addEventListener("error", (event) => {
  errorHandler.report(event);
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
