import firebase from "../shared/config/firebaseConfig";
import _ from "lodash";
import {
  DATABASE_COLLECTIONS,
  API_SERVER_URL,
  CLOUD_FUNCTIONS,
} from "../shared/config/constants";
import { IAppState } from "../store/IAppState";
import { Observable } from "rxjs";
import { getIdTokenFromLocalStorage } from ".";
import { writeException } from "../shared/services/LoggingService";

const { NETO_AUTHENTICATION } = CLOUD_FUNCTIONS;

const {
  USER_DETAILS,
  CONNECTORS,
  CONNECTOR_CONFIGURATION,
} = DATABASE_COLLECTIONS;
export let getConnectorsSnapshotUnsubscribe: any;

export const deleteConnectorDataService = async (
  docId: string,
  currentUserId: string
) => {
  const db = firebase.firestore();
  await db
    .collection(`${USER_DETAILS}`)
    .doc(currentUserId)
    .collection(`${CONNECTORS}`)
    .doc(docId)
    .delete()
    .then(() => {
    })
    .catch((error: any) => {
      console.error("Error removing document: ", error);
      writeException(`ErrorMessage: ${error.message}, UserID: ${currentUserId}, ConnectorID: ${docId}`);      
    });
};

export const getConnectors = async () => {
  try {
    const db = firebase.firestore();
    const connectors = await db.collection(`${CONNECTOR_CONFIGURATION}`).get();

    return connectors;
  } catch (e: any) {
    console.error("Error adding document: ", e);
    await writeException(`ErrorMessage: ${e.message}`); 
  }
};

export const addUserConnector = async (userId: string, connector: any): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    const db = firebase.firestore();
    const connectorsCollection = db
      .collection(`${USER_DETAILS}`)
      .doc(userId)
      .collection(`${CONNECTORS}`);
    connectorsCollection.add({
      type: connector?.id,
      source: connector?.source,
      target: connector?.target,
      label: connector?.name,
      mappings: {},
      isMappingsEmpty: true,
      myob: {
        company: {
          companyId: "",
          name: "",
        },
        credentials: {},
        isConnected: false,
      },
      neto: {
        credentials: {},
        isConnected: false,
      },
    });

    resolve({ isSucceed: true, message: "Connector is added!" });
  });
};

export const getUserConnectors = async (userId: string) => {
  try {
    const db = firebase.firestore();
    const userConnectors = await db
      .collection(`${USER_DETAILS}`)
      .doc(userId)
      .collection(`${CONNECTORS}`)
      .get();

    return userConnectors;
  } catch (e: any) {
    console.error("Error getting document: ", e);
    await writeException(`ErrorMessage: ${e.message}, UserID: ${userId}`); 
  }
};

export const syncMyobConnectivity = (userId: string) => {
  const db = firebase.firestore();
  return new Observable((subscriber) => {
    getConnectorsSnapshotUnsubscribe = db
      .collection(`${USER_DETAILS}`)
      .doc(userId)
      .collection(`${CONNECTORS}`)
      .onSnapshot((snapshot) => {
        subscriber.next(snapshot?.docs);
      });
  });
};

export const disconnectConnector = async (
  property: string,
  data: any,
  userId: string,
  connectorId: string,
  isRemoveMapping: boolean
) => {
  try {
    if (userId) {
      const db = firebase.firestore();
      let connector = await db
        .collection(`${USER_DETAILS}`)
        .doc(userId)
        .collection(`${CONNECTORS}`)
        .doc(connectorId)
        .get();
      if (isRemoveMapping) {
        if (connector.exists) {
          let connectorData = _.get(connector.data(), property);
          Object.assign(connectorData, data);
          connector.ref.update({
            [property]: connectorData,
            mappings: {},
            isMappingsEmpty: true
          });
        }
        return {
          isSucceed: true,
          message: "Disconnected! and mappings are removed successfully!",
        };
      } else {
        if (connector.exists) {
          let connectorData = _.get(connector.data(), property);
          Object.assign(connectorData, data);
          connector.ref.update({
            [property]: connectorData,
          });
          return { isSucceed: true, message: "Disconnected!" };
        }
      }
    }
  } catch(e: any) {
    await writeException(`ErrorMessage: ${e.message}, UserID: ${userId}, ConnectorID: ${connectorId}`); 
    return { isSucceed: false, message: e.message };
  }
};


export const saveNetoConnectionConfig = async (data: any) => {
  try {
    const idToken = await getIdTokenFromLocalStorage();
    let res = await fetch(`${API_SERVER_URL}/${NETO_AUTHENTICATION}/save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${idToken}`,
      },
      body: JSON.stringify(data),
    });

    if (res.ok) {
      let response = await res.json();
      return { isSuccess: true, response: response?.message };

    } else {
      let response = await res.json();
      return { isSuccess: false, response: response?.message };
    }
  } catch (e: any) {
    console.error(e);
   await writeException(`ErrorMessage: ${e.message}`); 
    return { isSuccess: false, response: e.message };
  }
};
