import * as React from "react";
import SubscriptionContainer from '../features/Subscription/SubscriptionContainer';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLISHABLE_KEY } from "../shared/config/constants";

const SubscriptionsPage : React.FC = () => {
    const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

    return (
        <Elements stripe={stripePromise}>
            <SubscriptionContainer />
        </Elements>
    )
}

export default SubscriptionsPage