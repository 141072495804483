import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { deleteConnectorService } from "./services/ContainerService";
import { isEmpty } from "lodash";
import NetoImg from "../assets/maropost-logo.svg";
import MyobImg from "../assets/Myob.png";
import { IAppState } from "../store/IAppState";

export const mappingButtonTemplate = (rowData: any, State: IAppState) => (
  <div className="text-center">
    {rowData?.company?.sourceCompanyIsConnected &&
      rowData.company?.targetCompanyIsConnected ? (
      <Link
        to={{
          pathname: `/neto_to_myob_mappings/${State?.currentUser?.isAddonUser ? rowData?.company?.sourceCompanyName : rowData?.company?.info?.StoreDomain}/${rowData?.connector.id}`,
        }}
      >
        <Button
          label={`Mappings`}
          className={` p-button-raised text-nowrap p-button-sm p-button-${rowData?.company?.targetCompanyIsConnected &&
            rowData.company?.sourceCompany
            ? "secondary"
            : "secondary"
            } p-button-text`}
        ></Button>
      </Link>
    ) : (
      <Button
        label={`Mappings`}
        disabled
        className={` p-button-raised text-nowrap p-button-sm p-button-${rowData.company?.targetCompanyIsConnected &&
          rowData.company?.sourceCompanyIsConnected
          ? ""
          : "secondary"
          } p-button-text`}
      ></Button>
    )}
  </div>
);


export const deleteButtonTemplate = (rowData: any, isAddOnUser: boolean, currentUserId: string) => ( // State: IAppState) => (
  <div className="d-flex">
    {isAddOnUser
    // !State?.currentUser?.isAddonUser && !State?.currentUser?.isAddonUser === true 
    && <Button
      onClick={() => confirmConnectorDelete(rowData?.connector.id, currentUserId)}
      icon="pi pi-trash"
      className="p-button-rounded p-button-danger p-button-text "
    />}
  </div>
);

const confirmConnectorDelete = (docId: string, currentUserId: string) => {
  confirmDialog({
    message: "Do you want to delete this Connector?",
    header: "Delete Confirmation",
    icon: "pi pi-times-circle",
    acceptClassName: "p-button-danger",
    accept: () => deleteConnectorService(docId, currentUserId),
  });
};


export const headerTemplate = (State: IAppState, isAddModalOpen: any, setIsAddModalOpen: any) => (
  <div className=" d-flex justify-content-between p-3 bg-light">
    <h4 className="mt-2">Connectors</h4>
    {!State?.currentUser?.isAddonUser && !State?.currentUser?.isAddonUser === true &&
      <Button
        icon="pi pi-plus"
        label="Add Connector"
        className="p-button p-button-secondary bg-white shadow p-button-text"
        onClick={() => setIsAddModalOpen(!isAddModalOpen)}
      />}
  </div>
);

export const actionButtonsTemplate = (rowData: any, State: IAppState, confirmDisconnectDialog: any, openAuthPopup: any, toggleNetoConfigModal: any) => (
  <div className="d-flex">
    {rowData.company?.targetCompanyIsConnected ? (
      <Button
        icon="pi pi-ban"
        label={`Disconnect ${rowData?.company?.targetCompany}`}
        className="w-45 p-button-raised text-nowrap p-button-sm p-button-danger p-button-text"
        onClick={(e) =>
          confirmDisconnectDialog(
            e,
            rowData?.company?.targetCompany,
            rowData?.connector.id, ""
          )
        }
      ></Button>
    ) : (
      <Button
        className="w-45 p-button-raised text-nowrap p-button-sm p-button-secondary p-button-text "
        onClick={() => {
          openAuthPopup(rowData?.connector.id);
          return false;
        }}
        icon="pi pi-link"
        label={`Connect ${rowData?.company?.targetCompany}`}
      ></Button>
    )}

    {State.currentUser.isAddonUser && State.currentUser.isAddonUser === true ? "" : (rowData.company?.sourceCompanyIsConnected ?
      <Button
        icon="pi pi-ban"
        label={`Disconnect ${rowData?.company?.sourceCompany}`}
        className="w-45 p-button-raised text-nowrap p-button-sm p-button-danger p-button-text"
        onClick={(e) =>
          confirmDisconnectDialog(
            e,
            rowData?.company?.sourceCompany,
            rowData?.connector.id,
            rowData?.company?.sourceCompanyName
          )
        }
      ></Button>
      :
      <Button
        icon="pi pi-link"
        label={`Connect ${rowData?.company?.sourceCompany}`}
        className="w-45 p-button-raised text-nowrap p-button-sm p-button-secondary p-button-text"
        onClick={() => {
          toggleNetoConfigModal(
            rowData?.neto,
            rowData?.source,
            rowData?.connector.id
          );
        }}
      />
    )}
  </div>
);

export const detailsTemplate = (rowData: any, State: IAppState, modalOpen: any, setWebhookURL: any, setModalOpen: any) => (
  <div className="text-truncate pt-2 align-left">
  <div>
    <img width={40} height={21} src={MyobImg} className="mr-2" />
    <small className={`${rowData.company?.targetCompanyIsConnected && rowData.company?.targetCompanyIsConnected === true && rowData.company?.targetCompanyName ? "text-success" : "text-danger"}`}>
      :{" "}
      {rowData.company?.targetCompanyName
        ? rowData.company?.targetCompanyName
        : "Not Connected"}
    </small>
  </div>
  <div className="mb-1">
    <img width={40} height={15} src={NetoImg} className="mr-2" />
    <small className={`${rowData.company?.sourceCompanyIsConnected === true && (!isEmpty(rowData.company?.sourceCompanyName) || !isEmpty(rowData.company?.sourceCompanyNameTwo))  ? "text-success" : "text-danger"}`} > 
      :{" "}
      {State.currentUser.isAddonUser && State.currentUser.isAddonUser !== true && (!isEmpty(rowData.company?.sourceCompanyName) || !isEmpty(rowData.company?.sourceCompanyNameTwo)) ? 
        (rowData.company?.sourceCompanyIsConnected && rowData.company?.sourceCompanyIsConnected === true ? 
          rowData.company?.sourceCompanyName ? rowData.company?.sourceCompanyName : 
          rowData.company?.sourceCompanyNameTwo ? rowData.company?.sourceCompanyNameTwo 
        : "Not Connected" : "Not Connected") 
        
        : (rowData.company?.sourceCompanyName ? rowData.company?.sourceCompanyName : 
          rowData.company?.sourceCompanyNameTwo ? rowData.company?.sourceCompanyNameTwo 
          : "Not Connected") 
      }
    </small>
  </div>
  <div className="ml-1">
    <Button
      disabled={rowData.company.sourceCompany === "Maropost" ? false : true}
      className=" text-nowrap p-button-sm p-button-secondary p-button-text "
      onClick={() => {
        setWebhookURL(rowData.instruction?.url || "");
        setModalOpen(!modalOpen);
      }}
    >
      View Instructions
    </Button>
  </div>
</div>

)


