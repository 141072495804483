import React, { useEffect, useState, useContext } from "react";
import Spinner from "../../shared/components/Spinner";
import { AppContext } from "../../store/AppContext";
import { useStripe } from "@stripe/react-stripe-js";
import SelectInput from "../../components/SelectInput";
import { SUBSCRIPTION_STATUSES } from "../../shared/config/constants";
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { evaluateSubscriptionPrice, getFunctionRefFromFirebaseAppService, getProductsItemFromFirebaseService, subscribeSnapshotService } from "./services/SubscriptionService";
import { Subscription } from "rxjs";
import { SubscriptionPriceModel } from "./models/SubscriptionPriceModel";
import { getProductsItemFirebase } from "../../dataServices";


const SubscriptionContainer: React.FC = () => {
  let { State, Dispatch } = useContext(AppContext);
  const stripe = useStripe();
  const subscriptionPlanKeys = { checkoutSession: "checkout_sessions", subscriptions: "subscriptions", payments: "payments" }
  const [Products, setproducts] = useState<any>();
  const [subcriptionPlanErrorMessage, setSubcriptionPlanErrorMessage] = useState<string>();
  const [trialCancelledInfo, setTrialCancelledInfo] = useState<string>();
  const [subscriptionPlanRemainingTrialDays, setSubscriptionPlanRemainingTrialDays] = useState<number>();
    const [onSubscribeSaving, setOnSubscribeSaving] = useState<boolean>(false);
  const [isAllSelectionDisabled, setIsAllSelectionDisabled] = useState<boolean>(false);
  const [selectedPlanPriceId, setSelectedPlanPriceId] = useState<SubscriptionPriceModel>({
    quantity: 0, priceId: "",
    productIndex: "", mode: "",
  });
  const [isSubscriptionSaving, setIsSubscriptionSaving] = useState<boolean>(false);
  const [currentPlanIndex, setCurrentPlanIndex] = useState<number>();
  const [selectedPlanId, setSelectedPlanId] = useState<string>("");
  const hasSubscription = State.currentUser.isUserHaveSubscription;
  let subscribeSnapshotObserver: Subscription

  const getProductsItem = async () => {
    let products:any = await getProductsItemFirebase();
    const docRef = await getProductsItemFromFirebaseService(State.currentUser.currentUserId, subscriptionPlanKeys.subscriptions);

    if (!docRef.empty) {
      await products?.map(async (payload: any) => {
        await docRef?.docs?.map(async (subscription) => {
          const { product, status, trial_end, trial_start } = subscription.data();

          let productId: string = await product.id;

          if (payload?.product?.id === productId) {
            if (status === SUBSCRIPTION_STATUSES.ACTIVE || SUBSCRIPTION_STATUSES.TRIALING) {
              setSelectedPlanId(payload?.product?.id);
              setIsAllSelectionDisabled(true);

              if (status === SUBSCRIPTION_STATUSES.TRIALING) {
                const trialStart = new Date(trial_start.seconds * 1000);
                const trialEnd = new Date(trial_end.seconds * 1000);
                const difference = trialEnd.getTime() - trialStart.getTime();
                setSubscriptionPlanRemainingTrialDays((difference / (1000 * 3600 * 24)));
              }
              // else{
              //   if(trial_start.seconds > 0 && trial_end.seconds > 0) {
              //     const trialStart = new Date(trial_start.seconds * 1000);
              //     const trialEnd = new Date(trial_end.seconds * 1000);
              //     const difference = trialEnd.getTime() - trialStart.getTime();

              //     if(difference === 0)
              //       setSubscriptionPlanTrialExpired("Your trial is expired!");

              //   }
              // }
            }

            if (status === SUBSCRIPTION_STATUSES.CANCELLED) {
              setSubcriptionPlanErrorMessage("Either your invoice didn't paid with in due date, or payment is failed!");
              setIsAllSelectionDisabled(false);
            }

            if (status === SUBSCRIPTION_STATUSES.INCOMPLETE) {
              setSubcriptionPlanErrorMessage("The initial payment attempt fails!");
              setIsAllSelectionDisabled(false);
            }

            if (status === SUBSCRIPTION_STATUSES.INCOMPLETE_EXPIRED) {
              setSubcriptionPlanErrorMessage("The first invoice is not paid within the duration of 23 hours!");
              setIsAllSelectionDisabled(false);
            }

            if (status === SUBSCRIPTION_STATUSES.PAST_DUE) {
              setSubcriptionPlanErrorMessage("The invoice didn't paid with in the due date!");
              setIsAllSelectionDisabled(false);
            }

            if (status === SUBSCRIPTION_STATUSES.UNPAID) {
              setSubcriptionPlanErrorMessage("The invoice didn't paid after additional deadline!");
              setIsAllSelectionDisabled(false);
            }
          }
        });
      });
    }

    setproducts(products);
    Dispatch({ Type: "DISABLE_APP_LOADING", Payload: false });
  };

  useEffect(() => {
    Dispatch({ Type: "ENABLE_APP_LOADING", Payload: true });
    getProductsItem();
    return () => {
      subscribeSnapshotObserver?.unsubscribe();
    }
  }, []);

  useEffect(() => {
    setTrialCancelledInfo(State?.currentUser?.isUserHaveSubscriptionError ?? "");
  }, [State.currentUser.isUserHaveSubscriptionError])

  const priceEvaluation = (priceId: string, productIndex: number, planId: string) => {
    // const priceModel = { quantity: 0, priceId: "", productIndex: "", mode: "" };
    // if (Products[selectedPlanPriceId.productIndex]?.recurring?.usage_type !== "metered") {
    //   priceModel.quantity = 1;
    //   priceModel.priceId = priceId;
    //   priceModel.productIndex = productIndex.toString();
    // } else {
    //   priceModel.quantity = 0;
    //   priceModel.priceId = priceId;
    //   priceModel.productIndex = productIndex.toString();
    // }
    const priceEvaluationsModel: SubscriptionPriceModel = evaluateSubscriptionPrice(Products, selectedPlanPriceId, priceId, productIndex, planId);
    setCurrentPlanIndex(productIndex);
    setSelectedPlanPriceId(priceEvaluationsModel);
  };

  const Subscribe = async () => {
    setOnSubscribeSaving(!onSubscribeSaving);
    setIsSubscriptionSaving(true);
    const checkoutSession: any = {
      collect_shipping_address: true,
      allow_promotion_codes: true,
      line_items: [
        {
          price: selectedPlanPriceId.priceId,
          quantity: selectedPlanPriceId.quantity,
        },
      ],
      success_url: window.location.origin,
      cancel_url: window.location.origin,
      metadata: {
        // productId: Products[selectedPlanPriceId.productIndex]?.product?.id,
      },
    };

    // For one time payments set mode to payment.
    if (Products[selectedPlanPriceId.productIndex]?.type === "one_time")
      checkoutSession.mode = "payment";



    subscribeSnapshotObserver = (await subscribeSnapshotService(State?.currentUser?.currentUserId, checkoutSession)).subscribe((e: any) => {
      const { error, sessionId } = e;
      if (error) {
        document.querySelectorAll("button").forEach((b) => (b.disabled = false));
      }
      if (sessionId) {
        setIsSubscriptionSaving(false);
        stripe?.redirectToCheckout({ sessionId: sessionId });
      }
    });
    // setIsSubscriptionSaving(false);
  };


  const AccessCustomerPortal = async () => {
    Dispatch({ Type: "ENABLE_APP_LOADING", Payload: true });
    const functionRef = getFunctionRefFromFirebaseAppService();
    const { data } = await functionRef({ returnUrl: window.location.origin });
    window.location.assign(data.url);
    Dispatch({ Type: "DISABLE_APP_LOADING", Payload: false });
  };

  return (
    <div className="container-fluid">
      <h4 className="mt-5 mb-5"><mark>Select Subscription Plan </mark></h4>
      <div>
        {State.isLoading ? (
          <Spinner />
        ) : (
          <div className="mx-auto" style={{ maxWidth: '25.2rem' }}>
            {Products &&
              Products.length > 0 &&
              Products?.map((payload: any, index: number) => (
                <div
                  key={index}
                  className={`${selectedPlanId === payload.product.id
                    ? "border-success"
                    : ""
                    } card mb-4 shadow-sm`}>

                  <Card title={payload.product.name} style={{ width: '25em' }} >
                    <p className="p-m-0">{payload.product.description}</p>
                    <br />
                    <SelectInput
                      onChange={(value: string) =>
                        priceEvaluation(value, index, payload.product.name)
                      }
                      disabled={isAllSelectionDisabled}
                      options={payload.prices.map((doc: any, index: any) => ({
                        label: `${new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: doc.data?.currency,
                        }).format(
                          ((doc.data?.unit_amount / 100).toFixed(
                            2
                          ) as unknown) as number
                        )} per ${doc.data?.interval}`,
                        value: doc.id,
                      }))}
                      selectedOptionValue={
                        currentPlanIndex === index ? selectedPlanPriceId : ""
                      }
                    />
                    <br />
                    {/* <button
                    type="button"
                    disabled={
                      isAllSelectionDisabled
                        ? true
                        : currentPlanIndex === index
                        ? false
                        : true
                    }
                    onClick={() => Subscribe()}
                    className="btn btn-lg btn-block btn-dark mt-4"
                  >
                    {isSubscriptionSaving && currentPlanIndex === index ? (
                      <span
                        className="spinner-border text-light mr-2"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </span>
                    ) : (
                      ""
                    )}
                    Subscribe
                  </button> */}
                    <Button type="button" label="Subscribe" className="p-mr-2 p-mb-2" style={{ width: '23rem', backgroundColor: "#212529", border: '#212529' }}
                      disabled={isAllSelectionDisabled ? true : currentPlanIndex === index ? false : true} onClick={() => Subscribe()}>
                      {isSubscriptionSaving && currentPlanIndex === index ? (
                        <span
                          className="spinner-border text-light mr-2"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </span>
                      ) : (
                        ""
                      )}
                    </Button>

                  </Card>
                </div>
              ))}
          </div>
        )}
      </div>

      {State.isLoading ? (
        <Spinner />
      ) : (!trialCancelledInfo && subscriptionPlanRemainingTrialDays && <div>
        <h2 className="badge badge-pill badge-warning">Your trial ends in: {`${subscriptionPlanRemainingTrialDays} days!`}</h2>
      </div>)
      }

      {State.isLoading ? (
        <Spinner />
      ) : (trialCancelledInfo && <div>
        <div className="alert alert-danger" role="alert">{trialCancelledInfo}</div>
      </div>)}

      {State.isLoading ? (
        <Spinner />
      ) : (subcriptionPlanErrorMessage && <div>
        <div className="alert alert-danger" role="alert">{subcriptionPlanErrorMessage}</div>
      </div>)}

      {State.isLoading ? (
        <Spinner />
      ) : ((hasSubscription || (trialCancelledInfo && !hasSubscription)) && (
        <div className="alert alert-info mt-5" role="alert">
          <button
            className="btn btn-info"
            onClick={() => AccessCustomerPortal()}>
            Access Customer Portal
          </button>
        </div>
      ))}
    </div>
  );
};


export default SubscriptionContainer