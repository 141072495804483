import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import Alert from "../../../../components/Alert";
import Label from "../../../../components/Label";
import Boolean from "../../../../components/Boolean";
import SelectInput from "../../../../components/SelectInput";
import { AppContext } from "../../../../store/AppContext";
import * as util from '../../../../shared/Utilities';
import { synchronizeProductsMyobToNeto } from '../services/MappingService';
import { ProductStockMappingsModel } from "../../../../shared/models/mappings/UserMappingModels";
import MultiSelectInput from "../../../../components/MultiSelectInput";

export interface IMYOBToNetoProductStockProps {
  productStockMapping: ProductStockMappingsModel,
  setProductStockMappings: (value: ProductStockMappingsModel) => void
  isMappingsEmpty: boolean,
  errors: any,
  touched: any,
  onInputBlurred: any,
}

const MYOBToNetoProductStockMapping: React.FC<IMYOBToNetoProductStockProps> = (props) => {

  let { State, Dispatch } = useContext(AppContext);
  const { id } = useParams<any>();

  const [productStock, setProductStock] = useState<ProductStockMappingsModel>({} as ProductStockMappingsModel)
  const [syncMyobProductsToNetoLoading, setSyncMyobProductsToNetoLoading] = useState<boolean>(false);

  const headerTemplate = (headingText: string, hasError: boolean, smallText?: string) => (<React.Fragment><div className="d-flex"><h6>{headingText} {smallText && <small>({smallText})</small>}</h6>
    {hasError && <small className="ml-3 mt-1 text-danger">(There are some validation errors)</small>}</div></React.Fragment>);

  const header = {
    sourceHeader: 'Maropost',
    targetHeader: 'MYOB AccountRight',
    header1: headerTemplate("", false),
  };

  let [touchedFields, setTouchedFields] = useState(props.touched);

  useEffect(() => {
    setTouchedFields(props.touched)
  }, [props.touched]);

  const onInputBlur = (touched: any) => {
    props.onInputBlurred(touched);
  }

  useEffect(() => {
    setProductStock(props.productStockMapping)
  }, [props.productStockMapping]);

  const setProductStockMapping = () => {
    props.setProductStockMappings(productStock)
  }

  const syncMyobProductsToNeto = async () => {
    setSyncMyobProductsToNetoLoading(true);
    let result = await synchronizeProductsMyobToNeto(State?.currentUser?.currentUserId, id);
    if (result?.isSucceed) {
      Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: true, message: result?.message } });
      setSyncMyobProductsToNetoLoading(false);
    }
    else {
      Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: false, message: result?.message } });
      setSyncMyobProductsToNetoLoading(false);
    }
  }

  return (
    <div className='container-fluid'>
      <div className="row">
        <div className="col d-flex justify-content-between">
          <Boolean
            hasError={
              props?.touched?.productStockMapping?.isChecked &&
                props?.errors?.productStockMapping?.isChecked ? true : false
            }
            text={"When Stock Levels are added in MYOB AccountRight, Update Products Stock in Maropost"}
            value={productStock.isChecked}
            onChange={(val: boolean) => setProductStock({ ...productStock, isChecked: val })}
            onBlur={() => {
              onInputBlur({
                ...touchedFields, productStockMapping: {
                  ...touchedFields.productStockMapping, isChecked: true
                }
              });
              if (props.productStockMapping?.isChecked != productStock.isChecked)
                setProductStockMapping();
            }}
          />
          <button disabled={props.isMappingsEmpty ? true : (!productStock.isChecked || syncMyobProductsToNetoLoading)} onClick={syncMyobProductsToNeto} type="button" className="btn btn-dark btn-sm">Sync
            <FontAwesomeIcon spin={syncMyobProductsToNetoLoading} className="ml-1" icon={faSync} />
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
        </div>
        <div className="col-6 text-right">
          <Link to={{ pathname: "/productsGrid" }} target="_blank">
            {!_.isEmpty(productStock.syncErrorMessage) ?
              <small className="badge badge-danger" data-toggle="tooltip" data-placement="top" title={productStock.syncErrorMessage}> <i className="pi pi-exclamation-triangle" style={{ 'fontSize': '1em' }}></i> Sync Error </small> : ''}
            {productStock.syncErrorMessage ? <br /> : ''}
          </Link>
          {!_.isEmpty(productStock.lastRunAt) ?
            <small className="text-muted"> <i className="pi pi-check-circle" style={{ 'fontSize': '1em' }}></i> {productStock.message},{productStock.lastRunAt && util.userTimezone(productStock.lastRunAt)}  </small> :
            <small className="text-muted"> <i className="pi pi-clock" style={{ 'fontSize': '1em' }}></i> No Synchronized Data</small>
          }
        </div>
      </div>
      <br />

      <Alert heading={"Note"} text={"By activating this option you confirm MYOB AccountRight is your master stock management application, all stock levels are correctly set up and product codes/SKU's  match between the systems. Learn more about stock sync."} />
      <div className='row'>
        <div className='col-6 d-flex'>
          <Label text={'I want my Products to be'} /> <p className="text-danger">*</p>
        </div>
        <div className='col-6'>
          <SelectInput
            hasError={
              props.touched?.productStockMapping?.syncAs &&
                props.errors?.productStockMapping?.syncAs ? true : false}
            options={State.formsLookup?.genericSyncOptions?.values ?? []}
            selectedOptionValue={productStock.syncAs}
            onChange={(val: string) => setProductStock({ ...productStock, syncAs: val })}
            onBlur={(e: any) => {
              onInputBlur({
                ...touchedFields, productStockMapping: {
                  ...touchedFields.productStockMapping, syncAs: true
                }
              });
              if (props.productStockMapping?.syncAs !== productStock.syncAs)
                setProductStockMapping();
            }}
          />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-6 d-flex">
          <Label text={"Myob To Maropost Product"} /> <p className="text-danger">*</p>
        </div>
        <div className="col-6">
          <SelectInput
            hasError={
              props.touched?.productStockMapping?.myobToNetoProductFrequency &&
                props.errors?.productStockMapping?.myobToNetoProductFrequency ? true : false
            }
            options={State.formsLookup?.genericSchedulerFrequency?.values!}
            selectedOptionValue={productStock.myobToNetoProductFrequency}
            onChange={(val: string) => setProductStock({ ...productStock, myobToNetoProductFrequency: val })}
            onBlur={(e: any) => {
              onInputBlur({
                ...touchedFields, productStockMapping: {
                  ...touchedFields.productStockMapping, myobToNetoProductFrequency: true
                }
              });
              if (props.productStockMapping?.myobToNetoProductFrequency !== productStock.myobToNetoProductFrequency)
                setProductStockMapping();
            }}
          />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-6 text-left">
          <Label text="Products price sync from MYOB to Maropost" />
        </div>
        <div className=" col-6">
          <Boolean
            hasError={
              props?.touched?.productStockMapping?.isCheckedProducts &&
                props?.errors?.productStockMapping?.isCheckedProducts ? true : false
            }
            text={''}
            value={productStock.isProductsPriceSync}
            onChange={(val: boolean) => setProductStock({ ...productStock, isProductsPriceSync: val })}
            onBlur={(e: any) => {
              onInputBlur({
                ...props?.touched, productStockMapping: {
                  ...props?.touched.productStockMapping, isProductsPriceSync: true
                }
              });
              if (props?.productStockMapping?.isProductsPriceSync != productStock.isProductsPriceSync)
                setProductStockMapping();
            }}
          />

        </div>
      </div>
      <br />
      <div className='row'>
        <div className='col-md-6 d-flex'>
          <Label
            text={"Fields to excluded during item sync"}
          /> 
        </div>
        <div className='col-md-6'>
          <MultiSelectInput
            hasError={
              props.touched?.productStockMapping?.excludedFields &&
                props.errors?.productStockMapping?.excludedFields ? true : false}
            options={State.formsLookup?.productStockMapping?.excludedFields.values!}
            onChange={(value: Array<string>) => setProductStock({ ...productStock, excludedFields: value })}
            selectedOptions={productStock.excludedFields}
            onBlur={() => {
              onInputBlur({
                ...touchedFields, productStock: {
                  ...touchedFields.productStock, excludedFields: true
                }
              });
              if (props.productStockMapping.excludedFields !== productStock.excludedFields)
                setProductStockMapping();
            }}
          />
        </div>
      </div>
      <br />
      <div className="row text-left ml-2">
        <div className="col-6">
          <h6>{header.sourceHeader}</h6>
        </div>
        <div className="col-6">
          <h6>{header.targetHeader}</h6>
        </div>
      </div>
      <hr />

      <div className="row">
        <div className="col-6 text-left">
          <Label text="Products quantity synchronized with multiple locations" />
        </div>
        <div className=" col-6">
          <Boolean
            hasError={
              props?.touched?.productStockMapping?.isCheckedBox &&
                props?.errors?.productStockMapping?.isCheckedBox ? true : false
            }
            text={''}
            value={productStock.isCheckedBox}
            onChange={(val: boolean) => setProductStock({ ...productStock, isCheckedBox: val })}
            onBlur={(e: any) => {
              onInputBlur({
                ...props?.touched, productStockMapping: {
                  ...props?.touched.productStockMapping, isCheckedBox: true
                }
              });
              if (props?.productStockMapping?.isCheckedBox != productStock.isCheckedBox)
                setProductStockMapping();
            }}
          />

        </div>
      </div>
      <br />
      {!productStock.isCheckedBox &&
        <React.Fragment>
          <div className="row">
            <div className="col-6 d-flex">
              <Label text={'Take Stock Quantity from'} /> <p className="text-danger">*</p>
            </div>
            <div className="col-6">
              <SelectInput
                hasError={
                  props?.touched?.productStockMapping?.stockQuantity &&
                    props?.errors?.productStockMapping?.stockQuantity ? true : false
                }
                options={State?.formsLookup?.productStockMapping?.stockQuantity?.values!}
                selectedOptionValue={productStock.stockQuantity}
                onChange={(val: string) => setProductStock({ ...productStock, stockQuantity: val })}
                onBlur={(e: any) => {
                  onInputBlur({
                    ...touchedFields, productStockMapping: {
                      ...touchedFields.productStockMapping, stockQuantity: true
                    }
                  });
                  if (props.productStockMapping?.stockQuantity !== productStock.stockQuantity)
                    setProductStockMapping();
                }}
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-6 d-flex">
              <Label text={'Default Warehouse'} /> <p className="text-danger">*</p>
            </div>
            <div className="col-6">
              <SelectInput
                hasError={
                  props?.touched?.productStockMapping?.defaultWarehouse &&
                    props?.errors?.productStockMapping?.defaultWarehouse ? true : false
                }
                options={State?.formsLookup?.productStockMapping?.defaultWarehouse?.values!}
                selectedOptionValue={productStock.defaultWarehouse}
                onChange={(val: string) => setProductStock({ ...productStock, defaultWarehouse: val })}
                onBlur={(e: any) => {
                  onInputBlur({
                    ...touchedFields, productStockMapping: {
                      ...touchedFields.productStockMapping, defaultWarehouse: true
                    }
                  });
                  if (props.productStockMapping?.defaultWarehouse !== productStock.defaultWarehouse)
                    setProductStockMapping();
                }}
              />
            </div>
          </div>
        </React.Fragment>}
    </div>
  )
}

export default MYOBToNetoProductStockMapping