import * as React from "react";
import { useState } from 'react';
import { NetoRMAOrderViewModel } from "../models/SaleOrderViewModels";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";
import NetoImg from "../../../assets/maropost-logo.svg";
import * as util from '../../../shared/Utilities'
import "./NetoRMA.css";
import _ from "lodash";
import { ConvertNetoLineItemToGlobalLineItemInterface } from "../services/OrderService";

export interface INetoRMADetailProps {
  NetoRMA: Array<NetoRMAOrderViewModel>;
}

const NetoRmaDetial: React.FC<INetoRMADetailProps> = (props) => {
  const [netoRMAToggle, setNetoRMAToggle] = useState<boolean>(false);
  const [expandedRows, setExpandedRows] = useState<any[]>([]);

  const headerTemplate = (
    <img src={NetoImg} alt="Myob" style={{ width: "4rem" }} className="ml-4 mt-3" />
  );

  const dateBodyTemplateforNeto = (rowData: any) => {
    return (
      <React.Fragment>
        <div>
          {!_.isEmpty(rowData.DateApproved) ? <p>{util.userTimezone(rowData?.DateApproved)}</p> : ''}
        </div>
      </React.Fragment>
    );
  }

  const rowExpansionTemplate = (data: any) => {
    return (
      <div className="rma-subtable">
        <DataTable
          id="nestedNetoViewTable"
          value={ConvertNetoLineItemToGlobalLineItemInterface(
            data?.RmaLines?.RmaLine
          )}>

          <Column field="Quantity" header="Qty"></Column>
          <Column field="ItemName" header="Product Name"></Column>
          <Column field="SKU" header="SKU"></Column>
          <Column field="RefundSubtotal" header="Refund Subtotal"></Column>
          <Column field="Tax" header="Tax Code"></Column>
          <Column field="ResolutionOutcome" header="Outcome"></Column>
        </DataTable>
      </div>
    );
  };

  return (
    <Card header={headerTemplate}>
      <DataTable
        value={props.NetoRMA}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        dataKey="RmaID">

        <Column expander style={{ width: "3em" }} />
        <Column field="RmaID" header="ID"></Column>
        <Column field="RmaStatus" header="Status"></Column>
        <Column field="DateApproved" header="Date" body={dateBodyTemplateforNeto}></Column>
        <Column field="RefundTotal" header="Refund Total"></Column>
        <Column field="StaffUsername" header="Processed By"></Column>
      </DataTable>
    </Card>
  );
};

export default NetoRmaDetial;
