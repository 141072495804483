import React, { useEffect, useState, useContext, useRef } from "react";
import { AppContext } from "../../../store/AppContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import _ from "lodash";
import { CustomerGridModel } from "../models";
import {
  emptyMessageTemplate,
  headerGroup,
  lastSyncAtBodyTemplate,
  myobErrorMessageTemplate,
  myobStatusBodyTemplate,
  netoErrorMessageTemplate,
  netoStatusBodyTemplate,
  rowExpansionTemplate,
} from "../GridBodyTemplates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSlash } from "@fortawesome/free-solid-svg-icons";
interface ICustomerGridErrorComponentProps{
    customerData: any,
    loading: boolean
}

const CustomerGridErrorComponent: React.FC<ICustomerGridErrorComponentProps> = ({ customerData, loading }) => {
  let { State, Dispatch } = useContext(AppContext);
  const dt = useRef<DataTable>(null);

  const [customersData, setcustomersData] = useState<Array<CustomerGridModel>>(customerData ?? []);
  const [expandedRows, setExpandedRows] = useState<any[]>([]);

  useEffect(() => {
    setcustomersData(customerData);
  }, [customerData]);


  const reset = () => {
    dt.current?.reset();
  }

  const header = (
    <div className="d-flex justify-content-between">
        <h5 className="text-left">Failed Customers</h5>
        <div className="col-6 text-right">
            <button
                type="button"
                className="btn btn-outline-secondary ml-2"
                onClick={reset}
            >
                <div className="fa-layers ml-2">
                    <FontAwesomeIcon className="mr-2" icon={faFilter} />
                    <FontAwesomeIcon className="mr-2" icon={faSlash} />
                </div>
                Clear
            </button>
        </div>
    </div>
  );

  return (
    <div className="container-fluid">
      <DataTable
        ref={dt}
        dataKey="Number"
        header={header}
        headerColumnGroup={headerGroup}
        value={customersData}
        className="p-mt-6"
        autoLayout
        emptyMessage={emptyMessageTemplate}
        lazy={loading}
        loading={loading}
        paginator
        rows={10}
        sortField="lastSyncAtForFilter"
        sortOrder={-1}
        selectionMode="multiple"
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={(data: any) => rowExpansionTemplate([data])}
        totalRecords={customersData?.length}
        rowsPerPageOptions={[10, 20, 50]}
        paginatorClassName="p-jc-end"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      >
        <Column expander style={{ width: "3em" }} />
        <Column field="NetoCustomer.Username" />
        <Column field="MyobCustomer.DisplayID" />
        <Column field="NetoCustomer.ActiveText" body={netoStatusBodyTemplate} />
        <Column
          field="MyobCustomer.IsActiveText"
          body={myobStatusBodyTemplate}
        />
        <Column field="CompanyOrUser" />
        <Column
          field="NetoCustomer.syncErrorMessage"
          body={netoErrorMessageTemplate}
        />
        <Column
          field="MyobCustomer.syncErrorMessage"
          body={myobErrorMessageTemplate}
        />
        <Column
          filterField="lastSyncAt"
          field="lastSyncAtForFilter"
          body={lastSyncAtBodyTemplate}
        />
      </DataTable>
    </div>
  );
};

export default CustomerGridErrorComponent;