import * as React from 'react';
import { InputText } from 'primereact/inputtext';

export interface IInputProps {
  onChange: (value: string) => void,
  onBlur?: (value: string) => void,
  maxLength?: number,
  match?: string,
  value: string,
  hasError?: boolean,
  readOnly?: boolean,
  placeholder?: string,
  disabled?: boolean,
}

const Input : React.FC<IInputProps> = ({hasError, value, onChange, onBlur, readOnly, maxLength, placeholder}) => {
  return (
    <div className="p-field p-col-6 p-inputgroup">
      <InputText 
        className={`${hasError && 'border border-danger'}`}
        value={value}
        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => e.key === 'Enter' && e.preventDefault()}
        onChange={(e) => onChange(e.target.value.replace(/\s/g, ''))}
        onBlur={(e) => onBlur && onBlur(e.target.value ?? '')}
        readOnly={readOnly}
        maxLength={maxLength}
        placeholder={placeholder}
      />
    </div>
  )
}

export default Input