import * as React from 'react';
import { Card } from 'primereact/card';
import NetoRMA from './NetoRMA';
import MyobRMA from './MyobRMA';
import { RMAListModel } from '../models/SaleOrderViewModels';
import { Button } from 'primereact/button';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppContext } from '../../../store/AppContext';
import { reExportOrderRMA } from '../services/OrderService';
import netoToMyob from "../../../assets/netoToMyob.png"
export interface IRMADetailProps {
    rmaList: Array<RMAListModel>
    connectorId?: string,
    netoOrderId?: string,
}

const RMA: React.FC<IRMADetailProps> = (props) => {
    const [reprocessLoading, setReprocessLoading] = React.useState<boolean>(false);
    let { State, Dispatch } = React.useContext(AppContext);

    const reExportRMA = async () => {
        setReprocessLoading(true);
        const requestModel: any = {
            userId: /*State?.currentUser?.isAdminUser && localStorage.SelectedIdByAdmin ? localStorage.SelectedIdByAdmin : */State.currentUser.currentUserId,
            connectorId: props.connectorId,
            ordersIds: [props.netoOrderId],
            isReExportRma: true,
        };
        const orderReprocessRequestResponse = await reExportOrderRMA(requestModel);
        if (orderReprocessRequestResponse.isSucceed) {
          Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: true, message: "Successful" } });
        }
        else {
          Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: false, message: orderReprocessRequestResponse.errorMessage } });
        }
        setReprocessLoading(false);
      }


    const headerComponent = (
        <div className="p-3 d-flex justify-content-between" style={{ backgroundColor: "#d3d3d3b8" }}>
            <h4 className="mt-2">
                RMA Details
            <img src={netoToMyob} alt="netoToMyob" className="px-2" style={{width:"12rem"}} />
            </h4>
            <Button className="p-button-secondary p-button-sm p-button-raised bg-dark"    disabled={reprocessLoading}
            onClick={reExportRMA} type="button">
                <FontAwesomeIcon
                    spin={reprocessLoading}
                    className="mr-2"
                    icon={faSync}
                /> ReExport RMAs</Button>
        </div>
    );
    
    return (
        <div>
            <Card header={headerComponent} className="text-left">
                <div className="p-1">
                    <NetoRMA
                        NetoRMA={props.rmaList.map((r: RMAListModel) => r.incomingOrder)}
                    />
                    <MyobRMA
                        MyobRMA={props.rmaList.map((r: RMAListModel) => r.outgoingOrder)}
                    />
                </div>
            </Card>
            <br />
        </div>
    );
}

export default RMA