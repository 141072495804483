import * as React from "react";
import { useState } from 'react';
import ConfigureWebhookInstructionOne from '../../assets/ConfigureWebhookInNeto(1).png';
import ConfigureWebhookInstructionTwo from '../../assets/ConfigureWebhookInNeto(2).png';
import ConfigureWebhookInstructionThree from '../../assets/ConfigureWebhookInNeto(3).png';
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Galleria } from 'primereact/galleria';

export interface IInstructionComponentProps {
  webhookUrl: string,
  isOpen: boolean,
  setModalOpen: (value: boolean) => void
}

const InstructionsComponent: React.FC<IInstructionComponentProps> = (props) => {
  const [activeIndex, setActiveIndex] = useState<any>();
  const images = [ConfigureWebhookInstructionTwo, ConfigureWebhookInstructionOne, ConfigureWebhookInstructionThree];
  let galleria : Galleria | null = null;
  let webhookURL: any = {};

  const responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];

  const itemTemplate = (item: any) => {
    return <img src={item} alt={''} style={{ width: '170vh', display: 'block' }} />;
  }

  const thumbnailTemplate = (item: any) => {
    return <img src={item} alt={''} width={150} height={150} style={{ display: 'block' }} />;
  }

  const copyToClipboard = (e: any) => {
    e.preventDefault();
    webhookURL.select();
    document.execCommand("copy");
  };

  const renderFooter = () => {
    return (
      <Button label="Close" style={{ position: 'static' }} className="p-button-raised p-button-danger" onClick={() => props.setModalOpen(!props.isOpen)} />
    );
  }

  return (
    <Dialog header="Maropost Webhook Instructions" visible={props.isOpen} onHide={() => { }} closable={false} draggable={false} breakpoints={{ '960px': '75vw' }} style={{ width: '40vw' }} footer={renderFooter()}>
      <p>
        Follow these Instructions to Configure Your Webhook in MYOB AccountRight.
      </p>
      <ul>
        <li>Go to your Neto Store</li>
        <li>Select <b>{'Settings & Tools'}</b> from the left side of navigation</li>
        <li>Select <b>{'All Settings & Tools'}</b></li>
        <li>Now under the Development Tools section, select <b>{'API Settings'}</b></li>
        <li>Check the option <b>{'Enable Neto API Webhook'}</b></li>
        <li>Paste the following URL in <b>{'Neto API Webhook URL'}</b></li>
        <li>Click on Save Changes</li>
      </ul>
      <div className="input-group mb-3">
        <input type="text" className="form-control copyBtn" ref={(ref) => (webhookURL = ref)} value={props.webhookUrl} readOnly={true} />
        <div className="input-group-append">
          <button className="btn btn-info copyBtn" onClick={(e) => copyToClipboard(e)} >
            <FontAwesomeIcon icon={faCopy} />
          </button>
        </div>
      </div>

      <Galleria ref={(el) => {galleria = el}} value={images} responsiveOptions={responsiveOptions} numVisible={7} style={{ maxWidth: '850px' }}
        activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}
        circular fullScreen showItemNavigators showThumbnails item={itemTemplate} thumbnail={thumbnailTemplate} />

      <div className="d-flex justify-content-center">
        {
          images && images.map((image, index) => {
            let imgEl = <img src={image} alt={`instruction${index}`} width={150} height={150} style={{ cursor: 'pointer' }} onClick={() => {
              setActiveIndex(index); galleria?.show(); } 
            } />
            return (
              <div className="p-col-3 ml-2" key={index}>
                {imgEl}
              </div>
            )
          })
        }
      </div>

      {/* <div className="row">
        <div className="" >
          <img width={150} height={150} src={ConfigureWebhookInstructionTwo} className="img-thumbnail rounded zoom" />
        </div>

        <div className="" >
          <img width={150} height={150} src={ConfigureWebhookInstructionOne} className="img-thumbnail rounded zoom" />
        </div>

        <div className="" >
          <img width={150} height={150} src={ConfigureWebhookInstructionThree} className="img-thumbnail rounded zoom" />
        </div>
      </div> */}
    </Dialog>
  );
};

export default InstructionsComponent;