import { useEffect, useContext, useState } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { AppContext } from "./store/AppContext";
import { PrivateRoute } from "./features/authentication/PrivateRoute";
import { PublicRoute } from "./features/authentication/PublicRoute";
import { AdminRoute } from "./features/authentication/AdminRoute";
import { loggedIn$, onIdTokenChange$ } from './shared/config/firebaseConfig';
import Navigation from "./shared/components/Navigation";
import SaleOrderGridPage from "./pages/SaleOrderGridPage";
import RMAGridPage from "./pages/RMAGridPage";
import PaymentGridPage from "./pages/PaymentGridPage";
import MYOBAuthResult from "./pages/MYOBAuthResult";
import Toast from "./components/Toast";
import UserProfile from "./pages/UserProfile";
import UsersGridPage from "./pages/UsersGridPage";
import AddOnUserPage from "./pages/AddOnUserPage";
import setUserDataInStore from "./features/authentication/services/authenticationServices";
import Spinner from "./shared/components/Spinner";
import SignUp from "./features/membership/SignUp/SignUpPage";
import SignIn from "./features/membership/SignIn/SignInPage";
import SalesOrderDataPreviewPage from "./pages/OrderDetailsPage";
import RMADetailsPage from "./pages/RMADetailsPage";
import PaymentDetailsPage from "./pages/PaymentDetailsPage";
import CustomerGridPage from "./pages/CustomerGridPage";
import ProductGridPage from "./pages/ProductGridPage";
import NetoToMyobMappingsPage from "./pages/NetoToMyobMappingsPage";
import ConnectorsPage from "./pages/ConnectorsPage";
import UserInfoPage from "./pages/UserInfoPage";


import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primeicons/primeicons.css";
import "./App.css";



const App = () => {
  let { State, Dispatch } = useContext(AppContext);
  const [userData, setUserData] = useState<any>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loggedIn$().subscribe(async (user) => {
      await setUserDataInStore(user, setUserData, Dispatch);
      setLoading(false)
    })
    if (!localStorage.idToken) {
      setLoading(false)
    }
  }, []);

  useEffect(() => {
    onIdTokenChange$().subscribe(
      // async (idToken) => {
      // if (idToken) {
      //   console.log("🚀 ~ file: App.tsx ~ line 48 ~ onIdTokenChange$ ~ idToken", idToken)
      //   let token:string=idToken
    )
  }, []);



  return (
    <div className="App">
      <Router>
        {window.location.pathname !== "/myobauthresult" && (
          <Navigation />
        )}
        {!loading &&
          <Switch>
            {/* Myob Route Popup Window*/}
            <Route path="/myobauthresult" component={MYOBAuthResult} />

            {/* PublicRoutes */}
            <PublicRoute path="/addOnUser" component={AddOnUserPage} />
            <PublicRoute exact path={"/signup"} component={SignUp} />
            <PublicRoute exact path={"/signin"} component={SignIn} />

            {/* PrivateRoutes */}
            <PrivateRoute exact path="/connectors" component={ConnectorsPage} />
            <PrivateRoute path="/saleOrderDataPreview/:connectorId/:orderId" component={SalesOrderDataPreviewPage} />
            <PrivateRoute path="/RMADataPreview/:connectorId/:orderId/:netoRMAId" component={RMADetailsPage} />
            <PrivateRoute path="/PaymentDataPreview/:connectorId/:orderId/:netoPaymentId" component={PaymentDetailsPage} />
            <PrivateRoute path="/neto_to_myob_mappings/:storeName/:id" component={NetoToMyobMappingsPage} />
            <PrivateRoute exact path="/salesOrderGridNew" component={SaleOrderGridPage} />
            {/* <PrivateRoute exact path="/salesOrderGrid" component={SaleOrderGridPageApproach1} /> */}
            <PrivateRoute exact path="/customersGridNew" component={CustomerGridPage} />
            {/* <PrivateRoute exact path="/customersGrid" component={CustomerGridPageApproach1} /> */}
            <PrivateRoute exact path="/productsGridNew" component={ProductGridPage} />
            {/* <PrivateRoute exact path="/productsGrid" component={ProductGridPageApproach1} /> */}
            <PrivateRoute exact path="/profile" component={UserProfile} />
            <PrivateRoute exact path="/RMAGrid" component={RMAGridPage} />
            <PrivateRoute exact path="/PaymentGrid" component={PaymentGridPage} />

            {/* <PrivateRoute exact path="/subscriptions" component={SubscriptionsPage} /> */}

            {/* AdminRoute */}
            <AdminRoute exact path="/userprofiles" component={UsersGridPage} />
            <AdminRoute exact path="/usersInfo" component={UserInfoPage} />
            
            {/* Redirect */}
            <Redirect path="*" to={"/connectors"} />
          </Switch>
        }
      </Router>
      {loading && <Spinner />}
      <Toast status={State.requestStatus} />
    </div>
  );



};
export default App;
