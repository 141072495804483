import * as React from 'react';
import ProductGridContainer from '../features/Product';
import './ProductGridPage.css';

const ProductGridPage: React.FC = () => {
    return (
        <ProductGridContainer />
    );
}

export default ProductGridPage
