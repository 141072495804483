import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { Card } from "primereact/card";
import { confirmPopup } from "primereact/confirmpopup";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { AppContext } from "../store/AppContext";
import Spinner from "../shared/components/Spinner";
import InstructionsComponent from "./components/InstructionsComponent";
import NetoConfigurationComponent from "./components/NetoConfigurationComponent";
import AddConnector from "./components/AddConnector";
import { actionButtonsTemplate, deleteButtonTemplate, detailsTemplate, headerTemplate, mappingButtonTemplate } from "./GridBodyTemplates";
import { connectorsSnapshot, createUserConnector, disconnectConnectorService, getAllConnectors, getUserAllConnectors, populateGridService, savenetoConfigurationService } from "./services/ContainerService";
import { Subscription } from "rxjs";
// import { useParams } from "react-router";

const ConnectorsContainer: React.FC = () => {
  let { State, Dispatch } = useContext(AppContext);

  const [connectors, setConnectors] = useState<any>();
  const [userConnectors, setUserConnectors] = useState<any>();
  const [backDrop, setBackDrop] = useState<boolean>(false);
  const [currentDocId, setCurrentDocId] = useState<string>("");
  const [webhookURL, setWebhookURL] = useState<string>("");
  const [netoConnection, setNetoConnection] = useState({
    WebhookApiKey: "",
    ApiKey: "",
    StoreDomain: "",
    UserName: "",
    WebhookUri: "",
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [netoModalOpen, setNetoModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isNetoConfigSaving, setIsNetoConfigSaving] = useState<boolean>(false);
  const [netoConfigError, setNetoConfigError] = useState<string | null>(null);
  const [addAConnector, setAddAConnector] = useState<boolean>(false);
  const [netoOAuthConnectModal, setNetoOAuthConnectModal] = useState<boolean>(false);
  const [netoOAuthFrame, setNetoOAuthFrame] = useState<boolean>(false);
  const [deAuthNeto, setDeAuthNeto] = useState({ isOpen: false, url: '', error: '', isLoading: false });
  const [addConnectorErrorMessage, setAddConnectorErrorMessage] = useState<string>("");
  const [disableLoading, setDisableLoading] = useState(false);
  // const { id } = useParams<any>();
  let openWindow: Window | null = null;

  window.addEventListener(
    "message",
    (event) => {
      if (event.origin !== window.origin) return;

      event.data === "true" && setBackDrop(false);
      
    },
    false
  );

  useEffect(() => {
    Dispatch({ Type: "ENABLE_APP_LOADING", Payload: true });

    // if(id){
    //   Dispatch({ Type: 'IS_USER_LOGGED_IN', Payload: {...State.currentUser, currentUserId: id}});
    // }

    const getConnectorConfig = async () => {
      let list = await getUserAllConnectors(
        /*State?.currentUser?.isAdminUser && localStorage.SelectedIdByAdmin ? localStorage.SelectedIdByAdmin : */
        State.currentUser.currentUserId
      );
      setUserConnectors(list?.docs);
      list?.docs?.map((doc) => {
        setNetoConnection({
          WebhookApiKey: doc.data()?.neto?.credentials?.webhookApiKey ?? "",
          ApiKey: doc.data()?.neto?.credentials?.storeApiKey ?? "",
          StoreDomain: doc.data()?.neto?.credentials?.storeDomain ?? "",
          UserName: doc.data()?.neto?.credentials?.userName ?? "",
          WebhookUri: doc.data()?.neto?.credentials?.webhookUri ?? "",
        });
      });
      Dispatch({ Type: "DISABLE_APP_LOADING", Payload: false });
    };
    getConnectorConfig();
  }, []);

  useEffect(() => {
      let connectorsSubscription: Subscription = connectorsSnapshot(/*State?.currentUser?.isAdminUser && localStorage.SelectedIdByAdmin ? localStorage.SelectedIdByAdmin : */
        State.currentUser.currentUserId)
      .subscribe((e) => {  setUserConnectors(e) });
  }, []);

  useEffect(() => {
    if (userConnectors) {
      userConnectors.map((user: any) => {
        if (user.id === currentDocId && State?.currentUser?.isAddonUser && user.data().neto?.isConnected) {
          setNetoOAuthConnectModal(false);
          setNetoOAuthFrame(true);
        }
        else {
          if (deAuthNeto.isOpen && user.id === currentDocId && State?.currentUser?.isAddonUser && user.data().neto?.isConnected === false) {
            setDeAuthNeto({ ...deAuthNeto, isLoading: false, url: '', isOpen: false });
          }
        }
      })
    }
  }, [userConnectors])

  useEffect(() => {
    Dispatch({ Type: "ENABLE_APP_LOADING", Payload: true });
    const getConnectors = async () => {
      let list = await getAllConnectors();
      setConnectors(
        list
          ? list?.docs?.map((doc) => {
            return {
              id: doc.id,
              source: doc.data()?.source,
              target: doc.data()?.target,
              label: doc.data()?.label,
            };
          })
          : []
      );
      Dispatch({ Type: "DISABLE_APP_LOADING", Payload: false });
    };
    getConnectors();
  }, [/*State?.currentUser?.isAdminUser && localStorage.SelectedIdByAdmin ? localStorage.SelectedIdByAdmin : */State.currentUser.currentUserId]);

  const openAuthPopup = (docId: string) => {
    setCurrentDocId(docId);
    setBackDrop(true);
    localStorage.setItem("currentUserId", State.currentUser.currentUserId);
    localStorage.setItem("currentDocId", docId);
    var left = window.screen.width / 2 - 600 / 2;
    var top = window.screen.height / 2 - 600 / 2;
    openWindow = window.open(
      `https://secure.myob.com/oauth2/account/authorize?client_id=jwb4bfgcsbf29pevrk7dz6kp&redirect_uri=${encodeURI(
        `${window.location.origin}/myobauthresult`
      )}&response_type=code&scope=CompanyFile&state=${/*State?.currentUser?.isAdminUser && localStorage.SelectedIdByAdmin ? localStorage.SelectedIdByAdmin : */State.currentUser.currentUserId
      }`,
      "_blank",
      `width=600,height=600,scrollbars=no,resizable=no,top=${top},left=${left}`
    );
    openWindow?.focus();
    document.onmousedown = focusPopup;
    document.onkeyup = focusPopup;
    document.onmousemove = focusPopup;
    openWindow?.addEventListener("beforeunload", (event) => {
      setBackDrop(false);
    });
  };

  var timer = setInterval(function () {
    if (openWindow && openWindow?.closed) {
      clearInterval(timer);
      setBackDrop(false);
    }
  }, 1000);

  const focusPopup = () => {
    if (openWindow && !openWindow.closed) {
      openWindow.focus();
    }
  };

  const saveNetoConfiguration = async () => {
    await savenetoConfigurationService(setIsNetoConfigSaving, setDisableLoading, netoConnection, userConnectors, setNetoConfigError, Dispatch, State, currentDocId, setNetoModalOpen, netoModalOpen);
  };

  const addConnector = async (value: any) => {
    setAddAConnector(true);
    let result = await createUserConnector(
      /*State?.currentUser?.isAdminUser && localStorage.SelectedIdByAdmin ? localStorage.SelectedIdByAdmin : */State.currentUser.currentUserId,
      value
    );

    if (result?.isSucceed) {
      setAddAConnector(false);
      setIsAddModalOpen(!isAddModalOpen);
      Dispatch({
        Type: "SET_REQUEST_STATUS",
        Payload: { isSucceed: true, message: "Connector added Successfully" },
      });
    } else {
      setAddConnectorErrorMessage(result?.message);
      setAddAConnector(false);
      Dispatch({
        Type: "SET_REQUEST_STATUS",
        Payload: { isSucceed: false, message: result?.message },
      });
    }
  };

  const toggleNetoConfigModal = (neto: any, source: string, docId: string) => {
    if (State?.currentUser?.isAddonUser) {
      setNetoOAuthConnectModal(true);
      // setCurrentlySelectedSource(source);
      setCurrentDocId(docId);
    }
    else {
      setNetoConnection({
        WebhookApiKey: neto?.credentials?.webhookApiKey ?? "",
        ApiKey: neto?.credentials?.storeApiKey ?? "",
        StoreDomain: neto?.credentials?.storeDomain ?? "",
        UserName: neto?.credentials?.userName ?? "",
        WebhookUri: neto?.credentials?.webhookUri ?? "",
      });
      // setCurrentlySelectedSource(source);
      setNetoModalOpen(!netoModalOpen);
      setCurrentDocId(docId);
    }
  };

  const confirmDisconnectDialog = (event: any, source: string, docId: string, sourceCompanyName: string,) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to Disconnect?",
      icon: "pi pi-exclamation-triangle",
      accept: () => confirmDeleteMapping(source, docId, sourceCompanyName),
    });
    
  };

  const confirmDeleteMapping = (source: string, docId: string, sourceCompanyName: string) => {
    confirmDialog({
      message: "Do You also want to remove existing Mappings?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => disconnectConnectorService(State.currentUser.currentUserId, source, docId, true, sourceCompanyName, deAuthNeto, setDeAuthNeto, Dispatch),
      reject: () => disconnectConnectorService(State.currentUser.currentUserId, source, docId, false, sourceCompanyName, deAuthNeto, setDeAuthNeto, Dispatch),
    });
  };

  const populateGridModel = () => (populateGridService(userConnectors));
  const details = (rowData: any) => (detailsTemplate(rowData, State, modalOpen, setWebhookURL, setModalOpen));
  const actionButtons = (rowData: any) => (actionButtonsTemplate(rowData, State, confirmDisconnectDialog, openAuthPopup, toggleNetoConfigModal));
  const mappingButton = (rowData: any) => (mappingButtonTemplate(rowData, State));
  const deleteButton = (rowData: any) => (deleteButtonTemplate(rowData, (!State?.currentUser?.isAddonUser && !State?.currentUser?.isAddonUser === true), State.currentUser.currentUserId));
  const header = () => (headerTemplate(State, isAddModalOpen, setIsAddModalOpen));

  return (
    <div className="container-fluid">
      {!backDrop ? (
        <>
          <Card header={header} className="shadow text-left mt-5">
            <DataTable autoLayout className="p-datatable-sm" value={populateGridModel()} >
              <Column headerStyle={{ width: "0.225rem" }} className="text-center" field="numberOfRows" header="#"></Column>
              <Column headerStyle={{ width: "0.225rem" }} className="text-center" field="connector.name" header="Type"></Column>
              <Column className="text-center" field="connector.label" header="Name"></Column>
              <Column className="text-left" body={details} header="Details"></Column>
              <Column className="text-center" headerStyle={{ whiteSpace: "nowrap", textAlign: "center" }} body={actionButtons} header="Connection"              ></Column>
              <Column className="text-center" body={mappingButton} header="Mapping"   ></Column>
              <Column className="text-center" body={deleteButton} header=""></Column>
            </DataTable>
          </Card>
        </>
      ) : (
        <Spinner />
      )}

      {State.isLoading ? (<Spinner />) : (
        <NetoConfigurationComponent
          isOpen={netoModalOpen}
          onChange={(val: boolean) => { setNetoModalOpen(val); setNetoConfigError(""); }}
          netoConnection={netoConnection}
          setNetoConnection={setNetoConnection}
          saveNetoConfiguration={saveNetoConfiguration}
          isLoading={isNetoConfigSaving}
          alertMsg={netoConfigError}
          disabledLoading={disableLoading}
        />
      )}
      <InstructionsComponent webhookUrl={webhookURL} isOpen={modalOpen} setModalOpen={setModalOpen} />
      <AddConnector
        connectors={connectors}
        isOpen={isAddModalOpen}
        onToggle={setIsAddModalOpen}
        onAdd={addConnector}
        addConnectorLoading={addAConnector}
        setAddConnectorLoading={setAddAConnector}
        errorMessage={addConnectorErrorMessage}
      />
    </div>
  );
};

export default ConnectorsContainer;