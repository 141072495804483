import React, { FC, useEffect, useContext, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { AppContext } from "../../store/AppContext";

interface IProps {
    exact?: boolean;
    path: string;
    component: React.ComponentType<any>;
}

export const PublicRoute: FC<IProps> = ({ component: Component, ...rest }) => {
    let { State } = useContext(AppContext);
    const [isAuth, setIsAuth] = useState(State.currentUser.loggedIn);

    useEffect(() => {
        setIsAuth(State.currentUser.loggedIn)
    }, [State]);

    return (
        <>
            <Route {...rest} render={(props) => {
                return !isAuth ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={"/connectors"} />
                );
            }}
            />
        </>
    );
};

