import * as React from 'react';
import { Card } from 'reactstrap';
import { Tags } from '../models/SaleOrderViewModels';

export interface ITagsDetailProps {
    tags: Tags
}

const OrderTags : React.FC<ITagsDetailProps> = ({ tags }) => {
    const headerComponent = (
        <div className="p-3"  style={{ backgroundColor: "#d3d3d3b8" }}>
            <h4>Tags</h4>
        </div>
    );

    return (
        <Card header={headerComponent} className="text-left mt-3 mb-3">
            <div className="p-4">
                <p><b>Storename: </b> {tags?.StoreName} </p>
            </div>
        </Card>
    );
}

export default OrderTags
