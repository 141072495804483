import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faSync } from '@fortawesome/free-solid-svg-icons';
import _ from "lodash";
import Alert from "../../../../components/Alert";
import Label from '../../../../components/Label';
import Boolean from '../../../../components/Boolean';
import SelectInput from '../../../../components/SelectInput';
import { AppContext } from '../../../../store/AppContext';
import { synchronizeProductsNetoToMyob } from '../services/MappingService';
import * as util from '../../../../shared/Utilities';
import { ItemMappingsModel } from "../../../../shared/models/mappings/UserMappingModels";
import NetoProductToMYOBItemMappingProductOptions from "./Item/NetoProductToMYOBItemMappingProductOptions";

export interface INetoProductToAddMYOBItemProps {
  productMapping: ItemMappingsModel,
  setProductMapping: (value: ItemMappingsModel) => void,
  isMappingsEmpty: boolean,
  errors: any,
  touched: any,
  submitCount: any
  onInputBlurred: (obj: any) => void
}

const NetoProductToMYOBItemMapping: React.FC<INetoProductToAddMYOBItemProps> = (props) => {
  let { State, Dispatch } = useContext(AppContext);
  const { id } = useParams<any>();
  const [itemMappings, setItemMappings] = useState<ItemMappingsModel>({} as ItemMappingsModel);
  const [ProductOptionsToggle, setProductOptionsToggle] = useState<boolean>(false);
  const [InventorizedItemsToggle, setInventorizedItemsToggle] = useState<boolean>(false);
  const [isTab1Active, setIsTab1Active] = useState<number | undefined>(undefined);
  const [isTab2Active, setIsTab2Active] = useState<number | undefined>(undefined);

  const headerTemplate = (headingText: string, hasError: boolean, smallText?: string) => (<React.Fragment><div className="d-flex"><h6>{headingText} {smallText && <small>({smallText})</small>}</h6>
    {hasError && <small className="ml-3 mt-1 text-danger">(There are some validation errors)</small>}</div></React.Fragment>);

  const header = {
    sourceHeader: 'Maropost',
    targetHeader: 'MYOB AccountRight',
    header1: headerTemplate("Product Options", ProductOptionsToggle),
    header2: headerTemplate("Inventorized Items", InventorizedItemsToggle),
  };

  const [syncNetoProductsToMyobItemLoading, setSyncNetoProductsToMyobItemLoading] = useState<boolean>(false);

  let [touchedFields, setTouchedFields] = useState(props.touched);

  useEffect(() => {
    setTouchedFields(props.touched);
  }, [props.touched]);

  useEffect(() => {
    setItemMappings(props.productMapping ?? {} as ItemMappingsModel);
  }, [props.productMapping]);

  useEffect(() => {
    //#region Product Mapping 
    if (props.submitCount > 0 && ((props.touched?.productMapping?.useIncomeAccount && props.errors?.productMapping?.useIncomeAccount)
      || (props.touched?.productMapping?.useExpenseAccount && props.errors?.productMapping?.useExpenseAccount)
      || (props.touched?.productMapping?.useSellingTax && props.errors?.productMapping?.useSellingTax)
      || (props.touched?.productMapping?.useBuyingTax && props.errors?.productMapping?.useBuyingTax)
      || (props.touched?.productMapping?.useInventorizedItems && props.errors?.productMapping?.useInventorizedItems)
    )) {
      setIsTab1Active(0);
      setProductOptionsToggle(true);
    }
    else{
      setProductOptionsToggle(false);
    }
    //#endregion
    //#region Inventorized Items
    if (itemMappings.useInventorizedItems === "InventorizedItems")
      setIsTab2Active(0);
    
    if(props.submitCount > 0 && ((props.touched?.productMapping?.useIncomeAccountForInventorizedItems && props.errors?.productMapping?.useIncomeAccountForInventorizedItems)
      || (props.touched?.productMapping?.useCOGSAccount && props.errors?.productMapping?.useCOGSAccount)
      || (props.touched?.productMapping?.useInventoryAssestAccount && props.errors?.productMapping?.useInventoryAssestAccount)
    )) {
      setIsTab2Active(0);
      setInventorizedItemsToggle(true);
    }
    else{
      setInventorizedItemsToggle(false);
    }
    //#endregion
  }, [props.touched?.productMapping, props.errors?.productMapping]);

  const onInputBlur = (touched: any) => {
    props.onInputBlurred(touched);
  }

  const setItemMapping = () => {
    props.setProductMapping(itemMappings);
  }

  const onSubComponentsFocusChanged = (value: ItemMappingsModel) => {
    props.setProductMapping(value);
  }

  const syncNetoToMyobProducts = async () => {
    setSyncNetoProductsToMyobItemLoading(true);
    let result = await synchronizeProductsNetoToMyob(State?.currentUser?.currentUserId, id);
    if (result?.isSucceed) {
      Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: true, message: result?.message } });
      setSyncNetoProductsToMyobItemLoading(false);
    }
    else {
      Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: false, message: result?.message } });
      setSyncNetoProductsToMyobItemLoading(false);
    }
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col d-flex justify-content-between">
          <Boolean
            hasError={
              props.errors?.productMapping?.isChecked &&
                props.errors?.productMapping?.isChecked ? true : false
            }
            text={'When a Product is created in Maropost, Create new Items in MYOB AccountRight'}
            value={itemMappings.isChecked}
            onChange={(val: boolean) => setItemMappings({ ...itemMappings, isChecked: val })}
            onBlur={() => {
              onInputBlur({
                ...touchedFields, productMapping: {
                  ...touchedFields.productMapping, isChecked: true
                }
              });
              if (props.productMapping?.isChecked != itemMappings.isChecked)
                setItemMapping();
            }}
          />
          <button disabled={props.isMappingsEmpty ? true : (!itemMappings.isChecked || syncNetoProductsToMyobItemLoading)} onClick={syncNetoToMyobProducts} type="button" className="btn btn-dark btn-sm">Sync
            <FontAwesomeIcon spin={syncNetoProductsToMyobItemLoading} className="ml-1" icon={faSync} />
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
        </div>
        <div className="col-6 text-right">
          <Link to={{ pathname: "/productsGrid" }} target="_blank">
            {!_.isEmpty(itemMappings.syncErrorMessage) ?
              <small className="badge badge-danger" data-toggle="tooltip" data-placement="top" title={itemMappings.syncErrorMessage}> <i className="pi pi-exclamation-triangle" style={{
                'fontSize': '1em'
              }}></i> Sync Error </small>
              : ''}
            {itemMappings.syncErrorMessage ? <br /> : ''}
          </Link>
          {!_.isEmpty(itemMappings.lastRunAt) ?
            <small className="text-muted"> <i className="pi pi-check-circle" style={{ 'fontSize': '1em' }}></i> {itemMappings.message},{itemMappings.lastRunAt && util.userTimezone(itemMappings.lastRunAt)}  </small> :
            <small className="text-muted"> <i className="pi pi-clock" style={{ 'fontSize': '1em' }}></i> No Synchronized Data</small>
          }
        </div>
      </div>
      <br />
      <Alert heading={"NOTE"} text={'By activating this feature you allow our system to mass create all products from the Maropost to your MYOB AccountRight if we are unable to match to an existing item. Learn More about product matching.'} />

      <div className="row">
        <div className="col-6 d-flex">
          <Label text={'Maropost to Myob Product'} /> <p className="text-danger">*</p>
        </div>
        <div className="col-6">
          <SelectInput
            hasError={props?.touched?.productMapping?.netoToMyobProduct &&
              props?.errors?.productMapping?.netoToMyobProduct ? true : false}
            options={State.formsLookup?.genericSchedulerFrequency?.values!}
            selectedOptionValue={itemMappings.netoToMyobProduct}
            onChange={(val: string) => setItemMappings({ ...itemMappings, netoToMyobProduct: val })}
            onBlur={(e: any) => {
              onInputBlur({
                ...touchedFields, productMapping: {
                  ...touchedFields.productMapping, netoToMyobProduct: true
                }
              });
              if (props.productMapping?.netoToMyobProduct !== itemMappings.netoToMyobProduct)
                setItemMapping();
            }}
          />
        </div>
      </div>
      <br />
      <Accordion activeIndex={isTab1Active}>
        <AccordionTab header={header.header1}>
          <React.Fragment>
            <NetoProductToMYOBItemMappingProductOptions 
              touched={props.touched}
              errors={props.errors}
              itemMappings={itemMappings}
              onFocusChanged={onSubComponentsFocusChanged}
              onInputBlur={onInputBlur}
            />
            <br />
            {itemMappings.useInventorizedItems === "InventorizedItems" &&
              <Accordion activeIndex={isTab2Active}>
                <AccordionTab header={header.header2}>
                  <React.Fragment>
                    <div className="row">
                      <div className="col-6 d-flex">
                        <Label text={'Use Income Account for Inventorized Items'} /> <p className="text-danger">*</p>
                        <span className="pl-2"> <FontAwesomeIcon icon={faQuestionCircle} /> </span>
                      </div>
                      <div className="col-6">
                        <SelectInput
                          hasError={
                            props?.touched?.productMapping?.useIncomeAccountForInventorizedItems &&
                              props?.errors?.productMapping?.useIncomeAccountForInventorizedItems ? true : false
                          }
                          options={State.formsLookup?.itemMapping?.incomeAccountForInventorizedItems.values!}
                          selectedOptionValue={itemMappings.useIncomeAccountForInventorizedItems}
                          onChange={(val: string) => setItemMappings({ ...itemMappings, useIncomeAccountForInventorizedItems: val })}
                          onBlur={() => {
                            onInputBlur({
                              ...touchedFields, productMapping: {
                                ...touchedFields.productMapping, useIncomeAccountForInventorizedItems: true
                              }
                            });
                            if (props.productMapping.useIncomeAccountForInventorizedItems !== itemMappings.useIncomeAccountForInventorizedItems)
                              setItemMapping();
                          }}
                        />
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-6 d-flex">
                        <Label text={'Use COGS Account for Inventorized Items'} /> <p className="text-danger">*</p>
                        <span className="pl-2"> <FontAwesomeIcon icon={faQuestionCircle} /> </span>
                      </div>
                      <div className="col-6">
                        <SelectInput
                          hasError={
                            props?.touched?.productMapping?.useCOGSAccount &&
                              props?.errors?.productMapping?.useCOGSAccount ? true : false
                          }
                          options={State.formsLookup?.itemMapping?.COGSAccount.values!}
                          selectedOptionValue={itemMappings.useCOGSAccount}
                          onChange={(val: string) => setItemMappings({ ...itemMappings, useCOGSAccount: val })}
                          onBlur={() => {
                            onInputBlur({
                              ...touchedFields, productMapping: {
                                ...touchedFields.productMapping, useCOGSAccount: true
                              }
                            });
                            if (props.productMapping.useCOGSAccount !== itemMappings.useCOGSAccount)
                              setItemMapping();
                          }}
                        />
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-6 d-flex">
                        <Label text={'Use Inventory Asset Account for Inventorized Items'} /> <p className="text-danger">*</p>
                        <span className="pl-2"> <FontAwesomeIcon icon={faQuestionCircle} /> </span>
                      </div>
                      <div className="col-6">
                        <SelectInput
                          hasError={
                            props?.touched?.productMapping?.useInventoryAssestAccount &&
                              props?.errors?.productMapping?.useInventoryAssestAccount ? true : false
                          }
                          options={State.formsLookup?.itemMapping?.inventoryAssestAccount.values!}
                          selectedOptionValue={itemMappings.useInventoryAssestAccount}
                          onChange={(val: string) => setItemMappings({ ...itemMappings, useInventoryAssestAccount: val })}
                          onBlur={() => {
                            onInputBlur({
                              ...touchedFields, productMapping: {
                                ...touchedFields.productMapping, useInventoryAssestAccount: true
                              }
                            });
                            if (props.productMapping.useInventoryAssestAccount !== itemMappings.useInventoryAssestAccount)
                              setItemMapping();
                          }}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                </AccordionTab>
              </Accordion>
            }
          </React.Fragment>
        </AccordionTab>
      </Accordion>
    </div>
  )
}

export default NetoProductToMYOBItemMapping