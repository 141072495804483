import _ from "lodash";
import firebase from "../shared/config/firebaseConfig";
import { QueryDocumentRecordsModel } from "./DataServiceModels";
import { DATABASE_COLLECTIONS, API_SERVER_URL, CLOUD_FUNCTIONS } from "../shared/config/constants";
import { writeException } from "../shared/services/LoggingService";
const { USER_DETAILS, CONNECTORS } = DATABASE_COLLECTIONS;
const { LOGGING } = CLOUD_FUNCTIONS;
const dbRef = firebase.firestore();

export const getAllUserConnectors = (
  currentUserId: string
): Promise<QueryDocumentRecordsModel> => {
  return new Promise(async (reslove, reject) => {
    try {
      const connectors = await dbRef
        .collection(`${USER_DETAILS}`)
        .doc(currentUserId)
        .collection(`${CONNECTORS}`)
        .get();
      reslove({ isSucceed: true, result: connectors.docs });
    } catch (e: any) {
      reslove({ isSucceed: false, errorMessage: e.Message });
      await writeException(`ErrorMessage: ${e.message}, UserID: ${currentUserId}`);

    }
  });
};

export const getIdTokenFromLocalStorage = async (): Promise<string> => {
  const idToken = localStorage.idToken ?? "";
  return idToken;
};

export const writeLog = async (
  type: string,
  message: string
) => {
  return new Promise(async (resolve, reject) => {
  try {
    const idToken = await getIdTokenFromLocalStorage();
    let res = await fetch(`${API_SERVER_URL}/${LOGGING}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `${idToken}`
        },
        body: JSON.stringify({
          type: type,
          message: message
        }),
      });
      resolve({isSucceed: true, message: ''});
  } catch (error: any) {
    console.error(error);
    reject(error);
    // await writeException(`ErrorMessage: ${error.message}`);      
  }
});
}