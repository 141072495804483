import { getFunctionRefFromFirebaseApp, getProductsItemFromFirebase, subscribeSnapshot } from "../../../dataServices";
import { SubscriptionPriceModel } from "../models/SubscriptionPriceModel";

export const evaluateSubscriptionPrice = (Products: any, selectedPlanPriceId: any, priceId: string, productIndex: number, planId: string) => {
    const priceModel : SubscriptionPriceModel = { quantity: 0, priceId: "", productIndex: "", mode: "" };
    if (Products[selectedPlanPriceId.productIndex]?.recurring?.usage_type !== "metered") {
      priceModel.quantity = 1;
      priceModel.priceId = priceId;
      priceModel.productIndex = productIndex.toString();
    } else {
      priceModel.quantity = 0;
      priceModel.priceId = priceId;
      priceModel.productIndex = productIndex.toString();
    }

    return priceModel;
}

export const getProductsItemFromFirebaseService = (userId: string, collection: any) => {
  return getProductsItemFromFirebase(userId, collection);
}

export const subscribeSnapshotService = (userId: string, checkoutSession: any) => {
  return subscribeSnapshot(userId, checkoutSession);
}

export const getFunctionRefFromFirebaseAppService = () => {
  return getFunctionRefFromFirebaseApp();
}




