import React, { useEffect, useState, useContext, useRef } from "react";
import { AppContext } from "../../../store/AppContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import _ from "lodash";
import { CustomerGridModel } from "../models";
import {
  emptyMessageTemplate,
  lastSyncAtBodyTemplate,
  myobStatusBodyTemplate,
  netoStatusBodyTemplate,
  rowExpansionTemplate,
  statusItemTemplate,
} from "../GridBodyTemplates";
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { PaginatorTemplate } from 'primereact/paginator';
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { InputText } from "primereact/inputtext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSlash } from "@fortawesome/free-solid-svg-icons";

interface ICustomerGridComponentProps{
    customerData: Array<CustomerGridModel>,
    loading: boolean,
    setLazyParams : (value: any) => void,
    lazyParams: any,
    setLoading: (value: boolean) => void,
    setStartAfterRecord : (value: any) => void,
    setStartBeforeRecord : (value: any) => void,
    isNextDisabled: boolean,
    isPrevDisabled: boolean,
    selectedConnector: string,
    setCustomerType: (value: string) => void,
    customerType: string,
}

const CustomerGridComponent: React.FC<ICustomerGridComponentProps> = ({ setLazyParams, lazyParams,
    loading, setLoading, setStartAfterRecord, setStartBeforeRecord, isNextDisabled,isPrevDisabled, selectedConnector, customerData, setCustomerType, customerType }) => {
  let { State, Dispatch } = useContext(AppContext);
  const dt = useRef<DataTable>(null);

  const [customersData, setcustomersData] = useState<Array<CustomerGridModel>>(customerData ?? []);
  const [expandedRows, setExpandedRows] = useState<any[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<any>();
  const [selectedStatus2, setSelectedStatus2] = useState<any>();
  const [dateFilter, setDateFilter] = useState<any>();
  const [filter1, setFilter1] = useState<string>('');
  const [filter2, setFilter2] = useState<string>('');
  const [filter3, setFilter3] = useState<string>('');
  const filterFields = {
    Username: 'neto.Username',
    DispalyID: 'myob.DisplayID',
    ActiveText: 'neto.Active',
    IsActiveText: 'myob.IsActive',
    CompanyOrUser1: 'myob.CompanyName',
    CompanyOrUser2: 'myob.FirstName',
    CompanyOrUser3: 'myob.LastName',
    syncErrorMessage: 'neto.syncErrorMessage',
    myobSyncErrorMessage: 'myob.syncErrorMessage',
    lastSyncAt: 'lastSyncAt',
  }

  useEffect(() => {
    setcustomersData(customerData);
  }, [customerData]);

  useEffect(() => {
    // setLoading(true);
    // setLazyParams({
    //   first: 0,
    //   rows: 5,
    //   page: 1,
    //   sortField: "lastSyncAt",
    //   sortOrder: -1,
    //   filters: undefined,
    //   pageMode: '',
    //   pageSize: 50,
    // });
    setFilter1('');
    setFilter2('');
    setFilter3('');
    setSelectedStatus('');
    setSelectedStatus2('');
    setDateFilter('');
    setcustomersData([]);
  }, [selectedConnector]);

  const onPageRowsSizeChanged = (limit: number) => {
    setStartAfterRecord({});
    setStartBeforeRecord({});
    let params = {...lazyParams, pageSize: limit };
    if(limit === 1) {
      params.pageMode = 'ALL';
    } else {
      params.pageMode = '';
    }

    setLazyParams(params);
  }

  const onPageChange = (mode: string) => {
    switch(mode){
        case 'NEXT':
            setLazyParams({ ...lazyParams, page : lazyParams.page + 1, pageMode: 'NEXT' });
            break;
        case 'PREV':
            setLazyParams({ ...lazyParams, page : lazyParams.page - 1, pageMode: 'PREV' });
            break;
    }
  }

  const getDataTableSortField = (field: string) => {
    if(field === filterFields.lastSyncAt){
      return 'lastSyncAtForFilter';
    } else if(field === filterFields.Username){
      return 'NetoCustomer.Username';
    } else if(field === filterFields.DispalyID){
      return 'MyobCustomer.DisplayID';
    }
  }

  const getDbFilterField = (field: string) => {
    if(field === 'lastSyncAt'){
      return filterFields.lastSyncAt;
    } else if(field === 'NetoCustomer.Username'){
      return filterFields.Username;
    } else if(field === 'MyobCustomer.DisplayID'){
      return filterFields.DispalyID;
    } else if(field === 'CompanyOrUser'){
      return customerType;
    } else if(field === 'NetoCustomer.ActiveText'){
      return filterFields.ActiveText;
    } else if(field === 'MyobCustomer.IsActiveText'){
      return filterFields.IsActiveText;
    } else if(field === "NetoCustomer.syncErrorMessage"){
      return filterFields.syncErrorMessage
    } else if (field === "MyobCustomer.syncErrorMessage"){
      return filterFields.myobSyncErrorMessage;
    }

    return '';
  }

  const reset = (isCompleteReset?: boolean) => {
    dt.current?.reset();
    setFilter1('');
    setFilter2('');
    setFilter3('');
    setSelectedStatus('');
    setSelectedStatus2('');
    setDateFilter('');

    if(isCompleteReset && isCompleteReset === true){
      setLazyParams({
        first: 0,
        rows: 5,
        page: 1,
        sortField: "lastSyncAt",
        sortOrder: -1,
        filters: undefined,
        pageMode: '',
        pageSize: 50,
      });
    } else {
      let params = { ...lazyParams,
        first: 0,
        rows: 5,
        page: 1,
        sortField: "lastSyncAt",
        sortOrder: -1,
        filters: undefined,
      }

      if(params.pageMode !== 'ALL'){
        params.pageMode = '';
      }

      setLazyParams(params);
    }
  }

  const onCustomFilterChange : any = (value: string, field: string, index: number) => {
    if(index === 1){
        setFilter1(value);
        setFilter2('');
        setFilter3('');
        setSelectedStatus('')
        setSelectedStatus2('')
        setDateFilter('')
    } else if(index === 2){
        setFilter1('');
        setFilter2(value);
        setFilter3('');
        setSelectedStatus('')
        setSelectedStatus2('')
        setDateFilter('')
    } else if(index === 3){
        setFilter1('');
        setFilter2('');
        setFilter3(value);
        setSelectedStatus('')
        setSelectedStatus2('')
        setDateFilter('')
    }else if(index === 4){
        setFilter1('');
        setFilter2('');
        setFilter3('');
        setSelectedStatus('')
        setSelectedStatus2('')
        setDateFilter('')


    }else if(index === 5){
        setFilter1('');
        setFilter2('');
        setFilter3('');
        setSelectedStatus('')
        setSelectedStatus2('')
        setDateFilter('')
    }
    else if(index === 6){
      setFilter1('');
      setFilter2('');
      setFilter3('');
      setSelectedStatus(value)
      setSelectedStatus2('')
      setDateFilter('')
    } 
    else if(index === 7){
      setFilter1('');
      setFilter2('');
      setFilter3('');
      setSelectedStatus('')
      setSelectedStatus2(value)
      setDateFilter('')
    }  
    else if(index === 8){
      setFilter1('');
      setFilter2('');
      setFilter3('');
      setSelectedStatus('')
      setSelectedStatus2('')
      setDateFilter(value)
    } 

    let queryText = value.trimStart();
    queryText = queryText.trimEnd();

    if(!_.isEmpty(queryText) && queryText.length > 1){
      let param: any = {...lazyParams, sortField: getDbFilterField(field), filters: {[field]: { value: queryText, matchMode: "contains"}} };
      
      if(param.pageMode !== 'ALL'){
        param.pageMode = '';
      }

      setLazyParams(param);
    }

    if(_.isEmpty(value)){
      reset();
    }
  }

  const statuses = ['Active', 'InActive'];
  const modelFilter1 = (field: string, index: number) => { 
    return <InputText value={filter1} style={{ width: '100%' }} className="ui-column-filter"
        onChange={(event) => onCustomFilterChange(event.target.value, field, index)} />
  }
  const modelFilter2 = (field: string, index: number) => { 
      return <InputText value={filter2} style={{ width: '100%' }} className="ui-column-filter"
          onChange={(event) => onCustomFilterChange(event.target.value, field, index)} />
  }
  const modelFilter3 = (field: string, index: number) => { 
    return <>
      <Dropdown value={customerType} options={[{label: 'First Name', value: filterFields.CompanyOrUser2 },
        {label: 'Last Name', value: filterFields.CompanyOrUser3 }, {label: 'Company', value: filterFields.CompanyOrUser1 }]} 
        onChange={(e) => setCustomerType(e.value)} placeholder="Select a Status" className="p-column-filter" />
      <InputText value={filter3} style={{ width: '100%' }} className="ui-column-filter"
        onChange={(event) => onCustomFilterChange(event.target.value, field, index)} />
    </>
  }
  const modelFilter6 = (field: string, index: number) => {
    return (
      <Dropdown value={selectedStatus} options={statuses}
        onChange={(event) => onCustomFilterChange(event.target.value, field, index)}
        itemTemplate={statusItemTemplate}
        placeholder="Select a Status" className="p-column-filter" />
    );
  }
  const modelFilter7 = (field: string, index: number) => {
    return (
      <Dropdown value={selectedStatus2} options={statuses}
        onChange={(event) => onCustomFilterChange(event.target.value, field, index)}
        itemTemplate={statusItemTemplate}
        placeholder="Select a Status" className="p-column-filter" />
    );
  }
  const modelFilter8 = (field: string, index: number) => {
    return (
      <Calendar readOnlyInput value={dateFilter} onChange={(event) => onCustomFilterChange(moment(event.target.value?.toString())?.format('YYYY-MM-DD'), field, index)} 
      placeholder="Search" dateFormat="dd-mm-yy" className="p-column-filter" style={{ width: '150px' }} />
    );
  }

  const header = (
    <div className="d-flex justify-content-between">
      <h5 className="text-left">Customers</h5>
      <div className="col-6 text-right">
          <button
            type="button"
            className="btn btn-outline-secondary ml-2"
            onClick={() => reset(true)}>
            <div className="fa-layers ml-2">
              <FontAwesomeIcon className="mr-2" icon={faFilter} />
              <FontAwesomeIcon className="mr-2" icon={faSlash} />
            </div>
            Clear
          </button>
      </div>
    </div>
  );

  const filterElementCustom1 = modelFilter1("NetoCustomer.Username", 1);
  const filterElementCustom2 = modelFilter2("MyobCustomer.DisplayID", 2);
  const filterElementCustom3 = modelFilter3("CompanyOrUser", 3);
  const filterElementCustom6 = modelFilter6("NetoCustomer.ActiveText", 6);
  const filterElementCustom7 = modelFilter7("MyobCustomer.IsActiveText", 7);
  const filterElementCustom8 = modelFilter8("lastSyncAt", 8);
  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column />
        <Column header="Username" />
        <Column header="Username" />
        <Column header="State" colSpan={2} />
        <Column header="Name/Company" colSpan={1} />
        {/* {toggleViewModel === 2 && <Column header="Sync Error Message" colSpan={2} />} */}
        <Column header="Last Sync At" colSpan={1} />
      </Row>
      <Row>
        <Column />
        <Column
          header="Maropost"
          filter
          filterMatchMode="contains"
          filterPlaceholder="Search"
          field="NetoCustomer.Username"
          filterElement={filterElementCustom1}
        />
        <Column
          header="Myob"
          filter
          filterMatchMode="contains"
          filterPlaceholder="Search"
          field="MyobCustomer.DisplayID"
          filterElement={filterElementCustom2}
        />
        <Column
          header="Maropost"
          filter
          filterMatchMode="contains"
          filterPlaceholder="Search"
          filterField="NetoCustomer.ActiveText"
          // sortable
          // sortField="NetoCustomer.ActiveText"
          field="NetoCustomer.ActiveText"
          filterElement={filterElementCustom6}
        />
        <Column
          header="Myob"
          filter
          filterMatchMode="contains"
          filterPlaceholder="Search"
          filterField="MyobCustomer.IsActiveText"
          // sortable
          sortField="MyobCustomer.IsActiveText"
          field="MyobCustomer.IsActiveText"
          filterElement={filterElementCustom7}
        />

        <Column
          header="Myob"
          filter
          filterMatchMode="contains"
          filterPlaceholder="Search"
          // sortable
          // field="CompanyOrUser"
          filterElement={filterElementCustom3}
        />
        {/* {toggleViewModel === 2 &&
          <Column
            header="Maropost"
            filter
            filterMatchMode="contains"
            filterPlaceholder="Search"
            field="NetoCustomer.syncErrorMessage"
            filterElement={filterElementCustom4}

          />}
        {toggleViewModel === 2 &&
          <Column
            header="Myob"
            filter
            filterMatchMode="contains"
            filterPlaceholder="Search"
            field="MyobCustomer.syncErrorMessage"
            filterElement={filterElementCustom5}

          />
        } */}
        <Column
          filter
          filterMatchMode="contains"
          filterPlaceholder="Search"
          sortable
          filterField="lastSyncAt"
          field="lastSyncAtForFilter"
          filterElement={filterElementCustom8}
          sortableDisabled={_.isEmpty(customersData)}
        />
      </Row>
    </ColumnGroup>
  );

  const template2: PaginatorTemplate = {
    'FirstPageLink': '',
    'LastPageLink': '',
    'NextPageLink': (options) => {
      return <Button label="Next" disabled={isNextDisabled} onClick={() => onPageChange('NEXT')} className="p-button-raised p-button-info" />
    },
    'PageLinks': '',
    'PrevPageLink': (options) => {
      return <Button label="Prev" disabled={isPrevDisabled} onClick={() => onPageChange('PREV')} className="p-button-raised p-button-info" />
    },
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options: any) => {
      const dropdownOptions = [
        { label: 50, value: 50 },
        { label: 100, value: 100 },
        { label: 200, value: 200 },
        { label: 300, value: 300 },
        { label: 400, value: 400 },
        { label: 500, value: 500 },
        { label: 1000, value: 1000 },
        { label: 'All', value: 1 }
      ];
      return (
        <>
          <span className="p-mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Page Size: </span>
          <Dropdown value={lazyParams.pageSize} options={dropdownOptions} onChange={(e) => onPageRowsSizeChanged(e.value)} appendTo={document.body} />
        </>
      );
    },
    'CurrentPageReport': (options: any) => {
      return (
        <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
        </span>
      )
    }
  };

  const onPage = (event: any) => {
    let _lazyParams = { ...lazyParams, ...event };
    // if ((event?.page + 1) > lazyParams?.page) {
    //   setPaginationMode("NEXT");
    // } else if ((event?.page) < lazyParams?.page) {
    //   setPaginationMode("PREV");
    // }
    // else {
    //   setPaginationMode("");
    // }

    if ((lazyParams?.filters != event?.filters) || (lazyParams?.rows != event?.rows)) {
      setStartAfterRecord({}); 
      setStartBeforeRecord({}); 
      _lazyParams.pageMode = '';
    }

    const keys = event?.filters ? Object.keys(event?.filters) : [];
    if(keys?.includes('lastSyncAtForFilter')){
      _lazyParams = { ..._lazyParams, sortField: filterFields.lastSyncAt }
    } else if(keys?.includes('NetoCustomer.Username')){
      _lazyParams = { ..._lazyParams, sortField: filterFields.Username }
    } else if(keys?.includes('MyobCustomer.DisplayID')){
      _lazyParams = { ..._lazyParams, sortField: filterFields.DispalyID }
    } else if(keys?.includes('CompanyOrUser')){
      _lazyParams = { ..._lazyParams, sortField: customerType }
    } else if(keys?.includes('NetoCustomer.ActiveText')){
      _lazyParams = { ..._lazyParams, sortField: filterFields.ActiveText }
    } else if(keys?.includes('MyobCustomer.IsActiveText')){
      _lazyParams = { ..._lazyParams, sortField: filterFields.IsActiveText }
    }

    setLazyParams(_lazyParams);
  }

  const onSort = (event: any) => {
    let SortOrder = -1;
    if(lazyParams?.sortOrder === -1){
      SortOrder = 1;
    }
    let _lazyParams = { ...lazyParams, sortOrder: SortOrder };

    if(event?.sortField === 'lastSyncAtForFilter'){
      _lazyParams = { ..._lazyParams, sortField: filterFields.lastSyncAt }
    } 

    if(_lazyParams.filters) {
      const keys = Object.keys((_lazyParams.filters) as any) ?? [];
      if(!keys?.includes('lastSyncAt')){
          _lazyParams.filters = undefined;
          setDateFilter('');
      } 
    }

    if(_lazyParams.pageMode !== 'ALL'){
      _lazyParams.pageMode = '';
    }

    setFilter1('');
    setFilter2('');
    setFilter3('');
    setSelectedStatus('');
    setSelectedStatus2('');

    setLazyParams(_lazyParams);
  }

  return (
    <div className="container-fluid">
      <DataTable
        ref={dt}
        lazy
        paginator
        paginatorTemplate={template2}
        first={lazyParams.first}
        rows={lazyParams.pageSize === 1 ? customersData?.length : lazyParams.pageSize}
        removableSort
        // onPage={onPage} 
        onSort={onSort} 
        sortField={getDataTableSortField(lazyParams.sortField)} 
        sortOrder={lazyParams?.sortOrder === 1 ? 1 : -1}
        filters={lazyParams.filters}
        headerColumnGroup={headerGroup}
        header={header}
        value={customersData}
        className="p-mt-6"
        autoLayout
        loading={loading}
        emptyMessage={emptyMessageTemplate}
        paginatorClassName="p-jc-end"
        scrollable scrollHeight="400px"
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={(data: any) => rowExpansionTemplate([data])}>
        <Column expander style={{ width: "3em" }} />
        <Column field="NetoCustomer.Username" />
        <Column field="MyobCustomer.DisplayID" />
        <Column field="NetoCustomer.ActiveText" body={netoStatusBodyTemplate} />
        <Column field="MyobCustomer.IsActiveText" body={myobStatusBodyTemplate} />
        <Column field="CompanyOrUser" />
        <Column filterField="lastSyncAt" field="lastSyncAtForFilter" body={lastSyncAtBodyTemplate} />
      </DataTable>
    </div>
  );
};

export default CustomerGridComponent;