import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faSync } from '@fortawesome/free-solid-svg-icons';
import _ from "lodash";
import Alert from "../../../../../components/Alert";
import Label from '../../../../../components/Label';
import Boolean from '../../../../../components/Boolean';
import SelectInput from '../../../../../components/SelectInput';
import { AppContext } from '../../../../../store/AppContext';
import { synchronizeProductsNetoToMyob } from '../../services/MappingService';
import * as util from '../../../../../shared/Utilities';
import { ItemMappingsModel } from "../../../../../shared/models/mappings/UserMappingModels";

export interface INetoProductToMYOBItemMappingProductOptionsProps {
    touched: any,
    errors: any,
    itemMappings: ItemMappingsModel,
    onInputBlur: (val: any) => void,
    onFocusChanged: (value: ItemMappingsModel) => void,
}

const NetoProductToMYOBItemMappingProductOptions: React.FC<INetoProductToMYOBItemMappingProductOptionsProps> = (props) => {
    let { State, Dispatch } = useContext(AppContext);
    const [itemMapping, setItemMapping] = useState<ItemMappingsModel>(props.itemMappings ?? {} as ItemMappingsModel);

    useEffect(() => {
        setItemMapping(props.itemMappings);
    }, [props.itemMappings]);

    const onFocusChanged = () => {
        props.onFocusChanged(itemMapping);
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-6 d-flex">
                    <Label text={'Use Income Account'} /> <p className="text-danger">*</p>
                    <span className="pl-2"> <FontAwesomeIcon icon={faQuestionCircle} /> </span>
                </div>
                <div className="col-6">
                    <SelectInput
                        hasError={props?.touched?.productMapping?.useIncomeAccount &&
                            props?.errors?.productMapping?.useIncomeAccount ? true : false}
                        options={State.formsLookup?.itemMapping?.incomeAccount.values!}
                        selectedOptionValue={itemMapping.useIncomeAccount}
                        onChange={(val: string) => setItemMapping({ ...itemMapping, useIncomeAccount: val })}
                        onBlur={(e: any) => {
                            props.onInputBlur({
                                ...props.touched, productMapping: {
                                    ...props.touched.productMapping, useIncomeAccount: true
                                }
                            });
                            if (props.itemMappings?.useIncomeAccount !== itemMapping.useIncomeAccount)
                                onFocusChanged();
                        }}
                    />
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-6 d-flex">
                    <Label text={'Use Expense Account'} /> <p className="text-danger">*</p>
                    <span className="pl-2"> <FontAwesomeIcon icon={faQuestionCircle} /> </span>
                </div>
                <div className="col-6">
                    <SelectInput
                        hasError={
                            props?.touched?.productMapping?.useExpenseAccount &&
                                props?.errors?.productMapping?.useExpenseAccount ? true : false
                        }
                        options={State.formsLookup?.itemMapping?.expenseAccount.values!}
                        selectedOptionValue={itemMapping.useExpenseAccount}
                        onChange={(val: string) => setItemMapping({ ...itemMapping, useExpenseAccount: val })}
                        onBlur={() => {
                            props.onInputBlur({
                                ...props.touched, productMapping: {
                                    ...props.touched.productMapping, useExpenseAccount: true
                                }
                            });
                            if (props.itemMappings.useExpenseAccount !== itemMapping.useExpenseAccount)
                                onFocusChanged();
                        }}
                    />
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-6 d-flex">
                    <Label text={'Use Selling Tax Code'} /> <p className="text-danger">*</p>
                </div>
                <div className="col-6">
                    <SelectInput
                        hasError={
                            props?.touched?.productMapping?.useSellingTax &&
                                props?.errors?.productMapping?.useSellingTax ? true : false
                        }
                        options={State.formsLookup?.itemMapping?.sellingTax.values!}
                        selectedOptionValue={itemMapping.useSellingTax}
                        onChange={(val: string) => setItemMapping({ ...itemMapping, useSellingTax: val })}
                        onBlur={() => {
                            props.onInputBlur({
                                ...props.touched, productMapping: {
                                    ...props.touched.productMapping, useSellingTax: true
                                }
                            });
                            if (props.itemMappings.useSellingTax !== itemMapping.useSellingTax)
                                onFocusChanged();
                        }}
                    />
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-6 d-flex">
                    <Label text={'Use Buying Tax Code'} /> <p className="text-danger">*</p>
                </div>
                <div className="col-6">
                    <SelectInput
                        hasError={
                            props?.touched?.productMapping?.useBuyingTax &&
                                props?.errors?.productMapping?.useBuyingTax ? true : false
                        }
                        options={State.formsLookup?.itemMapping?.buyingTax.values!}
                        selectedOptionValue={itemMapping.useBuyingTax}
                        onChange={(val: string) => setItemMapping({ ...itemMapping, useBuyingTax: val })}
                        onBlur={() => {
                            props.onInputBlur({
                                ...props.touched, productMapping: {
                                    ...props.touched.productMapping, useBuyingTax: true
                                }
                            });
                            if (props.itemMappings.useBuyingTax !== itemMapping.useBuyingTax)
                                onFocusChanged();
                        }}
                    />
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-6 d-flex">
                    <Label text={'Create Inventorized Items as'} /> <p className="text-danger">*</p>
                    <span className="pl-2"> <FontAwesomeIcon icon={faQuestionCircle} /> </span>
                    
                </div>
                <div className="col-6">
                    <SelectInput
                        hasError={
                            props?.touched?.productMapping?.useInventorizedItems &&
                                props?.errors?.productMapping?.useInventorizedItems ? true : false
                        }
                        options={State.formsLookup?.itemMapping?.inventorizedItems.values!}
                        selectedOptionValue={itemMapping.useInventorizedItems}
                        onChange={(val: string) => setItemMapping({ ...itemMapping, useInventorizedItems: val })}
                        onBlur={() => {
                            props.onInputBlur({
                                ...props.touched, productMapping: {
                                    ...props.touched.productMapping, useInventorizedItems: true
                                }
                            });
                            if (props.itemMappings.useInventorizedItems !== itemMapping.useInventorizedItems)
                                onFocusChanged();
                        }}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default NetoProductToMYOBItemMappingProductOptions
