import firebase from "../shared/config/firebaseConfig";
import { DATABASE_COLLECTIONS } from '../shared/config/constants';
import { async, Observable } from 'rxjs';
const { CHECKOUT_SESSIONS, PRODUCTS, PRICES,CUSTOMERS } = DATABASE_COLLECTIONS;
const db = firebase.firestore();


export let subscribeSnapshotUnsubscribe: any
export const subscribeSnapshot = async (userId: string, checkoutSession: any) => {
    const docRef = await db
        .collection(`${CUSTOMERS}`)
        .doc(userId)
        .collection(`${CHECKOUT_SESSIONS}`)
        .add(checkoutSession);
    return new Observable((subscriber) => {
        subscribeSnapshotUnsubscribe = docRef.onSnapshot(async (snap: any) => {
            const { error, sessionId } = await snap.data();
            subscriber.next({ error, sessionId });
        }
        )
    })
}
export const getFunctionRefFromFirebaseApp = () => firebase.app().functions("us-central1").httpsCallable("ext-firestore-stripe-subscriptions-createPortalLink");
export const getProductsItemFromFirebase = async (userId: string, collection: any) => {
    return await firebase
        .firestore()
        .collection(`${CUSTOMERS}`)
        .doc(userId)
        .collection(collection)
        .get();
}
export const getProductsItemFirebase = async () => {
    const db = firebase.firestore();
    let products: any = [];
    let myProducts = await db
      .collection(`${PRODUCTS}`)
      .where("active", "==", true)
      .get();
  
    for (const doc of myProducts?.docs) {
      const priceSnap = await doc.ref
        .collection(`${PRICES}`)
        .where("active", "==", true)
        .orderBy("unit_amount")
        .get();
  
      await products.push({
        product: { id: doc.id, ...doc.data() },
        prices: priceSnap.docs.map((price) => {
          return {
            id: price.id,
            data: price.data(),
          };
        }),
      });
    }}