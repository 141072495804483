import * as React from 'react'
import { Card } from 'primereact/card';
import { Data, TermModel } from '../models/SaleOrderViewModels';
import _ from 'lodash';

export interface IDataDetailProps {
    data: Data,
    terms: TermModel,
}

const OrderData: React.FC<IDataDetailProps> = ({ data, terms }) => {
    const headerComponent = (
        <div className="p-3" style={{ backgroundColor: "#d3d3d3b8" }}>
            <h4>Summary</h4>
        </div>
    );

    return (
        <Card header={headerComponent} className="text-left">
            <div className="px-3">
                <div className="row p-3">
                    {data?.ShippingTotal !== "0" &&
                        <div className="col">
                            <div className="headings-size">
                                Shipping:
                            </div>
                            <p>{data?.ShippingTotal} </p>
                        </div>
                    }
                    {data?.SurchargeTotal !== "0" &&
                        <div className="col">
                            <div className="headings-size">
                                Surcharge:
                            </div>
                            <p>{data?.SurchargeTotal} </p>
                        </div>
                    }
                    {data?.ProductSubtotal !== "0" &&
                        <div className="col">
                            <div className="headings-size">
                                Product Subtotal:
                            </div>
                            <p>{data?.ProductSubtotal}</p>
                        </div>
                    }
                    {data?.GrandTotal !== "0" &&
                        <div className="col">
                            <div className="headings-size">
                                Grand Total:
                            </div>
                            <p>{data?.GrandTotal} </p>
                        </div>
                    }
                </div>
            </div>
            <hr />
            <div className="px-3">
                <div className="row p-3">
                    {terms?.PaymentTerm &&
                        <div className="col">
                            <div className="headings-size">
                                Payment Term:
                            </div>
                            <div className="d-flex">
                                <p>{terms?.PaymentTerm} </p>
                                {terms?.BalanceDueDate !== "0" &&
                                    <p className="ml-3">{terms?.BalanceDueDate} </p>}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Card>
    );
}

export default OrderData
