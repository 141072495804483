import React, { useState } from "react";
import MembershipContainer from "../membershipContainer";
import { ISignUp } from "../models";
import { loginWithGoogleService, SignUpWithEmailAndPasswordService } from "../services/membershipService";

const SignUp: React.FC = () => {
  const [state, setState] = useState<ISignUp>({
    newEmail: "",
    newPassword: "",
    errorMessage: "",
  });

  const loginWithGoogle = async () => {
    try {
      await loginWithGoogleService()
    }
    catch (error: any) {
      setState({ ...state, errorMessage: error.message });
    }
  };

  const signup = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    try {
      const { newEmail, newPassword } = state
      await SignUpWithEmailAndPasswordService(newEmail, newPassword);
    } catch (error: any) {
      setState({ ...state, errorMessage: error.message });
    }
  };

  const onEmailChange = (event: { target: { value: string } }) => {
    setState({ ...state, newEmail: event.target.value });
  };

  const onPasswordChange = (event: { target: { value: string } }) => {
    setState({ ...state, newPassword: event.target.value });
  };
  return (
    <>
      <MembershipContainer
        onEmailChange={onEmailChange}
        onPasswordChange={onPasswordChange}
        onSubmit={signup}
        errorMessage={state.errorMessage}
        loginWithGoogle={loginWithGoogle}
        email={state.newEmail}
        password={state.newPassword}
        isSignUp
      />
    </>
  );
};

export default SignUp;

