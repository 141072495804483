import { getUserConnectorConfigurations } from '../dataServices/MappingDataService';
import moment from 'moment-timezone';

const isLoggedIn = async () => {
    return window.localStorage.getItem("haveUser") === 'true';
}

const convertUTCDate = (date: string) => {
    return moment.utc(date, "YYYY-MM-DD HH:mm:ss").local().format("DD MMM yyyy hh:mm A");
}
const convertLocalDate = (date: string) => {
    return moment(new Date(date)).format("DD MMM yyyy hh:mm A");
}

const getTimezoneFromFirebase = async (userId: string, connectorId: string) => {
    const user = await getUserConnectorConfigurations(userId, connectorId);
    user?.result?.mappings?.synchronizationFeatures?.accountTimezone ? localStorage.setItem("timezone", user?.result?.mappings?.synchronizationFeatures?.accountTimezone) : localStorage.removeItem("timezone");
};

const userTimezone = (date: string) =>  {
    return moment(new Date(date)).tz(localStorage.timezone?localStorage.timezone:moment.tz.guess()).format("DD MMM yyyy hh:mm A");
};

const getUserTimezoneStartAndEndOfADay = (date: string) => {
    const startDayTime = "00:00:01";
    const endDayTime = "23:59:59";

    var startOfTheDay = moment(date + 'T' + startDayTime);
    var endOfTheDay = moment(date + 'T' + endDayTime);

    var startAt = moment.tz(startOfTheDay, localStorage.timezone).utc().format();
    var endAt = moment.tz(endOfTheDay, localStorage.timezone).utc().format();

    return { startAt, endAt }
}

export { isLoggedIn, convertUTCDate, convertLocalDate, getTimezoneFromFirebase ,userTimezone, getUserTimezoneStartAndEndOfADay };