import * as React from "react";
import { useParams } from 'react-router-dom';
import PaymentDetailsContainer from "../features/Order/PaymentDetailsContainer";

const PaymentDetailsPage: React.FC<any> = (props) => {
  const { orderId, connectorId,netoPaymentId } = useParams<any>();

  return (
    <div>
      <PaymentDetailsContainer
        docId={orderId}
        selectedConnector={connectorId}
        netoPaymentId={netoPaymentId}
      />
    </div>
  );
};

export default PaymentDetailsPage;
