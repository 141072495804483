import _ from "lodash";
import firebase from "../shared/config/firebaseConfig";
import { DATABASE_COLLECTIONS, STATIC_DOCUMENTS_ID } from '../shared/config/constants';
import { Observable } from 'rxjs';
const db = firebase.firestore();
const { USER_DETAILS, CONNECTORS, FAILED_SYNC_PRODUCTS, SUCCESS_SYNC_PRODUCTS, RECORDCOUNTER } = DATABASE_COLLECTIONS;
const { PRODUCT } = STATIC_DOCUMENTS_ID;

export let getFailedImportProductsSnapshotUnsubcribe: any
export let getImportedProductsSnapshotUnsubscribe: any
let getProductsRecordCount: any

export const getProductsTotalCounts = (userId: string, connector: string) => {
    return new Observable(subscriber => {
        getProductsRecordCount = db.collection(`${USER_DETAILS}`).doc(userId)
        .collection(`${CONNECTORS}`).doc(connector)
        .collection(`${RECORDCOUNTER}`).doc(PRODUCT).onSnapshot((snapshot) => {
          subscriber.next(snapshot?.data()?.count);
        });
    });
  }

const makeFailedSearchClauses = (failedImportCustomersRef: firebase.firestore.Query<firebase.firestore.DocumentData> | undefined, query: any, sortField: string, sortOrder: number) => {
    let ref = failedImportCustomersRef;
    if (query && query.length > 0) {
      query?.map((q: any) => {
        if(q?.key === "lastSyncAt"){
          ref = ref?.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc').where(q?.key, '>=', q?.value?.startAt).where(q?.key, '<=', q?.value?.endAt);
        }
        else if(q?.key === "myob.IsInventoried"){
          ref = ref?.orderBy(firebase.firestore.FieldPath.documentId()).where(q?.key, '==', q?.value);
        }
        else if(q?.key === "neto.Virtual"){ //"neto.IsInventoried"){
          ref = ref?.orderBy(firebase.firestore.FieldPath.documentId()).where(q?.key, '==', q?.value);
        }
        else{
        ref = ref?.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc').where(q?.key, '>=', q?.value).where(q?.key, '<=', q?.value + '\uf8ff');
        }
      })
    }
    return ref;
  }
  
  const makeSuccessSearchClauses = (successImportCustomersRef: firebase.firestore.Query<firebase.firestore.DocumentData> | undefined, query: any, sortField: string, sortOrder: number) => {
    let ref = successImportCustomersRef;
    if (query && query.length > 0) {
      query?.map((q: any) => {
        if(q?.key === "lastSyncAt"){
          ref = ref?.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc').where(q?.key, '>=', q?.value?.startAt).where(q?.key, '<=', q?.value?.endAt);
        }
        else if(q?.key === "myob.IsInventoried"){
          ref = ref?.orderBy(firebase.firestore.FieldPath.documentId()).where(q?.key, '==', q?.value);
        } 
        else if(q?.key === "neto.Virtual"){ //"neto.IsInventoried"){
          ref = ref?.orderBy(firebase.firestore.FieldPath.documentId()).where(q?.key, '==', q?.value);
        }
        else{
          ref = ref?.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc').where(q?.key, '>=', q?.value).where(q?.key, '<=', q?.value + '\uf8ff');
        }
      })
    }
    return ref;
  }

export const getImportFailedProducts = (userId: string, connector: string, limit: number, sortField: string, sortOrder: number, firstVisible?: any, lastVisible?: any, mode?: string, query?: any) => {
    return new Observable(subscriber => {
        let failedImportProductsRef: firebase.firestore.Query<firebase.firestore.DocumentData> | undefined = undefined;
        if (lastVisible && mode === 'NEXT') {
          failedImportProductsRef = db.collection(`${USER_DETAILS}`).doc(userId)
            .collection(`${CONNECTORS}`).doc(connector)
            .collection(`${FAILED_SYNC_PRODUCTS}`);
            
          if(!_.isEmpty(query)){
            failedImportProductsRef = makeFailedSearchClauses(failedImportProductsRef, query, sortField, sortOrder);
            failedImportProductsRef = failedImportProductsRef?.startAfter(lastVisible).limit(limit);
          } else {
            failedImportProductsRef = failedImportProductsRef.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc').startAfter(lastVisible).limit(limit)
          }
        }
        else if (firstVisible && mode === 'PREV') {
          failedImportProductsRef = db.collection(`${USER_DETAILS}`).doc(userId)
            .collection(`${CONNECTORS}`).doc(connector)
            .collection(`${FAILED_SYNC_PRODUCTS}`);
            
          if(!_.isEmpty(query)){
            failedImportProductsRef = makeFailedSearchClauses(failedImportProductsRef, query, sortField, sortOrder);
            failedImportProductsRef = failedImportProductsRef?.endBefore(firstVisible).limitToLast(limit);
          } else {
            failedImportProductsRef = failedImportProductsRef.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc').endBefore(firstVisible).limitToLast(limit)
          }
        }
        else if(mode === 'ALL'){
          failedImportProductsRef = db.collection(`${USER_DETAILS}`).doc(userId)
            .collection(`${CONNECTORS}`).doc(connector)
            .collection(`${FAILED_SYNC_PRODUCTS}`);
            
          if (!_.isEmpty(query)){
            failedImportProductsRef = makeFailedSearchClauses(failedImportProductsRef, query, sortField, sortOrder);
          } 
          else{
            failedImportProductsRef = failedImportProductsRef.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc');
          }
        }
        else{
          failedImportProductsRef = db.collection(`${USER_DETAILS}`).doc(userId)
            .collection(`${CONNECTORS}`).doc(connector)
            .collection(`${FAILED_SYNC_PRODUCTS}`);
          
          if(!_.isEmpty(query)){
            failedImportProductsRef = makeFailedSearchClauses(failedImportProductsRef, query, sortField, sortOrder);
            failedImportProductsRef = failedImportProductsRef?.limit(limit);
          } else {
            failedImportProductsRef = failedImportProductsRef.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc').limit(limit)
          }
        }

        if (failedImportProductsRef) {
          getFailedImportProductsSnapshotUnsubcribe = failedImportProductsRef.onSnapshot((snapshot) => {
            let lastVal = snapshot?.docs[snapshot?.docs.length - 1];
            let firstVal = snapshot?.docs[0];
            subscriber.next({ products: snapshot?.docs, lastVisible: lastVal, firstVisible: firstVal });
          });
        }
    })
}

export const getImportedProducts = (userId: string, connector: string, limit: number, sortField: string, sortOrder: number, firstVisible?: any, lastVisible?: any, mode?: string, query?: any) => {
    return new Observable(subscriber => { 
      
      console.log("ProductGrid-QUERY: ", query);

      let successImportProductsRef: firebase.firestore.Query<firebase.firestore.DocumentData> | undefined = undefined;
      if (lastVisible && mode === 'NEXT') {
          successImportProductsRef = db.collection(`${USER_DETAILS}`).doc(userId)
            .collection(`${CONNECTORS}`).doc(connector)
            .collection(`${SUCCESS_SYNC_PRODUCTS}`);
            
          if(!_.isEmpty(query)){
            successImportProductsRef = makeSuccessSearchClauses(successImportProductsRef, query, sortField, sortOrder);
            successImportProductsRef = successImportProductsRef?.startAfter(lastVisible).limit(limit);
          } else {
            successImportProductsRef = successImportProductsRef.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc').startAfter(lastVisible).limit(limit)
          }
        }
        else if (firstVisible && mode === 'PREV') {
          successImportProductsRef = db.collection(`${USER_DETAILS}`).doc(userId)
            .collection(`${CONNECTORS}`).doc(connector)
            .collection(`${SUCCESS_SYNC_PRODUCTS}`);
            
          if(!_.isEmpty(query)){
            successImportProductsRef = makeSuccessSearchClauses(successImportProductsRef, query, sortField, sortOrder);
            successImportProductsRef = successImportProductsRef?.endBefore(firstVisible).limitToLast(limit);
          } else {
            successImportProductsRef = successImportProductsRef.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc').endBefore(firstVisible).limitToLast(limit);
          }
        }
        else if(firstVisible && mode === 'ALL'){
          successImportProductsRef = db.collection(`${USER_DETAILS}`).doc(userId)
            .collection(`${CONNECTORS}`).doc(connector)
            .collection(`${SUCCESS_SYNC_PRODUCTS}`);
          
          if (!_.isEmpty(query)){
            successImportProductsRef = makeSuccessSearchClauses(successImportProductsRef, query, sortField, sortOrder);
          } 
          else{
            successImportProductsRef = successImportProductsRef.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc');
          }
        }
        else {
          successImportProductsRef = db.collection(`${USER_DETAILS}`).doc(userId)
            .collection(`${CONNECTORS}`).doc(connector)
            .collection(`${SUCCESS_SYNC_PRODUCTS}`);
          
          if(!_.isEmpty(query)){
            successImportProductsRef = makeSuccessSearchClauses(successImportProductsRef, query, sortField, sortOrder);
            successImportProductsRef = successImportProductsRef?.limit(limit);
          } 
          else {
            successImportProductsRef = successImportProductsRef.orderBy(sortField, sortOrder === -1 ? 'desc' : 'asc').limit(limit)
          }
        }

        if (successImportProductsRef) {
            getImportedProductsSnapshotUnsubscribe = successImportProductsRef.onSnapshot((snapshot) => {
                let lastVal = snapshot?.docs[snapshot?.docs.length - 1];
                let firstVal = snapshot?.docs[0];
                subscriber.next({ products: snapshot?.docs, firstVisible: firstVal, lastVisible: lastVal });
            });
        }
    })
};


export const getImportFailedProductsApproach1 = (userId: string, connector: string) => {
    return new Observable(subscriber => {
        getFailedImportProductsSnapshotUnsubcribe = db.collection(`${USER_DETAILS}`).doc(userId)
            .collection(`${CONNECTORS}`).doc(connector)
            .collection(`${FAILED_SYNC_PRODUCTS}`).onSnapshot((snapshot) => {
                subscriber.next(snapshot?.docs);
            });
    })
}

export const getImportedProductsApproach1 = (userId: string, connector: string) => {
    return new Observable(subscriber => {
        getImportedProductsSnapshotUnsubscribe = db.collection(`${USER_DETAILS}`).doc(userId)
            .collection(`${CONNECTORS}`).doc(connector)
            .collection(`${SUCCESS_SYNC_PRODUCTS}`).onSnapshot((snapshot) => {
                subscriber.next(snapshot?.docs);
            });
    })
};