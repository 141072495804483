import  React from 'react'
import { FormsLookup } from '../shared/models/mappings/FormLookups';
import { UserMappingModel } from '../shared/models/mappings/UserMappingModels';
import { IAction } from './IAction';
import { CurrentUser, SalesOrder, IAppState } from './IAppState';

export interface IAppContextType{    
    State: IAppState
    Dispatch: (action:IAction)=>void
}

export const AppContext = React.createContext<IAppContextType>(
    {
        State:{
            formsLookup: {} as FormsLookup,
            userMappings: {} as UserMappingModel,
            isLoading: false,
            requestStatus: {},
            currentUser: {} as CurrentUser,
            userMyobToNetoMappings: {},
            salesOrder: {} as SalesOrder,
            selectedConnectorOnGrid: ""
        }, 
        Dispatch: () => {}
    });