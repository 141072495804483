import { reExportOrderRMARequest } from './../../../dataServices/OrderDataService';
import _ from "lodash";
import { ValueLabel } from "../../../shared/models";
import * as util from "../../../shared/Utilities";
import {
  getAllUserConnectors,
  reprocessOrdersRequest,
  reprocessOrderRequest,
  markAsCloseOrdersRequestRequest,
} from "../../../dataServices";
import { QueryDocumentSnapshot, DocumentData } from "@firebase/firestore-types";
import {
  OrdersReprocessRequest,
  OrderReprocessRequest,
  SaleOrderEntityModel,
  SalesOrderGridModel,
  MarkAsClosePayload
} from "../models";
import {
  NetoSaleOrderModel,
  MyobSaleOrderModel,
  NetoRMAModel,
  lineItem as MyobLineLineItem,
} from "../models";
import {
  LineItem,
  NetoRMAOrderViewModel,
  PaymentDetail,
  RMALineItem,
  RMAListModel,
  SaleOrderViewModel,
} from "../models/SaleOrderViewModels";

// export const getOrdersCount = async (userId: string, connector: string): Promise<number> => {
//   return await getOrdersTotalCount(userId, connector);
// }

export const getAllConnectorLabels = (
  currentUserId: string
): Promise<Array<ValueLabel>> => {
  return new Promise(async (resolve, reject) => {
    const model = await getAllUserConnectors(currentUserId);
    if (model.isSucceed && !_.isEmpty(model.result)) {
      const list = filterConnectorLabels(model.result);
      resolve(list);
    } else {
      resolve([]);
    }
  });
};

export const convertOrderEntitytoGridModel = (
  orderEntity: SaleOrderEntityModel,
  index: number
) => {
  let orderDatePlaced = util.userTimezone(orderEntity.Date);
  return {
    number: (index + 1).toString(),
    netoOrderId: orderEntity?.IncomingOrder?.OrderID
      ? orderEntity?.IncomingOrder?.OrderID
      : orderEntity?.IncomingOrder?.Number
        ? orderEntity?.IncomingOrder?.Number
        : "",
    myobOrderId: orderEntity?.OutgoingOrder?.Number ?? "",
    myobCustomer: orderEntity?.OutgoingOrder?.Customer?.Name ?? "",
    myobOrderStatus: orderEntity?.OutgoingOrder?.Status ?? "",
    datePlaced: orderDatePlaced.toString() ?? "",
    datePlacedForFilter: new Date(orderDatePlaced),
    status: {
      orderStatus: orderEntity?.Status,
      message: orderEntity?.Message,
      warningMessage: orderEntity?.WarningMessage,
      date: orderEntity?.Date,
    },
    documentId: orderEntity.id,
    incomingOrder: orderEntity?.IncomingOrder ?? {},
    outgoingOrder: orderEntity?.OutgoingOrder ?? {},
    netoCustomer: orderEntity?.IncomingOrder?.Username ?? "",
    netoOrderStatus: orderEntity?.IncomingOrder?.OrderStatus ?? "",
  } as SalesOrderGridModel;
};



export const convertOrderEntityToRMAGridModel = (
  orderRMA: any,
  orderEntity: any,
  index: number
) => {
  let orderDatePlaced = util.userTimezone(orderEntity.Date);
  return {
    number: (index + 1).toString(),
    netoRmaId: orderRMA?.RmaIncoming?.RmaID,
    netoOrderId: orderEntity?.IncomingOrder?.OrderID
      ? orderEntity?.IncomingOrder?.OrderID
      : orderEntity?.IncomingOrder?.Number
        ? orderEntity?.IncomingOrder?.Number
        : "",
    myobOrderId: orderEntity?.OutgoingOrder?.Number ?? "",
    myobRmaId: orderRMA?.RmaOutgoing?.Number,
    netoRmaStatus: orderRMA?.RmaIncoming?.RmaStatus,
    myobRmaStatus: orderRMA?.RmaOutgoing?.Status,
    syncErrorMessage: orderRMA?.syncErrorMessage,

    myobCustomer: orderEntity?.OutgoingOrder?.Customer?.Name ?? "",
    myobOrderStatus: orderEntity?.OutgoingOrder?.Status ?? "",
    datePlaced: orderDatePlaced.toString() ?? "",
    datePlacedForFilter: new Date(orderDatePlaced),
    status: {
      orderStatus: orderEntity?.Status,
      message: orderEntity?.Message,
      warningMessage: orderEntity?.WarningMessage,
      date: orderEntity?.Date,
    },
    documentId: orderEntity.id,
    netoCustomer: orderEntity?.IncomingOrder?.Username ?? "",
    netoOrderStatus: orderEntity?.IncomingOrder?.OrderStatus ?? "",
    incomingOrder: orderEntity?.IncomingOrder ?? {},
    outgoingOrder: orderEntity?.OutgoingOrder ?? {},

  } as SalesOrderGridModel;




};

export const convertOrderEntityToPaymentGridModel = (
  orderPayment: any,
  orderEntity: any,
  index: number
) => {
  let orderDatePlaced = util.userTimezone(orderEntity.Date);
  return {
    number: (index + 1).toString(),
    netoPaymentId: orderPayment?.neto?.ID ?? "",
    myobPaymentId: orderPayment?.myob?.ReceiptNumber ?? "",
    netoOrderId: orderEntity?.IncomingOrder?.OrderID
      ? orderEntity?.IncomingOrder?.OrderID
      : orderEntity?.IncomingOrder?.Number
        ? orderEntity?.IncomingOrder?.Number
        : "",
    myobOrderId: orderEntity?.OutgoingOrder?.Number ?? "",
    myobCustomer: orderEntity?.OutgoingOrder?.Customer?.Name ?? "",
    myobOrderStatus: orderEntity?.OutgoingOrder?.Status ?? "",
    datePlaced: orderDatePlaced.toString() ?? "",
    datePlacedForFilter: new Date(orderDatePlaced),
    status: {
      orderStatus: orderEntity?.Status,
      message: orderEntity?.Message,
      warningMessage: orderEntity?.WarningMessage,
      date: orderEntity?.Date,
    },
    documentId: orderEntity.id,
    netoCustomer: orderEntity?.IncomingOrder?.Username ?? "",
    netoOrderStatus: orderEntity?.IncomingOrder?.OrderStatus ?? "",
    incomingOrder: orderEntity?.IncomingOrder ?? {},
    outgoingOrder: orderEntity?.OutgoingOrder ?? {},
    netoPaymentDatePlaced: util.userTimezone(orderPayment?.neto?.DatePaid).toString(),
    myobPaymentDatePlaced: util.userTimezone(orderPayment?.myob?.Date).toString()

  } as SalesOrderGridModel;
};






export const reprocessOrders = async (
  userId: string,
  connectorId: string,
  payload?: Array<SalesOrderGridModel>,
  isForced?: boolean
) => {
  console.log("OrderService reprocessOrders 78: ", connectorId);
  if (_.isEmpty(payload)) return false;
  const orderData: OrdersReprocessRequest = {
    orders:
      payload?.map((order: SalesOrderGridModel) => ({
        orderId: order.netoOrderId,
        orderStatus: order?.incomingOrder?.OrderStatus,
      })) ?? [],
  };
  return await processOrdersRequest(orderData, userId, connectorId, isForced);
};

export const markAsCloseOrders = async (userId: string, connectorId: string, payload?: Array<SalesOrderGridModel>,) => {
  // if (_.isEmpty(payload)) return false;
  const orderIds = payload?.map((order: SalesOrderGridModel) => (order?.netoOrderId))
  return await markAsCloseOrdersRequest(orderIds ?? [], userId, connectorId);
};

export const markAsCloseOrdersRequest = async (
  orders: Array<string>,
  userId: string,
  connectorId: string,
) => {
  const response = await markAsCloseOrdersRequestRequest(userId, connectorId, orders);
  return response.isSucceed;
};


export const processOrdersRequest = async (
  orders: OrdersReprocessRequest,
  userId: string,
  connectorId: string,
  isForced?: boolean
) => {
  const response = await reprocessOrdersRequest(userId, connectorId, orders, isForced);
  return response.isSucceed;
};

export const processOrderRequest = async (payload: OrderReprocessRequest): Promise<any> => {
  const response = await reprocessOrderRequest(payload);
  return response;
};

export const reExportOrderRMA = async (payload: any): Promise<any> => {
  const response = await reExportOrderRMARequest(payload);
  return response;
};

export function Convert(
  source: string,
  status: string,
  message: string,
  warning: string,
  date: string,
  payload: any,
  netoAndMyobOrderIds?: string
) {

  const NetoOrderPayments: Array<any> = payload?.Payments?.payments.map((x: any) => x.neto); // as NetoSaleOrderModel;
  let MyobOrderPayments: Array<any> = payload?.Payments?.payments.map((x: any) => x.myob); // as MyobSaleOrderModel;
  const netoFormatPayment: Array<PaymentDetail> = [];

  // MyobOrderPayments = MyobOrderPayments && MyobOrderPayments?.OrderPayment;

  NetoOrderPayments && MyobOrderPayments && NetoOrderPayments.map((orderPayment) => {
    let myobPayment: Array<PaymentDetail> = [];
    let neto: PaymentDetail = {
      id: orderPayment.PaymentID,
      Type: orderPayment?.Type ?? "",
      AccountName: orderPayment?.PaymentMethodName ?? "",
      PaymentMethod: orderPayment?.PaymentMethod ?? "",
      AccountDescription: orderPayment?.AccountDescription ?? "",
      Date: orderPayment?.DatePaid ?? "",
      Reference: "",
      ReferenceNumber: orderPayment?.ReferenceNumber,
      ReferenceDescription: "",
      Amount: orderPayment?.AmountPaid ?? "",
      ProcessBy: orderPayment?.ProcessBy ?? "",
    };

    MyobOrderPayments.map((myobPaymentResponse: any) => {
      if (neto.ReferenceNumber == myobPaymentResponse.ReceiptNumber) {
        // neto?.Date?.split(" ").join("") ===
        //   myobPaymentResponse?.Date?.split("T").join("") &&
        // neto.Amount == myobPaymentResponse.AmountReceived
        // ) {
        let myob: PaymentDetail = {
          id: myobPaymentResponse?.UID ?? "",
          Type: "",
          AccountName: myobPaymentResponse?.Account?.Name ?? "",
          AccountDescription: orderPayment?.AccountDescription ?? "",
          Date: myobPaymentResponse?.Date,
          Reference: "",
          Memo: myobPaymentResponse?.Memo,
          ReceiptNumber: myobPaymentResponse?.ReceiptNumber ?? "",
          ReferenceDescription: "",
          ReferenceNumber: myobPaymentResponse?.ReferenceNumber ?? "",
          Amount: myobPaymentResponse?.AmountApplied ?? "",
          Customer: myobPaymentResponse?.Customer?.Name ?? "",
        };
        myobPayment.push(myob);
      }
    });

    neto.NetoAndMyobPayment = myobPayment;
    neto.Memo = myobPayment?.map((x: any) => x.Memo).join(",");
    netoFormatPayment.push(neto);
  });






  switch (source) {
    case "neto":
      const NetoOrder = payload?.IncomingOrder as NetoSaleOrderModel;
      let MyobOrderForpayment: any = payload?.OutgoingOrder as MyobSaleOrderModel;

      const viewModel: SaleOrderViewModel = {
        OrderNumber:
          NetoOrder?.OrderID && NetoOrder?.OrderID !== ""
            ? NetoOrder?.OrderID
            : NetoOrder?.Number,
        OrderStatus: status ?? "",
        OrderMessage: message ?? "",
        OrderWarning: warning ?? "",
        OrderDate: util.convertLocalDate(date) ?? "",

        OrderDates: {
          CreatedOn: NetoOrder?.DatePlaced
            ? util.convertUTCDate(NetoOrder?.DatePlaced)
            : "",
          UpdatedOn: NetoOrder?.DateUpdated
            ? util.convertUTCDate(NetoOrder?.DateUpdated)
            : "",
          DueDate: "",
        },
        OrderInternalStatus: NetoOrder?.OrderStatus ?? "",
        Total: "",
        OrderAmounts:
          NetoOrder?.TaxInclusive === "True"
            ? "Yes"
            : NetoOrder?.TaxInclusive === "False"
              ? "No"
              : "",
        ShippingMethod: NetoOrder?.ShippingOption ?? "",
        Customer: {
          Id: "",
          UserName: NetoOrder?.Username ?? "",
          Emails: { Home: "", Work: "" },
          Phones: { Home: "", Mobile: "", Work: "" },
          References: "",
        },
        BillingAddress: {
          FirstName: NetoOrder?.BillFirstName || "",
          LastName: NetoOrder?.BillLastName || "",
          Address: NetoOrder?.BillStreetLine1 || "",
          City: NetoOrder?.BillCity || "",
          Zip: NetoOrder?.BillPostCode || "",
          State: NetoOrder?.BillState || "",
          Country: NetoOrder?.BillCountry || "",
          isActive: true,
        },
        ShippingAddress: {
          FirstName: NetoOrder?.ShipFirstName || "",
          LastName: NetoOrder?.ShipLastName || "",
          Address: NetoOrder?.ShipStreetLine1 || "",
          City: NetoOrder?.ShipCity || "",
          Zip: NetoOrder?.ShipPostCode || "",
          State: NetoOrder?.ShipState || "",
          Country: NetoOrder?.ShipCountry || "",
          isActive: true,
        },
        LineItems: [],
        TotalLineItemAmount: "",
        PaymentDetail: [],
        TotalPaymentAmount: "",
        Data: {
          ShippingSignature: NetoOrder?.ShippingSignature ?? "",
          SurchargeTotal: NetoOrder?.SurchargeTotal ?? "",
          ShippingTotal: NetoOrder?.ShippingTotal ?? "",
          ProductSubtotal: NetoOrder?.ProductSubtotal ?? "",
          GrandTotal: NetoOrder?.GrandTotal ?? "",
        },
        Tags: {
          StoreName: "",
          isActive: false,
        },
        Term: {
          PaymentTerm: NetoOrder?.PaymentTerms ?? "",
          BalanceDueDate: NetoOrder?.BalanceDueDate?.toString() ?? "",
        },
        Notes: {
          OrderInternalNotes: NetoOrder?.InternalOrderNotes ?? "",
        },
      };

      NetoOrder &&
        NetoOrder?.OrderLine &&
        NetoOrder?.OrderLine?.map((orderLine) => {
          const line: LineItem = {
            Quantity: orderLine.Quantity ?? "",
            ItemName: orderLine.ProductName ?? "",
            ItemDescription: orderLine.ItemNotes ?? "",
            Tax: orderLine.TaxCode ?? "",
            Price:
              orderLine.UnitPrice?.substring(
                0,
                orderLine.UnitPrice?.indexOf(".") + 3
              ) ?? "",
            Discount: orderLine.ProductDiscount ?? "",
            DiscountPercent: orderLine.PercentDiscount?.toString() || "",
            Amount: `${parseFloat(orderLine.Quantity) *
              parseFloat(orderLine.UnitPrice ?? "") -
              parseFloat(orderLine.ProductDiscount)
              }`,
            SKU: orderLine.SKU ?? "",
            Location: orderLine.WarehouseName ?? "",
          };
          viewModel.LineItems.push(line);
        });

      viewModel.PaymentDetail = netoFormatPayment;

      return viewModel;

    case "myob":
      const MyobOrder = payload?.OutgoingOrder as MyobSaleOrderModel;
      const NetoOrderForPayment: any = payload?.IncomingOrder as NetoSaleOrderModel;
      const myobViewModel: SaleOrderViewModel = {
        OrderNumber: MyobOrder?.Number ?? "",
        OrderStatus: status ?? "",
        OrderMessage: message ?? "",
        OrderWarning: warning ?? "",
        OrderDate: util.convertLocalDate(date) ?? "",
        OrderDates: {
          CreatedOn: MyobOrder?.Date
            ? util.convertUTCDate(MyobOrder?.Date)
            : "",
          UpdatedOn: MyobOrder?.LastModified
            ? util.convertUTCDate(MyobOrder?.LastModified)
            : "",
          DueDate: "",
        },
        Total: "",
        OrderInternalStatus: MyobOrder?.Status ?? "",
        OrderAmounts:
          MyobOrder?.IsTaxInclusive === true
            ? "Yes"
            : MyobOrder?.IsTaxInclusive === false
              ? "No"
              : "",
        ShippingMethod: MyobOrder?.ShippingMethod ?? "",
        Customer: {
          Id: MyobOrder?.Customer?.UID ?? "",
          UserName: MyobOrder?.Customer?.DisplayID ?? "",
          Emails: { Home: "", Work: "" },
          Phones: { Home: "", Mobile: "", Work: "" },
          References: "",
        },
        ShippingAddress: {
          FirstName: "",
          LastName: "",
          Address: "",
          City: "",
          Zip: "",
          State: "",
          Country: "",
          isActive: false,
        },
        BillingAddress: {
          FirstName: "",
          LastName: "",
          Address: "",
          City: "",
          Zip: "",
          State: "",
          Country: "",
          isActive: false,
        },
        LineItems: [],
        TotalLineItemAmount: "",
        PaymentDetail: [],
        TotalPaymentAmount: "",
        Data: {
          ShippingSignature: "false",
          SurchargeTotal: "",
          ShippingTotal: "",
          ProductSubtotal: MyobOrder?.Subtotal ?? "",
          GrandTotal: MyobOrder?.BalanceDueAmount ?? "",
        },
        Tags: {
          StoreName: "",
          isActive: false,
        },
        Term: {
          PaymentTerm: MyobOrder?.Terms?.PaymentIsDue ?? "",
          BalanceDueDate: MyobOrder?.Terms?.BalanceDueDate?.toString() ?? "",
        },
        Notes: {
          OrderInternalNotes: MyobOrder?.Comment ?? "",
        },
      };

      MyobOrder &&
        MyobOrder?.Lines &&
        MyobOrder?.Lines.map((orderLine) => {
          const line: LineItem = {
            Quantity: orderLine?.ShipQuantity ?? "",
            ItemName: orderLine?.Description ?? "",
            ItemDescription: orderLine?.Description ?? "",
            Tax: orderLine?.TaxCode?.Code ?? "",
            Price: orderLine?.UnitPrice ?? "",
            // ActualPrice: '',
            Discount: orderLine?.DiscountPercent?.toString() ?? "",
            // AcutalDiscount: '',
            Amount: orderLine?.Total ?? "",
            // ActualAmount: '',
            DiscountPercent: orderLine?.DiscountPercent ?? "",
            SKU: orderLine?.Item?.Number ?? "",
            Location: !_.isEmpty(orderLine?.Location)
              ? `${orderLine?.Location?.Name ?? ""} ${!_.isEmpty(orderLine?.Location?.Identifier)
                ? "(" + orderLine?.Location?.Identifier + ")"
                : ""
              }`
              : "",
          };
          myobViewModel.LineItems.push(line);
        });
      myobViewModel.PaymentDetail = netoFormatPayment;
      return myobViewModel;

    case "netoRMA":
      // const NetoRMAOrder = payload as NetoRMAModel;
      const NetoRMAOrder = payload?.RmaIncoming as any;
      const rmaViewModel: any = {
        RMANumber: NetoRMAOrder?.RmaID ?? "",
        RMAStatus: NetoRMAOrder?.RmaStatus ?? "",
        RMADate: util.convertLocalDate(NetoRMAOrder?.DateApproved) ?? "",
        Customer: {
          Id: "",
          UserName: NetoRMAOrder?.StaffUsername ?? "",
          Emails: { Home: "", Work: "" },
          Phones: { Home: "", Mobile: "", Work: "" },
          References: "",
        },
        NetoAndMyobOrderId: netoAndMyobOrderIds ?? ""
      };
      rmaViewModel.LineItems = ConvertNetoLineItemToGlobalLineItemInterface(!_.isArray(NetoRMAOrder?.RmaLines?.RmaLine) ? [NetoRMAOrder?.RmaLines?.RmaLine] : NetoRMAOrder?.RmaLines?.RmaLine);
      // console.log("hanan",NetoRMAOrder?.RmaLines?.RmaLine);


      return rmaViewModel;

    case "myobRMA":
      const MyobRMAOrder = payload?.RmaOutgoing as any;
      const myobrmaViewModel: any = {
        RMANumber: MyobRMAOrder?.Number ?? "",
        RMAStatus: MyobRMAOrder?.Status ?? "",
        RMADate: util.convertLocalDate(MyobRMAOrder?.Date) ?? "",
        Customer: {
          Id: "",
          UserName: MyobRMAOrder?.Customer?.DisplayID ?? "",
          Emails: { Home: "", Work: "" },
          Phones: { Home: "", Mobile: "", Work: "" },
          References: "",
        },
        NetoAndMyobOrderId: netoAndMyobOrderIds ?? ""
      };
      myobrmaViewModel.LineItems = ConvertMyobLineItemToGlobalLineItemInterface(MyobRMAOrder?.Lines)


      return myobrmaViewModel;


    case "netoPayment":
      const netoFormatSinglePayment: Array<PaymentDetail> = [];
      const NetoOrderOfPayment = payload?.IncomingOrder as NetoSaleOrderModel;
      let netoPayment = [payload?.payment?.neto];
      let myobPayment1 = [payload?.payment?.myob];
      netoPayment && myobPayment1 && netoPayment.map((orderPayment) => {
        let myobPayment: Array<PaymentDetail> = [];
        let neto: PaymentDetail = {
          id: orderPayment.PaymentID,
          Type: orderPayment?.Type ?? "",
          AccountName: orderPayment?.PaymentMethodName ?? "",
          PaymentMethod: orderPayment?.PaymentMethod ?? "",
          AccountDescription: orderPayment?.AccountDescription ?? "",
          Date: orderPayment?.DatePaid ?? "",
          Reference: "",
          ReferenceNumber: orderPayment?.ReferenceNumber,
          ReferenceDescription: "",
          Amount: orderPayment?.AmountPaid ?? "",
          ProcessBy: orderPayment?.ProcessBy ?? "",
        };

        myobPayment1.map((myobPaymentResponse: any) => {
          if (neto.ReferenceNumber == myobPaymentResponse.ReceiptNumber) {
            let myob: PaymentDetail = {
              id: myobPaymentResponse?.UID ?? "",
              Type: "",
              AccountName: myobPaymentResponse?.Account?.Name ?? "",
              AccountDescription: orderPayment?.AccountDescription ?? "",
              Date: myobPaymentResponse?.Date,
              Reference: "",
              Memo: myobPaymentResponse?.Memo,
              ReceiptNumber: myobPaymentResponse?.ReceiptNumber ?? "",
              ReferenceDescription: "",
              ReferenceNumber: myobPaymentResponse?.ReferenceNumber ?? "",
              Amount: myobPaymentResponse?.AmountApplied ?? "",
              Customer: myobPaymentResponse?.Customer?.Name ?? "",
            };
            myobPayment.push(myob);
          }
        });

        neto.NetoAndMyobPayment = myobPayment;
        neto.Memo = myobPayment?.map((x: any) => x.Memo).join(",");
        netoFormatSinglePayment.push(neto);
      });

      const netoPaymentViewModel: any = {
        OrderNumber:
          NetoOrderOfPayment?.OrderID && NetoOrderOfPayment?.OrderID !== ""
            ? NetoOrderOfPayment?.OrderID
            : NetoOrderOfPayment?.Number,
        OrderStatus: status ?? "",
        OrderDate: util.convertLocalDate(date) ?? "",

        OrderDates: {
          CreatedOn: NetoOrderOfPayment?.DatePlaced
            ? util.convertUTCDate(NetoOrderOfPayment?.DatePlaced)
            : "",
          UpdatedOn: NetoOrderOfPayment?.DateUpdated
            ? util.convertUTCDate(NetoOrderOfPayment?.DateUpdated)
            : "",
          DueDate: "",
        },
        Customer: {
          Id: "",
          UserName: NetoOrderOfPayment?.Username ?? "",
          Emails: { Home: "", Work: "" },
          Phones: { Home: "", Mobile: "", Work: "" },
          References: "",
        },
        LineItems: [],
        TotalLineItemAmount: "",
        PaymentDetail: [],
        TotalPaymentAmount: "",

      };
      netoPaymentViewModel.PaymentDetail = netoFormatSinglePayment;
      return netoPaymentViewModel;

    case "myobPayment":
      const myobFormatSinglePayment: Array<PaymentDetail> = [];
      const myobOrderOfPayment = payload?.OutgoingOrder as MyobSaleOrderModel;
      let netoPayments = [payload?.payment?.neto];
      let myobPayments = [payload?.payment?.myob];
      netoPayments && myobPayments && netoPayments.map((orderPayment) => {
        let myobPayment: Array<PaymentDetail> = [];
        let neto: PaymentDetail = {
          id: orderPayment.PaymentID,
          Type: orderPayment?.Type ?? "",
          AccountName: orderPayment?.PaymentMethodName ?? "",
          PaymentMethod: orderPayment?.PaymentMethod ?? "",
          AccountDescription: orderPayment?.AccountDescription ?? "",
          Date: orderPayment?.DatePaid ?? "",
          Reference: "",
          ReferenceNumber: orderPayment?.ReferenceNumber,
          ReferenceDescription: "",
          Amount: orderPayment?.AmountPaid ?? "",
          ProcessBy: orderPayment?.ProcessBy ?? "",
        };

        myobPayments.map((myobPaymentResponse: any) => {
          if (neto.ReferenceNumber == myobPaymentResponse.ReceiptNumber) {
            let myob: PaymentDetail = {
              id: myobPaymentResponse?.UID ?? "",
              Type: "",
              AccountName: myobPaymentResponse?.Account?.Name ?? "",
              AccountDescription: orderPayment?.AccountDescription ?? "",
              Date: myobPaymentResponse?.Date,
              Reference: "",
              Memo: myobPaymentResponse?.Memo,
              ReceiptNumber: myobPaymentResponse?.ReceiptNumber ?? "",
              ReferenceDescription: "",
              ReferenceNumber: myobPaymentResponse?.ReferenceNumber ?? "",
              Amount: myobPaymentResponse?.AmountApplied ?? "",
              Customer: myobPaymentResponse?.Customer?.Name ?? "",
            };
            myobPayment.push(myob);
          }
        });

        neto.NetoAndMyobPayment = myobPayment;
        neto.Memo = myobPayment?.map((x: any) => x.Memo).join(",");
        myobFormatSinglePayment.push(neto);
      });
      const myobPaymentViewModel: any = {
        OrderNumber: myobOrderOfPayment?.Number ?? "",
        OrderStatus: status ?? "",
        OrderDate: util.convertLocalDate(date) ?? "",
        OrderDates: {
          CreatedOn: myobOrderOfPayment?.Date
            ? util.convertUTCDate(myobOrderOfPayment?.Date)
            : "",
          UpdatedOn: myobOrderOfPayment?.LastModified
            ? util.convertUTCDate(myobOrderOfPayment?.LastModified)
            : "",
          DueDate: "",
        },
        Customer: {
          Id: myobOrderOfPayment?.Customer?.UID ?? "",
          UserName: myobOrderOfPayment?.Customer?.DisplayID ?? "",
          Emails: { Home: "", Work: "" },
          Phones: { Home: "", Mobile: "", Work: "" },
          References: "",
        },
      };
      myobPaymentViewModel.PaymentDetail = myobFormatSinglePayment;
      return myobPaymentViewModel;

    default:
      return null;
  }
}

/* Order RMA services */
export function getRmaList(payload: any) {
  let rmaList: Array<RMAListModel> = [];
  if (payload && _.size(payload) > 0) {
    payload.map((rma: any) => {
      rmaList.push({
        incomingOrder: ConvertNetoRma(rma.RmaIncoming),
        outgoingOrder: ConvertMyobRma(rma.RmaOutgoing),
      });
    });
  }

  return rmaList;
}

export function ConvertNetoRma(payload: any) {
  const NetoRMAOrder = payload as NetoRMAOrderViewModel;
  const date: any = NetoRMAOrder?.DateApproved;
  const orderDateParsed = util.convertUTCDate(NetoRMAOrder?.DateApproved); //new Date(date);
  const NetoRMAViewModel: NetoRMAOrderViewModel = {
    RmaID: NetoRMAOrder.RmaID,
    DateApproved: orderDateParsed,
    RefundSubtotal: NetoRMAOrder.RefundSubtotal,
    RefundTaxTotal: NetoRMAOrder.RefundTaxTotal,
    RefundTotal: NetoRMAOrder.RefundTotal,
    RmaStatus: NetoRMAOrder.RmaStatus,
    StaffUsername: NetoRMAOrder.StaffUsername,
    RmaLines: {
      RmaLine: [],
    },
  };
  const rmaLines = !_.isArray(NetoRMAOrder.RmaLines.RmaLine)
    ? [NetoRMAOrder.RmaLines.RmaLine]
    : NetoRMAOrder.RmaLines.RmaLine;
  rmaLines.map((orderLine) => {
    const line: RMALineItem = {
      ItemStatus: orderLine.ItemStatus,
      ProductName: orderLine.ProductName,
      Quantity: orderLine.Quantity,
      RefundSubtotal: orderLine.RefundSubtotal,
      ReturnReason: orderLine.ReturnReason,
      ItemName: orderLine.ItemName,
      Discount: orderLine.Discount,
      RmaLineID: orderLine.RmaLineID,
      Tax: orderLine.Tax,
      SKU: orderLine.SKU,
      ItemNotes: orderLine.ItemNotes,
      WarehouseName: orderLine.WarehouseName,
      ResolutionOutcome: orderLine.ResolutionOutcome,
      TaxCode: orderLine.TaxCode,
    };
    NetoRMAViewModel.RmaLines.RmaLine.push(line);
  });
  return NetoRMAViewModel;
}

export function ConvertMyobRma(payload: any) {
  const MyobRMAOrder = payload as MyobSaleOrderModel;
  const date: any = MyobRMAOrder?.Date;
  const orderDateParsed = util.convertUTCDate(MyobRMAOrder?.Date ?? "");
  const MyobRMAViewModel: MyobSaleOrderModel = {
    UID: MyobRMAOrder?.UID ?? "",
    BalanceDueAmount: MyobRMAOrder?.BalanceDueAmount ?? "",
    TotalTax: MyobRMAOrder?.TotalTax ?? "",
    TotalAmount: MyobRMAOrder?.TotalAmount ?? "",
    Subtotal: MyobRMAOrder?.Subtotal ?? "",
    Customer: MyobRMAOrder?.Customer ?? "",
    Status: MyobRMAOrder?.Status ?? "",
    Lines: [],
    Number: MyobRMAOrder?.Number ?? "",
    Date: orderDateParsed,
  };
  MyobRMAOrder?.Lines?.map((orderLine) => {
    const line: MyobLineLineItem = {
      UnitPrice: orderLine?.UnitPrice ?? "",
      UnitCount: orderLine?.ShipQuantity ?? orderLine?.UnitCount ?? "",
      SKU: orderLine?.Item?.Number ?? "",
      Total: orderLine?.Total ?? "",
      RowID: orderLine?.RowID ?? "",
      TaxCode: {
        UID: orderLine?.TaxCode?.UID ?? "",
        Code: orderLine?.TaxCode?.Code ?? "",
      },
      Description: orderLine?.Description ?? "",
      Account: {
        Name: orderLine?.Account?.Name ?? orderLine?.Account?.DisplayID ?? "",
        // ? orderLine?.Account?.Name ?? ""
        // : orderLine?.Account?.DisplayID
        //   ? orderLine?.Account.DisplayID ?? ""
        //   : "",
        DisplayID: orderLine?.Account?.DisplayID ?? "",
      },
    };
    MyobRMAViewModel.Lines.push(line);
  });
  return MyobRMAViewModel;
}

export function ConvertNetoLineItemToGlobalLineItemInterface(
  lineItems: Array<RMALineItem>
): Array<LineItem> {
  const lineItemArray: Array<LineItem> = [];
  lineItems?.map((orderLine) => {
    const line: any = {
      Quantity: orderLine.Quantity ?? "",
      ItemName: orderLine.ProductName ?? "",
      ItemDescription: orderLine.ItemNotes || "",
      Tax: orderLine.TaxCode ?? "",
      Price:
        parseFloat(orderLine.RefundSubtotal) / parseFloat(orderLine.Quantity),
      Discount: orderLine?.Discount ?? "0.00",
      DiscountPercent: "",
      Amount: orderLine.RefundSubtotal,
      SKU: orderLine.SKU ?? "",
      RefundSubtotal: orderLine.RefundSubtotal ?? "",
      ResolutionOutcome: orderLine.ResolutionOutcome ?? "",
      Location: orderLine.WarehouseName ?? "",
    };
    lineItemArray.push(line);
  });
  return lineItemArray;
}

export function ConvertMyobLineItemToGlobalLineItemInterface(
  lineItems: Array<MyobLineLineItem>
): Array<LineItem> {

  const lineItemArray: Array<LineItem> = [];
  lineItems?.map((orderLine) => {
    const line: any = {
      Quantity: orderLine ? orderLine?.ShipQuantity ?? "" : "",
      ItemName: orderLine?.Description ?? "",
      ItemDescription: orderLine?.Description ?? "",
      Tax: orderLine?.TaxCode?.Code ?? "",
      Price: orderLine?.UnitPrice ?? "",
      Discount: orderLine?.DiscountPercent?.toString() ?? "",
      Amount: orderLine?.Total ?? "",
      Account: orderLine?.Account?.Name ?? orderLine?.Account?.DisplayID,
      DiscountPercent: orderLine?.DiscountPercent ?? "",
      SKU: orderLine?.Item?.Number ?? "",
      Location: `${orderLine?.Location?.Name ?? ""} (${orderLine?.Location?.Identifier ?? ""
        })`,
    };
    lineItemArray.push(line);
  });

  return lineItemArray;
}

const filterConnectorLabels = (
  connectors?: Array<QueryDocumentSnapshot<DocumentData>>
) => {
  const labels: Array<ValueLabel> = [];
  connectors?.map((doc: QueryDocumentSnapshot<DocumentData>) => {
    labels.push({ label: doc.data()?.label, value: doc.id });
  });
  return labels;
};
