import * as React from 'react';
import { useEffect, useState, useContext } from 'react';
import firebase from '../../shared/config/firebaseConfig';
import { AppContext } from '../../store/AppContext';
import { createBrowserHistory } from "history";
import _ from 'lodash';
import { RMAListModel, SaleOrderViewModel } from './models/SaleOrderViewModels';
import { Button } from "primereact/button";
import Header from './details/Header';
import SaleOrder from './details/SaleOrder';
import Address from './details/Address';
import LineItem from './details/LineItem';
import Payment from './details/Payment';
import OrderData from './details/OrderData';
import Tags from './details/Tags';
import Notes from './details/Notes';
import RMA from './details/RMA';
import { Convert, getRmaList } from './services/OrderService';
import { DATABASE_COLLECTIONS } from '../../shared/config/constants';
import './OrderDetailsContainer.css';
const { USER_DETAILS, CONNECTORS, ORDERS } = DATABASE_COLLECTIONS;

export interface ISaleOrderDetailProps {
    docId: string,
    selectedConnector: string
}

const OrderDetailsContainer: React.FC<ISaleOrderDetailProps> = (props) => {
    let { State } = useContext(AppContext);
    const history = createBrowserHistory();
    const [toggleViewModel, setToggleViewModel] = useState<number>(1);
    const [viewModel, setViewModel] = useState<SaleOrderViewModel>({} as SaleOrderViewModel);
    const [incomingOrderProps, setIncomingOrderProps] = useState({ OrderId: "", Status: "" });
    const [rmaList, setRmaList] = useState<Array<RMAListModel>>([]);
    
    useEffect(() => {
        const db = firebase.firestore();
        db.collection(`${USER_DETAILS}`).doc(/*State?.currentUser?.isAdminUser && localStorage.SelectedIdByAdmin ? localStorage.SelectedIdByAdmin : */State.currentUser.currentUserId)
            .collection(`${CONNECTORS}`).doc(props?.selectedConnector)
            .collection(`${ORDERS}`).doc(props?.docId).onSnapshot(snapshot => {
                const data = snapshot?.data();
                let netoOrder = Convert('neto', data?.Status,
                    data?.Message,data?.WarningMessage, data?.Date,
                    data) ?? {} as SaleOrderViewModel;

                let myobOrder = Convert('myob', data?.Status,
                    data?.Message,data?.WarningMessage, data?.Date,
                    data) ?? {} as SaleOrderViewModel;

                const rmaList = getRmaList(data?.RMA);
                setIncomingOrderProps({ OrderId: netoOrder?.OrderNumber, Status: netoOrder?.OrderInternalStatus });
                setViewModel(toggleViewModel === 2 ? myobOrder : netoOrder);
                setRmaList(rmaList);
            });
    }, [toggleViewModel]);


    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between mt-1">
                <div className="topnav mt-2">
                    <Button className={`${toggleViewModel && toggleViewModel === 1 ? "bg-dark text-white p-button-secondary p-button-sm " : "p-button-secondary p-button-sm  p-button-text p-button-plain"}`} onClick={() => setToggleViewModel(1)}>Maropost Order</Button>
                    <Button className={`${toggleViewModel && toggleViewModel === 2 ? "bg-dark text-white p-button-secondary p-button-sm " : "p-button-secondary p-button-sm  p-button-text p-button-plain"}`} onClick={() => setToggleViewModel(2)}>Myob Order</Button>
                </div>
                <Button icon="pi pi-angle-left" label="Go Back" className="p-button-text p-button-plain" onClick={() => history.goBack()} />
            </div>
            <br />
            <Header
                OrderID={viewModel.OrderNumber}
                Username={viewModel?.Customer?.UserName}
                OrderStatus={viewModel.OrderStatus}
                OrderMessage={viewModel.OrderMessage}
                OrderWarning={viewModel?.OrderWarning}
                OrderDate={viewModel.OrderDate}
                OrderInternalStatus={viewModel?.OrderInternalStatus}
                ConnectorId={props?.selectedConnector}
                incomingOrderProps={incomingOrderProps}
            />
            <SaleOrder
                DatePlaced={viewModel?.OrderDates?.CreatedOn}
                DateUpdate={viewModel?.OrderDates?.UpdatedOn}
                DateDue={viewModel?.OrderDates?.DueDate}
                Total={viewModel?.Total}
                OrderAmount={viewModel?.OrderAmounts}
                ShippingMethod={viewModel?.ShippingMethod}
                OrderStatus={viewModel?.OrderInternalStatus}
            />
            <div className="d-flex">
                {viewModel?.BillingAddress?.isActive ?
                    <Address
                        Title={'Billing Address'}
                        FirstName={viewModel?.BillingAddress?.FirstName}
                        LastName={viewModel?.BillingAddress?.LastName}
                        Address={viewModel?.BillingAddress?.Address}
                        PostCode={viewModel?.BillingAddress?.Zip}
                        City={viewModel?.BillingAddress?.City}
                        Country={viewModel?.BillingAddress?.Country}
                        State={viewModel?.BillingAddress?.State}
                    /> : ''
                }
                {viewModel?.ShippingAddress?.isActive ?
                    <Address
                        Title={'Shipping Address'}
                        FirstName={viewModel?.ShippingAddress?.FirstName}
                        LastName={viewModel?.ShippingAddress?.LastName}
                        Address={viewModel?.ShippingAddress?.Address}
                        PostCode={viewModel?.ShippingAddress?.Zip}
                        City={viewModel?.ShippingAddress?.City}
                        Country={viewModel?.ShippingAddress?.Country}
                        State={viewModel?.ShippingAddress?.State}
                    /> : ''
                }
                </div>
                <LineItem
                    lineItems={viewModel?.LineItems}
                    totalLineItemAmount={viewModel?.TotalLineItemAmount}
                />
                {!_.isEmpty(viewModel?.PaymentDetail) && _.size(viewModel?.PaymentDetail) > 0 &&
                    <Payment
                        PaymentDetails={viewModel?.PaymentDetail}
                        TotalPayment={viewModel?.TotalPaymentAmount}
                    />
                }
                <OrderData
                    data={viewModel?.Data}
                    terms={viewModel?.Term}
                />
                {viewModel?.Tags?.isActive &&
                    <Tags
                        tags={viewModel?.Tags}
                        />
                    }
                <Notes
                    Notes={viewModel?.Notes}
                    />
                {!_.isEmpty(rmaList) && _.size(rmaList) > 0 &&
                <RMA
                    rmaList={rmaList}
                    connectorId={props?.selectedConnector}
                    netoOrderId={incomingOrderProps?.OrderId}
                />
                }
        </div>
    )
}

export default OrderDetailsContainer
