import * as React from "react";
import { useEffect, useState } from "react";
import { ValueLabel } from '../shared/models';
import { MultiSelect, MultiSelectChangeParams } from 'primereact/multiselect';

export interface IMultiSelectInputProps {
    options: Array<ValueLabel>,
    selectedOptions?: Array<string>,
    onChange: (list: Array<string>) => void,
    onBlur?: (value: Array<string>) => void,
    hasError?: boolean
}

const MultiSelectInput: React.FC<IMultiSelectInputProps> = (props) => {
    const [selectedOptions, setSelectedOptions] = useState<Array<string>>([]);

    useEffect(() => {
        if(props.selectedOptions){
            setSelectedOptions(props.selectedOptions);
            props.onChange(props.selectedOptions);
        }
    }, [props.selectedOptions]);

    return (
        <div className="p-field p-col-6 p-inputgroup">
            <MultiSelect
                className={`${props.hasError && 'border border-danger'}`} 
                value={selectedOptions ? selectedOptions : []}
                options={props.options ? props.options : []}
                onChange={(e: MultiSelectChangeParams) => props.onChange && props.onChange(e.value)}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => props.onBlur && props.onBlur([e.target.nodeValue ?? ''] ?? [])}
                optionLabel="label"
                optionValue="value"
                // placeholder="Select Order Status(es)"
                // display="chip"
                onHide={() => props.onBlur && props.onBlur(selectedOptions)}
            />
        </div>
    )
}

export default MultiSelectInput
