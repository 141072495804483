import { loginWithGoogleAuth, SignUpWithEmailAndPassword, SignInWithEmailAndPassword, setMYOBCompany, setAccessTokenWithMyobAuthenticationCode } from '../../../dataServices/MembershipDataService';

export const loginWithGoogleService = async () => {
    await loginWithGoogleAuth();
}

export const SignUpWithEmailAndPasswordService = async (email: string, password: string) => {
    await SignUpWithEmailAndPassword(email, password);
}

export const SignInWithEmailAndPasswordService = async (email: string, password: string) => {
    await SignInWithEmailAndPassword(email, password);
}

export const setMyobAuthorizationToken = (data: any) => {
    return setAccessTokenWithMyobAuthenticationCode(data);
}

export const setMyobCompanyInConnector = async (userId: string, connectorId: string, data: any) => {
    return await setMYOBCompany(userId, connectorId, data);
}

