import React, { FC, useEffect, useContext, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { AppContext } from "../../store/AppContext";

interface IProps {
    exact?: boolean;
    path: string;
    component: React.ComponentType<any>;
}

export const AdminRoute: FC<IProps> = ({ component: Component, ...rest }) => {
    let { State } = useContext(AppContext);
    const [isAdmin, setIsAdmin] = useState((State.currentUser.loggedIn && State.currentUser.isAdminUser));

    useEffect(() => {
        setIsAdmin((State.currentUser.loggedIn && State.currentUser.isAdminUser));
    }, [State]);

    return (
        <>
            <Route
                {...rest}
                render={(props) => {
                    return isAdmin ? (
                        <Component {...props} />
                    ) : (
                        <Redirect to={"/signin"} />
                    );
                }}
            />
        </>
    );
};
