import _ from "lodash";
import { ADMIN_SERVER_URL } from "../shared/config/constants";
import { getIdTokenFromLocalStorage } from "./DataService";
import { CLOUD_FUNCTIONS } from "../shared/config/constants";
import { QuerySystemUsersModel, SynchronizeProcessModel, SystemUserRequestModel } from ".";
import { writeException } from "../shared/services/LoggingService";
const { UPDATE_SYSTEM_USERS, GET_SYSTEM_USERS } = CLOUD_FUNCTIONS;

export const updateSystemUsers = async (
  systemUser: SystemUserRequestModel
): Promise<SynchronizeProcessModel> => {
  return new Promise(async (resolve, reject) => {
    try {
      const idToken = await getIdTokenFromLocalStorage();
      let result = await fetch(`${ADMIN_SERVER_URL}/${UPDATE_SYSTEM_USERS}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${idToken}`,
        },
        body: JSON.stringify({ user: systemUser }),
      });

      if (result.ok) {
        let response = await result.json();
        const { isSucceed, errors } = response?.result;

        if (isSucceed)
          resolve({
            isSucceed: true,
            message: "Users are Updated Successfully!",
          });
        else {
          resolve({ isSucceed: false, message: errors?.toString() });
        }
      } else {
        resolve({
          isSucceed: false,
          message: "Users are not updated, due to some error!",
        });
      }
    } catch (error: any) {
      resolve({ isSucceed: false, message: error.message });
      await writeException(`ErrorMessage: ${error.message}`);
    }
  });
};

export const getAllSystemUsers = async (size?: number): Promise<QuerySystemUsersModel> => {
  return new Promise(async (resolve, reject) => {
    try {
      const idToken = await getIdTokenFromLocalStorage();
      let uri = `${ADMIN_SERVER_URL}/${GET_SYSTEM_USERS}`;
      if (size && size > 0) uri = `${uri}?size=${size}`;
      let result = await fetch(uri, {
        headers: {
          Authorization: `${idToken}`,
        },
      });

      if (result.ok) {
        let res = await result.json();
        if (res.isSuccess)
          resolve({ isSucceed: true, result: res?.result });
        else
          resolve({
            isSucceed: false,
            message: "Some error occurred while getting Users!",
          });
      } else {
        resolve({ isSucceed: false, message: "Some error occurred!" });
      }
    } catch (e: any) {
      resolve({ isSucceed: false, message: e.message });
      await writeException(`ErrorMessage: ${e.message}`);

    }
  });
};