import * as React from 'react';
import { useEffect, useState } from 'react';
import { Calendar, CalendarChangeParams } from 'primereact/calendar';
import moment from 'moment';

const Datepicker: React.FC<IDatepickerProps> = (props) => {
  let pickerRef: React.LegacyRef<Calendar> = null;

  const [date8, setDate8] = useState<Date | Date[] | undefined>(undefined);

  useEffect(() => {
    if (props.selectedDate) {
      setDate8((new Date(props.selectedDate)));
      props.onBlur(props.selectedDate);
    }
  }, [props.selectedDate])

  return (
    <div className="p-field p-col-6 p-inputgroup">
      <Calendar 
            ref={pickerRef} 
            id="icon" 
            className={`${props.hasError && 'border border-danger'}`} 
            value={date8} 
            onChange={(e: CalendarChangeParams) => props?.onChange(e.value ? moment(e.value as Date).format('YYYY-MM-DDTHH:mm:ss') : '')} 
            onBlur={() => props.onBlur(props.selectedDate)}
            showTime={props?.paymentAndRma ? false : true} 
            showSeconds = {props?.paymentAndRma ? false : true}
            showButtonBar 
            hideOnDateTimeSelect
            showIcon
            dateFormat="dd/mm/yy" 
            placeholder="Select Date"
        />

    </div>
  );
};

export interface IDatepickerProps {
  selectedDate: string,
  hasError?: boolean,
  onChange: (date: string) => void,
  onBlur: (e: any) => void,
  max?: boolean
  paymentAndRma?:boolean
}

export default Datepicker