import firebase from "firebase";
import * as React from "react";
import { useEffect, useState } from "react";
import { Card } from "primereact/card";
import { ValueLabel } from "../shared/models";
import { getAllConnectorLabels } from "../features/Order/services/OrderService";
import _ from "lodash";
import { ProgressBar } from 'primereact/progressbar';

const UserSchedulerStatue2: React.FC<any> = ({user}) => {
  const [selectedUser, setSelectedUser] = useState<any>(user.id);
  const [syncProcessingStates, setSyncProcessingStates] = useState<any>();
  const [connectors, setConnectors] = useState<Array<ValueLabel>>([
    { label: "", value: "" },
  ]);
  const [selectedConnector, setSelectedConnector] = useState<string>("");

  useEffect(() => {
    const getConnector = async () => {
      const connectorsList = await getAllConnectorLabels(user.id);
      setSelectedConnector(
        connectorsList.length && connectorsList[0].value
          ? connectorsList[0].value
          : ""
      );
      setConnectors(connectorsList);
      setSyncProcessingStates({});
    };
    selectedUser && getConnector();
  }, [selectedUser]);

  useEffect(() => {
    const getConnector = async () => {
      firebase
        .firestore()
        .collection("user-details")
        .doc(user.id)
        .collection(`connectors`)
        .doc(selectedConnector)
        .onSnapshot((snapshot: any) => {
          setSyncProcessingStates(
            snapshot.data()?.mappings?.SyncProcessingStates ?? {}
          );
        });
    };
    selectedConnector && getConnector();
  }, [selectedConnector]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          {user.id && (
            <div>
              {!_.isEmpty(syncProcessingStates) ? (
                <div className="text-left ml-5">
                  <div className="row">
                    <div className="col-6">
                      <h2 className="my-5">Sync Processing Current States</h2>
                      {Object.keys(syncProcessingStates).map((key, i) => (
                        <>
                          {_.isBoolean(syncProcessingStates[key]) && (
                            <p className="row" key={i}>
                              <span className="h5">
                                {_.isBoolean(syncProcessingStates[key]) && (
                                  <span className="h5 font-weight-bold">
                                    - {key}
                                  </span>
                                )}
                              </span>
                              <span className="col">
                                {_.isBoolean(syncProcessingStates[key]) &&
                                  (syncProcessingStates[key] == false ? (
                                    <span className="h5 text-danger">
                                      Currently Not In Running State
                                    </span>
                                  ) : (
                                    <span className="h5 text-success">
                                      Currently In Running State
                                      <ProgressBar mode="indeterminate" className="w-75" color="green" style={{ height: '3px' }}></ProgressBar>
                                    </span>
                                  ))}
                              </span>
                            </p>
                          )}
                        </>
                      ))}
                    </div>
                    <div className="col-6">
                      <h2 className="my-5">Last Sync Processed Time</h2>
                      {Object.keys(syncProcessingStates).map((key, i) => (
                        <>
                          {!_.isBoolean(syncProcessingStates[key]) && (
                            <p className="row" key={i}>
                              <span className="h5">
                                {!_.isBoolean(syncProcessingStates[key]) && (
                                  <span className="h5 font-weight-bold">
                                    {" "}
                                    - {key}
                                  </span>
                                )}
                              </span>
                              <span className="col">
                                {!_.isBoolean(syncProcessingStates[key]) && (
                                  <span className="h5">
                                    {syncProcessingStates[key]}
                                  </span>
                                )}
                              </span>
                            </p>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                    <h3 className="mt-4">No Sync Processing Data Found </h3>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserSchedulerStatue2;
