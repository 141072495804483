import * as React from "react";
import OrderGridContainer from "../features/Order/OrderGridContainer";
import "./SaleOrderGridPage.css"

const SaleOrderGridPage: React.FC = () => {
  return (
    <OrderGridContainer />
  );
};

export default SaleOrderGridPage;
