import _ from 'lodash';
import { ValueLabel } from '../../../shared/models';
import { getAllUserConnectors, getFailedImportCustomers, getImportedCustomers, getFailedImportCustomersApproach1, getImportedCustomersApproach1 } from '../../../dataServices';
import { QueryDocumentSnapshot, DocumentData } from "@firebase/firestore-types";
import { CustomerEntityModel, CustomerGridModel, MyobCustomerModel, NetoCustomerModel } from '../models';
import * as util from '../../../shared/Utilities';

export const getAllConnectorLabels = (
  currentUserId: string
): Promise<Array<ValueLabel>> => {
  return new Promise(async (resolve, reject) => {
    
      const model = await getAllUserConnectors(currentUserId);
      if (model.isSucceed && !_.isEmpty(model.result)) {
        const list = filterConnectorLabels(model.result);
        resolve(list);
      } else {
        resolve([]);
      }    
  });
};

export const getFailedImportCustomersDataSnapshot = (userId: string, connector: string, limit: number, sortField: string, sortOrder: number, firstVisible?: any, lastVisible?: any, mode?: string, query?: any) => {
    return getFailedImportCustomers(userId, connector, limit, sortField, sortOrder, firstVisible, lastVisible, mode, query);
}

export const getImportedCustomersDataSnapshot = (userId: string, connector: string, limit: number, sortField: string, sortOrder: number, firstVisible?: any, lastVisible?: any, mode?: string, query?: any) => {
    return getImportedCustomers(userId, connector, limit, sortField, sortOrder, firstVisible, lastVisible, mode, query);
}

export const getFailedImportCustomersDataSnapshotApproach1 = (userId: string, connector: string) => {
    return getFailedImportCustomersApproach1(userId, connector);
}

export const getImportedCustomersDataSnapshotApproach1 = (userId: string, connector: string) => {
    return getImportedCustomersApproach1(userId, connector);
}

export const convertCustomerEntityToGridModel = (userData: CustomerEntityModel, index: number) => {
    let formattedDate = util.userTimezone((userData?.lastSyncAt ?? ''));

    return {
        Number: (index + 1).toString(),   
        lastSyncAt: formattedDate,
        lastSyncAtForFilter: formattedDate,
        NetoCustomer: transformNetoCustomerModel(userData?.neto),
        MyobCustomer: transformMyobCustomerModel(userData?.myob),
        CompanyOrUser: checkUserIsIndidualOrCompany(userData?.myob),
        Address: mapAddressMyobToNeto(userData?.neto, userData?.myob)
    } as CustomerGridModel
}

const checkUserIsIndidualOrCompany = (user?: MyobCustomerModel) => {
    if (user?.IsIndividual) {
        return user?.FirstName?.concat((user?.LastName ?? ''));
    } else if (user?.CompanyName) {
        return user?.CompanyName
    } else {
        return '';
    }
}

const mapAddressMyobToNeto = (netoCustomer?: NetoCustomerModel, myobCustomer?: MyobCustomerModel) => {
    let addresses = { BillingAddress: {} as any, ShippingAddress: {} as any };
    if(netoCustomer && myobCustomer && !_.isEmpty(myobCustomer?.Addresses)){
        for (let index = 0; index < myobCustomer?.Addresses?.length; index++) {
            if (index == 0) {
                const address = myobCustomer?.Addresses[index];
                addresses.BillingAddress = address;
                netoCustomer.EmailAddress = !_.isEmpty(address?.Email) ? address.Email : "";
            } else if (index == 1) {
                const address = myobCustomer.Addresses[index];
                addresses.ShippingAddress = address;
                netoCustomer.SecondaryEmailAddress = !_.isEmpty(address?.Email) ? address.Email : "";
            } else {
                break;
            }
        }
    }
    return addresses;
}

const transformNetoCustomerModel = (netoCustomer?: NetoCustomerModel) => {
    if(netoCustomer){
        return {...netoCustomer, ActiveText: netoCustomer?.Active === "True" ? "Active" : "InActive"};
    }

    return {} as NetoCustomerModel;
}

const transformMyobCustomerModel = (myobCustomer?: MyobCustomerModel) => {
    if(myobCustomer){
        return {...myobCustomer, IsActiveText: myobCustomer?.IsActive === true ? "Active" : "InActive"};
    }

    return {} as MyobCustomerModel;
}

const filterConnectorLabels = (connectors?: Array<QueryDocumentSnapshot<DocumentData>>) => {
    const labels: Array<ValueLabel> = [];
    connectors?.map((doc: QueryDocumentSnapshot<DocumentData>) => {
        labels.push({ label: doc.data()?.label, value: doc.id });
    });
    return labels;
}
