import { Message } from 'primereact/message';
import _ from "lodash";
import firebase from "../shared/config/firebaseConfig";
import {
  QueryUserConfigurationModel,
  QueryMappingLookupsModel,
  SynchronizeProcessModel,
  QueryItemsModel,
} from "./DataServiceModels";
import { getIdTokenFromLocalStorage } from "./DataService";
import {
  API_SERVER_URL,
  DATABASE_COLLECTIONS,
  CLOUD_FUNCTIONS,
} from "../shared/config/constants";
import { resolve } from "path";
import { UserMappingModel } from "../shared/models/mappings/UserMappingModels";
import { writeException } from "../shared/services/LoggingService";

const { USER_DETAILS, CONNECTORS } = DATABASE_COLLECTIONS;
const {
  GET_UI_MAPPING,
  ORDER_PAYMENT_SYNC_MYOB_TO_NETO,
  NETO_RMA_SYNC_TASK,
  ORDER_PAYMENT_SYNC_NETO_TO_MYOB,
  PRODUCT_SYNC_NETO_TO_MYOB_TASK,
  PRODUCT_SYNC_MYOB_TO_NETO,
  CUSTOMER_SYNC_MYOB_TO_NETO,
  EXISTING_CUSTOMERS_MAPPING,
  EXISTING_ORDER_IMPORTING,
  EXISTING_PRODUCTS_IMPORTING,
  GET_UI_MAPPING_ITEMS,
  PROCESS_BULK_ORDERS,
  LOGGING,
} = CLOUD_FUNCTIONS;
const dbRef = firebase.firestore();

export const getMappingsFormLookups = async (
  userId: string,
  connectorId: string
): Promise<QueryMappingLookupsModel> => {
  return new Promise(async (resolve, reject) => {
    try {
      const idToken = await getIdTokenFromLocalStorage();
      let res: Response = await fetch(
        `${API_SERVER_URL}/${GET_UI_MAPPING}?userId=${userId}&connectorId=${connectorId}`,
        {
          headers: {
            Authorization: `${idToken}`,
          },
        }
      );

      if (res.ok) {
        const response = <QueryMappingLookupsModel>await res.json();
        resolve(response);
      } else {
        const response = <QueryMappingLookupsModel>await res.json();
        resolve(response);
      }
    } catch (e: any) {
      resolve(<QueryMappingLookupsModel>{
        isSuccess: false,
        message: e.message,
      });
      await writeException(`ErrorMessage: ${e.message}, UserID: ${userId}, ConnectorID: ${connectorId}`);      
    }
  });
};

export const getUserConnectorConfigurations = async (
  userId: string,
  connectorId: string
): Promise<QueryUserConfigurationModel> => {
  return new Promise(async (resolve, reject) => {
    let connectorDoc = dbRef
      .collection(`${USER_DETAILS}`)
      .doc(userId)
      .collection(`${CONNECTORS}`)
      .doc(connectorId);

    const userConfiguration = <QueryUserConfigurationModel>{ isSucceed: true };
    userConfiguration.result = (
      await connectorDoc.get()
    ).data() as typeof userConfiguration.result;
    resolve(userConfiguration);
  });
};

export const synchronizeMyobPaymentsToNeto = async (
  userId: string,
  connectorId: string
): Promise<SynchronizeProcessModel> => {
  return new Promise(async (resolve, reject) => {
    try {
      const idToken = await getIdTokenFromLocalStorage();
      let res: Response = await fetch(
        `${API_SERVER_URL}/${ORDER_PAYMENT_SYNC_MYOB_TO_NETO}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${idToken}`,
          },
          body: JSON.stringify({
            userId: userId,
            connectorId: connectorId,
          }),
        }
      );

      if (res.ok) {
        let response = await res.json();
        resolve({ isSucceed: true, message: response?.message });
      } else {
        let response = await res.json();
        resolve({ isSucceed: false, message: response?.message });
      }
    } catch (error: any) {
      resolve({ isSucceed: false, message: error.message });
      await writeException(`ErrorMessage: ${error.message}, UserID: ${userId}, ConnectorID: ${connectorId}`);
    }
  });
};

export const synchronizeNetoRmaToMyob = async (
  userId: string,
  connectorId: string
): Promise<SynchronizeProcessModel> => {
  return new Promise(async (resolve, reject) => {
    try {
      const idToken = await getIdTokenFromLocalStorage();
      let result = await fetch(`${API_SERVER_URL}/${NETO_RMA_SYNC_TASK}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${idToken}`,
        },
        body: JSON.stringify({
          userId: userId,
          connectorId: connectorId,
        }),
      });

      if (result.ok) {
        let response = await result.json();
        resolve({ isSucceed: true, message: response?.message });
      } else {
        let response = await result.json();
        resolve({ isSucceed: false, message: response?.message });
      }
    } catch (error: any) {
      resolve({ isSucceed: false, message: error.message });
      await writeException(`ErrorMessage: ${error.message}, UserID: ${userId}, ConnectorID: ${connectorId}`);
    }
  });
};

export const synchronizeNetoPaymentsToMyob = async (
  userId: string,
  connectorId: string
): Promise<SynchronizeProcessModel> => {
  return new Promise(async (resolve, reject) => {
    try {
      const idToken = await getIdTokenFromLocalStorage();
      let result = await fetch(
        `${API_SERVER_URL}/${ORDER_PAYMENT_SYNC_NETO_TO_MYOB}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${idToken}`,
          },
          body: JSON.stringify({
            userId: userId,
            connectorId: connectorId,
          }),
        }
      );

      if (result.ok) {
        let response = await result.json();
        resolve({ isSucceed: true, message: response?.message });
      } else {
        let response = await result.json();
        resolve({ isSucceed: false, message: response?.message });
      }
    } catch (error: any) {
      resolve({ isSucceed: false, message: error.message });
      await writeException(`ErrorMessage: ${error.message}, UserID: ${userId}, ConnectorID: ${connectorId}`);

    }
  });
};

export const synchronizeNetoToMyobProducts = async (
  userId: string,
  connectorId: string
): Promise<SynchronizeProcessModel> => {
  return new Promise(async (resolve, reject) => {
    try {
      const idToken = await getIdTokenFromLocalStorage();
      let res = await fetch(
        `${API_SERVER_URL}/${PRODUCT_SYNC_NETO_TO_MYOB_TASK}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${idToken}`,
          },
          body: JSON.stringify({
            userId: userId,
            connectorId: connectorId,
          }),
        }
      );

      if (res.ok) {
        let response = await res.json();
        resolve({ isSucceed: true, message: response?.message });
      } else {
        let response = await res.json();
        resolve({ isSucceed: false, message: response?.message });
      }
    } catch (error: any) {
      resolve({ isSucceed: false, message: error.message });
      await writeException(`ErrorMessage: ${error.message}, UserID: ${userId}, ConnectorID: ${connectorId}`);
    }
  });
};

export const synchronizeMyobProductsToNeto = async (
  userId: string,
  connectorId: string
): Promise<SynchronizeProcessModel> => {
  return new Promise(async (resolve, reject) => {
    try {
      const idToken = await getIdTokenFromLocalStorage();
      let result = await fetch(
        `${API_SERVER_URL}/${PRODUCT_SYNC_MYOB_TO_NETO}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${idToken}`,
          },
          body: JSON.stringify({
            userId: userId,
            connectorId: connectorId,
          }),
        }
      );

      if (result.ok) {
        let response = await result.json();
        resolve({ isSucceed: true, message: response?.message });
      } else {
        let response = await result.json();
        resolve({ isSucceed: false, message: response?.message });
      }
    } catch (error: any) {
      resolve({ isSucceed: false, message: error.message });
      await writeException(`ErrorMessage: ${error.message}, UserID: ${userId}, ConnectorID: ${connectorId}`);
    }
  });
};

export const synchronizeMyobCustomersToNeto = async (
  userId: string,
  connectorId: string
): Promise<SynchronizeProcessModel> => {
  return new Promise(async (resolve, reject) => {
    try {
      const idToken = await getIdTokenFromLocalStorage();
      let res = await fetch(`${API_SERVER_URL}/${CUSTOMER_SYNC_MYOB_TO_NETO}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${idToken}`,
        },
        body: JSON.stringify({
          userId: userId,
          connectorId: connectorId,
        }),
      });

      if (res.ok) {
        let response = await res.json();
        resolve({ isSucceed: true, message: response?.message });
      } else {
        let response = await res.json();
        resolve({ isSucceed: false, message: response?.message });
      }
    } catch (error: any) {
      resolve({ isSucceed: false, message: error.message });
      await writeException(`ErrorMessage: ${error.message}, UserID: ${userId}, ConnectorID: ${connectorId}`);
    }
  });
};

export const synchronizeExistingCustomers = async (
  userId: string,
  connectorId: string
): Promise<SynchronizeProcessModel> => {
  return new Promise(async (resolve, reject) => {
    try {
      const idToken = await getIdTokenFromLocalStorage();
      let res = await fetch(`${API_SERVER_URL}/${EXISTING_CUSTOMERS_MAPPING}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${idToken}`,
        },
        body: JSON.stringify({
          userId: userId,
          connectorId: connectorId,
        }),
      });

      if (res.ok) {
        let response = await res.json();
        resolve({ isSucceed: true, message: response?.message });
      } else {
        let response = await res.json();
        resolve({ isSucceed: false, message: response?.message });
      }
    } catch (error: any) {
      resolve({ isSucceed: false, message: error.message });
      await writeException(`ErrorMessage: ${error.message}, UserID: ${userId}, ConnectorID: ${connectorId}`);
    }
  });
};

export const synchronizeExistingOrders = async (
  userId: string,
  connectorId: string
): Promise<SynchronizeProcessModel> => {
  return new Promise(async (resolve, reject) => {
    try {
      const idToken = await getIdTokenFromLocalStorage();
      let res = await fetch(`${API_SERVER_URL}/${EXISTING_ORDER_IMPORTING}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${idToken}`,
        },
        body: JSON.stringify({
          userId: userId,
          connectorId: connectorId,
        }),
      });

      if (res.ok) {
        let response = await res.json();
        resolve({ isSucceed: true, message: response?.message });
      } else {
        let response = await res.json();
        resolve({ isSucceed: false, message: response?.message });
      }
    } catch (error: any) {
      resolve({ isSucceed: false, message: error.message });
      await writeException(`ErrorMessage: ${error.message}, UserID: ${userId}, ConnectorID: ${connectorId}`);
    }
  });
};

export const synchronizeExistingProducts = async (
  userId: string,
  connectorId: string
): Promise<SynchronizeProcessModel> => {
  return new Promise(async (resolve, reject) => {
    try {
      const idToken = await getIdTokenFromLocalStorage();
      let res = await fetch(
        `${API_SERVER_URL}/${EXISTING_PRODUCTS_IMPORTING}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${idToken}`,
          },
          body: JSON.stringify({
            userId: userId,
            connectorId: connectorId,
          }),
        }
      );

      if (res.ok) {
        let response = await res.json();
        resolve({ isSucceed: true, message: response?.message });
      } else {
        let response = await res.json();
        resolve({ isSucceed: false, message: response?.message });
      }
    } catch (error: any) {
      resolve({ isSucceed: false, message: error.message });
      await writeException(`ErrorMessage: ${error.message}, UserID: ${userId}, ConnectorID: ${connectorId}`);
    }
  });
};

export const synchronizeOrdersOptions = async (
  userId: string,
  connectorId: string
) :  Promise<SynchronizeProcessModel> => {
  return new Promise(async (resolve, reject) => {
  try {
    const idToken = await getIdTokenFromLocalStorage();
    let res = await fetch(
      `${API_SERVER_URL}/${PROCESS_BULK_ORDERS}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `${idToken}`
        },
        body: JSON.stringify({
          userId: userId,
          connectorId: connectorId,
        }),
      }
    );

    if (res.ok) {
      let response = await res.json();
      resolve({ isSucceed: true, message: response?.message });
    } else {
      let response = await res.json();
      resolve({ isSucceed: false, message: response?.message });
    }
  } catch (error: any) {
    await writeException(`ErrorMessage: ${error.message}, UserID: ${userId}, ConnectorID: ${connectorId}`);  
    return { isSucceed: false, message: error.message };
  }
  }
)};

export const getMappingItemsByQuery = async (
  userId: string,
  connectorId: string,
  text: string
): Promise<QueryItemsModel> => {
  return new Promise(async (resolve, reject) => {
    try {
      const idToken = await getIdTokenFromLocalStorage();
      let result = await fetch(
        `${API_SERVER_URL}/${GET_UI_MAPPING_ITEMS}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `${idToken}`
          },
          body: JSON.stringify({
            userId: userId,
            connectorId: connectorId,
            searchValue: text
          }),
        }
      );

      if (result.ok) {
        let response = await result.json();
        resolve({ isSucceed: true, result: response?.result });
      } else {
        let response = await result.json();
        resolve({ isSucceed: false, message: response?.message });
      }
    } catch (error: any) {
      resolve({ isSucceed: false, message: error.message });
      await writeException(`ErrorMessage: ${error.message}, UserID: ${userId}, ConnectorID: ${connectorId}`);
    }
  });
};

export const saveMappings = async (
  userId: string,
  connectorId: string,
  data: any,
  lastModifiedData: any
): Promise<SynchronizeProcessModel> => {
  return new Promise(async (resolve, reject) => {
    try {
      const mappingsRef = dbRef
        .collection(`${USER_DETAILS}`)
        .doc(userId)
        .collection(`${CONNECTORS}`)
        .doc(connectorId);
      mappingsRef.update({
        isMappingsEmpty: false,
        mappings: data,
        lastModifiedData
      });
      resolve({ isSucceed: true, message: "Mappings Stored Successfully!" });
    } catch (e: any) {
      resolve({ isSucceed: false, message: e.message });
      await writeException(`ErrorMessage: ${e.message}, UserID: ${userId}, ConnectorID: ${connectorId}`);
    }
  });
};

