import React from "react";
import { useAppState } from "./AppStore";
import { CurrentUser, IAppState, SalesOrder } from "./IAppState";
import { FormsLookup } from '../shared/models/mappings/FormLookups';
import { UserMappingModel } from '../shared/models/mappings/UserMappingModels';
import { AppContext } from "./AppContext";

export interface IAppProviderProps {
    children: React.ReactNode;
}

export const AppProvider = ({ children } : IAppProviderProps) => {
    const initAppState: IAppState = {
        formsLookup: {} as FormsLookup,
        userMappings: {} as UserMappingModel,
        isLoading: false,
        requestStatus: {},
        currentUser: {} as CurrentUser,
        userMyobToNetoMappings: {},
        salesOrder: {} as SalesOrder,
        selectedConnectorOnGrid: ""
    }
    const [State, Dispatch] = useAppState(initAppState);    

    return <AppContext.Provider value={{State:State, Dispatch}}>
        {children}
    </AppContext.Provider>

}