import React, { useEffect, useState, useContext } from "react";
import _ from "lodash";
import TimezoneSelect from 'react-timezone-select';
import { useParams } from 'react-router-dom';
import Label from '../../../../components/Label'
import SelectInput from '../../../../components/SelectInput';
import Boolean from '../../../../components/Boolean';
import Datepicker from '../../../../components/Datepicker';
import { AppContext } from '../../../../store/AppContext';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { SynchronizationFeaturesMappingsModel } from "../../../../shared/models/mappings/UserMappingModels";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { synchronizationOrders } from '../services/MappingService';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface ISyncronizationFeaturesProps {
    syncFeatures: SynchronizationFeaturesMappingsModel,
    setSyncFeatures: (value: SynchronizationFeaturesMappingsModel) => void,
    errors?: any,
    touched?: any,
    onInputBlurred: (obj: any) => void,
    isMappingsEmpty: boolean,
    submitCount: any
}

const SyncronizationFeatures: React.FC<ISyncronizationFeaturesProps> = (props) => {
    let { State, Dispatch } = useContext(AppContext);
    const { id } = useParams<any>();
    const [synchronizationFeatures, setSynchronizationFeatures] = useState<SynchronizationFeaturesMappingsModel>({} as SynchronizationFeaturesMappingsModel);
    const [synchronizationHasError, setSynchronizationHasError] = useState<boolean>(false);
    const [synchronizeOrdersLoading, setSynchronizeOrdersLoading] = useState<boolean>(false);
    const [isTabActive, setIsTabActive] = useState<number | undefined>(undefined);

    const headerTemplate = (headingText: string, hasError: boolean, smallText?: string) => (<React.Fragment><div className="d-flex"><h6>{headingText} {smallText && <small>({smallText})</small>}</h6>
        {hasError && <small className="ml-3 mt-1 text-danger">(There are some validation errors)</small>}</div></React.Fragment>);

    let [touchedFields, setTouchedFields] = useState(props.touched);

    const synchronizeOrders = async () => {
        setSynchronizeOrdersLoading(true);
        let result = await synchronizationOrders(State?.currentUser?.currentUserId, id);

        if(result?.isSucceed){
            Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: true, message: result?.message} });
            setSynchronizeOrdersLoading(false);
        }
        else{
            Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: false, message: result?.message} });
            setSynchronizeOrdersLoading(false);
        }
    }

    const onInputBlur = (touched: any) => {
        props.onInputBlurred(touched);
    }

    useEffect(() => {
        setTouchedFields(props.touched);
    }, [props.touched]);

    useEffect(() => {
        setSynchronizationFeatures(props.syncFeatures);
    }, [props.syncFeatures]);

    const header = headerTemplate("Syncronization Options", synchronizationHasError);

    useEffect(() => {
        //#region Syncronization Options
        const synchronizationFeatures = props.errors?.synchronizationFeatures ? Object.keys(props.errors?.synchronizationFeatures) : [];

        if (props.submitCount > 0 && ((props.touched?.synchronizationFeatures?.accountTimezone && props.errors?.synchronizationFeatures?.accountTimezone)
            || (props.touched?.synchronizationFeatures?.integrationsStartingDate && props.errors?.synchronizationFeatures?.integrationsStartingDate)
            || (props.touched?.synchronizationFeatures?.sendSyncReport && props.errors?.synchronizationFeatures?.sendSyncReport)
            // || (props.touched?.synchronizationFeatures?.netoToMyobCustomerFrequency && props.errors?.synchronizationFeatures?.netoToMyobCustomerFrequency)
            // || (props.touched?.synchronizationFeatures?.netoToMyobProductFrequency && props.errors?.synchronizationFeatures?.netoToMyobProductFrequency)
            // || (props.touched?.synchronizationFeatures?.myobToNetoCustomerFrequency && props.errors?.synchronizationFeatures?.myobToNetoCustomerFrequency)
            // || (props.touched?.synchronizationFeatures?.myobToNetoProductFrequency && props.errors?.synchronizationFeatures?.myobToNetoProductFrequency)
            // || (props.touched?.synchronizationFeatures?.myobToNetoPaymentFrequency && props.errors?.synchronizationFeatures?.myobToNetoPaymentFrequency)
        )) {
            setSynchronizationHasError(true);
            setIsTabActive(0);
        }
        // else if(!Object.keys(props.errors)?.includes('synchronizationFeatures')){
        //     setSynchronizationHasError(false);
         else if((!synchronizationFeatures.includes('accountTimezone')) ||
         (!synchronizationFeatures.includes('integrationsStartingDate')) ||
         (!synchronizationFeatures.includes('sendSyncReport'))){
            setSynchronizationHasError(false);
        }
        //#endregion
    }, [props.touched?.synchronizationFeatures, props.errors?.synchronizationFeatures])

    const setSyncFeatures = () => {
        props.setSyncFeatures(synchronizationFeatures);
    }

    return (
        <div className="container-fluid">
            <Accordion activeIndex={isTabActive}>
                <AccordionTab header={header}>
                    <React.Fragment>
                        <div className="row">
                            <div className=" col d-flex justify-content-between">
                                <Boolean
                                    hasError={
                                        props?.touched?.synchronizationFeatures?.isChecked &&
                                            props?.errors?.synchronizationFeatures?.isChecked ? true : false
                                    }
                                    text={'Synchronize from MYOB AccountRight to Maropost'}
                                    value={synchronizationFeatures.isChecked}
                                    onChange={(val: boolean) => setSynchronizationFeatures({ ...synchronizationFeatures, isChecked: val })}
                                    onBlur={(e: any) => {
                                        onInputBlur({
                                            ...props?.touched, synchronizationFeatures: {
                                                ...props?.touched.synchronizationFeatures, isChecked: true
                                            }
                                        });
                                        if (props?.syncFeatures?.isChecked != synchronizationFeatures.isChecked)
                                            setSyncFeatures();
                                    }}
                                />
                            </div>
                            <button disabled={props.isMappingsEmpty ? true : (!synchronizationFeatures.isChecked || synchronizeOrdersLoading)}
                                onClick={synchronizeOrders}
                                type="button" className="btn btn-dark btn-sm">Sync Orders
                                <FontAwesomeIcon spin={synchronizeOrdersLoading} className="ml-1" icon={faSync} />
                            </button>
                        </div>
                        <br />
                        <div className="row text-left ml-2">
                            <div className="col-6">
                                <h6>Maropost</h6>
                            </div>
                            <div className="col-6">
                                <h6>MYOB AccountRight</h6>
                            </div>
                        </div>
                        <hr />
                        <br />
                        <div className="row">
                            <div className="col-6 d-flex">
                                <Label text={'Account Timezone'} /> <p className="text-danger">*</p>
                            </div>
                            <div className="col-6">
                                <TimezoneSelect className={`text-left ${props.touched?.synchronizationFeatures?.accountTimezone &&
                                    props.errors?.synchronizationFeatures?.accountTimezone ? 'border border-danger' : ''}`}
                                    value={synchronizationFeatures.accountTimezone ?? ''}
                                    onChange={(val: any) => setSynchronizationFeatures({ ...synchronizationFeatures, accountTimezone: val?.value ?? '' })}
                                    onBlur={(e: any) => {
                                        onInputBlur({
                                            ...props?.touched, synchronizationFeatures: {
                                                ...props?.touched.synchronizationFeatures, accountTimezone: true
                                            }
                                        });
                                        if (props.syncFeatures?.accountTimezone !== synchronizationFeatures.accountTimezone)
                                            setSyncFeatures();
                                    }}
                                />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-6 d-flex">
                                <Label text={'Integrations Starting Date'} /> <p className="text-danger">*</p>
                            </div>
                            <div className="col-6">
                                <Datepicker
                                    hasError={
                                        props.touched?.synchronizationFeatures?.integrationsStartingDate &&
                                            props.errors?.synchronizationFeatures?.integrationsStartingDate ? true : false}
                                    selectedDate={synchronizationFeatures.integrationsStartingDate}
                                    onChange={(val: string) => setSynchronizationFeatures({ ...synchronizationFeatures, integrationsStartingDate: val })}
                                    onBlur={(e: any) => {
                                        onInputBlur({
                                            ...props?.touched, synchronizationFeatures: {
                                                ...props?.touched.synchronizationFeatures, integrationsStartingDate: true
                                            }
                                        });
                                        if (props.syncFeatures?.integrationsStartingDate !== synchronizationFeatures.integrationsStartingDate)
                                            setSyncFeatures();
                                    }}
                                />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-6 d-flex">
                                <Label text={'Send me a sync report email'} /> <p className="text-danger">*</p>
                            </div>
                            <div className="col-6">
                                <SelectInput
                                    hasError={
                                        props.touched?.synchronizationFeatures?.sendSyncReport &&
                                            props.errors?.synchronizationFeatures?.sendSyncReport ? true : false
                                    }
                                    options={State.formsLookup?.synchronizationFeatures?.syncReportEmail?.values!}
                                    selectedOptionValue={synchronizationFeatures.sendSyncReport}
                                    onChange={(val: string) => setSynchronizationFeatures({ ...synchronizationFeatures, sendSyncReport: val })}
                                    onBlur={(e: any) => {
                                        onInputBlur({
                                            ...props?.touched, synchronizationFeatures: {
                                                ...props?.touched.synchronizationFeatures, sendSyncReport: true
                                            }
                                        });
                                        if (props.syncFeatures?.sendSyncReport !== synchronizationFeatures.sendSyncReport)
                                            setSyncFeatures();
                                    }}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                </AccordionTab>
            </Accordion>
        </div>
    )
}

export default SyncronizationFeatures
