import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../../store/AppContext";
import _ from "lodash";
import { TabView, TabPanel } from 'primereact/tabview';
import Label from "../../components/Label";
import SelectInput from "../../components/SelectInput";
import { ValueLabel } from "../../shared/models";
import { getAllConnectorLabels } from "./services/OrderService";
import { SalesOrderGridModel } from "./models";
import * as util from '../../shared/Utilities'
import { getOrdersFromFirebase, getFailedOrdersFromFirebaseOld } from "../../dataServices";
import { Subscription } from "rxjs";
import OrderGridComponent from "./components/OrderGridComponent";
import OrderGridErrorComponent from "./components/OrderGridErrorComponent";


export const useDebouncedEffect = (effect: any, deps: any, delay: number) => {
    useEffect(() => {
        const handler = setTimeout(() => effect(), delay);

        return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps || [], delay]);
}

const OrderGridContainer: React.FC = () => {
    let { State, Dispatch } = useContext(AppContext);
    const [selectedConnector, setSelectedConnector] = useState<string>(State.selectedConnectorOnGrid ?? "");
    const [connectors, setConnectors] = useState<Array<ValueLabel>>([{ label: "", value: "" }]);
    const [selectedOrders, setSelectedOrders] = useState<Array<SalesOrderGridModel>>();
    const [salesOrderData, setSalesOrderData] = useState<any>([]);
    const [failedSalesOrderData, setFailedSalesOrderData] = useState<any>([]);
    const [startAfterRecord, setStartAfterRecord] = useState<any>();
    const [startBeforeRecord, setStartBeforeRecord] = useState<any>();
    const [activeTab, setActiveTab] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [fLoading, setFLoading] = useState<boolean>(false);
    const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);
    const [isPrevDisabled, setIsPrevDisabled] = useState<boolean>(false);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 5,
        page: 1,
        sortField: "Date",
        sortOrder: -1,
        filters: undefined,
        pageMode: '',
        pageSize: 50,
    });
    const filterFields = {
        netoOrderID: "IncomingOrder.OrderID",
        myobOrderID: "OutgoingOrder.Number",
        myobCustomer: "OutgoingOrder.Customer.Name",
        netoCustomer: "IncomingOrder.Username",
        myobStatus: "OutgoingOrder.Status",
        netoStatus: "IncomingOrder.OrderStatus",
        status: "Status",
        date: "Date",
    }
    let getOrdersFromFirebaseObserver: Subscription;

    useEffect(() => {
        console.log("CALLED first effect");
        (async () => {
            setLoading(true);
            const connectorsList = await getAllConnectorLabels(State.currentUser.currentUserId);
            setLoading(false);
            if (State.currentUser.currentUserId) {
                setSelectedConnector(connectorsList.length && connectorsList[0].value ? connectorsList[0].value : "");
            }
            setConnectors(connectorsList);
        })();
        return () => {
            Dispatch({
                Type: "SET_SALES_ORDER",
                Payload: { connector: "", data: [] },
            });
        };
    }, []);

    useEffect(() => {
        // if(activeTab === 0){
        //     setLoading(true); 
        //     processPagination();
        // } else { 
        //     connectorsSnapshot(selectedConnector);
        // }
        triggerPagination();
    }, [activeTab])

    useEffect(() => {
        setLoading(true); 
        setLazyParams({
            first: 0,
            rows: 5,
            page: 1,
            sortField: "Date",
            sortOrder: -1,
            filters: undefined,
            pageMode: '',
            pageSize: 50,
        });
        setSalesOrderData([]);
        setFailedSalesOrderData([]);
        // triggerPagination(); 
    }, [selectedConnector]);

    useDebouncedEffect(() => {
        setLoading(true); 
        processPagination();
    }, [lazyParams], 1000);
    
    useDebouncedEffect(() => { 
        // setLoading(true); 
        triggerPagination(); 
    }, [selectedConnector], 1000);

    const triggerPagination = () => {
        if(activeTab === 1){
            console.log("salesOrderData length", salesOrderData.length)
            if(_.isEmpty(salesOrderData)){
                setLoading(true); 
                processPagination();
            } else {
                const salesOrderDataCopy = [...salesOrderData];
                setSalesOrderData(salesOrderDataCopy);
            }
        } else {
            // if(_.isEmpty(failedSalesOrderData)){ 
                connectorsSnapshot(selectedConnector);
            // } else {
                // setFailedSalesOrderData(failedSalesOrderData);
            // }
        }
    }

    const connectorsSnapshot = async (connector: string) => {
        setFLoading(true);
        if (!_.isEmpty(connector)) {
            getOrdersFromFirebaseObserver = getFailedOrdersFromFirebaseOld(State.currentUser.currentUserId, selectedConnector).subscribe((e) => {
                setFailedSalesOrderData(e);
                setFLoading(false);
                // Dispatch({
                //     Type: "SET_SALES_ORDER",
                //     Payload: { connector: connector, data: [] },
                // });
            });
        }
        else {
            setFLoading(false);
        }
    };

    const processPagination = () => {
        if (State?.currentUser?.currentUserId && selectedConnector) {
            let filtersArray : any = [];
            if (lazyParams?.filters) {
                const filters: any = lazyParams.filters;
                if (filters?.myobCustomer) {
                    if(!_.isEmpty(filters?.myobCustomer?.value))
                        filtersArray.push({ key: filterFields.myobCustomer, value: filters?.myobCustomer?.value })
                }

                if (filters?.netoCustomer) {
                    if(!_.isEmpty(filters?.netoCustomer?.value ))
                       filtersArray.push({ key: filterFields.netoCustomer, value: filters?.netoCustomer?.value })
                }

                if (filters?.netoOrderId) {
                    if(!_.isEmpty(filters?.netoOrderId?.value))
                       filtersArray.push({ key: filterFields.netoOrderID, value: filters?.netoOrderId?.value })
                }

                if (filters?.myobOrderId) {
                    if(!_.isEmpty(filters?.myobOrderId?.value))
                       filtersArray.push({ key: filterFields.myobOrderID, value: filters?.myobOrderId?.value })
                }

                if (filters?.netoOrderStatus) {
                    if(!_.isEmpty(filters?.netoOrderStatus?.value))
                        filtersArray.push({ key: filterFields.netoStatus, value: filters?.netoOrderStatus?.value })
                }

                if (filters?.myobOrderStatus) {
                    if(!_.isEmpty(filters?.myobOrderStatus?.value))
                        filtersArray.push({ key: filterFields.myobStatus, value: filters?.myobOrderStatus?.value })
                }

                if (filters['status.orderStatus']) {
                    if(!_.isEmpty(filters['status.orderStatus']?.value))
                        filtersArray.push({ key: filterFields.status, value: filters['status.orderStatus']?.value })
                }

                if (filters['datePlaced']) {
                    if(filters['datePlaced']?.value)
                       filtersArray.push({ key: filterFields.date, value: util.getUserTimezoneStartAndEndOfADay(filters['datePlaced']?.value) });
                }
            }

            // const pageMode = limit ? (limit === 1 ? 'ALL' : paginationMode) : onPageRows === 1 ? 'ALL' : paginationMode;
            // console.log("PAGE MODE: ", pageMode);

            // let pageMode = '';
            // if(isRowsChanged && isRowsChanged === true){
            //     pageMode = limit && limit === 1 ? 'ALL' : '';
            // } else {
            //     pageMode = onPageRows === 1 ? 'ALL' : paginationMode
            // }

            getOrdersFromFirebaseObserver = getOrdersFromFirebase(State.currentUser.currentUserId,
                selectedConnector, lazyParams.pageSize, lazyParams?.sortField, lazyParams?.sortOrder, 
                startBeforeRecord, startAfterRecord, lazyParams.pageMode, filtersArray).subscribe((e: any) => {

                    if (e?.ordersData && e?.ordersData.length > 0) {
                        setSalesOrderData(e?.ordersData);
                        if (e?.startAfter) {
                            setStartAfterRecord(e?.startAfter);
                        }
                        if (e?.startBefore) {
                            setStartBeforeRecord(e?.startBefore);
                        }

                        if(lazyParams.pageMode === 'ALL'){
                            setIsNextDisabled(true);
                            setIsPrevDisabled(true);
                        } else {
                            setIsNextDisabled(false);
                            setIsPrevDisabled(false);
                        }

                        setLoading(false);
                    }
                    else {
                        if(lazyParams.pageMode === 'ALL'){
                            setSalesOrderData([]);
                            setIsNextDisabled(true);
                            setIsPrevDisabled(true);
                        } else if(lazyParams.pageMode === 'PREV'){
                            setIsPrevDisabled(true);
                        } else if(lazyParams.pageMode === 'NEXT'){
                            setIsNextDisabled(true);
                        } else {
                            setSalesOrderData([]);
                            setIsNextDisabled(true);
                            setIsPrevDisabled(true);
                        }

                        setLoading(false);
                    }
                });

            util.getTimezoneFromFirebase(
                State?.currentUser?.isAdminUser && localStorage.SelectedIdByAdmin ? localStorage.SelectedIdByAdmin : State.currentUser.currentUserId,
                selectedConnector
            );
        };
    }

    return (
        <div className="container-fluid">
            <div className="row mt-5">
                <div className="col-3">
                    <Label text={"Select Connector: "} />
                </div>
                <div className="col-9">
                    <SelectInput
                        options={connectors}
                        selectedOptionValue={selectedConnector}
                        onChange={(connector: string) => {
                            Dispatch({
                                Type: "SET_SELECTED_CONNECTOR",
                                Payload: { id: connector },
                            });
                            setSelectedConnector(connector)
                            setSelectedOrders([])
                        }}
                    />
                </div>
            </div>
            <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
                <TabPanel header={<b>Failed Orders</b>}>
                    <OrderGridErrorComponent 
                        loading={fLoading}
                        orderData={failedSalesOrderData}
                        setLoading={setFLoading}
                        selectedConnector={selectedConnector} 
                    />
                </TabPanel>
                <TabPanel header={<b>Processed Orders</b>}>
                    <OrderGridComponent 
                        orderData={salesOrderData}
                        isNextDisabled={isNextDisabled}
                        isPrevDisabled={isPrevDisabled}
                        lazyParams={lazyParams}
                        loading={loading}
                        setLoading={setLoading}
                        selectedConnector={selectedConnector}
                        setLazyParams={setLazyParams}
                        setStartAfterRecord={setStartAfterRecord}
                        setStartBeforeRecord={setStartBeforeRecord}
                    />
                </TabPanel>
            </TabView>
        </div>
    );
}


export default OrderGridContainer
