import firebase from "firebase";
import { DATABASE_COLLECTIONS } from '../../../shared/config/constants';
const { USER_DETAILS } = DATABASE_COLLECTIONS;
const db = firebase.firestore();


const isUserActive = async (userId: string) => {
    let res = await db.collection(`${USER_DETAILS}`).doc(userId).get();
    const user = res.data();
    return user?.isActive ? user?.isActive : false
};

const isUserIsAddon = async (userId: string) => {
    let res = await db.collection(`${USER_DETAILS}`).doc(userId).get();
    const user = res.data();
    return user?.isAddonUser ? user?.isAddonUser : false
};

const setUserDataInStore = async (user: any, setUserData: React.Dispatch<React.SetStateAction<object>>, Dispatch: (action: any) => void) => {
    if (user) {
        const idToken = await user?.getIdToken(true);
        localStorage.setItem("idToken", idToken);
        const isSignInWithGoogle = user.providerData[0]?.providerId === "password" ? false : true;
        let idTokenResult = await user.getIdTokenResult();
        // localStorage.setItem("isAuth", `true`);
        const isActive = await isUserActive(user.uid);
        const isAddonUser = await isUserIsAddon(user.uid);
        const userObject = {
            currentUserId: user.uid,
            loggedIn: true,
            loggedOff: false,
            isUserHaveSubscription: true,
            isUserHaveSubscriptionError: "",
            isActive: isActive,
            isAdminUser: idTokenResult.claims.admin,
            isAddonUser: isAddonUser,
            currentUserEmail: user.email,
            isSignInWithGoogle,
            idToken
        }
        // localStorage.setItem('userData', JSON.stringify(userObject))
        setUserData(userObject);
        Dispatch({
            Type: "IS_USER_LOGGED_IN",
            Payload: userObject,
        });
    } else {
        Dispatch({
            Type: "IS_USER_LOGGED_OFF",
            Payload: {
                currentUserId: null,
                loggedIn: null,
                loggedOff: true,
            },
        });
        setUserData({ });
    }
}


export default setUserDataInStore