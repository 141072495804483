import * as React from "react";
import _ from "lodash";
import { DataTable } from "primereact/datatable";
import { Badge } from "primereact/badge";
import { Tooltip } from "primereact/tooltip";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { CustomerGridModel } from "./models";
import NetoImg from "../../assets/maropost-logo.svg";
import MyobImg from "../../assets/Myob.png";

const headerTemplateNeto = (
  <img src={NetoImg} alt="Neto" style={{ width: "4rem" }} />
);
const headerTemplateMyob = (
  <img src={MyobImg} alt="Myob" style={{ width: "4rem" }} />
);


export const emptyMessageTemplate = (
  <div className="text-center">
    <b>No Records Found!</b>
  </div>
);

export const lastSyncAtBodyTemplate = (rowData: CustomerGridModel) => {
  return (
    <React.Fragment>
      <div>
        {!_.isEmpty(rowData?.lastSyncAt) ? <p>{rowData?.lastSyncAt}</p> : ""}
      </div>
    </React.Fragment>
  );
};

export const netoStatusBodyTemplate = (
  rowData: CustomerGridModel,
  index: any
) => {
  return (
    <div>
      <Badge
        value={rowData?.NetoCustomer?.Active === "True" ? "Active" : "InActive"}
        severity={
          rowData?.NetoCustomer?.Active === "True" ? "success" : "danger"
        }
        className={`customer${index?.rowIndex} p-mr-2`}
      ></Badge>
    </div>
  );
};

export const myobStatusBodyTemplate = (
  rowData: CustomerGridModel,
  index: any
) => {
  return (
    <div>
      <Badge
        value={rowData?.MyobCustomer?.IsActive === true ? "Active" : "InActive"}
        severity={
          rowData?.MyobCustomer?.IsActive === true ? "success" : "danger"
        }
        className={`customer${index?.rowIndex} p-mr-2`}
      ></Badge>
    </div>
  );
};

export const netoErrorMessageTemplate = (
  rowData: CustomerGridModel,
  index: any
) => {
  return (
    <div>
      {_.isEmpty(rowData?.NetoCustomer?.syncErrorMessage) ? (
        ""
      ) : (
        <>
          <div className={`text-danger customer${index?.rowIndex} p-mr-2`}>
            {rowData?.NetoCustomer?.syncErrorMessage}
          </div>
          {/* <Tooltip
                        position="top"
                        target={`.customer${index?.rowIndex}`}
                        content={rowData?.NetoCustomer?.syncErrorMessage}
                    />
                    <Badge
                        value={'Error Occurred'}
                        severity={"danger"}
                        className={`customer${index?.rowIndex} p-mr-2`}
                    ></Badge> */}
        </>
      )}
    </div>
  );
};

export const myobErrorMessageTemplate = (
  rowData: CustomerGridModel,
  index: any
) => {
  return (
    <div>
      {_.isEmpty(rowData?.MyobCustomer?.syncErrorMessage) ? (
        ""
      ) : (
        <>
          <div className={`text-danger customer${index?.rowIndex} p-mr-2`}>
            {rowData?.MyobCustomer?.syncErrorMessage}
          </div>
          {/* <Tooltip
                        position="top"
                        target={`.customer${index?.rowIndex}`}
                        content={rowData?.MyobCustomer?.syncErrorMessage}
                    /> 
                    <Badge
                        value={'Error Occured'}
                        severity={"danger"}
                        className={`customer${index?.rowIndex} p-mr-2`}
                    ></Badge> */}
        </>
      )}
    </div>
  );
};

export const netoNotesLength = (rowData: CustomerGridModel, index: any) => {
  return (
    <>
      {_.isEmpty(rowData?.NetoCustomer?.InternalNotes) ? (
        ""
      ) : (
        <>
          <Tooltip
            position="top"
            target={`.notes${index?.rowIndex}`}
            content={rowData?.NetoCustomer?.InternalNotes}
          />
          <div
            className={`text-truncate overflow-hidden notes${index?.rowIndex} p-mr-2`}
            style={{ width: "5rem", height: "1rem", textOverflow: "ellipsis" }}
          >
            {rowData?.NetoCustomer?.InternalNotes}
          </div>
        </>
      )}
    </>
  );
};

export const myobNotesLength = (rowData: CustomerGridModel, index: any) => {
  return (
    <>
      {_.isEmpty(rowData?.MyobCustomer?.Notes) ? (
        ""
      ) : (
        <>
          <Tooltip
            position="top"
            target={`.notes${index?.rowIndex}`}
            content={rowData?.MyobCustomer?.Notes}
          />
          <div
            className={`text-truncate overflow-hidden notes${index?.rowIndex} p-mr-2`}
            style={{ width: "5rem", height: "1rem", textOverflow: "ellipsis" }}
          >
            {rowData?.MyobCustomer?.Notes}
          </div>
        </>
      )}
    </>
  );
};

export const rowExpansionTemplate = (data: Array<CustomerGridModel>) => {
  console.log("Data On Customer expand: ", data)
  return (
    <div>
      <DataTable autoLayout dataKey="NetoCustomer.ID" value={data}> 
      {/* id="nestedNetoViewTable"> */}
        <Column header={headerTemplateNeto} style={{ width: "8em" }} />
        <Column header="Username" field="NetoCustomer.Username" />
        <Column
          header="State"
          field="NetoCustomer.Active"
          body={netoStatusBodyTemplate}
        />
        <Column header="Balance" field="NetoCustomer.AccountBalance" />
        <Column header="Email Address" field="NetoCustomer.EmailAddress" />
        <Column
          header="Secondary Email Address"
          field="NetoCustomer.SecondaryEmailAddress"
        />
        <Column header="On Credit Hold" field="NetoCustomer.OnCreditHold" />
        <Column
          header="Internal Notes"
          field="NetoCustomer.InternalNotes"
          body={netoNotesLength}
        />
        <Column header="User Group" field="NetoCustomer.UserGroup" />
        <Column header="Type" field="NetoCustomer.Type" />
        <Column
          header="Billing First Name"
          field="NetoCustomer.BillingAddress.BillFirstName"
        />
        <Column
          header="Billing Last Name"
          field="NetoCustomer.BillingAddress.BillLastName"
        />
        <Column
          header="Billing Street Line"
          field="NetoCustomer.BillingAddress.BillStreetLine1"
        />
        <Column
          header="Billing Phone"
          field="NetoCustomer.BillingAddress.BillPhone"
        />
        <Column
          header="Billing Company"
          field="NetoCustomer.BillingAddress.BillCompany"
        />
        <Column
          header="Billing State"
          field="NetoCustomer.BillingAddress.BillState"
        />
        <Column
          header="Billing Post Code"
          field="NetoCustomer.BillingAddress.BillPostCode"
        />
        <Column
          header="Billing Country"
          field="NetoCustomer.BillingAddress.BillCountry"
        />
        <Column
          header="Shipping First Name"
          field="NetoCustomer.ShippingAddress.ShipFirstName"
        />
        <Column
          header="Shipping Last Name"
          field="NetoCustomer.ShippingAddress.ShipLastName"
        />
        <Column
          header="Shipping Street Line"
          field="NetoCustomer.ShippingAddress.ShipStreetLine1"
        />
        <Column
          header="Shipping Phone"
          field="NetoCustomer.ShippingAddress.ShipPhone"
        />
        <Column
          header="Shipping Company"
          field="NetoCustomer.ShippingAddress.ShipCompany"
        />
        <Column
          header="Shipping State"
          field="NetoCustomer.ShippingAddress.ShipState"
        />
        <Column
          header="Shipping Post Code"
          field="NetoCustomer.ShippingAddress.ShipPostCode"
        />
        <Column
          header="Shipping Country"
          field="NetoCustomer.ShippingAddress.ShipCountry"
        />
      </DataTable>
      <DataTable autoLayout dataKey="MyobCustomer.UID" value={data}> 
      {/* id="nestedMyobViewTable"> */}
        <Column header={headerTemplateMyob} style={{ width: "8em" }} />
        <Column header="Display ID" field="MyobCustomer.DisplayID" />
        <Column
          header="State"
          field="MyobCustomer.IsActive"
          body={myobStatusBodyTemplate}
        />
        <Column header="Balance" field="MyobCustomer.CurrentBalance" />
        <Column header="Name/Company" field="CompanyOrUser" />
        <Column header="First Name" field="MyobCustomer.FirstName" />
        <Column header="Last Name" field="MyobCustomer.LastName" />
        <Column
          header="Notes"
          field="MyobCustomer.Notes"
          body={myobNotesLength}
        />
        <Column header="Bank Account Name" field="MyobCustomer.PaymentDetails.BankAccountName" />
        <Column header="Bank Account Number" field="MyobCustomer.PaymentDetails.BankAccountNumber" />
        <Column header="Name On Card" field="MyobCustomer.PaymentDetails.NameOnCard" />
        <Column header="Card Number" field="MyobCustomer.PaymentDetails.CardNumber" />
        <Column header="BSB Number" field="MyobCustomer.PaymentDetails.BSBNumber" />
        <Column header="Method" field="MyobCustomer.PaymentDetails.Method" />
        <Column
          header="Foreign Currency"
          field="MyobCustomer.ForeignCurrency"
        />
        <Column
          header="UseCustomerTaxCode"
          field="MyobCustomer.UseCustomerTaxCode"
        />
        <Column
          header="Hourly Billing Rate"
          field="MyobCustomer.SellingDetails?.HourlyBillingRate"
        />
        <Column
          header="InvoiceDelivery"
          field="MyobCustomer.SellingDetails?.InvoiceDelivery"
        />
        <Column
          header="ItemPriceLevel"
          field="MyobCustomer.SellingDetails?.ItemPriceLevel"
        />
        <Column
          header="IncomeAccount"
          field="MyobCustomer.SellingDetails?.IncomeAccount"
        />
        <Column
          header="ReceiptMemo"
          field="MyobCustomer.SellingDetails?.ReceiptMemo"
        />
        <Column
          header="SalesPerson"
          field="MyobCustomer.SellingDetails?.SalesPerson"
        />
        <Column
          header="Billing Location"
          field="Address.BillingAddress?.Location"
        />
        <Column header="Billing Phone" field="Address.BillingAddress.Phone1" />
        <Column
          header="Billing Contact Name"
          field="Address.BillingAddress.ContactName"
        />
        <Column header="Billing Email" field="Address.BillingAddress.Email" />
        <Column header="Billing City" field="Address.BillingAddress.City" />
        <Column header="Billing State" field="Address.BillingAddress.State" />
        <Column
          header="Billing Post Code"
          field="Address.BillingAddress.PostCode"
        />
        <Column
          header="Billing Country"
          field="Address.BillingAddress.Country"
        />
        <Column
          header="Shipping Location"
          field="Address.ShippingAddress.Location"
        />
        <Column
          header="Shipping Phone"
          field="Address.ShippingAddress.Phone1"
        />
        <Column
          header="Shipping Contact Name"
          field="Address.ShippingAddress.ContactName"
        />
        <Column header="Shipping Email" field="Address.ShippingAddress.Email" />
        <Column header="Shipping City" field="Address.ShippingAddress.City" />
        <Column header="Shipping State" field="Address.ShippingAddress.State" />
        <Column
          header="Shipping Post Code"
          field="Address.ShippingAddress.PostCode"
        />
        <Column
          header="Shipping Country"
          field="Address.ShippingAddress.Country"
        />
        <Column header="ABN" field="ABN" />
      </DataTable>
    </div>
  );
};

export const statusItemTemplate = (option: any) => {
  return (
      <Badge
      value={option}
      severity={
          option == "Active"
          ? "success"
            : option == "InActive"
            ? "danger"
            : "info"
      }
      className={`p-mr-2 mb-1`}
    ></Badge>
    );
}

export const headerGroup = (
  <ColumnGroup>
    <Row>
      <Column />
      <Column header="Username/DisplayID" colSpan={2} />
      <Column header="State" colSpan={2} />
      <Column header="Name/Company" colSpan={1} />
      <Column header="Sync Error Message" colSpan={2} />
      <Column header="Last Sync At" colSpan={1} />
    </Row>
    <Row>
      <Column />
      <Column
        header="Maropost"
        filter
        filterMatchMode="contains"
        filterPlaceholder="Search"
        field="NetoCustomer.Username"
      />
      <Column
        header="Myob"
        filter
        filterMatchMode="contains"
        filterPlaceholder="Search"
        field="MyobCustomer.DisplayID"
      />
      <Column
        header="Maropost"
        filter
        filterMatchMode="contains"
        filterPlaceholder="Search"
        filterField="NetoCustomer.ActiveText"
        sortField="NetoCustomer.ActiveText"
        field="NetoCustomer.ActiveText"
      />
      <Column
        header="Myob"
        filter
        filterMatchMode="contains"
        filterPlaceholder="Search"
        filterField="MyobCustomer.IsActiveText"
        sortField="MyobCustomer.IsActiveText"
        field="MyobCustomer.IsActiveText"
      />

      <Column
        header="Myob"
        filter
        filterMatchMode="contains"
        filterPlaceholder="Search"
        field="CompanyOrUser"
      />
      <Column
        header="Maropost"
        filter
        filterMatchMode="contains"
        filterPlaceholder="Search"
        field="NetoCustomer.syncErrorMessage"
      />
      <Column
        header="Myob"
        filter
        filterMatchMode="contains"
        filterPlaceholder="Search"
        field="MyobCustomer.syncErrorMessage"
      />
      <Column
        filter
        filterMatchMode="contains"
        filterPlaceholder="Search"
        sortable
        filterField="lastSyncAt"
        field="lastSyncAtForFilter"
      />
    </Row>
  </ColumnGroup>
);


export const headerGroupForApproach1 = (
  <ColumnGroup>
    <Row>
      <Column />
      <Column header="Username/DisplayID" colSpan={2} />
      <Column header="State" colSpan={2} />
      <Column header="Name/Company" colSpan={1} />
      <Column header="Sync Error Message" colSpan={2} />
      <Column header="Last Sync At" colSpan={1} />
    </Row>
    <Row>
      <Column />
      <Column
        header="Maropost"
        filter
        sortable
        filterMatchMode="contains"
        filterPlaceholder="Search"
        field="NetoCustomer.Username"
      />
      <Column
        header="Myob"
        filter
        sortable
        filterMatchMode="contains"
        filterPlaceholder="Search"
        field="MyobCustomer.DisplayID"
      />
      <Column
        header="Maropost"
        filter
        sortable
        filterMatchMode="contains"
        filterPlaceholder="Search"
        filterField="NetoCustomer.ActiveText"
        sortField="NetoCustomer.ActiveText"
        field="NetoCustomer.ActiveText"
      />
      <Column
        header="Myob"
        filter
        sortable
        filterMatchMode="contains"
        filterPlaceholder="Search"
        filterField="MyobCustomer.IsActiveText"
        sortField="MyobCustomer.IsActiveText"
        field="MyobCustomer.IsActiveText"
      />

      <Column
        header="Myob"
        filter
        sortable
        filterMatchMode="contains"
        filterPlaceholder="Search"
        field="CompanyOrUser"
      />
      <Column
        header="Maropost"
        filter
        sortable
        filterMatchMode="contains"
        filterPlaceholder="Search"
        field="NetoCustomer.syncErrorMessage"
      />
      <Column
        header="Myob"
        filter
        sortable
        filterMatchMode="contains"
        filterPlaceholder="Search"
        field="MyobCustomer.syncErrorMessage"
      />
      <Column
        filter
        filterMatchMode="contains"
        filterPlaceholder="Search"
        sortable
        filterField="lastSyncAt"
        field="lastSyncAtForFilter"
      />
    </Row>
  </ColumnGroup>
);