import * as React from 'react';
import { DataTable } from 'primereact/datatable';
import { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../../store/AppContext';
import { Column } from 'primereact/column';
import _ from 'lodash';
import { ProductGridModel } from '../models';
import { emptyMessageTemplate, headerGroup, lastSyncAtBodyTemplate, myobErrorMessageTemplate, netoErrorMessageTemplate, rowExpansionTemplate, SourceInventoriedTemplate, TargetInventoriedTemplate } from '../GridBodyTemplates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSlash } from '@fortawesome/free-solid-svg-icons';

interface IProductGridErrorComponentProp{
    productData: Array<ProductGridModel>,
    loading: boolean,
}

const ProductGridErrorComponent : React.FC<IProductGridErrorComponentProp> = ({ productData, loading }) => {
    let { State, Dispatch } = useContext(AppContext);
    const dt = useRef<DataTable>(null);
    const [ProductData, setProductData] = useState<Array<ProductGridModel>>([]);
    const [expandedRows, setExpandedRows] = useState<any>([]);
 
    useEffect(() => {
        setProductData(productData);
    }, [productData]);

    const reset = () => {
        dt.current?.reset();
      }
    
      const header = (
        <div className="d-flex justify-content-between">
            <h5 className="text-left">Failed Products</h5>
            <div className="col-6 text-right">
                <button
                    type="button"
                    className="btn btn-outline-secondary ml-2"
                    onClick={reset}
                >
                    <div className="fa-layers ml-2">
                        <FontAwesomeIcon className="mr-2" icon={faFilter} />
                        <FontAwesomeIcon className="mr-2" icon={faSlash} />
                    </div>
                    Clear
                </button>
            </div>
        </div>
      );

    return (
        <div className="container-fluid">
            <DataTable
                ref={dt}
                // dataKey="Number"
                header={header}
                headerColumnGroup={headerGroup}
                value={ProductData}
                className=""
                autoLayout
                sortField="lastSyncAtForFilter"
                sortOrder={-1}
                emptyMessage={emptyMessageTemplate}
                lazy={loading}
                loading={loading}
                paginator
                rows={10}
                selectionMode="multiple"
                expandedRows={expandedRows}
                onRowToggle={(e) => setExpandedRows(e.data)}
                rowExpansionTemplate={(data: any) => rowExpansionTemplate([data])}
                totalRecords={ProductData?.length}
                rowsPerPageOptions={[10, 20, 50]}
                paginatorClassName="p-jc-end"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">

                <Column expander style={{ width: '3em' }} />
                <Column field="NetoProduct.SKU" />
                <Column field="MyobProduct.Number" />
                <Column field="NetoProduct.Name" />
                <Column field="MyobProduct.Name" />
                <Column body={SourceInventoriedTemplate} field="NetoProduct.IsInventoriedNeto" />
                <Column body={TargetInventoriedTemplate} field="MyobProduct.IsInventoriedMyob" />
                <Column field="NetoProduct.syncErrorMessage" body ={netoErrorMessageTemplate}/>
                <Column field="MyobProduct.syncErrorMessage" body={myobErrorMessageTemplate}/>
                <Column filterField="LastSyncAt" field="lastSyncAtForFilter" body= {lastSyncAtBodyTemplate} />
            </DataTable>
        </div>
    )
}

export default ProductGridErrorComponent