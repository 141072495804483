import React, { useContext, useEffect, useState } from 'react'
import qs from 'qs'
import firebase from "../shared/config/firebaseConfig";
import { useHistory } from 'react-router-dom';
import Spinner from '../shared/components/Spinner';
import { AppContext } from '../store/AppContext';

const AddOnUserPage: React.FC = () => {
    let { State, Dispatch } = useContext(AppContext);
    const history = useHistory();
    const [message, setMessage] = useState("");

    useEffect(() => {
        const email = (qs.parse(window.location.search, { ignoreQueryPrefix: true }).email)?.toString()
        const password = (qs.parse(window.location.search, { ignoreQueryPrefix: true }).randomAccessToken)?.toString()
        if (email && password) {
            firebase.auth().onAuthStateChanged(async (user) => {
                if (user) {
                    if(user.email === email){
                        user.reload();
                        setUserAndReRoute();
                    }
                    else{
                        await firebase.auth().signOut();
                        await loggingInUser(email, password);
                    }
                }
                else{
                    await loggingInUser(email, password);
                }
            });
        } else {
            setMessage("The email or password is invalid. Please try again.");
        }
    }, []);

    const loggingInUser = async (email: string, password: string) => {
        try{
            // await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
            const userResult = await firebase.auth().signInWithEmailAndPassword(email, password);
            // if(userResult.user){
                setUserAndReRoute();
                // Dispatch({ Type: "DISABLE_APP_LOADING", Payload: false });
            // }
        } catch (error: any) {
            localStorage.setItem("isAddOnUser", `false`);
            setMessage(error.message);
            // Dispatch({ Type: "DISABLE_APP_LOADING", Payload: false });
        }
    }

    const setUserAndReRoute = () => {
        localStorage.setItem("isAddOnUser", `true`);
        history.replace("/connectors");
        window.location.reload();
    }

    return (
        <div className="container mt-5">
            {message ? <p className="text-danger">{message}</p> : <Spinner/>}
        </div>
    )
}

export default AddOnUserPage