import React, { useEffect, useState, useContext, useRef } from "react";
import { AppContext } from "../../../store/AppContext";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSlash, faSync } from "@fortawesome/free-solid-svg-icons";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { markAsCloseOrders, reprocessOrders } from "../services/OrderService";
import { SalesOrderGridModel } from "../models";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { actionBodyTemplate, actionBodyTemplateTwo, datePlacedBodyTemplate, emptyMessageTemplate, logoBody, statusBodyTemplate, statusItemTemplate } from "../GridBodyTemplates";
import { Subscription } from "rxjs";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";


interface IOrderGridErrorComponentProps {
    loading: boolean,
    setLoading: (value: boolean) => void,
    orderData: any,
    selectedConnector: string,
}

const OrderGridErrorComponent: React.FC<IOrderGridErrorComponentProps> = ({ loading, setLoading, orderData, selectedConnector }) => {
    let { State, Dispatch } = useContext(AppContext);
    const [selectedOrders, setSelectedOrders] = useState<Array<SalesOrderGridModel>>();
    const [salesOrderData, setSalesOrderData] = useState<any>(orderData ?? []);
    const [selectedStatus, setSelectedStatus] = useState<any>(null);
    const dt = useRef<DataTable>(null);
    const [reprocessLoading, setReprocessLoading] = useState<boolean>(false);
    const [reprocessLoadingSpin, setReprocessLoadingSpin] = useState<boolean>(false);
    const [reExportLoadingSpin, setReExportLoadingSpin] = useState<boolean>(false);
    const [markCloseOrderLoadingSpin, setMarkCloseOrderLoadingSpin] = useState<boolean>(false);
    const [isCloseOrderDialogOpen, setIsCloseOrderDialogOpen] = useState<boolean>(false);
    const [first, setFirst] = useState<any>({
        first: 0,
        rows: 10,
        page: 0,
        pageCount: 1
    });
    let getOrdersFromFirebaseObserver: Subscription;

    useEffect(() => {
        setSalesOrderData(orderData);
        selectedStatusOnPageLoad()
    }, [orderData])

    const reprocessing = async (isForced?: boolean) => {
        setReprocessLoading(true);
        isForced ? setReExportLoadingSpin(true) : setReprocessLoadingSpin(true);
        const reprocessOrderRequestResult = await reprocessOrders(State.currentUser.currentUserId, selectedConnector, selectedOrders, isForced);
        if (reprocessOrderRequestResult) {
            Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: true, message: "Orders exported from Maropost And MYOB in progress" } });
        }
        else {
            Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: false, message: "Some Error occurred! while Re-processing!" } });
        }
        setReprocessLoading(false);
        setReprocessLoadingSpin(false);
        setReExportLoadingSpin(false);
    }

    const reset = () => {
        dt.current?.reset();
        setSelectedStatus(null);
    }

    const statuses = [
        /*'ExportComplete', */'NotProcessed', 'ImportFailed', 'ExportFailed', 'ExportStart' /*'Imported'*/
    ];

    const onStatusFilterChange = (event: any) => {
        dt.current?.filter(event.value, 'status.orderStatus', 'equals');
        setSelectedStatus(event.value);
    }

    const selectedStatusOnPageLoad = () => {
        const selectedStatus: string = "ExportFailed"
        dt.current?.filter(selectedStatus, 'status.orderStatus', 'equals');
        setSelectedStatus(selectedStatus);
    }

    const statusesNeto = ['New', 'New Backorder', 'Backorder Approved', 'Pick', 'Pack', 'Pending Pickup', 'Pending Dispatch', 'Dispatched', 'Uncommitted', 'On Hold'];

    const renderStatusFilter = () => {
        return (
            <Dropdown style={{ width: '100%' }} value={selectedStatus} options={statuses}
                onChange={onStatusFilterChange}
                itemTemplate={statusItemTemplate}
                showClear placeholder="Select a Status" className="p-column-filter" />
        );
    }

    const statusFilterElement = renderStatusFilter();
    const markAsClose = async () => {
        setIsCloseOrderDialogOpen(false);
        setReprocessLoading(true);
        setMarkCloseOrderLoadingSpin(true)
        // const reprocessOrderRequestResult = await markAsCloseOrders(State?.currentUser?.isAdminUser && localStorage.SelectedIdByAdmin ? localStorage.SelectedIdByAdmin : State.currentUser.currentUserId, selectedConnector, selectedOrders);
        const closeOrderRequestResult = await markAsCloseOrders(State?.currentUser?.isAdminUser && localStorage.SelectedIdByAdmin ? localStorage.SelectedIdByAdmin : State.currentUser.currentUserId, selectedConnector, selectedOrders);
        if (closeOrderRequestResult) {
            Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: true, message: "Orders closed in the connector log" } });
        }
        else {
            Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: false, message: "Some Error occurred! while force close!" } });
        }
        setReprocessLoading(false);
        setReprocessLoadingSpin(false);
        setReExportLoadingSpin(false);
        setMarkCloseOrderLoadingSpin(false)
    }

    const header = (
        <div className="d-flex justify-content-between">
            <h5 className="text-left">Failed Sale Orders</h5>
            <div className="col-6 text-right">
                <button
                    type="button"
                    className="btn btn-outline-secondary mr-1"
                    disabled={!selectedOrders || !selectedOrders.length || reprocessLoading}
                    onClick={() => reprocessing(true)}
                >
                    <div className="fa-layers ml-2">
                        <FontAwesomeIcon className="mr-2" icon={faSync} spin={reExportLoadingSpin}
                        />
                    </div>
                    ReExport
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    disabled={!selectedOrders || !selectedOrders.length || reprocessLoading}
                    onClick={() => reprocessing()}
                >
                    <div className="fa-layers ml-2">
                        <FontAwesomeIcon className="mr-2" icon={faSync} spin={reprocessLoadingSpin}
                        />
                    </div>
                    ReProcess
                </button>

                {!_.isEmpty(selectedConnector) &&
                    <button
                        type="button"
                        className="btn btn-outline-secondary ml-1 mr-1"
                        disabled={!selectedOrders || !selectedOrders.length || markCloseOrderLoadingSpin}
                        onClick={() => setIsCloseOrderDialogOpen(true)}
                    >
                        <div className="fa-layers ml-2">
                            <FontAwesomeIcon className="mr-2" icon={faSync} spin={markCloseOrderLoadingSpin}
                            />
                        </div>
                        {/* {`Mark ${!selectedOrders || !selectedOrders.length ? "all" : "selected"} orders as close`} */}
                        {`Force Closed`}

                    </button>
                }
                <button
                    type="button"
                    className="btn btn-outline-secondary ml-2"
                    onClick={reset}
                >
                    <div className="fa-layers ml-2">
                        <FontAwesomeIcon className="mr-2" icon={faFilter} />
                        <FontAwesomeIcon className="mr-2" icon={faSlash} />
                    </div>
                    Clear
                </button>
            </div>
        </div>
    );
    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header=""></Column>
                <Column header=""></Column>
                <Column header="OrderID" colSpan={2} />
                <Column header="Customer" colSpan={2} />
                <Column header="Order Status" colSpan={2} />
                <Column header="Processed Status" />
                <Column header="Processed Date" />
                <Column header="Action"></Column>
            </Row>
            <Row>
                <Column headerStyle={{ width: '3em' }} />
                <Column headerStyle={{ width: '3em' }} />
                <Column header="Maropost" filter filterMatchMode="contains" filterPlaceholder="Search" field="netoOrderId" body={(data) => actionBodyTemplateTwo(selectedConnector, data)} />
                <Column header="Myob" filter filterMatchMode="contains" filterPlaceholder="Search" field="myobOrderId" />
                <Column header="Maropost" filter filterMatchMode="contains" filterPlaceholder="Search" field="netoCustomer" />
                <Column header="Myob" filter filterMatchMode="contains" filterPlaceholder="Search" field="myobCustomer" />
                <Column header="Maropost" filter filterMatchMode="contains" filterPlaceholder="Search" /*filterElement={orderStatusFilterElement}*/ field="netoOrderStatus" />
                <Column header="Myob" filter filterMatchMode="contains" filterPlaceholder="Search" style={{ minWidth: '5px' }} field="myobOrderStatus" />
                <Column header="Status" filter filterMatchMode="contains" filterPlaceholder="Search" sortField="status.orderStatus" field="status.orderStatus" filterElement={statusFilterElement} />
                <Column header="Date" filter filterMatchMode="contains" filterPlaceholder="Search by Processed Date" sortable filterField="datePlaced" field="datePlacedForFilter" />
                <Column />
            </Row>
        </ColumnGroup>
    );

    return (
        <div className="container-fluid">
            <DataTable
                ref={dt}
                headerColumnGroup={headerGroup}
                value={salesOrderData}
                header={header}
                className="p-mt-6"
                dataKey="number"
                rowHover
                autoLayout
                sortField="datePlacedForFilter" sortOrder={-1}
                emptyMessage={emptyMessageTemplate}
                lazy={loading}
                loading={loading}
                paginator
                rows={first.rows}
                first={first.first} onPage={(e) => setFirst(e)}
                // selectionMode="multiple"
                selection={selectedOrders}
                onSelectionChange={(e: any) => setSelectedOrders(e.value)}
                totalRecords={salesOrderData?.length}
                rowsPerPageOptions={[10, 20, 50]}
                paginatorClassName="p-jc-end"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                // scrollable scrollHeight="510px"
                // scrollable scrollHeight="flex"
                rowClassName={(rowData: any) => {
                    return { 'highlight': rowData.new };
                }}>
                <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
                <Column header="" body={() => logoBody()} />
                <Column field="netoOrderId" body={(data) => actionBodyTemplateTwo(selectedConnector, data)} />
                <Column field="myobOrderId" />
                <Column field="netoCustomer" />
                <Column field="myobCustomer" />
                <Column field="netoOrderStatus" />
                <Column field="myobOrderStatus" />
                <Column field="status.orderStatus" body={statusBodyTemplate} filterElement={statusFilterElement} />
                <Column filterField="datePlaced" field="datePlacedForFilter" body={datePlacedBodyTemplate} />
                <Column body={(data) => actionBodyTemplate(selectedConnector, data)} />
            </DataTable>

            <Dialog header="Force close orders" visible={isCloseOrderDialogOpen} onHide={() => { }} closable={false} draggable={false} breakpoints={{ '960px': '75vw' }} style={{ width: '35vw' }} >
                <p className="">It will mark the orders "Closed" in the connector log.
                    Please ensure to sync payments before running this process
                    The closed orders will not be updated in MYOB
                </p>
                {/* <h6>Do you wish to continue ?</h6> */}
                <div className="d-flex justify-content-between">
                    <h6 className="px-5 mt-3">Do you wish to continue ?</h6>
                    <div className="">
                        <Button label="Yes" className="px-5 m-1 p-button-raised p-button-info" disabled={markCloseOrderLoadingSpin} onClick={() => markAsClose()} />
                        <Button label="No" className="px-5 m-1 p-button-raised p-button-danger" onClick={() => setIsCloseOrderDialogOpen(false)} />
                    </div>
                </div>

            </Dialog>
        </div>
    );
}

export default OrderGridErrorComponent