import React, { useState } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from "primereact/card";
import { PaymentDetail } from '../models/SaleOrderViewModels';
import NetoImg from "../../../assets/maropost-logo.svg";
import MyobImg from "../../../assets/Myob.png";
import * as util from '../../../shared/Utilities';
import _ from "lodash";
import './Payment.css';
import netoToMyob from "../../../assets/netoToMyob.png"
import myobToNeto from "../../../assets/myobToNeto.png"



export interface IPaymentDetailProps {
    PaymentDetails: Array<PaymentDetail>,
    TotalPayment: string,
}

const Payment: React.FC<IPaymentDetailProps> = ({ PaymentDetails, TotalPayment }) => {
    const [expandedRows, setExpandedRows] = useState<any[]>([]);

    const headerTemplate = (
        <img src={NetoImg} alt="Neto" style={{ width: "4rem" }} />
    );

    const cardHeaderTemplate = (
        <div className="p-3" style={{ backgroundColor: "#d3d3d3b8" }}>
            <h4 className="mt-2">
                Payments
            </h4>
        </div>
    );

    const logoBody = (rowData: any) => {
    console.log("🚀 ~ file: Payment.tsx ~ line 37 ~ logoBody ~ rowData", rowData)
        const id = rowData?.id
        const memo = rowData?.Memo
        return (
            <React.Fragment>
                {memo && memo.includes(id) && <img src={netoToMyob} alt="netoToMyob" height="25rem" />}
                {memo && !memo.includes(id) && <img src={myobToNeto} alt="myobToNeto" height="25rem" />}
            </React.Fragment>
        );
      };

    const MyobheaderTemplate = (
        <img src={MyobImg} alt="Myob" style={{ width: "4rem" }} />
    )

    const dateBodyTemplateforNeto = (rowData: any) => {
        return (
            <React.Fragment>
                <div>
                    {!_.isEmpty(rowData.Date) ? <p>{util.userTimezone(rowData?.Date)}</p> : ''}
                </div>
            </React.Fragment>
        );
    }

    const paymentMethodTemplate = (rowData: any) => (
        <div>
            {rowData.AccountName}-{rowData.PaymentMethod}
        </div>
    );


    const dateBodyTemplateforMyob = (rowData: any) => {
        return (
            <React.Fragment>
                <div>
                    {!_.isEmpty(rowData.Date) ? <p>{util.userTimezone(rowData.Date)}</p> : ''}
                </div>
            </React.Fragment>
        );
    }

    const rowExpansionTemplate = (data: any) => {
        return (
            <DataTable
                header={MyobheaderTemplate}
                value={data.NetoAndMyobPayment}>

                <Column field="ReceiptNumber" header="Receipt Number"></Column>
                <Column field="AccountName" header="Account Name"></Column>
                <Column field="Date" header="Date" body={dateBodyTemplateforMyob}></Column>
                <Column field="Amount" header="Amount Paid"></Column>
                <Column field="Customer" header="Customer"></Column>
            </DataTable>
        );
    }

    return (
        <div >
            {PaymentDetails && PaymentDetails.length > 0 &&
                <Card header={cardHeaderTemplate} className="text-left">
                    <DataTable value={PaymentDetails}
                        expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                         onRowExpand={(e) => setExpandedRows(e.data)}
                        rowExpansionTemplate={rowExpansionTemplate} dataKey="id"
                        >

                        <Column expander style={{ width: '3em' }} />
                        <Column field="id"  body={logoBody} />

                        <Column field="id" header="Payment ID"></Column>
                        <Column body={paymentMethodTemplate} header="Payment Method"></Column>
                        <Column field="Date" header="Date" body={dateBodyTemplateforNeto}></Column>
                        <Column field="Amount" header="Amount"></Column>
                        <Column field="ProcessBy" header="Processed By"></Column>
                    </DataTable>
                </Card>
            }
            <br />
        </div>
    );
}

export default Payment
