import * as React from 'react';
import { Card } from 'primereact/card';
import * as util from '../../../shared/Utilities';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";

export interface ISaleOrderProps {
    DatePlaced: string,
    DateUpdate: string,
    DateDue: string,
    Total: string,
    OrderAmount: string,
    ShippingMethod: string,
    OrderStatus: string
}

const SaleOrder: React.FC<ISaleOrderProps> = ({ DatePlaced, DateUpdate, DateDue, Total, OrderAmount, ShippingMethod, OrderStatus }) => {
    const headerComponent = (
        <div className="p-3" style={{ backgroundColor: "#d3d3d3b8" }}>
            <h4>
                <FontAwesomeIcon icon={faShoppingCart} style={{ color: "#495057d4" }} />{" "}
                Sale Order
            </h4>
        </div>
    );

    return (
        <div>
            <Card header={headerComponent} className='text-left '>
                <div className="row pt-4 mx-0 pb-1">
                    <div className="col">
                        <div className='headings-size' style={{ color: "#495057" }}> Date Placed: </div>
                        {!isEmpty(DatePlaced) ? <p> {util.userTimezone(DatePlaced)} </p> : ''}
                    </div>
                    <div className="col">
                        <div className='headings-size' style={{ color: "#495057" }}> Date Updated: </div>
                        {!isEmpty(DateUpdate) ? <p>{util.userTimezone(DateUpdate)}</p> : ''}
                    </div>
                    <div className="col">
                        <div className='headings-size' style={{ color: "#495057" }}> Tax Inclusive: </div>
                        <p>{OrderAmount}</p>
                    </div> <div className="col">
                        <div className='headings-size' style={{ color: "#495057" }}> Order Status: </div>
                        <p>{OrderStatus}</p>
                    </div> <div className="col">
                        <div className='headings-size' style={{ color: "#495057" }}> Shipping Method </div>
                        <p>{ShippingMethod}</p>
                    </div>
                </div>
            </Card>
            <br />
        </div>
    )
}

export default SaleOrder

