import * as React from "react";
import { useState } from 'react';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";
import { MyobSaleOrderModel } from "../models";
import { ConvertMyobLineItemToGlobalLineItemInterface } from "../services/OrderService";
import MyobImg from "../../../assets/Myob.png";
import * as util from '../../../shared/Utilities'
import _ from "lodash";
import './MyobRMA.css'

export interface IMyobRMADetailProps {
  MyobRMA: Array<MyobSaleOrderModel>;
}

const MyobRmaDetial: React.FC<IMyobRMADetailProps> = (props) => {
  const [myobRMAToggle, setMyobRMAToggle] = useState<boolean>(false);
  const [expandedRows, setExpandedRows] = useState<any[]>([]);

  const headerTemplate = (
    <img src={MyobImg} alt="Myob" style={{ width: "4rem" }} className="ml-4 mt-3" />
  );

  const dateBodyTemplateforMyob = (rowData: any) => {
    return (
      <React.Fragment>
        <div>
          {!_.isEmpty(rowData?.Date) ? <p>{util.userTimezone(rowData?.Date)}</p> : ''}
        </div>
      </React.Fragment>
    );
  }

  const rowExpansionTemplate = (data: any) => {
    return (
      <div className="orders-subtable">
        <DataTable id="nestedMyobViewTable"
          value={ConvertMyobLineItemToGlobalLineItemInterface(
            data.Lines
          )}>

          <Column field="Quantity" header="Qty"></Column>
          <Column field="ItemName" header="Product Name"></Column>
          <Column field="SKU" header="SKU"></Column>
          <Column field="Amount" header="Total"></Column>
          <Column field="Tax" header="Tax Code"></Column>
          <Column field="Account" header="Account"></Column>
        </DataTable>
      </div>
    );
  };

  return (
    <div className="mt-2">
      <Card header={headerTemplate}>
        <DataTable
          id="parentMyobViewTable"
          value={props.MyobRMA}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
          dataKey="UID">

          <Column expander style={{ width: "3em" }} />
          <Column field="Number" header="Invoice No"></Column>
          <Column field="Status" header="Status"></Column>
          <Column field="Date" header="Date" body={dateBodyTemplateforMyob}></Column>
          <Column field="TotalAmount" header="Total Amount"></Column>
          <Column field="Customer.Name" header="Processed To"></Column>
        </DataTable>
      </Card>
    </div>
  );
};

export default MyobRmaDetial;
