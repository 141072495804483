// import React, { useEffect, useState, useContext } from "react";
// import { AppContext } from "../../store/AppContext";
// import _ from "lodash";
// import { TabView, TabPanel } from 'primereact/tabview';
// import Label from "../../components/Label";
// import SelectInput from "../../components/SelectInput";
// import { ValueLabel } from "../../shared/models";
// import { getAllConnectorLabels } from "./services/OrderService";
// import { SalesOrderGridModel } from "./models";
// import * as util from '../../shared/Utilities'
// import { getOrdersFromFirebase, getFailedOrdersFromFirebaseOld, getPaymentFromFirebase } from "../../dataServices";
// import { Subscription } from "rxjs";
// import PaymentGridComponent from "./components/PaymentGridComponent";
// import OrderGridErrorComponent from "./components/OrderGridErrorComponent";


// export const useDebouncedEffect = (effect: any, deps: any, delay: number) => {
//     useEffect(() => {
//         const handler = setTimeout(() => effect(), delay);

//         return () => clearTimeout(handler);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [...deps || [], delay]);
// }

// const PaymentGridContainer: React.FC = () => {
//     let { State, Dispatch } = useContext(AppContext);
//     const [selectedConnector, setSelectedConnector] = useState<string>(State.selectedConnectorOnGrid ?? "");
//     const [connectors, setConnectors] = useState<Array<ValueLabel>>([{ label: "", value: "" }]);
//     const [selectedOrders, setSelectedOrders] = useState<Array<SalesOrderGridModel>>();
//     const [salesOrderData, setSalesOrderData] = useState<any>([]);
//     const [paymentData, setPaymentData] = useState<any>([]);
//     const [failedSalesOrderData, setFailedSalesOrderData] = useState<any>([]);
//     const [startAfterRecord, setStartAfterRecord] = useState<any>();
//     const [startBeforeRecord, setStartBeforeRecord] = useState<any>();
//     const [activeTab, setActiveTab] = useState<number>(0);
//     const [loading, setLoading] = useState<boolean>(false);
//     const [fLoading, setFLoading] = useState<boolean>(false);
//     const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);
//     const [isPrevDisabled, setIsPrevDisabled] = useState<boolean>(false);
//     const [lazyParams, setLazyParams] = useState({
//         first: 0,
//         rows: 5,
//         page: 1,
//         sortField: "Date",
//         sortOrder: -1,
//         filters: undefined,
//         pageMode: '',
//         pageSize: 50,
//     });
//     const filterFields = {
//         netoOrderID: "IncomingOrder.OrderID",
//         myobOrderID: "OutgoingOrder.Number",
//         myobCustomer: "OutgoingOrder.Customer.Name",
//         netoCustomer: "IncomingOrder.Username",
//         myobStatus: "OutgoingOrder.Status",
//         netoStatus: "IncomingOrder.OrderStatus",
//         status: "Status",
//         date: "Date",
//     }
//     let getOrdersFromFirebaseObserver: Subscription;

//     useEffect(() => {
//         console.log("CALLED first effect");
//         (async () => {
//             setLoading(true);
//             const connectorsList = await getAllConnectorLabels(State.currentUser.currentUserId);
//             setLoading(false);
//             if (State.currentUser.currentUserId) {
//                 setSelectedConnector(connectorsList.length && connectorsList[0].value ? connectorsList[0].value : "");
//             }
//             setConnectors(connectorsList);
//         })();
//         return () => {
//             Dispatch({
//                 Type: "SET_SALES_ORDER",
//                 Payload: { connector: "", data: [] },
//             });
//         };
//     }, []);

//     useEffect(() => {
//         // if(activeTab === 0){
//         //     setLoading(true); 
//         //     processPagination();
//         // } else { 
//         //     connectorsSnapshot(selectedConnector);
//         // }
//         triggerPagination();
//     }, [activeTab])

//     useEffect(() => {
//         setLoading(true); 
//         setLazyParams({
//             first: 0,
//             rows: 5,
//             page: 1,
//             sortField: "Date",
//             sortOrder: -1,
//             filters: undefined,
//             pageMode: '',
//             pageSize: 50,
//         });
//         setSalesOrderData([]);
//         setFailedSalesOrderData([]);
//         // triggerPagination(); 
//     }, [selectedConnector]);

//     useDebouncedEffect(() => {
//         setLoading(true); 
//         processPagination();
//     }, [lazyParams], 1000);

//     useDebouncedEffect(() => { 
//         // setLoading(true); 
//         triggerPagination(); 
//     }, [selectedConnector], 1000);

//     const triggerPagination = () => {
//         if(activeTab === 1){
//             console.log("salesOrderData length", salesOrderData.length)
//             if(_.isEmpty(salesOrderData)){
//                 setLoading(true); 
//                 processPagination();
//             } else {
//                 const salesOrderDataCopy = [...salesOrderData];
//                 setSalesOrderData(salesOrderDataCopy);
//             }
//         } else {
//             // if(_.isEmpty(failedSalesOrderData)){ 
//                 connectorsSnapshot(selectedConnector);
//             // } else {
//                 // setFailedSalesOrderData(failedSalesOrderData);
//             // }
//         }
//     }

//     const connectorsSnapshot = async (connector: string) => {
//         setFLoading(true);
//         if (!_.isEmpty(connector)) {
//             getOrdersFromFirebaseObserver = getFailedOrdersFromFirebaseOld(State.currentUser.currentUserId, selectedConnector).subscribe((e) => {
//                 setFailedSalesOrderData(e);
//                 setFLoading(false);
//                 // Dispatch({
//                 //     Type: "SET_SALES_ORDER",
//                 //     Payload: { connector: connector, data: [] },
//                 // });
//             });
//         }
//         else {
//             setFLoading(false);
//         }
//     };

//     const processPagination = () => {
//         if (State?.currentUser?.currentUserId && selectedConnector) {
//             let filtersArray : any = [];
//             if (lazyParams?.filters) {
//                 const filters: any = lazyParams.filters;
//                 if (filters?.myobCustomer) {
//                     if(!_.isEmpty(filters?.myobCustomer?.value))
//                         filtersArray.push({ key: filterFields.myobCustomer, value: filters?.myobCustomer?.value })
//                 }

//                 if (filters?.netoCustomer) {
//                     if(!_.isEmpty(filters?.netoCustomer?.value ))
//                        filtersArray.push({ key: filterFields.netoCustomer, value: filters?.netoCustomer?.value })
//                 }

//                 if (filters?.netoOrderId) {
//                     if(!_.isEmpty(filters?.netoOrderId?.value))
//                        filtersArray.push({ key: filterFields.netoOrderID, value: filters?.netoOrderId?.value })
//                 }

//                 if (filters?.myobOrderId) {
//                     if(!_.isEmpty(filters?.myobOrderId?.value))
//                        filtersArray.push({ key: filterFields.myobOrderID, value: filters?.myobOrderId?.value })
//                 }

//                 if (filters?.netoOrderStatus) {
//                     if(!_.isEmpty(filters?.netoOrderStatus?.value))
//                         filtersArray.push({ key: filterFields.netoStatus, value: filters?.netoOrderStatus?.value })
//                 }

//                 if (filters?.myobOrderStatus) {
//                     if(!_.isEmpty(filters?.myobOrderStatus?.value))
//                         filtersArray.push({ key: filterFields.myobStatus, value: filters?.myobOrderStatus?.value })
//                 }

//                 if (filters['status.orderStatus']) {
//                     if(!_.isEmpty(filters['status.orderStatus']?.value))
//                         filtersArray.push({ key: filterFields.status, value: filters['status.orderStatus']?.value })
//                 }

//                 if (filters['datePlaced']) {
//                     if(filters['datePlaced']?.value)
//                        filtersArray.push({ key: filterFields.date, value: util.getUserTimezoneStartAndEndOfADay(filters['datePlaced']?.value) });
//                 }
//             }

//             // const pageMode = limit ? (limit === 1 ? 'ALL' : paginationMode) : onPageRows === 1 ? 'ALL' : paginationMode;
//             // console.log("PAGE MODE: ", pageMode);

//             // let pageMode = '';
//             // if(isRowsChanged && isRowsChanged === true){
//             //     pageMode = limit && limit === 1 ? 'ALL' : '';
//             // } else {
//             //     pageMode = onPageRows === 1 ? 'ALL' : paginationMode
//             // }

//             getOrdersFromFirebaseObserver = getOrdersFromFirebase(State.currentUser.currentUserId,
//                 selectedConnector, lazyParams.pageSize, lazyParams?.sortField, lazyParams?.sortOrder, 
//                 startBeforeRecord, startAfterRecord, lazyParams.pageMode, filtersArray).subscribe((e: any) => {

//                     if (e?.ordersData && e?.ordersData.length > 0) {
//                         setSalesOrderData(e?.ordersData);
//                         if (e?.startAfter) {
//                             setStartAfterRecord(e?.startAfter);
//                         }
//                         if (e?.startBefore) {
//                             setStartBeforeRecord(e?.startBefore);
//                         }

//                         if(lazyParams.pageMode === 'ALL'){
//                             setIsNextDisabled(true);
//                             setIsPrevDisabled(true);
//                         } else {
//                             setIsNextDisabled(false);
//                             setIsPrevDisabled(false);
//                         }

//                         setLoading(false);
//                     }
//                     else {
//                         if(lazyParams.pageMode === 'ALL'){
//                             setSalesOrderData([]);
//                             setIsNextDisabled(true);
//                             setIsPrevDisabled(true);
//                         } else if(lazyParams.pageMode === 'PREV'){
//                             setIsPrevDisabled(true);
//                         } else if(lazyParams.pageMode === 'NEXT'){
//                             setIsNextDisabled(true);
//                         } else {
//                             setSalesOrderData([]);
//                             setIsNextDisabled(true);
//                             setIsPrevDisabled(true);
//                         }

//                         setLoading(false);
//                     }
//                 });


//                 getOrdersFromFirebaseObserver = getPaymentFromFirebase(State.currentUser.currentUserId,
//                     selectedConnector).subscribe((e: any) => {
//                         if (e?.paymentData && e?.paymentData.length > 0) {
//                             setPaymentData(e?.paymentData);
//                         }

//                     });

//             util.getTimezoneFromFirebase(
//                 State?.currentUser?.isAdminUser && localStorage.SelectedIdByAdmin ? localStorage.SelectedIdByAdmin : State.currentUser.currentUserId,
//                 selectedConnector
//             );
//         };
//     }
// console.log("paymentData",paymentData);

//     return (
//         <div className="container-fluid">
//             <div className="row mt-5">
//                 <div className="col-3">
//                     <Label text={"Select Connector: "} />
//                 </div>
//                 <div className="col-9">
//                     <SelectInput
//                         options={connectors}
//                         selectedOptionValue={selectedConnector}
//                         onChange={(connector: string) => {
//                             Dispatch({
//                                 Type: "SET_SELECTED_CONNECTOR",
//                                 Payload: { id: connector },
//                             });
//                             setSelectedConnector(connector)
//                             setSelectedOrders([])
//                         }}
//                     />
//                 </div>
//             </div>
//             <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
//                 <TabPanel header={<b>Orders Payments</b>}>
//                     <PaymentGridComponent 
//                         // orderData={salesOrderData}
//                         orderData={paymentData}
//                         isNextDisabled={isNextDisabled}
//                         isPrevDisabled={isPrevDisabled}
//                         lazyParams={lazyParams}
//                         loading={loading}
//                         setLoading={setLoading}
//                         selectedConnector={selectedConnector}
//                         setLazyParams={setLazyParams}
//                         setStartAfterRecord={setStartAfterRecord}
//                         setStartBeforeRecord={setStartBeforeRecord}
//                     />
//                 </TabPanel>
//             </TabView>
//         </div>
//     );
// }


// export default PaymentGridContainer





import React, { useEffect, useState, useContext, useRef } from "react";
import moment from 'moment';
import { AppContext } from "../../store/AppContext";
import _ from "lodash";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Label from "../../components/Label";
import SelectInput from "../../components/SelectInput";
import Datepicker from '../../components/Datepicker';
import { ValueLabel } from "../../shared/models";
import { getAllConnectorLabels } from "./services/OrderService";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { actionBodyTemplateToPaymentDetial, actionBodyTemplateToRmaDetial, actionBodyTemplateTwo, emptyMessageTemplate } from "./GridBodyTemplates";
import * as util from '../../shared/Utilities';
import { getRMAFromFirebase, getPaymentFromFirebase } from "../../dataServices";
import { Subscription } from "rxjs";

const PaymentGridContainer: React.FC = () => {
    let { State, Dispatch } = useContext(AppContext);
    const [connectors, setConnectors] = useState<Array<ValueLabel>>([{ label: "", value: "" }]);
    const [selectedConnector, setSelectedConnector] = useState<string>(State.selectedConnectorOnGrid ?? "");
    const [salesPaymentData, setSalesPaymentData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    let [paymentDateFilter, setPaymentDateFilter] = useState<any>( moment().subtract(1, 'months').format('YYYY-MM-DDTHH:mm:ss'));
   
    
    const dt = useRef<DataTable>(null);
    let getPaymentFromFirebaseObserver: Subscription;

    useEffect(() => {
        (async () => {
            setLoading(true);
            const connectorsList = await getAllConnectorLabels(State.currentUser.currentUserId);
            setLoading(false);
            if (State.currentUser.currentUserId) {
                setSelectedConnector(connectorsList.length && connectorsList[0].value ? connectorsList[0].value : "");
            }
            setConnectors(connectorsList);
        })();

        return () => {
            Dispatch({
                Type: "SET_SALES_ORDER",
                Payload: { connector: "", data: [] },
            });
        };
    }, []);

    useEffect(() => {
        connectorsSnapshot(selectedConnector);
        if (State?.currentUser?.currentUserId && selectedConnector) {
            util.getTimezoneFromFirebase(State.currentUser.currentUserId, selectedConnector)
            return () => {
                getPaymentFromFirebaseObserver?.unsubscribe();
            }
        }
    }, [selectedConnector]);

    const connectorsSnapshot = async (connector: string) => {
        setLoading(true);
        if (!_.isEmpty(connector)) {

            getPaymentFromFirebaseObserver = getPaymentFromFirebase(State.currentUser.currentUserId, selectedConnector,paymentDateFilter).subscribe((e: any) => {
                setSalesPaymentData(e?.paymentData)
                setLoading(false);
                Dispatch({
                    Type: "SET_SALES_ORDER",
                    Payload: { connector: connector, data: [] },
                });

            });
        }
        else {
            setLoading(false);
        }
    };

    useEffect( () => {
      connectorsSnapshot(selectedConnector);
    },[paymentDateFilter])

    const header = (
            <div className="d-flex justify-content-center">
                <div className= "col-3">
                    <Label text={"Select order process date from: "} />
                </div>
                <div className="col">
                    <Datepicker
                        selectedDate={paymentDateFilter}
                        onChange={(val: string) => {
                            setPaymentDateFilter(val)
                        }}
                        onBlur={(e: any) => {
                            setPaymentDateFilter(e.toString())
                        }}
                        paymentAndRma={true}
                    />
                </div>
            </div>
    )


    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header=""></Column>
                <Column header="PaymentID" colSpan={2} />
                <Column header="OrderID" colSpan={2} />
                <Column header="Customer" colSpan={2} />
                <Column header="Processed Date" />
            </Row>
            <Row>
                <Column headerStyle={{ width: '3em' }} />

                <Column header="Maropost" filter filterMatchMode="contains" sortable filterPlaceholder="Search" field="netoPaymentId" />
                <Column header="Myob" filter filterMatchMode="contains" sortable filterPlaceholder="Search" field="myobPaymentId" />

                <Column header="Maropost" filter filterMatchMode="contains" sortable filterPlaceholder="Search" field="netoOrderId" />
                <Column header="Myob" filter filterMatchMode="contains" sortable filterPlaceholder="Search" field="myobOrderId" />

                <Column header="Maropost" filter filterMatchMode="contains" sortable filterPlaceholder="Search" field="netoCustomer" />
                <Column header="Myob" filter filterMatchMode="contains" sortable filterPlaceholder="Search" field="myobCustomer" />

                <Column filter filterMatchMode="contains" filterPlaceholder="Search by Date" sortable filterField="datePlaced" field="netoPaymentDatePlaced" />

                {/* <Column header="Maropost" filter filterMatchMode="contains" sortable filterPlaceholder="Search" filterField="datePlaced" field="netoPaymentDatePlaced" /> */}
                <Column header="Myob" filter filterMatchMode="contains" sortable filterPlaceholder="Search by Date"  filterField="datePlaced" field="myobPaymentDatePlaced" />

            </Row>
        </ColumnGroup>
    );


    return (
        <div className="container-fluid">
            <div className="row mt-5">
                <div className="col-2">
                    <Label text={"Select Connector: "} />
                </div>
                <div className="col-9">
                    <SelectInput
                        options={connectors}
                        selectedOptionValue={selectedConnector}
                        onChange={(connector: string) => {
                            Dispatch({
                                Type: "SET_SELECTED_CONNECTOR",
                                Payload: {
                                    id: connector
                                },
                            });
                            setSelectedConnector(connector)

                        }}
                    />
                </div>
            </div>
            {/* <div className="row mt-5">
                <div className="col-3" >
                    <Label text={"Select Date: "} />
                </div>
                <div className="col-9">
                    <Datepicker
                        selectedDate={paymentDateFilter}
                        onChange={(val: string) => {
                            setPaymentDateFilter(val)

                        }}
                        onBlur={(e: any) => {
                            setPaymentDateFilter(e.toString())

                        }}

                    />
                </div>
            </div> */}
            <br />

            <DataTable
                ref={dt}
                header={header}
                headerColumnGroup={headerGroup}
                value={salesPaymentData}
                className="p-mt-6"
                dataKey="number"
                rowHover
                autoLayout
                sortField="datePlacedForFilter"
                sortOrder={-1}
                emptyMessage={emptyMessageTemplate}
                lazy={loading}
                loading={loading}
                paginator
                rows={10}
                totalRecords={salesPaymentData?.length}
                rowsPerPageOptions={[10, 20, 50]}
                paginatorClassName="p-jc-end"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                rowClassName={(rowData: any) => {
                    return { 'highlight': rowData.new };
                }}>

                <Column
                    headerStyle={{ width: '3em' }} />
                <Column field="netoPaymentId" body={(data) => actionBodyTemplateToPaymentDetial(selectedConnector, data)} />
                <Column field="myobPaymentId" />
                <Column field="netoOrderId" />
                <Column field="myobOrderId" />
                <Column field="netoCustomer" />
                <Column field="myobCustomer" />
                <Column filterField="datePlaced" field="netoPaymentDatePlaced" />
                <Column filterField="datePlaced" field="myobPaymentDatePlaced" />
            </DataTable>

        </div>
    );
}

export default PaymentGridContainer