import * as React from 'react';
import { Message } from 'primereact/message';

export interface IAlertProps {
  text: string,
  heading: string
}

const Alert: React.FC<IAlertProps> = (props) => {
  return (
    <div className="p-col-12 p-md-3">
      <Message severity="info" text={props.heading ? `${props.heading}: ${props.text}` : `${props.text}`} />
    </div>
    // <div className="alert alert-danger text-justify" role="alert">
    //   <h6 className="alert-heading">{props.heading}</h6>
    //   <p>{props.text}</p>
    // </div>
  )
}

export default Alert