import * as React from 'react';

export interface ILabelProps {
  text: string
}

const Label: React.FC<ILabelProps> = (props) => {
  return (
    <div>
      <label>
        {props.text}
      </label>
    </div>
  )
}

export default Label