import * as React from 'react'
import { Checkbox } from 'primereact/checkbox';
import './Boolean.css'

export interface IBooleanProps {
  onChange: (value: boolean) => void,
  onBlur?: (value: boolean) => void,
  text: string,
  value: boolean,
  name: string,
  hasError?: boolean
}

const Boolean : React.FC<any> = ({onChange,onBlur,text,hasError,value}) => {
  return (
    <div className="d-flex p-field-checkbox">
      <input className="p-checkbox p-checkbox-box p-checkbox-icon pi" type="checkbox" checked={value}
        onChange={(e) => onChange(e.currentTarget.checked)}
        onBlur={(e) => onBlur && onBlur(e.currentTarget.checked)}
      />
      <label className={`pl-4 ${hasError && 'p-text-danger'}`}>
        {text}
      </label>
    </div>
  )
}

export default Boolean