import React, { useEffect, useState, useContext, useRef } from "react";
import { AppContext } from "../../store/AppContext";
import { DataTable } from "primereact/datatable";
import SelectInput from "../../components/SelectInput";
import Label from "../../components/Label";
import _ from "lodash";
import * as util from "../../shared/Utilities";
import { Subscription } from 'rxjs';
import {
  convertCustomerEntityToGridModel,
  getAllConnectorLabels,
  getFailedImportCustomersDataSnapshotApproach1,
  getImportedCustomersDataSnapshot,
} from "./services/CustomerService";
import { ValueLabel } from "../../shared/models";
import { CustomerEntityModel, CustomerGridModel } from "./models";
import { TabPanel, TabView } from "primereact/tabview";
import CustomerGridComponent from "./components/CustomerGridComponent";
import CustomerGridErrorComponent from "./components/CustomerGridErrorComponent";
import myobToNeto from "../../assets/myobToNeto.png"
import netoToMyob2Way from "../../assets/netoToMyob2Way.png"
import { getUserConnectorConfiguration } from "../Mappings/NetoToMyob/services/MappingService";

export const useDebouncedEffect = (effect: any, deps: any, delay: number) => {
  useEffect(() => {
      const handler = setTimeout(() => effect(), delay);

      return () => clearTimeout(handler);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps || [], delay]);
}

const CustomerGridContainer: React.FC = () => {
  let { State, Dispatch } = useContext(AppContext);
  const dt = useRef<DataTable>(null);

  const [connectors, setConnectors] = useState<Array<ValueLabel>>([{ label: "", value: "" },]);
  const [customersData, setcustomersData] = useState<Array<CustomerGridModel>>([]);
  const [customerFailedData, setCustomerFailedData] = useState<any>([]);
  const [selectedConnector, setSelectedConnector] = useState<string>(State.selectedConnectorOnGrid ?? "");
  const [loading, setLoading] = useState<boolean>(false);
  const [startAfterRecord, setStartAfterRecord] = useState<any>();
  const [startBeforeRecord, setStartBeforeRecord] = useState<any>();
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);
  const [isPrevDisabled, setIsPrevDisabled] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  let getOrdersFromFirebaseObserver: Subscription;

  const filterFields = {
    Username: 'neto.Username',
    DispalyID: 'myob.DisplayID',
    ActiveText: 'neto.Active',
    IsActiveText: 'myob.IsActive',
    CompanyOrUser1: 'myob.CompanyName',
    CompanyOrUser2: 'myob.FirstName',
    CompanyOrUser3: 'myob.LastName',
    syncErrorMessage: 'neto.syncErrorMessage',
    myobSyncErrorMessage: 'myob.syncErrorMessage',
    lastSyncAt: 'lastSyncAt',
  }
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 5,
    page: 1,
    sortField: "lastSyncAt",
    sortOrder: -1,
    filters: undefined,
    pageMode: '',
    pageSize: 50,
  });
  const [customerType, setCustomerType] = useState<string>(filterFields.CompanyOrUser1);
  const [netoToMyobSync, setNetoToMyobSync] = useState(false);
  const [myobToNetoSync, setMyobToNetoSync] = useState(false);


  useEffect(() => {
    (async () => {
      setLoading(true);
      const connectorsList = await getAllConnectorLabels(State.currentUser.currentUserId);
      setLoading(false);
      if (State.currentUser.currentUserId) {
        setSelectedConnector(connectorsList.length && connectorsList[0].value ? connectorsList[0].value : "");
      }
      setConnectors(connectorsList);
    })();

    return () => {
      Dispatch({
        Type: "SET_SALES_ORDER",
        Payload: { connector: "", data: [] },
      });
    };
  }, []);


  useEffect(() => {
    setLoading(true);
    setLazyParams({
      first: 0,
      rows: 5,
      page: 1,
      sortField: "lastSyncAt",
      sortOrder: -1,
      filters: undefined,
      pageMode: '',
      pageSize: 50,
    });
    setcustomersData([]);
  }, [selectedConnector]);


  useEffect(() => {
    (async () => {
      if (selectedConnector == "") return
      let userMappings = await getUserConnectorConfiguration(State.currentUser.currentUserId, selectedConnector);
      setMyobToNetoSync(userMappings?.result?.mappings?.myobCustomerToNeto?.isChecked)
    })();
   
  }, [selectedConnector]);

  useEffect(() => {
    triggerPagination();
}, [activeTab])

  useDebouncedEffect(() => { 
    // setLoading(true); 
    triggerPagination(); 
  }, [selectedConnector], 1000);
  
  useDebouncedEffect(() => { setLoading(true); processPagination(); }, [lazyParams], 1000);

  const processPagination = () => {
    if (State?.currentUser?.currentUserId && selectedConnector) {
      let filtersArray : any  = [];
      if (lazyParams?.filters) {
        const filters: any = lazyParams.filters;
        if (filters['NetoCustomer.Username']) {
          if(!_.isEmpty(filters['NetoCustomer.Username']?.value))
          filtersArray.push({ key: filterFields.Username, value: filters['NetoCustomer.Username']?.value })
        }

        if (filters['MyobCustomer.DisplayID']) {
          if(!_.isEmpty(filters['MyobCustomer.DisplayID']?.value))
          filtersArray.push({ key: filterFields.DispalyID, value: filters['MyobCustomer.DisplayID']?.value })
        }

        if (filters['NetoCustomer.ActiveText']) {
          if(!_.isEmpty(filters['NetoCustomer.ActiveText']?.value ))
          filtersArray.push({ key: filterFields.ActiveText, value: (filters['NetoCustomer.ActiveText']?.value 
           === 'Active' ? 'True' : 'False')})
        }

        if (filters['MyobCustomer.IsActiveText']) {
          if(!_.isEmpty(filters['MyobCustomer.IsActiveText']?.value))
          filtersArray.push({ key: filterFields.IsActiveText, value: (filters['MyobCustomer.IsActiveText']?.value 
           === 'Active' ? true : false) })
        }
        
        if (filters['CompanyOrUser']) {
          if(!_.isEmpty(filters['CompanyOrUser']?.value ))
          filtersArray.push({ key: customerType, value: filters['CompanyOrUser']?.value });
          // filtersArray.push({ key: filterFields.CompanyOrUser2, value: filters['CompanyOrUser']?.value });
          // filtersArray.push({ key: filterFields.CompanyOrUser3, value: filters['CompanyOrUser']?.value });
        }

        if (filters['NetoCustomer.syncErrorMessage']) {
          if(!_.isEmpty(filters['NetoCustomer.syncErrorMessage']?.value))
          filtersArray.push({ key: filterFields.syncErrorMessage, value: filters['NetoCustomer.syncErrorMessage']?.value })
        }

        if (filters['MyobCustomer.syncErrorMessage)']) {
          if(!_.isEmpty(filters['MyobCustomer.syncErrorMessage']?.value))
          filtersArray.push({ key: filterFields.myobSyncErrorMessage, value: filters['MyobCustomer.syncErrorMessage']?.value })
        }

        if (filters['lastSyncAt']) {
          if((filters?.lastSyncAt?.value))
            filtersArray.push({ key: filterFields.lastSyncAt, value: util.getUserTimezoneStartAndEndOfADay(filters?.lastSyncAt?.value) });
          // filtersArray.push({ key: filterFields.lastSyncAt, value: filters['lastSyncAt']?.value })
        }
      }

      let CustomerSubscription: Subscription;
        CustomerSubscription = getImportedCustomersDataSnapshot(State.currentUser.currentUserId, selectedConnector,
          lazyParams.pageSize, lazyParams?.sortField, lazyParams?.sortOrder, 
          startBeforeRecord, startAfterRecord, lazyParams.pageMode, filtersArray).subscribe((e: any) => {
            let customers: any = [];
            e?.customers && _.size(e?.customers) > 0 && e?.customers.map((doc: any, index: number) => {
              let userData: CustomerEntityModel = doc?.data();
              customers.push(convertCustomerEntityToGridModel(userData, index));
            });
            if (e?.customers && e?.customers.length > 0) {
              setcustomersData(customers);

              if (e?.lastVisible) {
                setStartAfterRecord(e?.lastVisible);
              }
              if (e?.firstVisible) {
                setStartBeforeRecord(e?.firstVisible);
              }

              if(lazyParams.pageMode === 'ALL'){
                setIsNextDisabled(true);
                setIsPrevDisabled(true);
              } else {
                setIsNextDisabled(false);
                setIsPrevDisabled(false);
              }
              
              setLoading(false);
            }
            else {
              if(lazyParams.pageMode === 'ALL'){
                setcustomersData([]);
                setIsNextDisabled(true);
                setIsPrevDisabled(true);
              } else if(lazyParams.pageMode === 'PREV'){
                setIsPrevDisabled(true);
              } else if(lazyParams.pageMode === 'NEXT'){
                setIsNextDisabled(true);
              } else {
                setcustomersData([]);
                setIsNextDisabled(true);
                setIsPrevDisabled(true);
              }

              setLoading(false);
            }
          });
      util.getTimezoneFromFirebase(State.currentUser.currentUserId,selectedConnector);
    };
  }

  const connectorsSnapshot = async () => {
    setLoading(true);
    if (!_.isEmpty(selectedConnector)) {
      let failedCustomerSubscription: Subscription = getFailedImportCustomersDataSnapshotApproach1(State.currentUser.currentUserId, selectedConnector).subscribe((e: any) => { 
        let customers : any = [];
        e?.map((doc: any, index: number) => {
          const userData: CustomerEntityModel = doc?.data();
          customers.push(convertCustomerEntityToGridModel(userData, index));
        });

        setCustomerFailedData(customers);
        setLoading(false);
      });
    }
    else {
      setLoading(false);
    }
  };

  const triggerPagination = () => {
    if(activeTab === 1){
      if(_.isEmpty(customersData)){
        setLoading(true); 
        processPagination();
      } else {
        const customersDataCopy = [...customersData];
        setcustomersData(customersDataCopy);
      }
    } else {
        // if(_.isEmpty(failedSalesOrderData)){ 
            connectorsSnapshot();
        // } else {
            // setFailedSalesOrderData(failedSalesOrderData);
        // }
    }
}

  return (
    <div className="container-fluid">
      <div className="row mt-5">
        <div className="col-3">
          <Label text={"Select Connector: "} />
        </div>
        <div className="col-9">
          <SelectInput
            options={connectors}
            selectedOptionValue={selectedConnector}
            onChange={(connector: string) => {
              Dispatch({
                Type: "SET_SELECTED_CONNECTOR",
                Payload: {
                  id: connector,
                },
              });
              setSelectedConnector(connector);
            }}
          />
        </div>
      </div>
      <br />
      {myobToNetoSync && !netoToMyobSync && <img src={myobToNeto} alt="myobToNeto" height="30rem" />}
      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header={<b>Failed Customers</b>}>
          <CustomerGridErrorComponent 
            customerData={customerFailedData}
            loading={loading}
          />      
        </TabPanel>
        <TabPanel header={<b>Synced Customers</b>}>
          <CustomerGridComponent 
            customerData={customersData}
            isNextDisabled={isNextDisabled}
            isPrevDisabled={isPrevDisabled}
            lazyParams={lazyParams}
            loading={loading}
            setLoading={setLoading}
            selectedConnector={selectedConnector}
            setLazyParams={setLazyParams}
            setStartAfterRecord={setStartAfterRecord}
            setStartBeforeRecord={setStartBeforeRecord}
            setCustomerType={setCustomerType}
            customerType={customerType}
          />
        </TabPanel>
      </TabView>
    
    </div>
  );
};

export default CustomerGridContainer;