import * as React from 'react';
import { useEffect, useState, useContext, useRef } from "react";
import { AppContext } from "../../../store/AppContext";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSlash, faSync } from "@fortawesome/free-solid-svg-icons";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ValueLabel } from "../../../shared/models";
import { markAsCloseOrders, reprocessOrders } from "../services/OrderService";
import { SalesOrderGridModel } from "../models";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { actionBodyTemplate, actionBodyTemplateTwo, datePlacedBodyTemplate, emptyMessageTemplate, logoBody, statusBodyTemplate, statusItemTemplate } from "../GridBodyTemplates";
import { Subscription } from "rxjs";
import { Dropdown } from "primereact/dropdown";
import { PaginatorTemplate } from "primereact/paginator";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { InputText } from "primereact/inputtext";
import { Dialog } from 'primereact/dialog';

interface IOrderGridComponentProps {
    setLazyParams: (value: any) => void,
    lazyParams: any,
    loading: boolean,
    setLoading: (value: boolean) => void,
    setStartAfterRecord: (value: any) => void,
    setStartBeforeRecord: (value: any) => void,
    isNextDisabled: boolean,
    isPrevDisabled: boolean,
    selectedConnector: string,
    orderData: any,
}

const OrderGridComponent: React.FC<IOrderGridComponentProps> = ({ setLazyParams, lazyParams,
    loading, setLoading, setStartAfterRecord, setStartBeforeRecord, isNextDisabled, isPrevDisabled, selectedConnector, orderData }) => {

    let { State, Dispatch } = useContext(AppContext);
    const [connectors, setConnectors] = useState<Array<ValueLabel>>([{ label: "", value: "" }]);
    const [selectedOrders, setSelectedOrders] = useState<Array<SalesOrderGridModel>>();
    const [salesOrderData, setSalesOrderData] = useState<any>(orderData ?? []);
    const [filter1, setFilter1] = useState<string>('');
    const [filter2, setFilter2] = useState<string>('');
    const [filter3, setFilter3] = useState<string>('');
    const [filter4, setFilter4] = useState<string>('');
    const [selectedStatus, setSelectedStatus] = useState<any>();
    const [selectedStatusNeto, setSelectedStatusNeto] = useState<any>();
    const [selectedStatusMyob, setSelectedStatusMyob] = useState<any>();
    const [dateFilter, setDateFilter] = useState<any>();
    const [reprocessLoading, setReprocessLoading] = useState<boolean>(false);
    const [reprocessLoadingSpin, setReprocessLoadingSpin] = useState<boolean>(false);
    const [reExportLoadingSpin, setReExportLoadingSpin] = useState<boolean>(false);
    const [markCloseOrderLoadingSpin, setMarkCloseOrderLoadingSpin] = useState<boolean>(false);
    const [isCloseOrderDialogOpen, setIsCloseOrderDialogOpen] = useState<boolean>(false);
    const dt = useRef<DataTable>(null);
    const filterFields = {
        netoOrderID: "IncomingOrder.OrderID",
        myobOrderID: "OutgoingOrder.Number",
        myobCustomer: "OutgoingOrder.Customer.Name",
        netoCustomer: "IncomingOrder.Username",
        myobStatus: "OutgoingOrder.Status",
        netoStatus: "IncomingOrder.OrderStatus",
        status: "Status",
        date: "Date",
    }
    let getOrdersFromFirebaseObserver: Subscription;

    useEffect(() => {
        setSalesOrderData(orderData);
    }, [orderData])

    useEffect(() => {
        setFilter1('');
        setFilter2('');
        setFilter3('');
        setFilter4('');
        setSelectedStatusNeto('');
        setSelectedStatusMyob('');
        setSelectedStatus('');
        setDateFilter('');
        setSalesOrderData([]);
    }, [selectedConnector]);

    const onCustomFilterChange: any = (value: string, field: string, index: number) => {
        if (index === 1) {
            setFilter1(value);
            setFilter2('');
            setFilter3('');
            setFilter4('');
            setSelectedStatusNeto('');
            setSelectedStatusMyob('');
            setSelectedStatus('')
            setDateFilter('')
        } else if (index === 2) {
            setFilter1('');
            setFilter2(value);
            setFilter3('');
            setFilter4('');
            setSelectedStatusNeto('');
            setSelectedStatusMyob('');
            setSelectedStatus('')
            setDateFilter('')
        } else if (index === 3) {
            setFilter1('');
            setFilter2('');
            setFilter3(value);
            setFilter4('');
            setSelectedStatusNeto('');
            setSelectedStatusMyob('');
            setSelectedStatus('')
            setDateFilter('')
        } else if (index === 4) {
            setFilter1('');
            setFilter2('');
            setFilter3('');
            setFilter4(value);
            setSelectedStatusNeto('');
            setSelectedStatusMyob('');
            setSelectedStatus('')
            setDateFilter('')
        } else if (index === 5) {
            setFilter1('');
            setFilter2('');
            setFilter3('');
            setFilter4('');
            setSelectedStatusNeto(value);
            setSelectedStatusMyob('');
            setSelectedStatus('')
            setDateFilter('')

        } else if (index === 6) {
            setFilter1('');
            setFilter2('');
            setFilter3('');
            setFilter4('');
            setSelectedStatusNeto('');
            setSelectedStatusMyob(value);
            setSelectedStatus('')
            setDateFilter('')
        }
        else if (index === 7) {
            setFilter1('');
            setFilter2('');
            setFilter3('');
            setFilter4('');
            setSelectedStatusNeto('');
            setSelectedStatusMyob('');
            setSelectedStatus(value)
            setDateFilter('')
        }
        else if (index === 8) {
            setFilter1('');
            setFilter2('');
            setFilter3('');
            setFilter4('');
            setSelectedStatusNeto('');
            setSelectedStatusMyob('');
            setSelectedStatus('')
            setDateFilter(value)
        }

        let queryText = value.trimStart();
        queryText = queryText.trimEnd();

        if (!_.isEmpty(queryText) && queryText.length > 1) {
            // let param : any = {...lazyParams, sortField: getDbFilterField(field), filters: {[field]: { value: queryText, matchMode: "contains"}} };
            let param: any = { ...lazyParams, filters: { [field]: { value: queryText, matchMode: "contains" } } };

            if (param.pageMode !== 'ALL') {
                param.pageMode = '';
            }

            setLazyParams(param);
        }

        if (_.isEmpty(value)) {
            reset();
        }
    }

    const onPageChange = (mode: string) => {
        switch (mode) {
            case 'NEXT':
                setLazyParams({ ...lazyParams, page: lazyParams.page + 1, pageMode: 'NEXT' });
                break;
            case 'PREV':
                setLazyParams({ ...lazyParams, page: lazyParams.page - 1, pageMode: 'PREV' });
                break;
        }
    }

    const modelFilter1 = (field: string, index: number) => {
        return <InputText value={filter1} style={{ width: '100%' }} className="ui-column-filter"
            onChange={(event) => onCustomFilterChange(event.target.value, field, index)} />
    }
    const modelFilter2 = (field: string, index: number) => {
        return <InputText value={filter2} style={{ width: '100%' }} className="ui-column-filter"
            onChange={(event) => onCustomFilterChange(event.target.value, field, index)} />
    }
    const modelFilter3 = (field: string, index: number) => {
        return <InputText value={filter3} style={{ width: '100%' }} className="ui-column-filter"
            onChange={(event) => onCustomFilterChange(event.target.value, field, index)} />
    }
    const modelFilter4 = (field: string, index: number) => {
        return <InputText value={filter4} style={{ width: '100%' }} className="ui-column-filter"
            onChange={(event) => onCustomFilterChange(event.target.value, field, index)} />
    }
    const modelFilter5 = (field: string, index: number) => {
        return (
            <Dropdown style={{ width: '100%' }} inputId="netoStatusdd" id="netoStatusdd" value={selectedStatusNeto} options={statusesNeto}
                onChange={(event) => onCustomFilterChange(event.target.value, field, index)}
                placeholder="Select a Status" className="p-column-filter" />
        );
    }
    const modelFilter6 = (field: string, index: number) => {
        return (
            <Dropdown style={{ width: '100%' }} inputId="myobStatusdd" id="myobStatusdd" value={selectedStatusMyob} options={statusesMyob}
                onChange={(event) => onCustomFilterChange(event.target.value, field, index)}
                placeholder="Select a Status" className="p-column-filter" />
        );
    }
    const modelFilter7 = (field: string, index: number) => {
        return (
            <Dropdown style={{ width: '100%' }} inputId="statusdd" id="statusdd" value={selectedStatus} options={statuses}
                onChange={(event) => onCustomFilterChange(event.target.value, field, index)}
                itemTemplate={statusItemTemplate}
                placeholder="Select a Status" className="p-column-filter" />
        );
    }
    const modelFilter8 = (field: string, index: number) => {
        return (
            <div style={{ width: '100%' }}>

                <Calendar readOnlyInput value={dateFilter} onChange={(event) => onCustomFilterChange(moment(event.value?.toString())?.format('YYYY-MM-DD'), field, index)}
                    placeholder="Search" dateFormat="dd-mm-yy" className="p-column-filter" style={{ width: '150px' }} />
            </div>
        );
    }

    const statuses = ['ExportComplete', /*'NotProcessed', 'ImportFailed', 'ExportFailed',*/ 'Imported'];
    const statusesNeto = ['New', 'New Backorder', 'Backorder Approved', 'Pick', 'Pack', 'Pending Pickup', 'Pending Dispatch', 'Dispatched', 'Uncommitted', 'On Hold'];
    const statusesMyob = ['Open', 'Closed', 'Order', 'Credit'];
    const filterElementCustom1 = modelFilter1("netoOrderId", 1);
    const filterElementCustom2 = modelFilter2("myobOrderId", 2);
    const filterElementCustom3 = modelFilter3("netoCustomer", 3);
    const filterElementCustom4 = modelFilter4("myobCustomer", 4);
    const filterElementCustom5 = modelFilter5("netoOrderStatus", 5);
    const filterElementCustom6 = modelFilter6("myobOrderStatus", 6);
    const filterElementCustom7 = modelFilter7("status.orderStatus", 7);
    const filterElementCustom8 = modelFilter8("datePlaced", 8);

    const reset = (isCompleteReset?: boolean) => {
        dt.current?.reset();
        setFilter1('');
        setFilter2('');
        setFilter3('');
        setFilter4('');
        setSelectedStatusNeto('');
        setSelectedStatusMyob('');
        setSelectedStatus('');
        setDateFilter('');
        if (isCompleteReset && isCompleteReset === true) {
            setLazyParams({
                first: 0,
                rows: 5,
                page: 1,
                sortField: "Date",
                sortOrder: -1,
                filters: undefined,
                pageMode: '',
                pageSize: 50,
            });
        } else {
            let params = {
                ...lazyParams,
                first: 0,
                rows: 5,
                page: 1,
                sortField: "Date",
                sortOrder: -1,
                filters: undefined,
            };

            if (params.pageMode !== 'ALL') {
                params.pageMode = '';
            }

            setLazyParams(params);
        }
    }

    const template2: PaginatorTemplate = {
        'FirstPageLink': '',
        'LastPageLink': '',
        'NextPageLink': (options) => {
            return <Button label="Next" disabled={isNextDisabled} onClick={() => onPageChange('NEXT')} className="p-button-raised p-button-info" />
        },
        'PageLinks': '',
        'PrevPageLink': (options) => {
            return <Button label="Prev" disabled={isPrevDisabled} onClick={() => onPageChange('PREV')} className="p-button-raised p-button-info" />
        },
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        'RowsPerPageDropdown': (options: any) => {
            const dropdownOptions = [
                { label: 50, value: 50 },
                { label: 100, value: 100 },
                { label: 200, value: 200 },
                { label: 300, value: 300 },
                { label: 400, value: 400 },
                { label: 500, value: 500 },
                { label: 1000, value: 1000 },
                { label: 'All', value: 1 }
            ];
            return (
                <>
                    <span className="p-mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Page Size: </span>
                    <Dropdown value={lazyParams.pageSize} options={dropdownOptions} onChange={(e) => onPageRowsSizeChanged(e.value)} appendTo={document.body} />
                </>
            );
        },
        'CurrentPageReport': (options: any) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                </span>
            )
        }
    };

    const markAsClose = async () => {
        setIsCloseOrderDialogOpen(false);
        setReprocessLoading(true);
        setMarkCloseOrderLoadingSpin(true)
        // const reprocessOrderRequestResult = await markAsCloseOrders(State?.currentUser?.isAdminUser && localStorage.SelectedIdByAdmin ? localStorage.SelectedIdByAdmin : State.currentUser.currentUserId, selectedConnector, selectedOrders);
        const closeOrderRequestResult = await markAsCloseOrders(State?.currentUser?.isAdminUser && localStorage.SelectedIdByAdmin ? localStorage.SelectedIdByAdmin : State.currentUser.currentUserId, selectedConnector, selectedOrders);
        if (closeOrderRequestResult) {
            Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: true, message: "Orders closed in the connector log" } });
        }
        else {
            Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: false, message: "Some Error occurred! while force close!" } });
        }
        setReprocessLoading(false);
        setReprocessLoadingSpin(false);
        setReExportLoadingSpin(false);
        setMarkCloseOrderLoadingSpin(false)
    }

    const header = (
        <div className="d-flex justify-content-between">
            <h5 className="text-left">Sale Orders</h5>
            <h5>Selected Orders: {selectedOrders?.length}</h5>
            <div className="col-6 text-right">
                <button
                    type="button"
                    className="btn btn-outline-secondary mr-1"
                    disabled={!selectedOrders || !selectedOrders.length || reprocessLoading}
                    onClick={() => reprocessing(true)}
                >
                    <div className="fa-layers ml-2">
                        <FontAwesomeIcon className="mr-2" icon={faSync} spin={reExportLoadingSpin}
                        />
                    </div>
                    ReExport
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    disabled={!selectedOrders || !selectedOrders.length || reprocessLoading}
                    onClick={() => reprocessing()}
                >
                    <div className="fa-layers ml-2">
                        <FontAwesomeIcon className="mr-2" icon={faSync} spin={reprocessLoadingSpin}
                        />
                    </div>
                    ReProcess
                </button>

                {!_.isEmpty(selectedConnector) &&
                    <button
                        type="button"
                        className="btn btn-outline-secondary ml-1 mr-1"
                        disabled={!selectedOrders || !selectedOrders.length || markCloseOrderLoadingSpin}

                        // disabled={markCloseOrderLoadingSpin}
                        onClick={() => setIsCloseOrderDialogOpen(true)}
                    >
                        <div className="fa-layers ml-2">
                            <FontAwesomeIcon className="mr-2" icon={faSync} spin={markCloseOrderLoadingSpin}
                            />
                        </div>
                        {/* {`Mark ${!selectedOrders || !selectedOrders.length ? "all" : "selected"} orders as close`} */}
                        {`Force Closed`}

                    </button>
                }

                <button
                    type="button"
                    className="btn btn-outline-secondary ml-2"
                    onClick={() => reset(true)}>
                    <div className="fa-layers ml-2">
                        <FontAwesomeIcon className="mr-2" icon={faFilter} />
                        <FontAwesomeIcon className="mr-2" icon={faSlash} />
                    </div>
                    Clear
                </button>
            </div>
        </div>
    );

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header=""></Column>
                <Column header=""></Column>
                <Column header="OrderID" colSpan={2} />
                <Column header="Customer" colSpan={2} />
                <Column header="Order Status" colSpan={2} />
                <Column header="Processed Status" />
                <Column header="Processed Date" />
                <Column header="Action"></Column>
            </Row>
            <Row>
                <Column headerStyle={{ width: '3em' }} />
                <Column headerStyle={{ width: '3em' }} />
                <Column header="Maropost" filter filterMatchMode="contains" filterElement={filterElementCustom1} filterPlaceholder="Search" field="netoOrderId" body={(data) => actionBodyTemplateTwo(selectedConnector, data)} />
                <Column header="Myob" filter filterMatchMode="contains" filterElement={filterElementCustom2} filterPlaceholder="Search" field="myobOrderId" />
                <Column header="Maropost" filter filterMatchMode="contains" filterElement={filterElementCustom3} filterPlaceholder="Search" field="netoCustomer" />
                <Column header="Myob" filter filterMatchMode="contains" filterElement={filterElementCustom4} filterPlaceholder="Search" field="myobCustomer" />
                <Column header="Maropost" filter filterMatchMode="contains" filterElement={filterElementCustom5} filterPlaceholder="Search" field="netoOrderStatus" />
                <Column header="Myob" filter filterMatchMode="contains" filterElement={filterElementCustom6} filterPlaceholder="Search" field="myobOrderStatus" />
                <Column header="Status" filter filterMatchMode="contains" filterPlaceholder="Search" sortField="status.orderStatus" field="status.orderStatus" filterElement={filterElementCustom7} />
                <Column header="Date" filter filterMatchMode="contains" filterPlaceholder="Search by Processed Date" sortable filterField="datePlaced" field="datePlacedForFilter"
                    filterElement={filterElementCustom8} />
                <Column />
            </Row>
        </ColumnGroup>
    );

    const reprocessing = async (isForced?: boolean) => {
        setReprocessLoading(true);
        isForced ? setReExportLoadingSpin(true) : setReprocessLoadingSpin(true);
        const reprocessOrderRequestResult = await reprocessOrders(State.currentUser.currentUserId, selectedConnector, selectedOrders, isForced);
        if (reprocessOrderRequestResult) {
            Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: true, message: "Orders exported from Maropost And MYOB in progress" } });
        }
        else {
            Dispatch({ Type: "SET_REQUEST_STATUS", Payload: { isSucceed: false, message: "Some Error occurred! while Re-processing!" } });
        }
        setReprocessLoading(false);
        setReprocessLoadingSpin(false);
        setReExportLoadingSpin(false);
    }

    const onPageRowsSizeChanged = (size: number) => {
        setStartAfterRecord({});
        setStartBeforeRecord({});
        let params = { ...lazyParams, pageSize: size };

        if (size === 1) {
            params.pageMode = 'ALL';
        } else {
            params.pageMode = '';
        }

        setLazyParams(params);
    }

    const onSort = (event: any) => {
        let SortOrder = -1;
        if (lazyParams?.sortOrder === -1) {
            SortOrder = 1;
        }
        let _lazyParams = { ...lazyParams, sortOrder: SortOrder };

        if (event.sortField === 'datePlacedForFilter') {
            _lazyParams = { ..._lazyParams, sortField: filterFields.date }
        }

        if (_lazyParams.filters) {
            const keys = Object.keys((_lazyParams.filters) as any) ?? [];

            switch (keys[0]) {
                case 'datePlaced':
                    break;
                case 'netoOrderStatus':
                    break;
                case 'myobOrderStatus':
                    break;
                case 'status.orderStatus':
                    break;
                default:
                    _lazyParams.filters = undefined;
                    break;
            }

        }

        if (_lazyParams.pageMode !== 'ALL') {
            _lazyParams.pageMode = '';
        }

        setFilter1('');
        setFilter2('');
        setFilter3('');
        setFilter4('');
        setLazyParams(_lazyParams);
    }

    const getDataTableSortField = (field: string) => {
        if (field === filterFields.date) {
            return 'datePlacedForFilter';
        }
        else if (field === filterFields.netoOrderID) {
            return 'netoOrderId';
        } else if (field === filterFields.myobOrderID) {
            return 'myobOrderId';
        } else if (field === filterFields.netoCustomer) {
            return 'netoCustomer';
        } else if (field === filterFields.myobCustomer) {
            return 'myobCustomer';
        } else if (field === filterFields.netoStatus) {
            return 'netoOrderStatus';
        } else if (field === filterFields.myobStatus) {
            return 'myobOrderStatus';
        } else if (field === filterFields.status) {
            return 'status.orderStatus';
        } else if (field === filterFields.status) {
            return 'status.orderStatus';
        }

        return 'datePlacedForFilter';
    }

    const getDbFilterField = (field: string) => {
        if (field === 'datePlaced') {
            return filterFields.date;
        }
        else if (field === 'netoOrderId') {
            return filterFields.netoOrderID;
        } else if (field === 'myobOrderId') {
            return filterFields.myobOrderID;
        } else if (field === 'netoCustomer') {
            return filterFields.netoCustomer;
        } else if (field === 'myobCustomer') {
            return filterFields.myobCustomer;
        } else if (field === 'netoOrderStatus') {
            return filterFields.netoStatus;
        } else if (field === 'myobOrderStatus') {
            return filterFields.myobStatus;
        } else if (field === 'status.orderStatus') {
            return filterFields.status;
        } else if (field === 'status.orderStatus') {
            return filterFields.status;
        }

        return '';
    }

    return (
        <div className="container-fluid">
            <DataTable
                lazy
                paginator
                first={lazyParams.first}
                rows={lazyParams.pageSize === 1 ? salesOrderData?.length : lazyParams.pageSize}
                // onPage={onPage}
                onSort={onSort} sortField={getDataTableSortField(lazyParams.sortField)} sortOrder={lazyParams.sortOrder === 1 ? 1 : -1}
                filters={lazyParams.filters} loading={loading} removableSort
                ref={dt}
                headerColumnGroup={headerGroup}
                value={salesOrderData}
                header={header}
                className="p-mt-6"
                dataKey="number"
                rowHover
                autoLayout
                emptyMessage={emptyMessageTemplate}
                paginatorTemplate={template2}
                selectionMode="multiple"
                selection={selectedOrders}
                onSelectionChange={(e: any) => setSelectedOrders(e.value)}
                paginatorClassName="p-jc-end"
                scrollable scrollHeight="520px"
                rowClassName={(rowData: any) => {
                    return { 'highlight': rowData.new };
                }}>

                <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
                <Column header="" body={() => logoBody()} />
                <Column field="netoOrderId" body={(data) => actionBodyTemplateTwo(selectedConnector, data)} />
                <Column field="myobOrderId" />
                <Column field="netoCustomer" />
                <Column field="myobCustomer" />
                <Column field="netoOrderStatus" />
                <Column field="myobOrderStatus" />
                <Column field="status.orderStatus" body={statusBodyTemplate} filterElement={filterElementCustom7} />
                <Column filterField="datePlaced" field="datePlacedForFilter" body={datePlacedBodyTemplate} />
                <Column body={(data) => actionBodyTemplate(selectedConnector, data)} />
            </DataTable>


            <Dialog header="Force close orders" visible={isCloseOrderDialogOpen} onHide={() => { }} closable={false} draggable={false} breakpoints={{ '960px': '75vw' }} style={{ width: '35vw' }} >
                <p className="">It will mark the orders "Closed" in the connector log.
                    Please ensure to sync payments before running this process
                    The closed orders will not be updated in MYOB
                </p>
                {/* <h6>Do you wish to continue ?</h6> */}
                <div className="d-flex justify-content-between">
                    <h6 className="px-5 mt-3">Do you wish to continue ?</h6>
                    <div className="">
                        <Button label="Yes" className="px-5 m-1 p-button-raised p-button-info" disabled={markCloseOrderLoadingSpin} onClick={() => markAsClose()} />
                        <Button label="No" className="px-5 m-1 p-button-raised p-button-danger" onClick={() => setIsCloseOrderDialogOpen(false)} />
                    </div>
                </div>

            </Dialog>
        </div>
    );
}

export default OrderGridComponent
